import React from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  defaultHandleOpenJujoFileFromServer,
  jujoHtmlDecoder,
  mapStateToProps,
  translate,
} from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import JujoLoading from '../../../../../../src/components/loading'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import { httpPost } from '../../../../../../src/services/apiService'
import OrderNotesComponent from './_parts/order_notes'

const CustomerSummary = loadable(() =>
  import('../../customers_group/customer_summary')
)

const classNames = require('classnames')

export class OrderDetailsComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  performActionOnDidMount = async () => {
    const { component_data } = this.state
    const { data } = this.props
    const { order_id } = data

    const cloned_data = cloneObj(component_data)
    cloned_data.loading = true
    cloned_data.show_addresses = false
    await this.updateData(cloned_data)

    const { environment, authentication } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-order-full-details',
      pDefValue: 'get-order-full-details',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
      order_id,
    })

    const { status } = response

    let order_details = {}
    if (status === 200 || status === 201) {
      const { details } = response.data
      order_details = details[order_id]
    }

    cloned_data.loading = false
    cloned_data.order_details = order_details
    await this.updateData(cloned_data)
  }

  renderTCMOptions = options => {
    const html = []

    if (options) {
      for (let i = 0; i !== options.length; i += 1) {
        const opt = options[i]
        const { section, section_label, value } = opt
        html.push(
          <div
            key={section}
            className={classNames(
              'd-flex flex-column ms-2 ms-md-4 mb-3 border-bottom'
            )}
          >
            <div className={classNames('me-1 fs-7')}>{section_label}</div>
            <div className={classNames('fw-bold pb-2')}>{value}</div>
          </div>
        )
      }
    }
    return html
  }

  renderEstimateProductOptions = options => {
    const html = []
    for (let i = 0; i !== options.length; i += 1) {
      const opt = options[i]
      const { key, title, value } = opt
      html.push(
        <div
          key={key}
          className={classNames(
            'd-flex flex-column ms-2 ms-md-4 mb-3 border-bottom'
          )}
        >
          {title && <div className={classNames('me-1 fs-7')}>{title}</div>}
          <div className={classNames('fw-bold pb-2')}>{value}</div>
        </div>
      )
    }
    return html
  }

  renderCouponDetails = () => {
    const { component_data } = this.state
    const { order_details } = component_data

    const { coupon_list } = order_details
    const coupons_count = coupon_list.length

    const html = []

    html.push(
      <div key="coupon_block">
        <div className={classNames('fw-bold fs-5 fc-1')}>
          {translate('coupon_details')}
        </div>
        {coupons_count === 0 && <div>{translate('no_coupon_message')}</div>}

        {coupon_list.map(coupon => (
          <div
            key={coupon.code}
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between align-items-md-center'
            )}
          >
            <div className={classNames('fw-bold fst-italic')}>
              {coupon.code}
            </div>
            <div className={classNames('fs-4 fw-bold fc-red-500')}>
              {`- € ${coupon.amount}`}
            </div>
          </div>
        ))}
      </div>
    )

    return html
  }

  renderShippingDetails = () => {
    const { component_data } = this.state
    const { order_details } = component_data

    const { shipping, shipping_cost } = order_details

    const html = []

    html.push(
      <div key="shipping_block">
        <div className={classNames('fw-bold fs-5 fc-1')}>
          {translate('shipping_details')}
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-md-center'
          )}
        >
          <div>{shipping}</div>
          <div className={classNames('fs-4 fw-bold')}>
            {`€ ${shipping_cost}`}
          </div>
        </div>
      </div>
    )

    return html
  }

  renderPaymentDetails = () => {
    const { component_data } = this.state
    const { order_details } = component_data

    const {
      payment_method_title,
      paid_date_formatted,
      stripe_fee,
      paypal_fee,
    } = order_details

    const parsed_stripe_fee = parseFloat(stripe_fee).toFixed(2)
    const parsed_paypal_fee = parseFloat(paypal_fee).toFixed(2)

    const paid_date_string = paid_date_formatted || translate('not_paid_yet')

    const payment_fee = parseFloat(
      parsed_stripe_fee + parsed_paypal_fee
    ).toFixed(2)

    const html = []

    html.push(
      <div key="payment_block">
        <div className={classNames('fw-bold fs-5 fc-1')}>
          {translate('payment_details')}
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-md-center'
          )}
        >
          <div>{translate('paid_date')}</div>
          <div
            className={classNames(
              'fw-bold',
              paid_date_formatted === '' ? 'fc-red-500' : 'fc-green-500'
            )}
          >
            {paid_date_string}
          </div>
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-md-center'
          )}
        >
          <div>{translate('payment_method')}</div>
          <div>{payment_method_title}</div>
        </div>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-md-center'
          )}
        >
          <div>{translate('payment_fee')}</div>
          <div
            className={classNames('fc-red-500 fw-bold fs-4')}
          >{`- € ${payment_fee}`}</div>
        </div>
      </div>
    )

    return html
  }

  handleOpenProductLink = url => {
    if (typeof window !== 'undefined') {
      window.open(url, '_blank').focus()
    }
  }

  productRowTemplate = (key, product_name, qty, price, url) => {
    const html = []

    html.push(
      <div
        key={`product_row_${key}`}
        className={classNames(
          'd-flex flex-column flex-md-row mb-1 align-items-center'
        )}
      >
        <div
          className={classNames(
            'd-flex flex-column flex-sm-row fs-5 w-100 justify-content-between'
          )}
        >
          <div className={classNames('d-flex align-items-center')}>
            <div>
              <span className={classNames('fw-bold me-2')}>{product_name}</span>
              <span
                className={classNames('fs-7 fw-bold fc-1')}
              >{`x${qty}`}</span>
            </div>
            {url && (
              <div
                className={classNames(
                  'background-image theme-svg link-icon ms-2'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                tabIndex={0}
                label="link"
                onClick={() => {
                  this.handleOpenProductLink(url)
                }}
                onKeyPress={() => {
                  this.handleOpenProductLink(url)
                }}
              />
            )}
          </div>
          <div
            className={classNames('fs-4 d-flex justify-content-end fw-bold')}
          >{`€ ${price}`}</div>
        </div>
      </div>
    )

    return html
  }

  handleDownloadFile = url => {
    if (typeof window !== 'undefined') {
      window.open(url)
    }
  }

  renderCustomProductAdditionalData = product_data => {
    const { authentication } = this.props
    const { access_token } = authentication

    const { custom_product, notes } = product_data
    const { attachments } = custom_product

    const html = []

    if (
      (!attachments || attachments.length === 0) &&
      (!notes || notes.length === 0)
    )
      return html

    if (attachments && attachments.length > 0) {
      html.push(
        <div
          key="custom_product_attachments"
          className={classNames(
            'd-flex flex-column ms-2 ms-md-4 mb-3 border-bottom pb-2'
          )}
        >
          <div className={classNames('fw-bold pb-2')}>
            {translate('attachments')}
          </div>
          {attachments.map((attach, i) => {
            const { name, url } = attach
            const fileUrl = `${url}?access_token=${access_token}`

            return (
              <div
                key={`attachment_${i}`}
                className={classNames(
                  'd-flex flex-column-reverse flex-md-row mb-2 mb-md-1'
                )}
              >
                <div className={classNames('d-flex')}>
                  <div
                    className={classNames(
                      'background-image download-icon theme-svg mx-1'
                    )}
                    style={{ width: 20, height: 20 }}
                    role="button"
                    label="open file"
                    tabIndex={0}
                    onClick={() => {
                      this.handleDownloadFile(fileUrl)
                    }}
                    onKeyPress={() => {
                      this.handleDownloadFile(fileUrl)
                    }}
                  />
                  <div
                    className={classNames(
                      'background-image eye-icon theme-svg mx-1'
                    )}
                    style={{ width: 20, height: 20 }}
                    role="button"
                    label="open file"
                    tabIndex={0}
                    onClick={() => {
                      defaultHandleOpenJujoFileFromServer(this, fileUrl)
                    }}
                    onKeyPress={() => {
                      defaultHandleOpenJujoFileFromServer(this, fileUrl)
                    }}
                  />
                </div>
                <div className={classNames('ms-1 fs-7 ffamily-secondary')}>
                  {name}
                </div>
              </div>
            )
          })}
        </div>
      )
    }

    if (notes && notes.length > 0) {
      html.push(
        <div
          key="custom_product_notes"
          className={classNames(
            'd-flex flex-column ms-2 ms-md-4 mb-3 border-bottom pb-2'
          )}
        >
          <div className={classNames('fw-bold pb-2')}>{translate('notes')}</div>
          <div>{notes}</div>
        </div>
      )
    }

    return html
  }

  renderOrderDetails = () => {
    const { component_data } = this.state
    const { order_details } = component_data

    const html = []
    const {
      from_estimate,
      tmc_data,
      sample_details,
      estimate_data,
      order_items_price_list,
      products_url_list,
    } = order_details

    if (from_estimate === false) {
      for (let i = 0; i !== tmc_data.length; i += 1) {
        const prod = tmc_data[i]
        const { order_item_id, order_item_name, qty, options } = prod

        const product_price = parseFloat(
          order_items_price_list[order_item_id]
        ).toFixed(2)

        const filtered_product_url = products_url_list.filter(
          x => x.order_item_id === order_item_id
        )
        const product_url =
          filtered_product_url.length > 0
            ? jujoHtmlDecoder(filtered_product_url[0].product_url)
            : null


        const filtered_sample_details = sample_details?.filter(x => x.order_item_id === order_item_id) || [];
        const samples = filtered_sample_details[0]?.samples || "";
        const samplesArray = samples.split(",");

        html.push(
          <div key={order_item_id} className={classNames('mb-2')}>
            {this.productRowTemplate(
              order_item_id,
              order_item_name,
              qty,
              product_price,
              product_url
            )}
            <div>{this.renderTCMOptions(options)}</div>
            <div>
              {samplesArray.map((sample, index) => (
                <div key={index}>{sample.trim()}</div>
              ))}
            </div>
          </div>
        )
      }
    } else {
      const { calculations, products } = estimate_data
      const { list } = calculations
      for (let i = 0; i !== list.length; i += 1) {
        const prod = list[i]
        const {
          key,
          product_name,
          quantity,
          options,
          total_product_price_with_options,
        } = prod

        const product_full_details = products[key]
        const { selected_product } = product_full_details
        const { ID: product_id } = selected_product

        const filtered_product_url = products_url_list.filter(
          x => x.product_id === product_id
        )
        const product_url =
          filtered_product_url.length > 0
            ? jujoHtmlDecoder(filtered_product_url[0].product_url)
            : null

        html.push(
          <div key={key} className={classNames('mb-2')}>
            {this.productRowTemplate(
              key,
              product_name,
              quantity,
              total_product_price_with_options,
              product_url
            )}
            {this.renderCustomProductAdditionalData(product_full_details)}
            <div>{this.renderEstimateProductOptions(options)}</div>
          </div>
        )
      }
    }
    return html
  }

  renderBody = () => {
    this.fieldRef = React.createRef()

    const { component_data } = this.state

    const { loading, order_details } = component_data
    const {
      order_id,
      order_status,
      order_total,
      event_date_formatted,
      customer,
    } = order_details

    const { user_id } = customer

    const html = []

    html.push(
      <div key="order_details_component">
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>
            <div
              className={classNames(
                'd-flex flex-column flex-md-row fs-5 fw-bold mb-2'
              )}
            >
              <div className={classNames('fc-1 me-md-2')}>
                {`${translate('order_details')} ${order_id}`}
              </div>
              <div>{translate(order_status)}</div>
            </div>

            <div
              className={classNames(
                'mb-3 fs-7 pb-1 border-bottom border-color-1'
              )}
            >
              <CustomerSummary customer_id={user_id} />

              <div className={classNames('fw-bold')}>
                <span className={classNames('me-1')}>
                  {`${translate('event_date')}:`}
                </span>
                <span className={classNames('fc-1 fs-6 ms-1')}>
                  {event_date_formatted}
                </span>
              </div>
            </div>
            <div
              className={classNames(
                'd-flex flex-column flex-md-row fs-4 justify-content-between align-items-center mb-4 border-bottom border-color-1 pb-3'
              )}
            >
              <div className={classNames('fw-bold fc-1')}>
                {translate('order_total')}
              </div>
              <div
                className={classNames(
                  'd-flex justify-content-end fw-bold fc-green-500 fs-3'
                )}
              >{`€ ${order_total}`}</div>
            </div>
            <div>{this.renderOrderDetails()}</div>
            <div
              className={classNames(
                'd-flex flex-column mt-4 border-bottom border-color-1 pb-2'
              )}
            >
              {this.renderCouponDetails()}
            </div>
            <div
              className={classNames(
                'd-flex flex-column mt-4 border-bottom border-color-1 pb-2'
              )}
            >
              {this.renderShippingDetails()}
            </div>
            <div
              className={classNames(
                'd-flex flex-column mt-4 border-bottom border-color-1 pb-2'
              )}
            >
              {this.renderPaymentDetails()}
            </div>
            <div className={classNames('d-flex flex-column mt-4')}>
              <div className={classNames('fw-bold fs-5 fc-1')}>
                {translate('notes')}
              </div>
              <OrderNotesComponent order_data={order_details} />
            </div>
          </div>
        )}
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(OrderDetailsComponent)
