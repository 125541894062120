import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class TextField extends GenericField {
  processOnChange = e => {
    const { field } = this.props
    const { uppercase, lowercase } = field
    let { value } = e.target
    value = uppercase === true ? value.toString().toUpperCase() : value
    value = lowercase === true ? value.toString().toLowerCase() : value
    this.setState({ fieldValue: value })
  }

  processOnBlur = e => {
    const { field } = this.props
    const { uppercase, lowercase } = field
    let { value } = e.target
    value = uppercase === true ? value.toString().toUpperCase() : value
    value = lowercase === true ? value.toString().toLowerCase() : value
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const {
      name,
      alias,
      disabled,
      translateRenderField,
      uppercase,
      lowercase,
      inputMode,
    } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const disabled_field = parseBoolean(disabled, 'false')

    let text_transform = uppercase ? 'uppercase' : ''
    text_transform = lowercase ? 'lowercase' : text_transform

    const inputModeParameter = inputMode || 'text'

    const html = []
    if (type === requestTypesMap.view) {
      html.push(
        <div key={uuid()}>
          {translateRenderField ? texts[locale][fieldValue] : fieldValue}
        </div>
      )
    } else {
      html.push(
        <input
          key={uuid()}
          type="text"
          name={alias || name}
          style={{ textTransform: text_transform }}
          defaultValue={
            translateRenderField ? texts[locale][fieldValue] : fieldValue
          }
          disabled={disabled_field}
          inputMode={inputModeParameter}
          onPaste={e => {
            if (field.paste_disabled === true) {
              e.preventDefault()
            }
            return false
          }}
          onChange={e => {
            this.processOnChange(e)
          }}
          onBlur={e => {
            this.processOnBlur(e)
          }}
        />
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(TextField)
