import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { getSelectedSvgNodes } from '../../../../../utils/svg/interactive_text'
import { mqMobileOrTablet } from '../../../../../utils/mediaquery'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGFontFamilyManager({ element_key, curr_nidx, fonts }) {
  const [isMobileOrTablet] = useState(mqMobileOrTablet())
  const [text_element, setTextElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const t_element = g_element.querySelector('text')
    setTextElement(t_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  const removeFontVariant = () => {
    const selected_nodes = getSelectedSvgNodes(element_key)
    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.style['font-family'] = ''
    }
  }

  const createFontFace = async (fv_id, variant) => {
    const svg_obj = text_element.closest('svg')

    const existing_style = document.getElementById(fv_id)
    if (existing_style) return

    const style_obj = document.createElement('style')
    style_obj.setAttribute('id', fv_id)

    const { src } = variant
    const fface_code = `
      @font-face {
        font-family:'${fv_id}';
        src: url('${src}');
      }`

    const styleBody = document.createTextNode(fface_code)
    style_obj.appendChild(styleBody)
    svg_obj.appendChild(style_obj)
  }

  const setFontVariant = async (fid, variant) => {
    const selected_nodes = getSelectedSvgNodes(element_key)

    const font_id = `font_${fid}`

    await createFontFace(font_id, variant)

    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.style['font-family'] = `${font_id}`
    }
  }

  const handleFontChanged = e => {
    const { value: selected_family_id } = e.target

    const selected_family = fonts.filter(
      x => x.id === parseInt(selected_family_id, 10)
    )[0]

    if (selected_family) {
      const { data: variant_list } = selected_family
      const default_variant = variant_list[0]

      setFontVariant(selected_family_id, default_variant)
    } else {
      removeFontVariant()
    }
  }

  const getDefaultValue = () => {
    const selected_node =
      text_element.querySelectorAll('tspan>tspan')[curr_nidx]

    if (selected_node) {
      const ffamily = selected_node.style['font-family']
      if (ffamily) {
        const parsed_ffamily = ffamily.split('_')[1]
        return parsed_ffamily
      }
    }
    return 0
  }

  const renderEditor = () => {
    const html = []

    if (isMobileOrTablet) {
      html.push(
        <div className={classNames('py-4')} key="m_font_selector">
          <div className={classNames('bg-white p-2 mb-2 border')}>
            <select
              key={curr_nidx}
              onChange={e => handleFontChanged(e)}
              defaultValue={getDefaultValue()}
            >
              <option value={0}>{translate('select')}</option>
              {fonts.map((font, idx) => {
                const { id, name } = font
                return (
                  <option key={`font_${idx}`} value={id}>
                    {name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      )
    } else {
      html.push(
        <div className={classNames('')} key="font_selector">
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('choose_the_font')}</div>
          </div>
          <div className={classNames('bg-white p-2 mb-2 border')}>
            <select
              key={curr_nidx}
              onChange={e => handleFontChanged(e)}
              defaultValue={getDefaultValue()}
            >
              <option value={0}>{translate('select')}</option>
              {fonts.map((font, idx) => {
                const { id, name } = font
                return (
                  <option key={`font_${idx}`} value={id}>
                    {name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      )
    }

    return html
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && renderEditor()}
    </>
  )
}

export default JujoSVGFontFamilyManager
