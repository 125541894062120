import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, getCustomizationParam } from '../../../../utils'

export class JujoBlogSingleArticleView extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderLastArticles = last_articles => {
    const { environment } = this.props
    const { locale } = environment
    const html = []
    for (let i = 0; i < last_articles.data.length; i += 1) {
      const last_article = last_articles.data[i]
      const { title, url } = last_article
      html.push(
        <a
          key={i}
          href={url[locale] || ''}
          alt={title[locale] || ''}
          className={classNames('text-decoration-none fc-5')}
        >
          <div
            className={classNames(
              'd-flex align-items-start border-bottom border-1 py-3'
            )}
          >
            <div
              className={classNames(
                ' blog-small-filled-arrow theme-svg-4 background-image me-2'
              )}
              label="logo"
              style={{
                height: '10px',
                width: '10px',
                marginTop: '6px',
              }}
            />

            <div className={classNames(' ')}>{Parser(title[locale] || '')}</div>
          </div>
        </a>
      )
    }
    return html
  }

  renderCategories = categories => {
    const html = []
    for (let i = 0; i < categories.length; i += 1) {
      const category = categories[i]
      const {
        category_name_translated,
        category_customizations,
        category_url_translated,
      } = category
      const fc = getCustomizationParam(category_customizations, 'fc')
      const theme_svg = getCustomizationParam(
        category_customizations,
        'theme-svg'
      )
      const border_color = getCustomizationParam(
        category_customizations,
        'border-color'
      )
      html.push(
        <a
          key={i}
          href={category_url_translated}
          alt={category_name_translated}
          className={classNames('text-decoration-none')}
        >
          <div
            className={classNames(
              'd-flex align-items-start border-bottom border-1 py-3',
              border_color
            )}
          >
            <div
              className={classNames(
                ' blog-small-filled-arrow background-image me-2',
                theme_svg
              )}
              label="logo"
              style={{
                height: '10px',
                width: '10px',
                marginTop: '6px',
              }}
            />

            <div className={classNames(fc)}>
              {Parser(category_name_translated || '')}
            </div>
          </div>
        </a>
      )
    }
    return html
  }

  renderArticle = article => {
    const { specialization, environment } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    const { title, subtitle, body, cover_image, prev_url, next_url } = article
    const cover_url = `${process.env.apiServerUrl}media/file?slug=${cover_image[locale]}`

    const prev_url_translated = (prev_url && prev_url[locale]) || ''
    const next_url_translated = (next_url && next_url[locale]) || ''

    html.push(
      <div className={classNames('d-flex flex-column')}>
        <div>
          <h1 className={classNames('fs-2 fc-5 fw-bold')}>
            {Parser(title[locale] || '')}
          </h1>
        </div>
        <div>
          <h2 className={classNames('fs-5 fc-5 mt-3')}>
            {Parser(subtitle[locale] || '')}
          </h2>
        </div>
        <div className={classNames('my-4')}>
          <img
            src={cover_url}
            className={classNames('img-fluid')}
            alt={title[locale]}
            title={title[locale]}
          />
        </div>
        <div className={classNames('my-4')}>{Parser(body[locale] || '')}</div>
        <div className={classNames('w-100 row my-5')}>
          <div className={classNames('col-6')}>
            {prev_url_translated !== '' && (
              <div key="prev_article">
                <a
                  href={prev_url_translated}
                  className={classNames('text-decoration-none fc-1')}
                >
                  <div className={classNames('d-flex align-items-center')}>
                    <div
                      className={classNames(
                        'blog-left-arrow theme-svg background-image me-2'
                      )}
                      label="logo"
                      style={{ height: '30px', width: '30px' }}
                    />
                    <div>
                      {texts[locale].prev_article || 'Prossimo articolo'}
                    </div>
                  </div>
                </a>
              </div>
            )}
          </div>
          {next_url_translated !== '' && (
            <div className={classNames('col-6')}>
              {next_url && next_url[locale] !== '' && (
                <div key="next_article">
                  <a
                    href={next_url_translated}
                    className={classNames('text-decoration-none fc-1')}
                  >
                    <div
                      className={classNames(
                        'd-flex align-items-center float-end'
                      )}
                    >
                      <div>
                        {texts[locale].next_article || 'Prossimo articolo'}
                      </div>
                      <div
                        className={classNames(
                          'blog-right-arrow theme-svg background-image ms-2'
                        )}
                        label="logo"
                        style={{ height: '30px', width: '30px' }}
                      />
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
    return html
  }

  render() {
    const { specialization, environment, session } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { session_data } = session
    const { blog_data } = session_data
    const { article, last_articles, categories } = blog_data

    return (
      <div className={classNames('container py-5')}>
        <div className={classNames('row')}>
          <div className={classNames('col-12 col-md-8')}>
            {this.renderArticle(article)}
          </div>
          <div className={classNames('col-0 col-md-1')} />
          <div className={classNames('col-12 col-md-3')}>
            <div className={classNames('d-flex flex-column')}>
              <div className={classNames('fc-1 fs-4 mb-2')}>
                {texts[locale].recent_articles}
              </div>
              {this.renderLastArticles(last_articles)}
              <div className={classNames('fc-1 fs-4 mt-5 mb-2')}>
                {texts[locale].categories}
              </div>
              {this.renderCategories(categories)}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoBlogSingleArticleView)
