import conditionCheckTypesMap from '../enums/conditionCheckTypesMap'
import sourcesMap from '../enums/sourcesMap'
import { getQsValue } from '../utils'

export function encodeEndPointUrl(ep) {
  //  https://secure.n-able.com/webhelp/NC_9-1-0_SO_en/Content/SA_docs/API_Level_Integration/API_Integration_URLEncoding.html

  const encodedCharacters = [
    { char: '$', value: '24' },
    { char: '&', value: '26' },
    { char: '+', value: '2B' },
    { char: ',', value: '2C' },
    { char: '/', value: '2F' },
    { char: ':', value: '3A' },
    { char: ';', value: '3B' },
    { char: '=', value: '3D' },
    { char: '?', value: '3F' },
    { char: '@', value: '40' },
  ]

  let encoded = encodeURI(ep)
  for (let i = 0; i !== encodedCharacters.length; i += 1) {
    const encChar = encodedCharacters[i]
    const { char, value } = encChar

    encoded = encoded.replace(new RegExp(`regex\\${char}`, 'g'), value)
  }

  return encoded
}

export function verifyFilterCondition(filter, authentication, environment) {
  const { conditions } = filter
  if (!conditions || conditions.length === 0) {
    return true
  }

  let errors = false

  for (let i = 0; i !== conditions.length; i += 1) {
    const cond = conditions[i]
    const { source, relatedField, checkType, check } = cond

    if (source === sourcesMap.user) {
      const { user } = authentication
      const relFieldValue = user[relatedField]
      if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          errors = check.includes(relFieldValue) === false
        } else {
          errors = check !== relFieldValue
        }
      }
    } else if (source === sourcesMap.environment) {
      const relFieldValue = environment[relatedField]
      if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          errors = check.includes(relFieldValue) === false
        } else {
          errors = check !== relFieldValue
        }
      }
    }

    if (errors === true) {
      break
    }
  }

  return !errors
}

export function postVerifyFiltercondition(filter, filterValue) {
  const { conditions } = filter
  if (!conditions || conditions.length === 0) {
    return true
  }

  let errors = false
  for (let i = 0; i !== conditions.length; i += 1) {
    const cond = conditions[i]
    const { source, checkType, check } = cond

    if (source === sourcesMap.self) {
      if (checkType === conditionCheckTypesMap.greater_than_or_equal_to) {
        errors = parseInt(filterValue, 10) < parseInt(check, 10)
      }
    }

    if (errors === true) {
      break
    }
  }

  return !errors
}

export function parseEndpoint(requestData) {
  const {
    ep,
    entity,
    requestType,
    defaultFilters,
    placeholderMapping,
    qsDefaultProps,
    parentData,
    dynamicFields,
    environment,
    authentication,
    advancedSearch,
  } = requestData

  const { page, perPage, searchValue, sort } = qsDefaultProps

  const filters = []
  const bracesPattern = /[^{]+(?=\})/g

  for (let i = 0; i !== defaultFilters.length; i += 1) {
    const filter = defaultFilters[i]
    const { fEntity, fSource, fValue, fDefValue } = filter

    let validFilter = true
    if (fEntity && fEntity.length > 0) {
      if (fSource === sourcesMap.parent && parentData) {
        if (fEntity.includes(parentData.entity)) {
          validFilter = true
        } else {
          validFilter = false
        }
      } else {
        validFilter = false
      }
    }

    if (!validFilter) {
      continue
    }

    validFilter = verifyFilterCondition(filter, authentication, environment)
    if (!validFilter) {
      continue
    }

    const placeholderList = fValue.match(bracesPattern)
    const placeholder =
      placeholderList && placeholderList.length > 0 ? placeholderList[0] : null

    let filterValue = ''

    if (fSource === sourcesMap.browserQueryString) {
      filterValue = getQsValue(placeholder)
    } else if (fSource === sourcesMap.dynamicField) {
      filterValue = dynamicFields[placeholder] || fDefValue
    } else if (fSource === sourcesMap.contextVariable) {
      filterValue = fDefValue
      if (environment) {
        const { path, contextVariables } = environment
        if (contextVariables[path] && contextVariables[path][placeholder]) {
          filterValue = contextVariables[path][placeholder]
        }
      }
    } else if (fSource === sourcesMap.environment) {
      filterValue =
        environment && placeholder ? environment[placeholder] : fDefValue
    } else if (fSource === sourcesMap.user) {
      const { user } = authentication
      filterValue = user && placeholder ? user[placeholder] : fDefValue
    } else if (fSource === sourcesMap.parent) {
      filterValue =
        (placeholder &&
          parentData &&
          parentData.data &&
          parentData.data[placeholder]) ||
        fDefValue
    } else if (fSource === sourcesMap.static) {
      filterValue = fDefValue
    }

    validFilter = postVerifyFiltercondition(filter, filterValue)
    if (!validFilter) {
      continue
    }

    const fDefinition = fValue.replace(`{${placeholder}}`, filterValue)
    filters.push(fDefinition)
  }

  const filtersString = filters.join('&')

  let parsedEp = filtersString ? `${ep}${filtersString}` : ep

  parsedEp = parsedEp
    .replace('{reqType}', requestType)
    .replace('{entity}', entity.replace(/_/g, '-'))
    .replace('{page}', page || 1)
    .replace('{perPage}', perPage || 100)
    .replace('{search}', searchValue || '')
    .replace('{sort}', sort || '')
    .replace('{advancedSearch}', advancedSearch || '')

  if (placeholderMapping) {
    for (let i = 0; i !== placeholderMapping.length; i += 1) {
      const placeholder = placeholderMapping[i]
      const { pSource, pKey, pValue, pDefValue } = placeholder

      let placeholderValue = pDefValue
      if (pSource === sourcesMap.dynamicField) {
        placeholderValue = dynamicFields[pValue] || pDefValue
      }

      if (pSource === sourcesMap.static) {
        placeholderValue = pDefValue
      }

      if (pSource === sourcesMap.user) {
        const { user } = authentication
        placeholderValue = user && placeholder ? user[pValue] : pDefValue
      }

      parsedEp = parsedEp.replace(pKey, placeholderValue)
    }
  }

  // parsedEp = encodeEndPointUrl(parsedEp + advancedSearch)
  parsedEp = encodeEndPointUrl(parsedEp)

  return parsedEp
}

export function baseRequestObject(
  ep,
  entity,
  requestType,
  environment,
  authentication
) {
  const baseReqData = {
    ep,
    entity,
    requestType,
    defaultFilters: [],
    placeholderMapping: [],
    qsDefaultProps: {
      page: 1,
      perPage: 100,
      searchValue: '',
      sort: '',
    },
    parentData: {},
    dynamicFields: {},
    environment,
    authentication,
    advancedSearch: '',
  }

  return baseReqData
}
