/* eslint-disable no-debugger */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { performFieldDidMount, performFieldDidUpdate } from '../../flow'

const classNames = require('classnames')

export class GenericField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      fieldValue: null,
      fieldDynamicProps: {},
      conditionsMet: false,
      data: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { dynamicForm: currentDForm } = this.props
    const { initialized, conditionsMet, data } = this.state
    const { field, dynamicForm } = nextProps
    const { fieldsToUpdate, resetKeyUpdated } = dynamicForm

    if (field.forceRenderAlways) {
      return true
    }
    if (
      field.forceRenderOnChange &&
      dynamicForm.fields[field.name] !== currentDForm.fields[field.name]
    ) {
      return true
    }
    if (
      field.forceRenderOnDataChanged &&
      JSON.stringify(nextState.data) !== JSON.stringify(data)
    ) {
      return true
    }
    if (
      initialized !== nextState.initialized &&
      nextState.initialized === true
    ) {
      return true
    }
    if (conditionsMet !== nextState.conditionsMet) {
      return true
    }
    if (
      field.resetOn &&
      resetKeyUpdated &&
      field.resetOn.includes(resetKeyUpdated)
    ) {
      return true
    }
    if (fieldsToUpdate.indexOf(field.name) >= 0) {
      return true
    }
    if (dynamicForm.errors[field.name] !== currentDForm.errors[field.name]) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const updatedState = await performFieldDidMount(this.props)
    const { fieldValue } = updatedState

    await this.setState(updatedState)
    await this.saveOnRedux(fieldValue)
  }

  componentDidUpdate = async prevProps => {
    const updatedState = await performFieldDidUpdate(
      { ...prevProps },
      { ...this.props },
      { ...this.state }
    )

    if (Object.keys(updatedState).length > 0) {
      const { fieldValue } = updatedState

      // eslint-disable-next-line react/no-did-update-set-state
      await this.setState(updatedState)
      await this.saveOnRedux(fieldValue)
    }
  }

  saveOnRedux = async value => {
    const { handleValueChanged, field } = this.props
    const { name } = field

    if (value === undefined || value === null) {
      return
    }

    await handleValueChanged(name, value)
  }

  processOnChange = value => {
    this.setState({ fieldValue: value })
    this.saveOnRedux(value)
  }

  renderField = () => {
    const html = []
    html.push(<div>Be creative! :)</div>)
    return html
  }

  render() {
    const { field, dynamicForm, environment, specialization } = this.props
    const { initialized, conditionsMet } = this.state
    const { translations } = specialization
    const { texts } = translations

    const { locale } = environment
    const { name, alias, description, hidden, customWidth } = field

    const { errors, type } = dynamicForm
    const fieldErrors = errors[name]

    return (
      <>
        {initialized && conditionsMet && (
          <>
            <div
              key={name}
              className={classNames(
                customWidth || 'col-12 col-sm-6',
                hidden ? 'visually-hidden' : '',
                'my-1'
              )}
            >
              <div className={classNames('fs-7 text-secondary ps-2')}>
                {alias ? translate(alias) : translate(name)}
              </div>
              <div
                className={classNames(
                  fieldErrors ? 'is-invalid' : '',
                  type === requestTypesMap.view
                    ? 'border p-2 rounded-4'
                    : 'form-control rounded-4 shadow-sm border-0'
                )}
              >
                {this.renderField()}
              </div>
              {fieldErrors && (
                <span key={uuid()} className={classNames('invalid-feedback')}>
                  {fieldErrors[0]}
                </span>
              )}
              {description &&
                description.map(d => (
                  <div
                    key={uuid()}
                    className={classNames('mt-1 fs-7 ps-2')}
                    style={{
                      opacity: '0.8',
                    }}
                  >
                    <i>{texts[locale][d]}</i>{' '}
                  </div>
                ))}
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GenericField)
