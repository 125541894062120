import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  getQsValue,
  mapStateToProps,
  translate,
} from '../../../../../../src/utils'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class PTQViewFileComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      file_url: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { environment, authentication } = this.props

    const entity_id = getQsValue('id')
    const file_name = getQsValue('name')

    let file_url = ''

    if (entity_id !== null && file_name !== null) {
      const requestData = baseRequestObject(
        apiCallMap.serverAction,
        'utils',
        apiRequestTypesMap.post,
        environment,
        authentication
      )

      requestData.placeholderMapping.push({
        pSource: 'static',
        pKey: '{action}',
        pValue: 'get-file-url',
        pDefValue: 'get-file-url',
      })

      const parsedEp = parseEndpoint(requestData)

      const response = await httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        {
          entity_id,
          file_name,
        },
        true
      )

      const { status, data } = response
      if (status === 200 || status === 201) {
        file_url = data.file_url
      }
    }

    this.setState({
      loading: false,
      file_url,
    })
  }

  renderFileUrl = () => {
    const { authentication } = this.props
    const { access_token } = authentication

    const { file_url } = this.state
    const html = []
    if (file_url === '') {
      html.push(
        <div
          key="file_not_found"
          className={classNames(
            'd-flex flex-column justify-content-center align-items-center py-4'
          )}
        >
          <div
            className={classNames('background-image sad-face-icon theme-svg')}
            style={{ width: 80, height: 80 }}
          />
          <div className={classNames('mt-2 fs-5 text-center')}>
            {translate('file_not_found_message')}
          </div>
        </div>
      )
    } else {
      const file_url_with_token = `${file_url}?access_token=${access_token}`
      html.push(
        <div
          key="file_container"
          className={classNames('d-flex justify-content-center')}
        >
          <img
            src={file_url_with_token}
            alt="file"
            className={classNames('ofit-sdown')}
            style={{ width: 'min(100%, 900px)' }}
          />
        </div>
      )
    }
    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderFileUrl()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PTQViewFileComponent)
