import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import JujoLoading from '../../../../../../../src/components/loading'
import ECDataListFieldComponent from '../../../../../../../src/components/common/fields/dataListField'

const classNames = require('classnames')

export class PTQEstimateCustomerSelectorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  renderCustomerSelectionField = () => {
    const { handleValueChanged } = this.props

    const datalista_field_def = {
      name: 'customer_id',
      alias: 'customer',
      fieldType: 'datalistField',
      renderField: 'name',
      valueField: 'ID',
      defValue: 0,
      apis: {
        getData: {
          apiCall: 'getAllKeyValue',
          requestType: 'get',
          relatedEntity: 'customer',
          defaultFilters: [
            {
              fEntity: [],
              fSource: 'environment',
              fValue: 'locale={locale}',
              fDefValue: 'it',
            },
          ],
        },
      },
    }

    const html = []
    html.push(
      <div key="customer_id_field">
        <div className={classNames('border rounded p-1')}>
          <ECDataListFieldComponent
            field={datalista_field_def}
            handleValueChanged={handleValueChanged}
            initialValue=""
          />
        </div>
      </div>
    )
    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>
            {this.renderCustomerSelectionField()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateCustomerSelectorComponent)
