import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import JujoEditableComponent from '../../../../../../src/components/editable_component'

const classNames = require('classnames')

export class EditTextsComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  renderBody = () => {
    this.fieldRef = React.createRef()

    const { data, comp_id } = this.props
    const { order_id } = data

    const html = []

    html.push(
      <div key="edit_texts_component" className={classNames('')}>
        <div>
          <JujoEditableComponent
            c_def={{
              comp: 'editable_component',
              entity: {
                source: 'api',
                defValue: 'order',
                action: 'get-texts-definition',
                data: {
                  order_id,
                  parent_component_id: comp_id,
                },
              },
              renderer: {},
              hooks: {},
            }}
          />
        </div>
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(EditTextsComponent)
