import React from 'react'
import loadable from '@loadable/component'
import Parser from 'html-react-parser'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class DashboardComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  renderDashboard = () => {
    const { authentication } = this.props
    const { user } = authentication
    const { name } = user

    const uppercase_name = name.charAt(0).toUpperCase() + name.slice(1)

    const html = []

    html.push(
      <div key="dashboard">
        <div className={classNames('fs-5 mb-2')}>
          <span>{translate('hello')}</span>
          <span className={classNames('fc-1 fw-bold ms-1')}>
            {uppercase_name}
          </span>
          <span>,</span>
        </div>
        <div>{Parser(translate('my_profile_incipit'), {})}</div>
      </div>
    )
    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderDashboard()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(DashboardComponent)
