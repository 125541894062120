import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  bindElementToComponent,
  cloneObj,
  // embedJSModule,
  mapStateToProps,
  retrieveBindedElement,
} from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import svgParsingStepsMap from '../../../../../../src/enums/svgParsingStepsMap'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const JujoFlowManager = loadable(() =>
  import('../../../../../../src/components/jujo_flow_manager')
)

const classNames = require('classnames')

export class PtqTemplateManagerComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  isFullscreenOnInit = () => true

  performActionOnWillUnmount = async () => {
    const clonedLoadGrid = retrieveBindedElement(this, 'cloned_load_grid_func')

    if (clonedLoadGrid) {
      await clonedLoadGrid()
    }
  }

  performActionOnDidMount = async () => {
    const { component_data } = this.state
    const { data } = this.props
    const { sender, initial_state, initial_data } = data

    // if (typeof document !== 'undefined') {
    //   embedJSModule('/ptq/ptq-svgeditor/template-creation/template-creation.mjs')
    // }

    bindElementToComponent(
      this,
      'cloned_load_grid_func',
      sender.props.gridComponent.loadGrid
    )

    const flow_initial_state = initial_state || svgParsingStepsMap.import_svg
    const flow_initial_data = initial_data || {}

    const cloned_data = cloneObj(component_data)
    cloned_data.initial_state = flow_initial_state
    cloned_data.initial_data = flow_initial_data
    cloned_data.loading = false
    await this.updateData(cloned_data)
  }

  renderBody = () => {
    this.fieldRef = React.createRef()

    // const { authentication } = this.props
    // const { access_token } = authentication

    const { component_data } = this.state

    const { loading, initial_state, initial_data } = component_data

    const html = []

    html.push(
      <div key="template_manager_component">
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>
            <JujoFlowManager
              config={{
                initial_state,
                initial_data,
                steps: {
                  import_svg: {
                    components: [
                      {
                        specialized: true,
                        key: 'svg_parser_component',
                        path: 'components/modeling_group/customized_svg_parser',
                      },
                    ],
                  },
                  editing: {
                    components: [
                      {
                        specialized: true,
                        key: 'svg_editor',
                        path: 'components/modeling_group/custom_svg_template_editor',
                      },
                    ],
                  },
                },
                injections: {
                  closeEditPanel: this.handleClose,
                },
              }}
            />
            {/* <template-creation token={access_token}></template-creation> */}
          </div>
        )}
      </div>
    )

    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PtqTemplateManagerComponent)
