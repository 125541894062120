import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ECRangeFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props
    const { name, customClass } = field

    const min = parseFloat(field.min || 0)
    const max = parseFloat(field.max || 10 ** 1000)
    const step = parseFloat(field.step || 1)

    return (
      <input
        className={classNames('', customClass || '')}
        type="range"
        min={min}
        step={step}
        max={max}
        defaultValue={initialValue}
        onChange={e => {
          const { value } = e.target
          handleValueChanged(name, parseFloat(value))
        }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECRangeFieldComponent)
