import lsEntryMap from '../../../enums/lsEntryMap'
import initialState from '../../reducers/environment/initialState'
import { cloneObj } from '../../../utils'
import { lsGet, lsSet } from '../../../services/localStorage'

export function updateEnvironment(env) {
  return async dispatch => {
    const newEnv = env || initialState

    lsSet(lsEntryMap.environment, newEnv)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment: newEnv,
    })
  }
}

export function updateTabIntoStorageAndRedux(key, idx) {
  return async dispatch => {
    const environment = lsGet(lsEntryMap.environment) || initialState
    const duplicatedEnvironment = { ...environment }

    const { tabs } = duplicatedEnvironment || {}
    tabs[key] = idx || 0
    lsSet(lsEntryMap.environment, duplicatedEnvironment)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment: duplicatedEnvironment,
    })
  }
}

export function updateGridIntoStorageAndRedux(entity, props) {
  return async dispatch => {
    const environment = lsGet(lsEntryMap.environment) || initialState
    const duplicatedEnvironment = cloneObj(environment)

    const { grids } = duplicatedEnvironment || {}

    if (!grids[entity]) {
      grids[entity] = {
        rows: {},
      }
    }

    for (let i = 0; i !== props.length; i += 1) {
      const prop = props[i]
      const { rowId, key, value } = prop
      if (rowId !== null) {
        if (!grids[entity].rows[rowId]) {
          grids[entity].rows[rowId] = {}
        }

        grids[entity].rows[rowId][key] = value
      } else {
        grids[entity][key] = value
      }
    }

    lsSet(lsEntryMap.environment, duplicatedEnvironment)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment: duplicatedEnvironment,
    })
  }
}

export function updateLocaleIntoStorageAndRedux(lo) {
  return async dispatch => {
    const environment = lsGet(lsEntryMap.environment) || initialState
    let currentLocale = environment.locale || process.env.locale

    if (typeof window !== 'undefined') {
      const { pathname } = window.location
      const initial_path = pathname.split('/')[1]
      const { languages } = process.env
      const lang_list = languages.split(',')

      if (lang_list.indexOf(initial_path) >= 0) {
        currentLocale = initial_path
      }
    }

    environment.locale = lo || currentLocale

    lsSet(lsEntryMap.environment, environment)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment,
    })
  }
}

export function updateContextVariablesIntoStorageAndRedux(cVarObj) {
  return async dispatch => {
    const environment = lsGet(lsEntryMap.environment) || initialState

    const { contextVariables } = environment
    const { path, key, value } = cVarObj

    const updatedContextVariables = { ...contextVariables }
    if (!updatedContextVariables[path]) {
      updatedContextVariables[path] = {}
    }

    updatedContextVariables[path][key] = value

    environment.contextVariables = updatedContextVariables
    lsSet(lsEntryMap.environment, environment)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment,
    })
  }
}

export function updatePermissionsIntoStorageAndRedux(permissions) {
  return async dispatch => {
    const environment = lsGet(lsEntryMap.environment) || initialState
    environment.permissions = permissions
    lsSet(lsEntryMap.environment, environment)

    dispatch({
      type: 'UPDATE_ENVIRONMENT',
      environment,
    })
  }
}
