import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECDateFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  // TODO Giuseppe intravede bug funzionali. Da migliorare
  handleOnChange = (name, value) => {
    const { handleKeyPressed, handleDateCompiled } = this.props
    const year = value.substring(0, 4)
    if (
      ((value.length === 10 && year > 1900) || value.length === 0) &&
      typeof handleDateCompiled === 'function'
    ) {
      handleDateCompiled(name, value)
    }
    if (typeof handleKeyPressed === 'function') {
      handleKeyPressed(name, value)
    }
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props
    const { name, renderDateType } = field

    let dateValue = ''
    if (
      initialValue &&
      initialValue !== '0000-00-00 00:00:00' &&
      initialValue !== '0000-00-00' &&
      initialValue.length >= 7
    ) {
      dateValue = new Date(initialValue.substr(0, 10))
        .toISOString()
        .slice(0, renderDateType && renderDateType !== 'date' ? 7 : 10)
    }

    return (
      <input
        type={renderDateType || 'date'}
        placeholder="yyyy-mm-dd"
        defaultValue={dateValue}
        onBlur={
          typeof handleValueChanged === 'function'
            ? e => {
                const { value } = e.target
                handleValueChanged(name, value)
              }
            : null
        }
        onChange={e => {
          const { value } = e.target
          this.handleOnChange(name, value)
        }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECDateFieldComponent)
