import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class PasswordField extends GenericField {
  processOnChange = e => {
    const { field } = this.props
    const { uppercase } = field
    let { value } = e.target
    value = uppercase === true ? value.toString().toUpperCase() : value
    this.setState({ fieldValue: value })
  }

  processOnBlur = e => {
    const { value } = e.target
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field } = this.props
    const { name, disabled } = field
    const disabled_field = parseBoolean(disabled, 'false')

    const html = []

    html.push(
      <input
        key={uuid()}
        type="password"
        name={name}
        defaultValue={fieldValue}
        disabled={disabled_field}
        onPaste={e => {
          if (field.paste_disabled === true) {
            e.preventDefault()
          }
          return false
        }}
        onChange={e => {
          this.processOnChange(e)
        }}
        onBlur={e => {
          this.processOnBlur(e)
        }}
      />
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(PasswordField)
