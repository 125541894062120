const lsGridPropsMap = {
  advancedSearch: 'advancedSearch',
  advancedSearchList: 'advancedSearchList',
  expanded: 'expanded',
  page: 'page',
  perPage: 'perPage',
  searchValue: 'searchValue',
  sort: 'sort',
}

export default lsGridPropsMap
