import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import sourcesMap from '../../../enums/sourcesMap'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECStaticFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      fieldValue: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { authentication, field, handleValueChanged } = this.props
    const { user } = authentication
    const { name, source, relatedField, defValue } = field
    let fieldValue = defValue
    if (source === sourcesMap.user) {
      fieldValue = user[relatedField]
    }

    // I use the setTimeout to create the thread and allow the processor
    // to manage parallel access concurrency on the data managed
    // by handleValueChanged function. Without this method, all fields would
    // try to update the centralized data at the same time creating conflicts
    setTimeout(() => {
      handleValueChanged(name, fieldValue)
      this.setState({ initialized: true, fieldValue })
    })
  }

  render() {
    const { initialized, fieldValue } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        {!initialized && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '25px 25px',
                backgroundRepeat: 'no-repeat',
                height: '25px',
                width: '25px',
              }}
            />
          </div>
        )}
        {initialized && <div>{fieldValue}</div>}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECStaticFieldComponent)
