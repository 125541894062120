import React from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'
import { translate } from '../../../utils'
import ParserContext from './parser_context'

const FilesSelection = loadable(() => import('./_parts/files_selection'))
const JujoSVGTemplateName = loadable(() => import('./_parts/template_name'))
const JujoDefaultFont = loadable(() => import('./_parts/default_font'))
const JujoButtonComponent = loadable(() => import('../../jujo_button'))

const JujoLoading = loadable(() => import('../../loading'))

function JujoSVGParser({ specializedFields, onComplete }) {
  return (
    <div>
      <ParserContext.Consumer>
        {({
          definition,
          setDefinition,
          isSaving,
          setIsSaving,
          onSvgFilesChanged,
          onFontFamiliesMappingChanged,
          onDefaultFontChanged,
          onTemplateNameChanged,
        }) => (
          <div className={classNames('')}>
            <div className={classNames('mb-3')}>
              {translate('new_template_instructions')}
            </div>
            <div className={classNames('bg-gray-200 p-4')}>
              <JujoSVGTemplateName onChange={onTemplateNameChanged} />
              <FilesSelection
                onSvgFilesChanged={onSvgFilesChanged}
                onFontFamiliesMappingChanged={onFontFamiliesMappingChanged}
              />
              <JujoDefaultFont onChange={onDefaultFontChanged} />
              {specializedFields && specializedFields(definition, setDefinition)}
              <div
                className={classNames(
                  'd-flex justify-content-center justify-content-md-end align-items-center mt-2'
                )}
              >
                {isSaving && (
                  <div className={classNames('bg-white p-1')}>
                    <JujoLoading />
                  </div>
                )}
                {!isSaving && (
                  <JujoButtonComponent
                    bstyle={0}
                    bclasses={
                      definition.front && definition.name
                        ? 'bg-orange-500 fc-white d-flex justify-content-center align-items-center'
                        : 'bg-gray-200 opacity-5 border border-color-gray d-flex justify-content-center align-items-center'
                    }
                    blabel={translate('continue')}
                    handleClick={() => {
                      if (definition.front && definition.name) {
                        setIsSaving(true)
                        onComplete(definition)
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </ParserContext.Consumer>
    </div>)
}

export default JujoSVGParser
