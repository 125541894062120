/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'

const classNames = require('classnames')

export class JujoEditableComponentSearch extends React.Component {
  constructor(props) {
    super(props)
    this.searchRef = React.createRef()

    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleSearch = async () => {
    const { sender } = this.props
    const { performSearch } = sender
    const { value } = this.searchRef.current

    performSearch(value)
  }

  render() {
    const { sender } = this.props
    const { searchValue } = sender.state

    return (
      <div className={classNames('my-2')}>
        <div className={classNames('d-flex border rounded-5 p-1')}>
          <input
            className={classNames('border-end fs-7')}
            type="text"
            placeholder={translate('search')}
            defaultValue={searchValue}
            ref={this.searchRef}
            onKeyDown={async e => {
              if (e.key === 'Enter') {
                await this.handleSearch()
              }
            }}
          />
          <div
            className={classNames('btn search-icon theme-svg ms-1 ps-3')}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '20px',
            }}
            label="clear grid"
            role="button"
            tabIndex={0}
            onClick={async () => {
              await this.handleSearch()
            }}
            onKeyPress={async () => {
              await this.handleSearch()
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoEditableComponentSearch)
