import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoSVGTextPanel = loadable(() => import('./text/text_panel'))
const JujoSVGImagePanel = loadable(() => import('./image/image_panel'))

function JujoSVGLeftPanel({ element_key, curr_nidx, fonts }) {
  const [element_type, setElementType] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    if (g_element === null) return

    const image = g_element.querySelector('image')
    const type = image ? 'image' : 'text'

    setElementType(type)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  return (
    <>
      {element_type === null && <></>}
      {element_type === 'image' && (
        <div key={element_key} className={classNames('d-flex flex-column')}>
          <div className={classNames('')}>
            <JujoSVGImagePanel element_key={element_key} />
          </div>
        </div>
      )}
      {element_type === 'text' && (
        <div key={element_key} className={classNames('d-flex flex-column')}>
          <div className={classNames('')}>
            <JujoSVGTextPanel
              element_key={element_key}
              curr_nidx={curr_nidx}
              fonts={fonts}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGLeftPanel
