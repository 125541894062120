import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import { defaultManageResponse } from '../../../../../src/utils'

export async function markAsShipped(sender) {
  const { props } = sender

  const { gridComponent, environment, authentication } = props
  const { state } = gridComponent
  const { selectedRows } = state
  const { idList } = selectedRows

  /* Creating a request object that will be used to make the api call. */
  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'shipment',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'mark-orders-as-shipped',
    pDefValue: 'mark-orders-as-shipped',
  })

  const parsedEp = parseEndpoint(requestData)

  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    { idList },
    true
  )

  defaultManageResponse(sender, {}, response)
}

export async function getShipmentDataByOrder(sender) {
  const { props, state } = sender

  const { environment, authentication } = props
  const { editModeTransitionalDataSource } = state
  const { order_id } = editModeTransitionalDataSource

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'shipment',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'get-order-shipment-data',
    pDefValue: 'get-order-shipment-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    {
      order_id,
    },
    true
  )

  const { data } = response
  return data.data
}

export async function saveShipmentData(sender) {
  const { state, props } = sender
  const { environment, authentication } = props
  const { localDataSource } = state

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'shipment',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'set-order-shipment-data',
    pDefValue: 'set-order-shipment-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    localDataSource,
    true
  )

  defaultManageResponse(sender, {}, response)
}

export async function exportGls(sender) {
  const { props } = sender
  const { authentication, environment, gridComponent } = props

  const { access_token } = authentication

  const { state } = gridComponent
  const { selectedRows } = state
  const { idList } = selectedRows

  const requestData = baseRequestObject(
    apiCallMap.genericExport,
    '',
    apiRequestTypesMap.post,
    environment,
    authentication
  )
  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'gsl-xlsx',
    pDefValue: 'gsl-xlsx',
  })

  const parsedEp = parseEndpoint(requestData)
  const data = {
    id_list: idList,
  }

  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    data,
    true
  )

  const { status } = response
  if (status === 200 || status === 201) {
    const { file_url } = response.data.data
    if (file_url) {
      const file_url_with_token = `${file_url}?access_token=${access_token}`
      window.open(file_url_with_token, '_blank')
    }
  }
}
