import React from 'react'
import classNames from 'classnames'
import { translate } from '../../../../../utils'

function JujoSVGDeleteBtn({ element_key, setFocusedElement }) {
  const handleDeleteClicked = () => {
    if (element_key) {
      const element = document.getElementById(element_key)
      element.remove()
      setFocusedElement(null)
    }
  }

  return (
    <div>
      <div
        className={classNames(
          'd-flex flex-column align-items-center w-100 w-md-70px',
          element_key === null ? 'opacity-5' : ''
        )}
        role="button"
        tabIndex={0}
        onClick={() => handleDeleteClicked()}
        onKeyPress={() => handleDeleteClicked()}
      >
        <div className={classNames('fs-8')}>{translate('delete')}</div>
        <div
          className={classNames('background-image trash-icon')}
          style={{ width: 25, height: 25 }}
        />
      </div>
    </div>
  )
}

export default JujoSVGDeleteBtn
