import merge from 'lodash/merge'
import { coreEntitesConfig } from '../../../../../../src/utils'

const address = require('./json/address.json')
const customer = require('./json/customer.json')
const estimate = require('./json/estimate.json')
const external_order = require('./json/external_order.json')
const external_products_mapping = require('./json/external_products_mapping.json')
const impose_model = require('./json/impose_model.json')
const paper_library = require('./json/paper_library.json')
const print_file = require('./json/print_file.json')
const printing_registration = require('./json/printing_registration.json')
const product = require('./json/product.json')
const order = require('./json/order.json')
const shipment = require('./json/shipment.json')
const svg_template = require('./json/svg_template.json')
const text_form = require('./json/text_form.json')

const specialized_entities = {
  address,
  customer,
  estimate,
  external_order,
  external_products_mapping,
  impose_model,
  paper_library,
  print_file,
  printing_registration,
  order,
  product,
  shipment,
  svg_template,
  text_form,
}

const core_entities = coreEntitesConfig()
const list = merge(core_entities, specialized_entities)
export { list }
