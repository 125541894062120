/* eslint-disable react/no-unused-state */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)
const ECNumberFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/numberField')
)

const classNames = require('classnames')

export class PTQEstimateTicketCustomPriceComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({
      loading: false,
    })
  }

  handleValueChanged = async (fieldName, value) => {
    const { handleCustomPriceChanged } = this.props

    const value_type = fieldName.replace(/^.*_(.*)$/, '$1')
    const key = fieldName.split('_').slice(0, -1).join('_')

    const record = { key, value, type: value_type }

    handleCustomPriceChanged(record)
  }

  drawPriceBlock = () => {
    const { element } = this.props
    const { key, customized_price_type, customized_price_value } = element

    const html = []

    html.push(
      <div
        key={`${key}_custom_price_${customized_price_type}_${customized_price_value}`}
      >
        <div
          className={classNames(
            'd-flex align-items-center my-1 p-1 rounded-2',
            customized_price_type === 'amount' && 'bg-orange-400'
          )}
        >
          <div className={classNames('fw-bold me-1 w-15px text-center')}>€</div>
          <div
            className={classNames('p-1 rounded bg-white w-70px ms-2 ms-md-0')}
          >
            <ECNumberFieldComponent
              field={{
                name: `${key}_amount`,
                min: 0,
                step: 0.01,
                customClass: 'text-center',
              }}
              initialValue={
                customized_price_type === 'amount' ? customized_price_value : 0
              }
              handleValueChanged={this.handleValueChanged}
            />
          </div>
        </div>
        <div
          className={classNames(
            'd-flex align-items-center my-1 p-1 rounded-2',
            customized_price_type === 'percentage' && 'bg-orange-400'
          )}
        >
          <div className={classNames('fw-bold me-1 w-15px text-center')}>%</div>
          <div
            className={classNames('p-1 rounded bg-white w-70px ms-2 ms-md-0')}
          >
            <ECNumberFieldComponent
              field={{
                name: `${key}_percentage`,
                min: 0,
                max: 100,
                step: 1,
                customClass: 'text-center',
              }}
              initialValue={
                customized_price_type === 'percentage'
                  ? customized_price_value
                  : 0
              }
              handleValueChanged={this.handleValueChanged}
            />
          </div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.drawPriceBlock()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateTicketCustomPriceComponent)
