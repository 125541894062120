import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'
import { getStoredGridProps } from '../helper/lsInteractionFunctions'
import { setPage, setPerPage } from '../helper/sortFilterFunctions'

const classNames = require('classnames')

export class GridPaginationComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  componentDidUpdate = async () => {}

  render() {
    const { specialization, environment, data, loadGrid } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const gridProps = getStoredGridProps(this.props)

    const { page } = gridProps
    const { per_page, total, from, to, last_page, current_page } = data
    let { page_array } = data
    page_array = page_array || [1, 2, 3, 4, 5]

    return (
      <div key="pagination" className={classNames('p-3 row fs-7')}>
        <div
          className={classNames(
            'col-12 col-md-3 d-flex flex-column align-items-center align-items-md-start'
          )}
        >
          <div>
            {texts[locale].pagination_page_of
              .replace('{page}', page)
              .replace('{to}', last_page || 1)}
          </div>
          <div>{`${texts[locale].total_rows}: ${total || 0}`}</div>
        </div>
        <div
          className={classNames(
            'col-12 col-md-6 d-flex align-items-center justify-content-center'
          )}
        >
          {/* First page */}
          <div
            className={classNames('btn page-first theme-svg')}
            style={{
              opacity: page === 1 ? '0.3' : '1',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '25px',
            }}
            label="first"
            role="button"
            tabIndex={0}
            onClick={async () => {
              if (page > 1) {
                await setPage(1, this.props)
                await loadGrid()
              }
            }}
            onKeyPress={async () => {
              if (page > 1) {
                await setPage(1, this.props)
                await loadGrid()
              }
            }}
          />

          {/* Prev Page */}
          <div
            className={classNames('btn page-prev theme-svg')}
            style={{
              opacity: page === 1 ? '0.3' : '1',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '25px',
            }}
            label="prev"
            role="button"
            tabIndex={0}
            onClick={async () => {
              if (page > 1) {
                await setPage(page - 1, this.props)
                await loadGrid()
              }
            }}
            onKeyPress={async () => {
              if (page > 1) {
                await setPage(page - 1, this.props)
                await loadGrid()
              }
            }}
          />

          {/* Prev page group */}
          {from > 1 && (
            <div
              key="next_serie"
              className={classNames(
                'btn d-flex align-items-center justify-content-center fc-1 '
              )}
              style={{
                width: '25px',
                height: '25px',
                paddingTop: '8px',
              }}
              label="prev"
              role="button"
              tabIndex={0}
              onClick={async () => {
                await setPage(from - 3, this.props)
                await loadGrid()
              }}
              onKeyPress={async () => {
                await setPage(from - 3, this.props)
                await loadGrid()
              }}
            >
              ...
            </div>
          )}

          {/* Page buttons */}
          {page_array.map(item => {
            const current_item = current_page - from + 1
            const page_number_to_show = from + item - 1
            if (page_number_to_show <= last_page) {
              return (
                <div
                  key={item}
                  className={classNames(
                    'btn d-flex align-items-center justify-content-center fs-7 mx-1 px-2',
                    current_item !== item ? 'fc-1' : 'fc-white',
                    current_item === item ? 'bg-1' : ''
                  )}
                  style={{
                    width: '25px',
                    height: '25px',
                    paddingTop: '8px',
                  }}
                  label="prev"
                  role="button"
                  tabIndex={0}
                  onClick={async () => {
                    if (current_item !== item) {
                      await setPage(page_number_to_show, this.props)
                      await loadGrid()
                    }
                  }}
                  onKeyPress={async () => {
                    if (current_item !== item) {
                      await setPage(page_number_to_show, this.props)
                      await loadGrid()
                    }
                  }}
                >
                  {page_number_to_show}
                </div>
              )
            }
            return ''
          })}

          {/* Next page group */}
          {to < last_page && (
            <div
              key="next_serie"
              className={classNames(
                'btn d-flex align-items-center justify-content-center fc-1 '
              )}
              style={{
                width: '25px',
                height: '25px',
                paddingTop: '8px',
              }}
              label="prev"
              role="button"
              tabIndex={0}
              onClick={async () => {
                await setPage(from + 3, this.props)
                await loadGrid()
              }}
              onKeyPress={async () => {
                await setPage(from + 3, this.props)
                await loadGrid()
              }}
            >
              ...
            </div>
          )}

          {/* Next page */}
          <div
            className={classNames('btn page-next theme-svg')}
            style={{
              opacity: page === last_page ? '0.3' : '1',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '25px',
            }}
            label="next"
            role="button"
            tabIndex={0}
            onClick={async () => {
              if (page < last_page) {
                await setPage(page + 1, this.props)
                await loadGrid()
              }
            }}
            onKeyPress={async () => {
              if (page < last_page) {
                await setPage(page + 1, this.props)
                await loadGrid()
              }
            }}
          />

          {/* Last Page */}
          <div
            className={classNames('btn page-last theme-svg')}
            style={{
              opacity: page === last_page ? '0.3' : '1',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '25px',
              height: '25px',
              backgroundSize: '25px',
            }}
            label="last"
            role="button"
            tabIndex={0}
            onClick={async () => {
              if (page !== last_page) {
                await setPage(last_page, this.props)
                await loadGrid()
              }
            }}
            onKeyPress={async () => {
              if (page !== last_page) {
                await setPage(last_page, this.props)
                await loadGrid()
              }
            }}
          />
        </div>
        <div
          className={classNames(
            'col-12 col-md-3 d-flex align-items-center justify-content-center justify-content-md-end'
          )}
        >
          <div>{`${texts[locale].rows_per_page}:`}</div>
          <div className={classNames('border rounded-3 ms-2')}>
            <select
              style={{ minWidth: '50px' }}
              defaultValue={per_page}
              onChange={async e => {
                await setPerPage(e.target.value, this.props)
                await loadGrid()
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridPaginationComponent)
