import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import loadable from '@loadable/component'
import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'
import { cloneObj, mapStateToProps, translate } from '../../../utils'

const JujoLoading = loadable(() => import('../../loading'))
const JujoButtonComponent = loadable(() => import('../../jujo_button'))
const JujoFlowManager = loadable(() => import('../../jujo_flow_manager'))

export class ECFontFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      variants: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initialValue } = this.props

    this.setState({ variants: initialValue || [], loading: false })
  }

  handleNewVariantClicked = () => {
    const { variants } = this.state

    // https://www.w3schools.com/css/css3_fonts.asp
    const new_variant_obj = {
      key: uuid(),
      weight: 'normal',
      style: 'normal',
      stretch: 'normal',
      src: '',
    }

    variants.push(new_variant_obj)
    this.setState({ variants })
  }

  findVariantPosition = key => {
    const { variants } = this.state

    let position = -1
    for (let i = 0; i !== variants.length; i += 1) {
      const v = variants[i]
      if (v.key === key) {
        position = i
        break
      }
    }

    return position
  }

  updateVariant = async variant => {
    const { variants } = this.state
    const { key } = variant

    const position = this.findVariantPosition(key)
    if (position >= 0) {
      const updated_variants = cloneObj(variants)
      updated_variants.splice(position, 1, variant)

      await this.setState({ variants: updated_variants })
      await this.saveChanges()
    }
  }

  deleteVariant = async key => {
    const { variants } = this.state

    const position = this.findVariantPosition(key)
    if (position >= 0) {
      const updated_variants = cloneObj(variants)
      updated_variants.splice(position, 1)
      await this.setState({ variants: updated_variants })
      await this.saveChanges()
    }
  }

  saveChanges = async () => {
    const { field, handleValueChanged } = this.props
    const { variants } = this.state
    const { name } = field

    await handleValueChanged(name, variants)
  }

  renderVariant = variant => {
    const { key, src } = variant

    const initial_flow_state =
      src === '' ? 'upload_variant_file' : 'view_variant'

    const html = []

    html.push(
      <div
        key={key}
        className={classNames(
          'p-3 p-md-4 my-3 mx-md-3 bg-light-gray shadow-sm rounded-3'
        )}
      >
        <JujoFlowManager
          config={{
            initial_state: initial_flow_state,
            initial_data: variant,
            steps: {
              upload_variant_file: {
                components: [
                  {
                    specialized: false,
                    key: 'upload_font_variant_component',
                    path: 'components/common/font_variant/upload',
                  },
                ],
              },
              edit_variant: {
                components: [
                  {
                    specialized: false,
                    key: 'edit_font_variant_component',
                    path: 'components/common/font_variant/edit',
                  },
                ],
              },
              view_variant: {
                components: [
                  {
                    specialized: false,
                    key: 'view_font_variant_component',
                    path: 'components/common/font_variant/view',
                  },
                ],
              },
            },
            injections: {
              updateVariant: this.updateVariant,
              deleteVariant: this.deleteVariant,
            },
          }}
        />
      </div>
    )

    return html
  }

  renderVariants = () => {
    const { variants } = this.state
    const html = []

    for (let i = 0; i !== variants.length; i += 1) {
      const variant = variants[i]
      html.push(<div key={`variant_${i}`}>{this.renderVariant(variant)}</div>)
    }

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('d-flex flex-column py-3')}>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-end'
              )}
            >
              <JujoButtonComponent
                bstyle={3}
                bwidth={200}
                bicon="add-typography-icon white-svg"
                blabel={translate('add_font_variant')}
                bdata={{}}
                handleClick={this.handleNewVariantClicked}
              />
            </div>
            <div>{this.renderVariants()}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECFontFieldComponent)
