import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'

import * as actionCreators from '../../store/actions'
import './styles.scss'

import LangSwitcherComponent from '../lang_switcher'
import { handleChangePageClicked } from '../page_layout/helper/pageFunctions'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class HeaderComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { environment } = this.props
    const { initialized } = this.state

    if (initialized !== nextState.initialized) {
      return true
    }

    if (environment.path !== nextProps.environment.path) {
      return true
    }

    return false
  }

  componentDidMount = () => {
    this.setState({ initialized: true })
  }

  render() {
    const { environment, specialization } = this.props
    const { menu } = specialization.config

    const { path } = environment

    return (
      <div
        key={uuid()}
        className={classNames(
          'header_container bg-white-gradient d-flex flex-column flex-md-row justify-content-center align-items-center sticky-top py-4 py-md-2 px-3 shadow-sm'
        )}
      >
        <img
          style={{ height: '45px' }}
          className={classNames('d-none d-md-flex me-md-auto')}
          src={`/${process.env.client}/logo.png`}
          alt="logo"
        />
        <div className={classNames('me-md-2')}>
          <div
            style={{ height: '100%' }}
            className={classNames(
              'd-flex justify-content-center justify-content-md-end'
            )}
          >
            {menu.admin.map(link => {
              const { url, icon } = link
              return (
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <div
                  key={uuid()}
                  className={classNames(
                    { selected: path === url },
                    'head-icon position-relative mx-1 my-auto',
                    icon
                  )}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleChangePageClicked(url, this.props)}
                  onKeyPress={() => handleChangePageClicked(url, this.props)}
                />
              )
            })}
          </div>
        </div>
        <div className={classNames('d-none d-md-flex')}>
          <LangSwitcherComponent />
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(HeaderComponent)
