import apiCallMap from '../../../enums/apiCallMap'
import dataRenderComponentTypesMap from '../../../enums/dataRenderComponentTypesMap'
// eslint-disable-next-line import/no-cycle
import { getStoredGridProps } from './lsInteractionFunctions'
import lsGridPropsMap from '../../../enums/lsGridPropsMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'
import apiRequestTypesMap from '../../../enums/apiRequestTypesMap'
import gridEditorTypesMap from '../../../enums/gridEditorTypesMap'
import { lsGet } from '../../../services/localStorage'
import { httpGet } from '../../../services/apiService'

export function getGridDefinition(props) {
  const { entity, specialization } = props
  const { entities } = specialization.config
  const gridDefinition =
    entities && entities[entity]
      ? entities[entity][dataRenderComponentTypesMap.jujo_grid]
      : {}
  return gridDefinition
}

export function composeRequestAdvancedSearch(
  parentData,
  advancedSearch,
  environment,
  authentication
) {
  const {
    apiCall,
    relatedEntity,
    requestType,
    placeholderMapping,
    defaultFilters,
  } = advancedSearch

  const requestData = baseRequestObject(
    apiCallMap[apiCall],
    relatedEntity,
    requestType,
    environment,
    authentication
  )
  requestData.defaultFilters = defaultFilters || []
  requestData.placeholderMapping = placeholderMapping || []
  requestData.parentData = parentData || {}
  const parsedEp = parseEndpoint(requestData)

  return {
    parsedEp,
    data: [],
  }
}

export async function getAdvancedSearch(props) {
  const { parentData, environment, authentication } = props

  const gridDefinition = getGridDefinition(props)

  const { apis } = gridDefinition
  if (!apis) {
    return {}
  }

  const { advancedSearch } = apis

  if (!advancedSearch) {
    return {}
  }

  const reqObj = composeRequestAdvancedSearch(
    parentData,
    advancedSearch,
    environment,
    authentication
  )
  const { parsedEp } = reqObj

  let returnData = []
  const response = await httpGet(`${process.env.apiUrl}${parsedEp}`)
  if (response) {
    const { status } = response
    if (status === 200) {
      returnData = response.data
    }
  }

  return returnData
}

export function composeRequestGridData(
  parentData,
  entity,
  environment,
  authentication,
  apis,
  gridProps
) {
  const { getData } = apis
  const { apiCall, defaultFilters, placeholderMapping, relatedEntity } = getData

  const requestEntity =
    relatedEntity && relatedEntity !== 'inherit' ? relatedEntity : entity

  const requestData = baseRequestObject(
    apiCallMap[apiCall],
    requestEntity,
    apiRequestTypesMap.get,
    environment,
    authentication
  )
  requestData.defaultFilters = defaultFilters || []
  requestData.parentData = parentData || {}
  requestData.placeholderMapping = placeholderMapping || []
  requestData.advancedSearch = gridProps.advancedSearch || ''
  requestData.qsDefaultProps = {
    page: gridProps.page,
    perPage: gridProps.perPage,
    searchValue: gridProps.searchValue,
    sort: gridProps.sort,
  }

  const parsedEp = parseEndpoint(requestData)

  return {
    parsedEp,
    data: [],
  }
}

export async function getGridData(props) {
  const { parentData, entity } = props

  const environment = lsGet('environment')
  const authentication = lsGet('authentication')

  const gridDefinition = getGridDefinition(props)
  const gridProps = getStoredGridProps(props)

  const { apis } = gridDefinition
  if (!apis) {
    return []
  }

  const reqObj = composeRequestGridData(
    parentData,
    entity,
    environment,
    authentication,
    apis,
    gridProps
  )
  const { parsedEp } = reqObj

  const request_ep = `${process.env.apiUrl}${parsedEp}`

  const responseObj = {
    request_ep,
    data: [],
    status: 200,
    message: '',
  }

  const result = await httpGet(request_ep)
  if (result) {
    const { status, data } = result
    if (status === 200) {
      if (Object.keys(gridProps.rows).length > 0) {
        for (let i = 0; i !== data.data.length; i += 1) {
          const row = data.data[i]
          row.expandMe = gridProps.rows[row.id]
            ? gridProps.rows[row.id].expanded
            : false
        }
      }

      responseObj.data = data
    } else {
      responseObj.status = status
      responseObj.message = data.message
    }
  }

  return responseObj
}

export async function expandCollapseRow(identifier, toggleState, props) {
  const { entity, updateGridIntoStorageAndRedux } = props

  const expandedProps = [
    {
      rowId: identifier,
      key: lsGridPropsMap.expanded,
      value: toggleState,
    },
  ]
  await updateGridIntoStorageAndRedux(entity, expandedProps)
  return true
}

export function getGridEditor(props) {
  const { specialization, entity } = props
  const { config } = specialization
  const { entities } = config
  const entityDef = entities[entity]
  const { jujo_grid } = entityDef

  const editor = jujo_grid.editor || gridEditorTypesMap.form
  return editor
}

export function calculateGridContainerwidth(gridDefinition, filteredColumns) {
  const { expandableRowContent, selectableRows } = gridDefinition

  let width = expandableRowContent ? 50 : 0

  if (selectableRows && selectableRows.enabled === true) {
    width += 50
  }

  for (let i = 0; i !== filteredColumns.length; i += 1) {
    const { customWidth } = filteredColumns[i]
    width += parseInt(customWidth, 10) || 145
  }
  return width
}

export function calculateColumnTemplate(gridDefinition, filteredColumns) {
  const { expandableRowContent, selectableRows } = gridDefinition

  let template =
    selectableRows && selectableRows.enabled === true ? '50px ' : ''
  template = expandableRowContent ? `${template} 50px ` : template

  for (let i = 0; i !== filteredColumns.length; i += 1) {
    const { customWidth } = filteredColumns[i]
    template += customWidth ? `${customWidth}px ` : '145px '
  }
  return template
}

export function getColumnWidth(filteredColumns, cName) {
  let width = 145
  const padding = 16
  for (let i = 0; i !== filteredColumns.length; i += 1) {
    const { name, customWidth } = filteredColumns[i]
    if (name === cName) {
      width = customWidth || width
      width -= padding
      break
    }
  }
  return width
}
