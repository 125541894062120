/* eslint-disable no-param-reassign */
import React from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class PrintingRegistrationDropPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  handleDelete = idx => {
    const { sender } = this.props
    const { handleDeleteFile } = sender
    handleDeleteFile(idx)
  }

  renderCustomizedFieldValue = () => {
    const { field_value } = this.props
    const html = []

    if (field_value.length === 0) return html
    const record = field_value[0]

    const { svg, svg_size } = record
    const { mm } = svg_size
    html.push(
      <div
        key="custom_view"
        className={classNames(
          'd-flex flex-column flex-md-row align-items-center'
        )}
      >
        <div
          className={classNames('w-250px mx-auto my-3 my-md-0 p-3 shadow-sm')}
        >
          {Parser(svg, {
            replace: domNode => {
              if (domNode.name === 'svg') {
                domNode.attribs.width = '100%'
                domNode.attribs.height = '100%'
              }
            },
          })}
        </div>
        <div className={classNames('w-100 ms-md-2')}>
          <div className={classNames('row p-2 p-md-0 text-center')}>
            <div className={classNames('col-12 col-md-6')}>
              {translate('width')}
            </div>
            <div className={classNames('col-12 col-md-6 fw-bold')}>
              {`${mm.width} mm`}
            </div>
          </div>
          <div className={classNames('row p-2 p-md-0 text-center')}>
            <div className={classNames('col-12 col-md-6')}>
              {translate('height')}
            </div>
            <div className={classNames('col-12 col-md-6 fw-bold')}>
              {`${mm.height} mm`}
            </div>
          </div>
        </div>
        <div className={classNames('my-2')}>
          <div
            className={classNames('background-image theme-svg trash-icon')}
            style={{ width: 20, height: 20 }}
            role="button"
            label="delete_button"
            tabIndex={0}
            onClick={() => {
              this.handleDelete(0)
            }}
            onKeyPress={() => {
              this.handleDelete(0)
            }}
          />
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && this.renderCustomizedFieldValue()}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PrintingRegistrationDropPreview)
