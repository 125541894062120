import classNames from 'classnames'
import React from 'react'
import { connect, useSelector } from 'react-redux'

import { translate } from '../../../../utils'

function JujoDefaultFont({ onChange }) {
  const { jujo_fonts: fonts } = useSelector(state => state.session.session_data)

  const handleSelectChange = event => {
    const selectedFontId = event.target.value
    const selectedFont = fonts.find(
      font => font.id === parseInt(selectedFontId, 10)
    )

    const selectedFontObj = {
      id: selectedFont.id,
      src: selectedFont.data[0].src,
    }

    onChange(selectedFontObj)
  }

  return (
    <div className={classNames('mb-3')}>
      <div className={classNames('ffamily-secondary mb-2 fs-5')}>
        <div>{translate('default_font')}</div>
      </div>
      <div className={classNames('bg-white p-2')}>
        <select onChange={e => handleSelectChange(e)}>
          <option value="-" label="Seleziona" />
          {fonts.map(font => (
            <option key={font.id} value={font.id}>
              {font.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default connect()(JujoDefaultFont)
