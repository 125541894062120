export function updateDynamicFormIntoRedux(updatedDForm) {
  return async dispatch => {
    dispatch({
      type: 'UPDATE_DYNAMIC_FORM',
      dynamicform: updatedDForm,
    })
  }
}

export function resetDynamicFormIntoRedux() {
  return async dispatch => {
    dispatch({
      type: 'RESET_DYNAMIC_FORM',
    })
  }
}
