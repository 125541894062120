import React from 'react'
import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class NotificationComponent extends React.Component {
  render() {
    const { body } = this.props

    return (
      <div
        className={classNames(
          'fst-italic fw-bold text-center rounded mb-3 p-2 fc-1'
        )}
      >
        {Parser(body, {})}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(NotificationComponent)
