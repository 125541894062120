/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import classNames from 'classnames'
import Parser from 'html-react-parser'

import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'
import {
  bytesValue,
  cloneObj,
  defaultHandleOpenJujoFileFromServer,
  mapStateToProps,
  translate,
  uploadFileToServer,
} from '../../../utils'
import fileVisibilityTypesMap from '../../../enums/fileVisibilityTypesMap'

const JujoLoading = loadable(() => import('../../loading'))

export class ECDroppableFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      field_value: [],
      accepted_types: [],
      max_file_size: 0,
      max_file_count: 0,
      upload_result: [],
      override: {},
      input_random_id: null,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { field, dataSource, initialValue } = this.props
    const { name, acceptedTypes, maxFileSize, maxFileCount, override } = field

    const accepted_types = acceptedTypes
      ? acceptedTypes.split(',').map(file_type => file_type.trim())
      : []
    const max_file_size = maxFileSize ? bytesValue(maxFileSize) : 0
    const max_file_count = maxFileCount ? parseInt(maxFileCount, 10) : 0
    const field_value = (dataSource && dataSource[name]) || initialValue || []

    this.setState({
      loading: false,
      field_value,
      accepted_types,
      max_file_size,
      max_file_count,
      override,
      input_random_id: uuid(),
    })
  }

  verifyOverrideFunc = async funcName => {
    const { override } = this.state

    if (override && override[funcName]) {
      const { specialized, action, actionPath, actionName } = override[funcName]
      let injected_actions = null
      let overrided_action = null

      if (action) {
        overrided_action = action
      } else if (specialized) {
        injected_actions =
          await require(`../../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
      } else {
        injected_actions = await require(`../../../${actionPath}`)
      }
      if (injected_actions || overrided_action) {
        return {
          injected_actions,
          actionName,
          overrided_action,
        }
      }
    }
    return false
  }

  manageFileUpload = async file => {
    const { field } = this.props
    const { accepted_types, max_file_size, max_file_count, field_value } =
      this.state

    const { type, name, size } = file

    const ext_in_name = `.${name.split('.').pop()}`
    const type_to_validate = type === '' ? ext_in_name : type

    if (
      accepted_types.length > 0 &&
      accepted_types.indexOf(type_to_validate) < 0
    ) {
      return {
        status: 400,
        message: translate('file_type_not_allowed'),
      }
    }
    if (max_file_count > 0 && field_value.length >= max_file_count) {
      return {
        status: 400,
        message: translate('max_files_allowed_reached'),
      }
    }
    if (max_file_size > 0 && size > max_file_size) {
      return {
        status: 400,
        message: translate('file_size_not_allowed'),
      }
    }

    let server_response = {}
    const result = {}

    const override_uploadFileToServer = await this.verifyOverrideFunc(
      'uploadFileToServer'
    )
    if (override_uploadFileToServer) {
      const { injected_actions, actionName } = override_uploadFileToServer
      server_response = await injected_actions[actionName](this, field, file)
      result.data = server_response.data
    } else {
      server_response = await uploadFileToServer(field, file, {})
    }

    result.name = name
    result.url = server_response.data.url
    result.status = server_response.status
    result.message =
      result.status === 200
        ? translate('file_uploaded_successfully')
        : translate('file_upload_error')

    return result
  }

  handleCloseNotification = index => {
    const { upload_result } = this.state
    const updated_result_list = cloneObj(upload_result)
    updated_result_list.splice(index, 1)
    this.setState({ upload_result: updated_result_list })
  }

  notifyUploadResult = () => {
    const { upload_result } = this.state
    const html = []
    for (let i = 0; i !== upload_result.length; i += 1) {
      const itm = upload_result[i]
      const { status, name, message } = itm
      if (status === 200) continue
      html.push(
        <div
          key={`notification_${i}`}
          className={classNames(
            'border border-color-1 fc-1 fs-8 p-2 shadow-sm mb-1 rounded-5'
          )}
        >
          <div className={classNames('position-relative')}>
            <div className={classNames('')}>
              {name && <span className={classNames('')}>{`${name}: `}</span>}
              <span className={classNames('fw-bold')}>{message}</span>
            </div>
            <div
              className={classNames(
                'background-image close-icon theme-svg position-absolute end-0 top-0'
              )}
              style={{ height: 15, width: 15 }}
              role="button"
              label="close notification"
              tabIndex={0}
              onClick={() => {
                this.handleCloseNotification(i)
              }}
              onKeyPress={() => {
                this.handleCloseNotification(i)
              }}
            />
          </div>
        </div>
      )
    }

    return html
  }

  addUploadedFiles = async files => {
    const { field_value } = this.state
    const { field, handleValueChanged } = this.props
    const { name } = field

    const files_to_parse = cloneObj(files)
    const updated_field_value = cloneObj(field_value)
    for (let i = 0; i !== files_to_parse.length; i += 1) {
      const file = files_to_parse[i]
      const { status } = file
      if (status === 200) {
        delete file.status
        delete file.message
        updated_field_value.push(file)
      }
    }

    this.setState({ field_value: updated_field_value })
    await handleValueChanged(name, updated_field_value)
  }

  handleDrop = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const { max_file_count } = this.state

    const { dataTransfer, target } = e

    const file_processor = []

    if (dataTransfer) {
      const { items, files } = dataTransfer
      if (items) {
        for (let i = 0; i < items.length; i += 1) {
          const itm = items[i]
          const { kind } = itm
          if (kind === 'file') {
            const file = itm.getAsFile()
            file_processor.push(this.manageFileUpload(file))
          }
        }
      } else {
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i]
          file_processor.push(this.manageFileUpload(file))
        }
      }
    } else {
      const { files } = target
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i]
        file_processor.push(this.manageFileUpload(file))
      }
    }

    const parsed_file_processor =
      max_file_count > 0
        ? file_processor.slice(0, max_file_count)
        : file_processor

    const drop_result = await Promise.all(parsed_file_processor)
    // const successfully_updated = upload_result.filter(x => x.status === 200)

    const override_onDropCompleted = await this.verifyOverrideFunc(
      'onDropCompleted'
    )
    if (override_onDropCompleted) {
      const { overrided_action, injected_actions, actionName } =
        override_onDropCompleted

      if (overrided_action) await overrided_action(this, drop_result)
      else {
        await injected_actions[actionName](this, drop_result)
      }
    } else {
      await this.onDropCompleted(drop_result)
    }
  }

  onDropCompleted = async drop_result => {
    await this.addUploadedFiles(drop_result)
    await this.setState({ loading: false, upload_result: drop_result })
  }

  handleDownloadFile = url => {
    if (typeof window !== 'undefined') {
      window.open(url)
    }
  }

  handleDeleteFile = async index => {
    const { field_value } = this.state
    const { field, handleValueChanged } = this.props
    const { name } = field

    const updated_field_value = cloneObj(field_value)
    updated_field_value.splice(index, 1)

    this.setState({ field_value: updated_field_value })
    await handleValueChanged(name, updated_field_value)
  }

  verifyCustomRenderComponent = () => {
    const { override } = this.state

    if (override && override.renderFieldValue) {
      const { specialized, compPath, compName } = override.renderFieldValue

      if (specialized) {
        const DynamicComponent =
          require(`../../../../jujo_specializations/src/${process.env.client}/${compPath}/${compName}`).default

        return DynamicComponent
      }

      const DynamicComponent =
        require(`../../../${compPath}/${compName}`).default

      return DynamicComponent
    }
    return false
  }

  renderFieldValue = () => {
    const { field_value } = this.state
    const DynamicComponent = this.verifyCustomRenderComponent()
    const html = []

    if (DynamicComponent) {
      html.push(
        <div key="custom_render_field_Value" className={classNames('w-100')}>
          <DynamicComponent sender={this} field_value={field_value} />
        </div>
      )
    } else {
      const { field, authentication } = this.props
      const { access_token } = authentication
      const { visibility } = field

      for (let i = 0; i !== field_value.length; i += 1) {
        const itm = field_value[i]
        const { name, url } = itm
        const fileUrl =
          visibility === fileVisibilityTypesMap.private
            ? `${url}?access_token=${access_token}`
            : url

        html.push(
          <div
            key={`item_${i}`}
            className={classNames(
              'd-flex justify-content-between align-items-center border border-color-1 p-2 text-center mx-0 mx-md-2 my-1 fs-8 rounded w-100'
            )}
          >
            <div className={classNames('text-break')}>{name}</div>
            <div className={classNames('d-flex')}>
              <div
                className={classNames(
                  'background-image eye-icon theme-svg mx-1'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                label="open file"
                tabIndex={0}
                onClick={() => {
                  defaultHandleOpenJujoFileFromServer(this, fileUrl)
                }}
                onKeyPress={() => {
                  defaultHandleOpenJujoFileFromServer(this, fileUrl)
                }}
              />
              <div
                className={classNames(
                  'background-image download-icon theme-svg mx-1'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                label="open file"
                tabIndex={0}
                onClick={() => {
                  this.handleDownloadFile(fileUrl)
                }}
                onKeyPress={() => {
                  this.handleDownloadFile(fileUrl)
                }}
              />
              <div
                className={classNames(
                  'background-image trash-icon theme-svg mx-1'
                )}
                style={{ width: 20, height: 20 }}
                role="button"
                label="open file"
                tabIndex={0}
                onClick={() => {
                  this.handleDeleteFile(i)
                }}
                onKeyPress={() => {
                  this.handleDeleteFile(i)
                }}
              />
            </div>
          </div>
        )
      }
    }

    return html
  }

  render() {
    const { loading, input_random_id } = this.state
    const { specialization, environment, field } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { custom_drag_drop_instruction, acceptedTypes } = field
    const instruction_text =
      texts[locale][custom_drag_drop_instruction] ||
      custom_drag_drop_instruction ||
      texts[locale].drag_drop_instruction

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>
            {this.notifyUploadResult()}
            <div
              className={classNames(
                'border rounded p-3 text-center fc-gray-400 bg-light-gray'
              )}
              onDrop={async e => {
                await this.handleDrop(e)
              }}
              onDragOver={e => {
                e.preventDefault()
              }}
            >
              <div>
                <div>{Parser(instruction_text, {})}</div>
                <div>
                  <label
                    htmlFor={input_random_id}
                    className={classNames(
                      'd-flex justify-content-center align-items-center my-2'
                    )}
                  >
                    <div
                      className={classNames(
                        'p-2 border rounded-50 fc-gray-400'
                      )}
                      style={{ width: 150 }}
                    >
                      {texts[locale].click_here}
                    </div>
                  </label>
                  <input
                    className={classNames('d-none')}
                    type="file"
                    accept={acceptedTypes}
                    id={input_random_id}
                    name="files"
                    multiple
                    onChange={async e => {
                      await this.handleDrop(e)
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'w-100 d-flex flex-column flex-md-row flex-wrap'
              )}
            >
              {this.renderFieldValue()}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECDroppableFieldComponent)
