import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../src/store/actions'
import { FillableComponent } from '../../../../../src/components/fillable_component'
import { JujoLoading } from '../../../../../src/components/loading'
import { cloneObj, mapStateToProps } from '../../../../../src/utils'

export class ProductEditorComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  performActionOnDidMount = async () => {
    const { component_data } = this.state

    const cloned_data = cloneObj(component_data)
    cloned_data.loading = true

    cloned_data.loading = false
    this.updateData(cloned_data)
  }

  renderBody = () => {
    const { component_data } = this.state
    const { data } = this.props
    const { ID } = data

    const { loading } = component_data

    const html = []

    html.push(
      <div key="order_details_component">
        {loading === true && <JujoLoading />}
        {loading === false && <div>ciao {ID}</div>}
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(ProductEditorComponent)
