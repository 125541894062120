/* eslint-disable react/no-unused-state */
import loadable from '@loadable/component'
import React from 'react'
import { connect } from 'react-redux'

import { JujoButtonComponent } from '../../../../../../../src/components/jujo_button'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  bindElementToComponent,
  defaultHandleGenericBtnClick,
  jujoEncoder,
  mapStateToProps,
  translate,
} from '../../../../../../../src/utils'
import previewStatusMap from '../../../../enums/preview_status_map'

const ECDroppableFieldComponent = loadable(() =>
  import('../../../../../../../src/components/common/fields/droppableField')
)

const ECTextAreaFieldComponent = loadable(() =>
  import('../../../../../../../src/components/common/fields/textAreaField')
)

const classNames = require('classnames')

export class AdminCommandLineComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      show_droppable_component: false,
      attachments: [],
      status: previewStatusMap.waiting_for_feedback,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data } = this.props
    bindElementToComponent(
      this,
      'cloned_load_grid_func',
      data.sender.props.gridComponent.loadGrid
    )
  }

  handleMessageChanged = async (_fieldName, value) => {
    this.setState({ text: jujoEncoder(value) })
  }

  handleFileUploaded = async (_name, value) => {
    this.setState({ attachments: value })
  }

  handleAttachmentBtnClicked = async () => {
    const { show_droppable_component } = this.state

    const updated_show_droppable_component = !show_droppable_component || false

    this.setState({
      show_droppable_component: updated_show_droppable_component,
    })
  }

  handleCloseDroppableComponent = async () => {
    this.setState({
      show_droppable_component: false,
    })
  }

  handleSendClicked = async () => {
    const custom_action_btn_definition = {
      actionPath: 'actions/orders',
      actionName: 'handleSendPreview',
      confirmation: {
        message: 'send_preview_confirmation_message',
      },
    }
    defaultHandleGenericBtnClick(custom_action_btn_definition, this)
  }

  render() {
    const { show_droppable_component } = this.state
    const { data } = this.props
    const { customer_id } = data

    return (
      <>
        {show_droppable_component === true && (
          <div
            className={classNames(
              'position-fixed w-100 start-0 bottom-0px bottom-md-260px bg-white h-270px h-md-250px zindex-2 shadow-sm px-3 px-md-5 overflow-y-scroll border-top border-md-unset'
            )}
          >
            <div
              className={classNames(
                'position-sticky w-100 start-0 top-0 bg-white py-2 d-flex justify-content-end'
              )}
            >
              <div
                className={classNames(
                  'background-image arrow-down-rounded-icon theme-svg h-20px w-20px'
                )}
                role="button"
                tabIndex={0}
                label="close"
                onClick={() => {
                  this.handleCloseDroppableComponent()
                }}
                onKeyPress={() => {
                  this.handleCloseDroppableComponent()
                }}
              />
            </div>
            <ECDroppableFieldComponent
              field={{
                name: 'preview_field',
                fieldType: 'droppableField',
                customWidth: 'col-12',
                acceptedTypes: 'application/pdf, image/png, image/jpeg',
                maxFileSize: '5MB',
                relatedEntity: 'order',
                visibility: 'private',
                fileOwner: {
                  source: 'static',
                  relatedField: '',
                  defValue: customer_id,
                },
              }}
              handleValueChanged={this.handleFileUploaded}
            />
          </div>
        )}
        <div
          key="admin_panel"
          className={classNames(
            'd-flex w-100 align-items-center py-2 py-md-4 bg-white position-fixed bottom-0 start-0'
          )}
        >
          <div
            className={classNames(
              'container d-flex flex-column-reverse flex-md-row px-md-5'
            )}
          >
            <div
              className={classNames(
                'p-1 border border-color-1 rounded-3 col-12 col-md-9'
              )}
            >
              <ECTextAreaFieldComponent
                field={{ name: 'message', richTextMode: true }}
                handleValueChanged={this.handleMessageChanged}
              />
            </div>
            <div
              className={classNames(
                'col-12 col-md-3 d-flex flex-column justify-content-end align-items-center'
              )}
            >
              <div className={classNames('mb-1 mb-md-2')}>
                <JujoButtonComponent
                  bclasses="bg-orange-500 fc-white"
                  blabel={translate('add_attachment')}
                  handleClick={this.handleAttachmentBtnClicked}
                />
              </div>
              <div className={classNames('mb-2 mb-md-unset')}>
                <JujoButtonComponent
                  bclasses="bg-green-500 fc-white"
                  blabel={translate('send')}
                  handleClick={this.handleSendClicked}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(AdminCommandLineComponent)
