/* eslint-disable no-eval */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import { connect } from 'react-redux'
import * as apiService from '../../../services/apiService'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ChangePasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processing_request: false,
      server_response: {},
      fields: {
        current_password: '',
        new_password: '',
      },
    }

    this.currentPassRef = React.createRef()
    this.newPassRef = React.createRef()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleShowPassFieldValue = fieldReferenceObj => {
    const { current } = fieldReferenceObj
    const { type } = current
    current.type = type === 'text' ? 'password' : 'text'
  }

  handleValueChanged = (field, e) => {
    const { fields } = this.state
    const { target } = e
    const { value } = target

    const updatedFields = { ...fields }
    updatedFields[field] = value
    this.setState({ fields: updatedFields })
  }

  handleSubmitChangePasswordRequest = async () => {
    const { fields } = this.state
    this.setState({ processing_request: true })

    const result = await apiService.httpUpdate(
      `${process.env.apiUrl}change-password`,
      fields
    )
    if (result) {
      this.setState({ processing_request: false, server_response: result })
    }
  }

  drawPassField = (field_name, label, ref) => {
    const html = []
    html.push(
      <div className={classNames('col-12 col-md-6 my-1')} key={label}>
        <div className={classNames('fs-7 text-secondary ps-2 d-flex')}>
          {label}
        </div>
        <div
          className={classNames(
            'form-control rounded-4 shadow-sm border-0 position-relative'
          )}
        >
          <input
            ref={ref}
            type="password"
            style={{ width: 'calc(100% - 20px)' }}
            onChange={e => {
              this.handleValueChanged(field_name, e)
            }}
          />
          <div
            className={classNames(
              'eye-icon theme-svg position-absolute bottom-0 end-0 translate-middle-y me-2'
            )}
            style={{
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '20px',
              width: '20px',
            }}
            label="show/hide"
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPassFieldValue(ref)
            }}
            onKeyPress={() => {
              this.handleShowPassFieldValue(ref)
            }}
          />
        </div>
      </div>
    )

    return html
  }

  render() {
    const { processing_request, server_response } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { status, data } = server_response

    return (
      <div className={classNames('container-fluid row mx-auto my-3 gy-3"')}>
        {this.drawPassField(
          'current_password',
          texts[locale].current_password,
          this.currentPassRef
        )}
        {this.drawPassField(
          'new_password',
          texts[locale].new_password,
          this.newPassRef
        )}
        <div className={classNames('d-flex flex-column')}>
          {!processing_request && (!status || status !== 200) && (
            <div
              className={classNames(
                'btn my-2 py-2 px-4 bg-1 fc-white ffamily-secondary'
              )}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleSubmitChangePasswordRequest()
              }}
              onKeyPress={() => {
                this.handleSubmitChangePasswordRequest()
              }}
            >
              {texts[locale].save}
            </div>
          )}
          {!processing_request && status && (
            <div className={classNames('border p-2 text-center')}>
              {data.message}
            </div>
          )}
          {processing_request && (
            <div
              className={classNames(
                'd-flex justify-content-center align-items-center p-2 shadow-sm'
              )}
            >
              {texts[locale].loading}
              <div
                className={classNames('loading-icon theme-svg ms-1')}
                style={{
                  backgroundPosition: 'center',
                  backgroundSize: '30px 30px',
                  backgroundRepeat: 'no-repeat',
                  height: '30px',
                  width: '30px',
                }}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ChangePasswordComponent)
