import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import { getCurrentPageDefinition } from '../components/page_layout/helper/pageFunctions'
import lsEntryMap from '../enums/lsEntryMap'
import pageInitialComponentsMap from '../enums/pageInitialComponentsMap'
import pageVisibilityMap from '../enums/pageVisibilityMap'
import screenSizeTypeMap from '../enums/screenSizeTypeMap'
import * as actionCreators from '../store/actions'
import { getSessionFromServer } from '../services/sessionHelper'
import { cloneObj, mapStateToProps, processLogout } from '../utils'

import '../assets/css/styles.scss'
import { lsGet } from '../services/localStorage'

require(`../../jujo_specializations/src/${process.env.client}/asset/styles.scss`)

const Helmet = loadable(() => import('react-helmet'))
const PageLayoutComponent = loadable(() => import('../components/page_layout'))

const LoginComponent = loadable(() =>
  import('../components/authentication/login')
)

const classNames = require('classnames')

class MainComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { authentication, environment } = this.props
    const { authentication: nextAuth, environment: nextEnv } = nextProps

    const { loaded } = this.state
    if (loaded !== nextState.loaded) {
      return true
    }
    if (environment.path !== nextEnv.path) {
      return true
    }
    if (authentication.force_login !== nextAuth.force_login) {
      return true
    }
    if (
      JSON.stringify(environment.permissions) !==
      JSON.stringify(nextEnv.permissions)
    ) {
      return true
    }
    if (
      Object.keys(authentication.user).length !==
      Object.keys(nextAuth.user).length
    ) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    console.log(`                                                                
      ...                  ...                   
      .....                .....                  
                                                  
      ####   #####  #####  #####     ##########   
      #####  #####  #####  #####   ############## 
      #####  #####  #####  #####  #   ##    ##   #
      #####  #####    ###  #####  ############### 
      #####  #####     ##  #####   ############## 
      #####    ########    #####      ########    
      #####                #####                  
    #######              #######          

    Software & Innovation 
    Enjoy the power of the ninja framework ... wataaaaaaaaaaaa :)
    `)
    await this.initEnvironment()

    await this.initSession()
    await this.initSpecialization()
    await this.initPath()
    await this.initScreenSize()
    await this.initSideController()
    await this.initAuthentication()
    await this.initCart()

    await this.verifyPermissionsOnPage()

    this.checkLocale()

    this.setState({ loaded: true })

    setTimeout(() => {
      this.initAnimationObserver()
    }, 0)
  }

  componentDidUpdate = async () => {
    const { loaded } = this.state
    if (loaded) {
      await this.initSession()
      await this.verifyPermissionsOnPage()
    }
  }

  initAnimationObserver = async () => {
    if (typeof document !== 'undefined') {
      const animated_class_list = ['scale-up-center', 'tracking-in-expand']

      for (let i = 0; i !== animated_class_list.length; i += 1) {
        const animated_class = animated_class_list[i]

        document.querySelectorAll(`.${animated_class}`).forEach(n => {
          const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                entry.target.classList.add(`animation-${animated_class}`)
                return
              }

              entry.target.classList.remove(`animation-${animated_class}`)
            })
          })
          observer.observe(n)
        })
      }
    }
  }

  checkLocale = () => {
    const { updateLocaleIntoStorageAndRedux } = this.props
    updateLocaleIntoStorageAndRedux()
  }

  initCart = async () => {
    const { updateCart } = this.props
    const storedCart = lsGet(lsEntryMap.cart)
    if (storedCart != null) {
      await updateCart(storedCart)
    }
  }

  initEnvironment = async () => {
    const { updateEnvironment } = this.props
    const storedEnv = lsGet(lsEntryMap.environment)
    await updateEnvironment(storedEnv)
  }

  initSession = async () => {
    const { updateSession, createNewSession } = this.props

    let storedSession = lsGet(lsEntryMap.session)
    if (storedSession === null) {
      await createNewSession()
      storedSession = lsGet(lsEntryMap.session)
    }

    const { session_id } = storedSession

    const session_data = await getSessionFromServer(session_id)
    storedSession.session_data = session_data
    await updateSession(storedSession)
  }

  getTranslations = async () => {
    const specialized_texts =
      await require(`../../jujo_specializations/src/${process.env.client}/translations/texts.json`)
    const jujo_texts = require('../translations/texts.json')

    const texts = {}
    const all_keys = Object.keys(jujo_texts)
    for (let i = 0; i !== all_keys.length; i += 1) {
      const k = all_keys[i]
      texts[k] = { ...jujo_texts[k], ...specialized_texts[k] }
    }

    return { texts }
  }

  initSpecialization = async () => {
    const { updateSpecialization } = this.props

    const spec = {}
    spec.actions =
      await require(`../../jujo_specializations/src/${process.env.client}/actions`)

    spec.config =
      await require(`../../jujo_specializations/src/${process.env.client}/config`)

    spec.translations = await this.getTranslations()

    await updateSpecialization(spec)
  }

  initPath = async () => {
    if (typeof window !== 'undefined') {
      const { environment, updateEnvironment } = this.props

      const { location } = window
      const { pathname } = location

      if (!environment.path || environment.path !== pathname) {
        const newEnvironment = cloneObj(environment)
        newEnvironment.path = pathname
        await updateEnvironment(newEnvironment)
      }
    }
  }

  initScreenSize = async () => {
    if (typeof window !== 'undefined') {
      const { environment, updateEnvironment } = this.props

      const { outerWidth } = window
      let screenSize = ''

      if (outerWidth > 1400) {
        screenSize = screenSizeTypeMap.xxl
      } else if (outerWidth >= 1200 && outerWidth < 1400) {
        screenSize = screenSizeTypeMap.xl
      } else if (outerWidth >= 992 && outerWidth < 1200) {
        screenSize = screenSizeTypeMap.lg
      } else if (outerWidth >= 768 && outerWidth < 992) {
        screenSize = screenSizeTypeMap.md
      } else if (outerWidth < 768) {
        screenSize = screenSizeTypeMap.sm
      }

      const newEnvironment = { ...environment }
      newEnvironment.screenSize = screenSize
      await updateEnvironment(newEnvironment)
    }
  }

  initSideController = async () => {
    const { environment, updateEnvironment } = this.props
    const { screenSize } = environment
    if (screenSize === screenSizeTypeMap.sm) {
      const newEnvironment = { ...environment }
      newEnvironment.sideControllerExpanded = false
      await updateEnvironment(newEnvironment)
    }
  }

  initAuthentication = async () => {
    const { reloadOrInitAuthenticationIntoRedux } = this.props
    reloadOrInitAuthenticationIntoRedux()
  }

  verifyPermissionsOnPage = async () => {
    const { environment, updatePermissionsIntoStorageAndRedux, session } =
      this.props
    const { path } = environment
    const { session_data } = session
    const { permissions } = session_data

    const pageDef = getCurrentPageDefinition(this.props)
    if (pageDef.visibility === pageVisibilityMap.public) {
      await updatePermissionsIntoStorageAndRedux(['r'])
      return
    }

    if (!permissions) {
      await updatePermissionsIntoStorageAndRedux([])
      return
    }

    let pagePermissions = permissions[`${path.substring(1)}/*`]
    if (pagePermissions === undefined) {
      const wildcard = `${path.split('/')[1]}/*`
      pagePermissions = permissions[wildcard] || ''
    }
    pagePermissions = pagePermissions === '' ? [] : pagePermissions.split('-')

    await updatePermissionsIntoStorageAndRedux(pagePermissions)
  }

  getPageComponent = () => {
    const { loaded } = this.state
    const { authentication, environment } = this.props
    const { user, force_login } = authentication

    if (!loaded) return pageInitialComponentsMap.loaded
    if (force_login) return pageInitialComponentsMap.login

    const isUserLogged = Object.keys(user).length > 0
    const pageDef = getCurrentPageDefinition(this.props)
    const { visibility } = pageDef

    if (!isUserLogged && visibility === pageVisibilityMap.private) {
      return pageInitialComponentsMap.login
    }

    const { permissions } = environment
    if (
      isUserLogged &&
      visibility === pageVisibilityMap.private &&
      permissions.length === 0
    ) {
      return pageInitialComponentsMap.no_permissions
    }

    return pageInitialComponentsMap.page
  }

  injectSpecializedJavascript = node => {
    try {
      const DynamicInjectionFunctions = require(`../../jujo_specializations/src/${process.env.client}/actions/injections`)
      const { customHelmetJSCode } = DynamicInjectionFunctions
      const code = customHelmetJSCode()

      return {
        ...node,
        props: {
          ...node.props,
          children: code,
        },
      }
    } catch (e) {
      return node
    }
  }

  render() {
    const { environment, specialization } = this.props
    const { locale } = environment

    const componentToShow = this.getPageComponent()
    return (
      <>
        <Helmet>
          <link
            rel="icon"
            type="image/png"
            href={`${process.env.site_url + process.env.client}/favicon.ico`}
          />

          {this.injectSpecializedJavascript(<script type="text/javascript" />)}

          <html lang={locale} className={classNames({ root: true })} />
        </Helmet>

        {componentToShow === pageInitialComponentsMap.loaded && <div />}
        {componentToShow === pageInitialComponentsMap.login && (
          <LoginComponent />
        )}
        {componentToShow === pageInitialComponentsMap.no_permissions && (
          <div
            className={classNames(
              'container d-flex flex-column m-auto justify-content-center text-center p-4 border rounded shadow-sm my-5'
            )}
          >
            <div>
              {specialization.translations.texts[locale].no_permissions}
            </div>
            <div
              className={classNames(
                'd-flex justify-content-center align-items-center'
              )}
            >
              <div
                className={classNames('fw-bold border-bottom my-4')}
                role="button"
                tabIndex={0}
                onClick={() => {
                  processLogout(this)
                }}
                onKeyPress={() => {
                  processLogout(this)
                }}
              >
                {specialization.translations.texts[locale].go_to_login}
              </div>
            </div>
          </div>
        )}
        {componentToShow === pageInitialComponentsMap.page && (
          <PageLayoutComponent />
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MainComponent)
