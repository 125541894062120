const gridCustomComponentsMap = {
  baguette: 'baguette',
  boolean: 'boolean',
  country: 'country',
  custom_button: 'custom_button',
  date: 'date',
  delete_button: 'delete_button',
  edit_button: 'edit_button',
  grouped_components: 'grouped_components',
  image: 'image',
  server_translated: 'server_translated',
  view_button: 'view_button',
}

export default gridCustomComponentsMap
