/* eslint-disable react/no-danger */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import {
  lockBodyScroll,
  mapStateToProps,
  retrieveComponent,
  unlockBodyScroll,
} from '../../utils'

const classNames = require('classnames')

export class MessageBoxComponent extends React.Component {
  componentWillUnmount() {
    unlockBodyScroll()
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = () => {
    lockBodyScroll()
  }

  renderButtons = () => {
    const { data } = this.props
    const { messageBox } = data
    const { buttons } = messageBox

    const html = []

    for (let i = 0; i !== buttons.length; i += 1) {
      const btn = buttons[i]
      const { text, handleFunction, params, stylePrimary } = btn

      html.push(
        <div
          key={`btn_${i}`}
          className={classNames(
            'text-center p-1 fs-7 rounded-50 mx-2 px-2 py-1',
            stylePrimary
              ? 'bg-1 bg1-gradient-270deg fc-white'
              : 'bg-white bshadow-c1 fc-1'
          )}
          style={{ minWidth: '100px' }}
          role="button"
          tabIndex={0}
          onClick={async () => {
            await handleFunction(params)
          }}
          onKeyPress={async () => {
            await handleFunction(params)
          }}
        >
          {text}
        </div>
      )
    }

    return html
  }

  handleClose = () => {
    const { comp_id } = this.props
    retrieveComponent(this, comp_id)
  }

  render() {
    const { specialization, environment, data } = this.props
    const { messageBox } = data
    const { icon, title, message } = messageBox

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <>
        <div
          className={classNames(
            'position-fixed top-0 start-0 bg-gray zindex-2'
          )}
          style={{
            height: '100vh',
            width: '100vw',
            backgroundBlendMode: 'multiply',
            mixBlendMode: 'multiply',
          }}
        />
        <div
          className={classNames(
            'bg-3 position-fixed top-50 start-50 translate-middle zindex-2 rounded-30 shadow'
          )}
          style={{ width: 'min(90%, 380px)', height: 'min(90%, 420px)' }}
        >
          <div className={classNames('d-flex flex-column p-4')}>
            <div
              className={classNames(
                'close-icon theme-svg-4 d-flex align-self-end'
              )}
              style={{
                height: '14px',
                width: '14px',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              role="button"
              tabIndex={0}
              label="close"
              onClick={() => {
                this.handleClose()
              }}
              onKeyPress={() => {
                this.handleClose()
              }}
            />
            <div
              className={classNames(
                'd-flex flex-column align-items-center position-absolute top-50 start-50 translate-middle w-100'
              )}
            >
              {icon && (
                <div
                  className={classNames(icon)}
                  style={{
                    height: '70px',
                    width: '80px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                />
              )}
              {title && (
                <div className={classNames('text-center fw-bold fs-5 mt-2')}>
                  {title !== '' ? title : texts[locale].warning}
                </div>
              )}
              <div
                className={classNames('fc-1 fs-6 text-center mt-4 text-break')}
                style={{
                  maxHeight: '185px',
                  width: 'min(100%, 310px)',
                  overflowY: 'auto',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: message,
                  }}
                />
              </div>
              <div className={classNames('d-flex justify-content-center my-4')}>
                {this.renderButtons()}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MessageBoxComponent)
