import React from 'react'
import { connect } from 'react-redux'
import JujoGridComponent from '../../jujo_grid'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class JujoGridViewComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  render() {
    const { entity } = this.props

    return (
      <div className={classNames('')}>
        <JujoGridComponent
          entity={entity}
          editableComponentProps={this.props}
        />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoGridViewComponent)
