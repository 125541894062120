import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ECQuantitySelectorFieldComponent extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, handleValueChanged, initialValue } = this.props
    const { name, max_quantity } = field
    return (
      <div className={classNames('d-flex align-items-center')}>
        <div
          className={classNames('minus-circle-icon theme-svg')}
          style={{
            height: '22px',
            width: '22px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: initialValue === 0 ? '0.3' : '1',
          }}
          label="decrease quantity"
          role="button"
          tabIndex={0}
          // eslint-disable-next-line no-loop-func
          onClick={async () => {
            if (initialValue === 0) return
            handleValueChanged(name, initialValue - 1)
          }}
          // eslint-disable-next-line no-loop-func
          onKeyPress={async () => {
            if (initialValue === 0) return
            handleValueChanged(name, initialValue - 1)
          }}
        />
        <div className={classNames('fw-bold mx-2 fs-5 fc-black')}>
          {initialValue}
        </div>
        <div
          className={classNames('plus-circle-icon theme-svg')}
          style={{
            height: '22px',
            width: '22px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: max_quantity === initialValue ? '0.3' : '1',
          }}
          label="increase quantity"
          role="button"
          tabIndex={0}
          // eslint-disable-next-line no-loop-func
          onClick={async () => {
            if (initialValue === max_quantity) return
            handleValueChanged(name, initialValue + 1)
          }}
          // eslint-disable-next-line no-loop-func
          onKeyPress={async () => {
            if (initialValue === max_quantity) return
            handleValueChanged(name, initialValue + 1)
          }}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECQuantitySelectorFieldComponent)
