import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

import './styles.scss'

const classNames = require('classnames')

export class TabsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      selectedTabIndex: -1,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { selectedTabIndex } = this.state
    if (selectedTabIndex !== nextState.selectedTabIndex) {
      return true
    }
    return false
  }

  componentDidMount = () => {
    const { environment, label } = this.props
    const { tabs } = environment

    this.setState({
      selectedTabIndex: tabs[label] || 0,
      initialized: true,
    })
  }

  handleTabSelected = idx => {
    const { updateTabIntoStorageAndRedux, label, tabList } = this.props
    updateTabIntoStorageAndRedux(label, idx)
    updateTabIntoStorageAndRedux('definition', tabList[idx])
    this.setState({ selectedTabIndex: idx }, () => {})
  }

  renderTabs = () => {
    const { environment, tabList, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { selectedTabIndex } = this.state

    const html = []
    for (let i = 0; i !== tabList.length; i += 1) {
      const tabDef = tabList[i]
      const { tabLabel } = tabDef
      html.push(
        <div
          key={uuid()}
          className={classNames(
            'col-12 col-md text-center text-nowrap align-self-center py-2 ffamily-secondary fst-uppercase',
            selectedTabIndex === i
              ? 'bshadow-white'
              : 'bshadow-c1 bg-white fc-1'
          )}
          role="button"
          tabIndex={0}
          onClick={() => {
            this.handleTabSelected(i)
          }}
          onKeyPress={() => {
            this.handleTabSelected(i)
          }}
        >
          {texts[locale][tabLabel] || tabLabel}
        </div>
      )
    }
    return html
  }

  render() {
    const { initialized } = this.state
    return (
      <>
        {initialized && (
          <div
            className={classNames(
              'container-fluid mx-auto p-0 mb-2 bg-1 fc-white fs-7 ffamily-secondary'
            )}
          >
            <div className={classNames('row m-0 justify-content-center')}>
              {this.renderTabs()}
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(TabsComponent)
