import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECCardPickerFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ initialized: true })
  }

  render() {
    const { initialized } = this.state
    const {
      specialization,
      environment,
      field,
      initialValue,
      handleValueChanged,
    } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const { name, options } = field

    return (
      <>
        {!initialized && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '100%' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '25px 25px',
                backgroundRepeat: 'no-repeat',
                height: '25px',
                width: '25px',
              }}
            />
          </div>
        )}
        {initialized && (
          <div className={classNames('row')}>
            {options.map(opt => (
              <div
                key={opt.value}
                className={classNames(
                  'col-12 col-md mx-2 my-1 d-flex d-flex px-3 py-2 rounded-5 align-items-center',
                  initialValue === opt.value
                    ? 'bg-1 fc-white'
                    : 'bg-white bshadow-c4 fc-4 '
                )}
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleValueChanged(name, opt.value)
                }}
                onKeyPress={() => {
                  handleValueChanged(name, opt.value)
                }}
              >
                <div
                  className={classNames(
                    opt.icon.name,
                    initialValue === opt.value ? 'white-svg' : 'theme-svg-4'
                  )}
                  style={{
                    width: opt.icon.width,
                    minWidth: opt.icon.width,
                    height: opt.icon.height,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                />
                <div className={classNames('ms-2')}>
                  <div className={classNames('fs-8 fw-bold mb-2')}>
                    {texts[locale][opt.title] || opt.title}
                  </div>
                  <div className={classNames('fs-9')}>
                    {texts[locale][opt.description] || opt.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECCardPickerFieldComponent)
