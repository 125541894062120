import React from 'react'
import { connect } from 'react-redux'

import JoditEditor from 'jodit-react'

import * as actionCreators from '../../../../store/actions'
import { GenericField } from './genericField'
import { mapStateToProps } from '../../../../utils'

export class RichTextField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field } = this.props

    const html = []

    const config = {
      enter: 'div',
      useSplitMode: true,
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
    }

    html.push(
      <JoditEditor
        key={field.name}
        value={fieldValue}
        config={config}
        tabIndex={0} // tabIndex of textarea
        onBlur={content => {
          this.processOnBlur(content)
        }}
      />
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(RichTextField)
