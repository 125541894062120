import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { httpGet } from '../../../../../../../src/services/apiService'
import { translate } from '../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../src/components/loading')
)

function SvgImposeModel({ impose_model_id, onChange }) {
  const [options, setOptions] = useState([])

  const initData = async () => {
    const ep = 'key-value-list/impose-model'
    const response = await httpGet(`${process.env.apiUrl}${ep}`)

    const { status, data } = response
    if (status === 200 || status === 202) {
      const { data: opts } = data
      setOptions(opts)
    }
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [impose_model_id])

  const handleImposeModelChanged = e => {
    const { value: selected_impose_model_id } = e.target
    onChange('impose_model', selected_impose_model_id)
  }

  return (
    <div className={classNames('')}>
      {options.length === 0 && <JujoLoading />}
      {options.length > 0 && (
        <div className={classNames('bg-white p-2 mb-2 border')}>
          <select
            onChange={e => handleImposeModelChanged(e)}
            defaultValue={impose_model_id}
          >
            <option value={0}>{translate('select')}</option>
            {options.map(opt => {
              const { id, name } = opt
              return (
                <option key={`opt_${id}`} value={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
      )}
    </div>
  )
}

export default SvgImposeModel
