import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { jujoHexDecode, jujoHexEncode, mapStateToProps } from '../../../utils'

export class ECTextFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { field, initialValue, handleValueChanged } = this.props

    const { name, disabled } = field
    const { uppercase, lowercase, maxLength, inputMode } = field || false

    const useHexEncoding = field.useHexEncoding || false
    const parsed_initial_value = useHexEncoding
      ? jujoHexDecode(initialValue)
      : initialValue

    let text_transform = uppercase ? 'uppercase' : ''
    text_transform = lowercase ? 'lowercase' : text_transform
    const inputModeParameter = inputMode || 'text'
    return (
      <input
        type="text"
        defaultValue={parsed_initial_value}
        disabled={disabled || false}
        maxLength={maxLength}
        inputMode={inputModeParameter}
        onChange={e => {
          let { value } = e.target
          value = uppercase ? value.toUpperCase() : value
          value = lowercase ? value.toLowerCase() : value

          const parsed_value = useHexEncoding ? jujoHexEncode(value) : value
          if (handleValueChanged !== null)
            handleValueChanged(name, parsed_value)
        }}
        style={{ textTransform: text_transform }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECTextFieldComponent)
