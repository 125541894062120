import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGTextAreaManager({ element_key }) {
  const [editable_element, setEditableElement] = useState(null)

  const initData = async () => {
    const foreign_element = document.getElementById(`foreign_${element_key}`)
    const e_element = foreign_element.querySelector('div')

    setEditableElement(e_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const getX = () => {
    const { style } = editable_element
    const m_left = style['margin-left']
    const parsed_mleft = parseFloat(m_left).toFixed(2)

    return parsed_mleft
  }
  const getY = () => {
    const { style } = editable_element
    const m_top = style['margin-top']
    const parsed_mtop = parseFloat(m_top).toFixed(2)

    return parsed_mtop
  }
  const getWidth = () => {
    const { style } = editable_element
    const { width } = style
    const parsed_width = parseFloat(width).toFixed(2)

    return parsed_width
  }

  const handleXChanged = e => {
    const { value: x } = e.target
    editable_element.style['margin-left'] = `${x}px`
  }
  const handleYChanged = e => {
    const { value: y } = e.target
    editable_element.style['margin-top'] = `${y}px`
  }
  const handleWidthChanged = e => {
    const { value: width } = e.target
    editable_element.style.width = `${width}px`
  }

  return (
    <>
      {editable_element === null && <JujoLoading />}
      {editable_element !== null && (
        <div className={classNames('')}>
          <div className={classNames('')}>
            <div>{translate('area_management')}</div>
          </div>
          <div className={classNames('d-flex flex-column flex-md-row')}>
            <div className={classNames('me-md-2')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('x')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={getX()}
                  onChange={e => handleXChanged(e)}
                />
              </div>
            </div>
            <div className={classNames('')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('y')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={getY()}
                  onChange={e => handleYChanged(e)}
                />
              </div>
            </div>
          </div>
          <div className={classNames('')}>
            <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
              {translate('width')}
            </div>
            <div className={classNames('bg-white px-2 py-1 rounded-5')}>
              <input
                type="number"
                defaultValue={getWidth()}
                onChange={e => handleWidthChanged(e)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGTextAreaManager
