import loadable from '@loadable/component'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import {
  embedStyleCode,
  mapStateToProps,
  removeStyleCode,
  translate,
} from '../../../../utils'

const JujoLoading = loadable(() => import('../../../loading'))

const classNames = require('classnames')

export class ViewVariantComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      ffamily: '',
      ff_key: '',
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    const { ff_key } = this.state

    if (typeof document !== 'undefined') {
      removeStyleCode(document, ff_key)
    }
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { transitionaData } = this.props
    const { key, src, weight, style } = transitionaData

    const ff_key = `ff_${key}`

    if (typeof document !== 'undefined') {
      const font_face_code = `
        @font-face {
          font-family:'${key}';
          src: url('${src}');
          font-weight: ${weight};
          font-style: ${style};
      }`

      embedStyleCode(document, font_face_code, 'head', ff_key)
    }

    this.setState({ ff_key, ffamily: `'${key}'`, loading: false })
  }

  handleEditVariantClicked = async () => {
    const { updateFlowState, updateFlowData, transitionaData } = this.props

    await updateFlowData(transitionaData)
    await updateFlowState('edit_variant')
  }

  handleDeleteVariantClicked = async () => {
    const { transitionaData, injections } = this.props
    const { deleteVariant } = injections
    const { key } = transitionaData

    await deleteVariant(key)
  }

  renderVariant = () => {
    const { ffamily } = this.state
    const { transitionaData } = this.props

    const { weight, style, stretch } = transitionaData

    const html = []
    html.push(
      <div
        key={ffamily}
        className={classNames('d-flex flex-column text-center text-md-row')}
      >
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-md-between'
          )}
        >
          <div className={classNames('d-flex flex-column flex-md-row')}>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-start me-md-2'
              )}
            >
              <div className={classNames('ffamily-secondary')}>
                {`${translate('weight')}:`}
              </div>
              <div className={classNames('fc-1 fw-bold ms-1')}>{weight}</div>
            </div>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-start me-md-2'
              )}
            >
              <div className={classNames('ffamily-secondary')}>
                {`${translate('style')}:`}
              </div>
              <div className={classNames('fc-1 fw-bold ms-1')}>{style}</div>
            </div>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-start me-md-2'
              )}
            >
              <div className={classNames('ffamily-secondary')}>
                {`${translate('stretch')}:`}
              </div>
              <div className={classNames('fc-1 fw-bold ms-1')}>{stretch}</div>
            </div>
          </div>
          <div
            className={classNames('d-flex justify-content-center my-2 my-md-0')}
          >
            <div
              className={classNames(
                'background-image theme-svg edit-icon mx-1'
              )}
              style={{ width: 20, height: 20 }}
              role="button"
              label="edit-variant"
              tabIndex={0}
              onClick={() => {
                this.handleEditVariantClicked()
              }}
              onKeyPress={() => {
                this.handleEditVariantClicked()
              }}
            />
            <div
              className={classNames(
                'background-image theme-svg trash-icon mx-1'
              )}
              style={{ width: 20, height: 20 }}
              role="button"
              label="delete-variant"
              tabIndex={0}
              onClick={() => {
                this.handleDeleteVariantClicked()
              }}
              onKeyPress={() => {
                this.handleDeleteVariantClicked()
              }}
            />
          </div>
        </div>
        <div
          className={classNames('mt-3')}
          style={{ fontSize: 20, fontFamily: ffamily }}
        >
          {translate('font_family_preview_label')}
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.renderVariant()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ViewVariantComponent)
