import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import { defaultManageResponse } from '../../../../../../src/utils'

export async function getImposeModelData(sender, impose_model_id) {
  const { props } = sender
  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'impose_model',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'get-data',
    pDefValue: 'get-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
    impose_model_id,
  })

  const { status, data } = response

  if (status === 200 || status === 201) {
    return data.data
  }
  return {}
}

export async function execGenerationProcess(sender, action) {
  const { props } = sender
  const { environment, authentication, row } = props

  const { id } = row

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'impose_model',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: action,
    pDefValue: action,
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
    id,
  })

  defaultManageResponse(sender, {}, response)
}

export async function generateImposedPrinting(sender) {
  const action = 'generate-imposed-printing'
  execGenerationProcess(sender, action)
}

export async function generateDieCut(sender) {
  const action = 'generate-die-cut'
  execGenerationProcess(sender, action)
}
