import React from 'react'
import classNames from 'classnames'
import { randomString, translate } from '../../../../../utils'
import {
  bindSvgDragActionToImageElement,
  bindSvgDragActionToTextElement,
} from '../../../../../utils/svg'
import { bindDoubleClickToElement } from '../../../../../utils/svg/double_click'

function JujoSVGDuplicateBtn({ svg_id, element_key, setFocusedElement }) {
  const handleDuplicateClicked = () => {
    if (element_key) {
      const element = document.getElementById(element_key)
      const cloned_element = element.cloneNode(true)

      const new_id = randomString(16)
      const current_label = cloned_element.getAttribute('label')

      cloned_element.setAttribute('id', new_id)
      cloned_element.setAttribute('label', `${current_label}_duplicated`)

      cloned_element.innerHTML = cloned_element.innerHTML.replaceAll(
        element_key,
        new_id
      )

      document.getElementById(svg_id).append(cloned_element)

      setTimeout(() => {
        const image_obj = cloned_element.querySelector('image')

        if (image_obj) {
          bindSvgDragActionToImageElement(svg_id, cloned_element)
          bindDoubleClickToElement(svg_id, cloned_element, setFocusedElement)
        } else {
          bindSvgDragActionToTextElement(svg_id, cloned_element)
          bindDoubleClickToElement(svg_id, cloned_element, setFocusedElement)
        }

        element.querySelector('rect[id=rect_border]').remove()

        setFocusedElement(new_id)
        // const doubleClickEvent = new Event('dblclick', {})
        // cloned_element.querySelector('text').dispatchEvent(doubleClickEvent)
      }, '1')
    }
  }

  return (
    <div>
      <div
        className={classNames(
          'd-flex flex-column align-items-center w-80px w-md-70px border border-md-unset p-1 p-md-0 m-1 m-md-0',
          element_key === null ? 'opacity-5' : ''
        )}
        role="button"
        tabIndex={0}
        onClick={() => handleDuplicateClicked()}
        onKeyPress={() => handleDuplicateClicked()}
      >
        <div className={classNames('fs-8')}>{translate('duplicate')}</div>
        <div
          className={classNames('background-image duplicate-icon')}
          style={{ width: 25, height: 25 }}
        />
      </div>
    </div>
  )
}

export default JujoSVGDuplicateBtn
