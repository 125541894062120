import React from 'react'
import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import uuid from 'react-uuid'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  defaultHandleOpenJujoFileFromServer,
  formatDate,
  jujoDecoder,
  mapStateToProps,
  translate,
} from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import AdminCommandLineComponent from './_parts/admin_command_line'
import CustomerCommandsComponent from './_parts/customer_commands'
import previewStatusMap from '../../../enums/preview_status_map'

const classNames = require('classnames')

export class PreviewComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    // overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  /* A function that is called when the component is mounted. It is used to fetch the data from the
  server. */
  performActionOnDidMount = async () => {
    const { component_data } = this.state
    const { environment, authentication, data } = this.props
    const { order_id } = data

    const cloned_data = cloneObj(component_data)
    cloned_data.loading = true
    await this.updateData(cloned_data)

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-preview-data',
      pDefValue: 'get-preview-data',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
      order_id,
    })

    const { status } = response

    let message_list = []
    let order_details = {}
    if (status === 200 || status === 201) {
      order_details = { ...response.data.data }
      delete order_details.preview_data
      message_list = [...response.data.data.preview_data]
    }

    /* Updating the state of the component. */
    cloned_data.loading = false
    cloned_data.order_details = order_details
    cloned_data.message_list = message_list

    await this.updateData(cloned_data)
  }

  openUrl = url => {
    const { authentication } = this.props
    const { access_token } = authentication

    const file_url = `${url}?access_token=${access_token}`
    defaultHandleOpenJujoFileFromServer(this, file_url)
  }

  renderAttachments = list => {
    const html = []
    html.push(
      <div
        key={uuid()}
        className={classNames('mt-2 p-2 rounded-2 bg-gray-100 bg-white')}
      >
        {list.map((attachment, idx) => {
          const { name, url } = attachment
          return (
            <div
              key={`attachment_${idx}`}
              className={classNames(
                'd-flex flex-column flex-md-row align-items-center'
              )}
              role="button"
              tabIndex={0}
              onClick={() => this.openUrl(url)}
              onKeyPress={() => this.openUrl(url)}
            >
              <div
                className={classNames(
                  'd-none d-md-block background-image attachment-icon theme-svg me-1'
                )}
                style={{ height: 20, width: 20 }}
              />
              <div className={classNames('text-break fc-1 fs-8')}>{name}</div>
            </div>
          )
        })}
      </div>
    )
    return html
  }

  renderMessages = () => {
    const { component_data } = this.state
    const { message_list } = component_data

    const { data, comp_id, authentication } = this.props

    const { sender, order_id } = data

    const logged_in_user = authentication.user

    const html = []

    if (message_list.length === 0) {
      html.push(
        <div
          key="no_preview_message"
          className={classNames('text-center p-4 fst-italic')}
        >
          {translate('no_previews_message')}
        </div>
      )
    } else {
      for (let i = message_list.length - 1; i >= 0; i -= 1) {
        const message = message_list[i]
        const {
          text,
          attachments,
          status,
          datetime,
          feedback,
          feedback_datetime,
          feedback_attachments,
          approved_datetime,
        } = message

        const decoded_text = jujoDecoder(text)
        const decoded_feedback = jujoDecoder(feedback)

        html.push(
          <div
            key={`message_${i}`}
            className={classNames(
              'rounded-3 p-3 mb-2 border border-2',
              status === previewStatusMap.waiting_for_feedback &&
                'border-color-1',
              status === previewStatusMap.change_request && 'border-danger',
              status === previewStatusMap.approved && 'border-success'
            )}
          >
            <div className={classNames('mb-3 pb-2 border-bottom border-1')}>
              <span> {`${translate('preview_status')}: `}</span>
              <span
                className={classNames(
                  'fw-bold',
                  status === previewStatusMap.waiting_for_feedback && 'fc-1',
                  status === previewStatusMap.change_request && 'text-danger',
                  status === previewStatusMap.waiting_for_feedback &&
                    'text-success'
                )}
              >
                {translate(status) || status}
              </span>
              {approved_datetime && (
                <span className={classNames('fst-italic fs-8 ms-1')}>
                  (
                  {formatDate(approved_datetime, false, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  )
                </span>
              )}
            </div>
            <div className={classNames('d-froot')}>
              <div
                className={classNames(
                  'background-image message-bubble-right-arrow theme-svg float-end w-20px h-20px'
                )}
                style={{ marginLeft: '-1px' }}
              />
              <div
                className={classNames(
                  'd-flex flex-column bg-1 p-2 fc-white float-end rounded-start-bottom-5 pe-md-4 shadow-sm'
                )}
                style={{ maxWidth: '85%' }}
              >
                <div
                  className={classNames('background-image logo-white mb-1')}
                  style={{
                    height: 18,
                    width: 120,
                  }}
                />
                <div> {Parser(decoded_text, {})}</div>
                {attachments &&
                  attachments.length > 0 &&
                  this.renderAttachments(attachments)}
                {datetime && (
                  <div className={classNames('fst-italic fs-8 mt-1')}>
                    {formatDate(datetime, false, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </div>
                )}
              </div>
            </div>
            {feedback && (
              <div className={classNames('d-froot mt-2')}>
                <div
                  className={classNames(
                    'background-image message-bubble-left-arrow white-svg float-start w-20px h-20px'
                  )}
                  style={{ marginRight: '-1px' }}
                />
                <div
                  className={classNames(
                    'd-flex flex-column float-start bg-white p-2 rounded-end-bottom-5 pe-md-4 shadow-sm'
                  )}
                  style={{ maxWidth: '85%' }}
                >
                  <div>{Parser(decoded_feedback, {})}</div>
                  {feedback_attachments &&
                    feedback_attachments.length > 0 &&
                    this.renderAttachments(feedback_attachments)}
                  {feedback_datetime && (
                    <div className={classNames('fst-italic fs-8 mt-1')}>
                      {formatDate(feedback_datetime, false, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
            {status === previewStatusMap.waiting_for_feedback &&
              logged_in_user.permissions_id > 2 &&
              i === message_list.length - 1 && (
                <CustomerCommandsComponent
                  data={{ sender, comp_id, order_id, message_list }}
                />
              )}
          </div>
        )
      }
    }

    return html
  }

  renderBody = () => {
    this.fieldRef = React.createRef()

    const { component_data } = this.state
    const { message_list, order_details } = component_data

    const { authentication, data, comp_id } = this.props
    const { user } = authentication
    const { permissions_id } = user

    const { sender, customer_id, order_id } = data

    const { first_name, last_name, email, phone, status } = order_details
    const html = []

    html.push(
      <div key="preview_component">
        <div
          className={classNames(
            'px-2 px-md-4 d-flex flex-column overflow-y-scroll'
          )}
          style={{
            height: permissions_id <= 2 ? 'calc(100vh - 320px)' : '90vh',
          }}
        >
          {permissions_id <= 2 && (
            <div
              className={classNames(
                'sticky-md-top p-2 bg-light-gray mb-2 fs-5 border-bottom d-flex text-center text-md-unset justify-content-center justify-content-md-start'
              )}
            >
              <div className={classNames('d-flex flex-column')}>
                <div
                  className={classNames(
                    'd-flex flex-column flex-md-row fw-bold'
                  )}
                >
                  <div className={classNames('fc-1 me-md-2')}>
                    {`${translate('order_number')} ${order_id}`}
                  </div>
                  <div>{translate(status)}</div>
                </div>
                <div
                  className={classNames(
                    'me-auto d-flex flex-column flex-md-row'
                  )}
                >
                  <div className={classNames('fw-bold me-md-2')}>
                    {`${first_name} ${last_name} (${customer_id})`}
                  </div>
                  <div>
                    <a
                      className={classNames(
                        'fw-bold fc-1 text-decoration-none'
                      )}
                      href={`https://wa.me/${phone.replace(/ /g, '')}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {phone}
                    </a>
                  </div>
                  <div className={classNames('mx-1 d-none d-md-flex')}>/</div>
                  <div>
                    <a
                      className={classNames(
                        'fw-bold fc-1 text-decoration-none'
                      )}
                      href={`mailto:${email}`}
                    >
                      {email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={classNames('')}>{this.renderMessages()}</div>
        </div>
        {permissions_id <= 2 && (
          <AdminCommandLineComponent
            data={{ sender, customer_id, order_id, comp_id, message_list }}
          />
        )}
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(PreviewComponent)
