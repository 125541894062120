/* eslint-disable react/no-unused-state */
import loadable from '@loadable/component'
import React from 'react'
import { connect } from 'react-redux'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../../../../src/components/message_box/helper'

import * as actionCreators from '../../../../../../../src/store/actions'
import {
  bindElementToComponent,
  cloneObj,
  injectComponent,
  jujoEncoder,
  mapStateToProps,
  retrieveBindedElement,
  retrieveComponent,
  translate,
} from '../../../../../../../src/utils'
import { savePreview } from '../../../../actions/orders'
import orderStatusMap from '../../../../enums/order_status_map'
import previewStatusMap from '../../../../enums/preview_status_map'

const JujoButtonComponent = loadable(() =>
  import('../../../../../../../src/components/jujo_button')
)

const ECTextAreaFieldComponent = loadable(() =>
  import('../../../../../../../src/components/common/fields/textAreaField')
)
const ECRadioCheckFieldComponent = loadable(() =>
  import(
    '../../../../../../../src/components/common/fields/radioButtonCheckBoxField'
  )
)
const ECDroppableFieldComponent = loadable(() =>
  import('../../../../../../../src/components/common/fields/droppableField')
)

const classNames = require('classnames')

export class CustomerCommandsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show_approve_block: false,
      show_feedback_block: false,
      data_source: {
        feedback: '',
        feedback_attachments: [],
        approved_conditions: {},
      },
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = () => {
    const { data } = this.props
    const { sender } = data
    bindElementToComponent(
      this,
      'cloned_reload_data_func',
      sender.props.reloadData
    )
  }

  handleChangeRequestClicked = async () => {
    this.setState({ show_feedback_block: true, show_approve_block: false })
  }

  handleApproveClicked = async () => {
    this.setState({ show_approve_block: true, show_feedback_block: false })
  }

  handlePreviewFeedbackChanged = async (_name, value) => {
    const { data_source } = this.state
    const updated_data_source = cloneObj(data_source)
    updated_data_source.feedback = jujoEncoder(value)
    this.setState({ data_source: updated_data_source })
  }

  handleApproveConditionsChanged = async (name, value) => {
    const { data_source } = this.state
    const updated_data_source = cloneObj(data_source)
    updated_data_source[name] = value
    this.setState({ data_source: updated_data_source })
  }

  handleFileUploaded = async (_name, value) => {
    this.setState({ feedback_attachments: value })
  }

  handleCompleteApproveRequest = async () => {
    const { data_source } = this.state

    const { approved_conditions } = data_source

    const approved_conditions_count = Object.keys(approved_conditions).filter(
      x => approved_conditions[x] === true
    ).length

    if (approved_conditions_count !== 5) {
      this.notifyValidationError(
        translate('preview_approval_no_condition_selected_error_message')
      )
      return
    }
    const { data } = this.props
    const { order_id, message_list } = data

    const cloned_message_list = cloneObj(message_list)
    const current_message = cloned_message_list[cloned_message_list.length - 1]

    current_message.status = previewStatusMap.approved
    current_message.approved_conditions = approved_conditions
    current_message.approved_datetime = Date.now()

    const preview_data = {
      order_id,
      messages: cloned_message_list,
      status: orderStatusMap.wc_vss,
    }
    await savePreview(this, preview_data)
    retrieveBindedElement(this, 'cloned_reload_data_func')()
  }

  handleCompleteChangeRequest = async () => {
    const { data_source, feedback_attachments } = this.state
    const { data } = this.props
    const { order_id, message_list } = data

    const { feedback } = data_source

    if (feedback.length === 0) {
      this.notifyValidationError(
        translate('preview_approval_no_feedback_error_message')
      )
      return
    }

    const cloned_message_list = cloneObj(message_list)
    const current_message = cloned_message_list[cloned_message_list.length - 1]

    current_message.status = previewStatusMap.change_request
    current_message.feedback = feedback
    current_message.feedback_datetime = Date.now()
    current_message.feedback_attachments = feedback_attachments

    const preview_data = {
      order_id,
      messages: cloned_message_list,
      status: orderStatusMap.wc_inattesaanteprima,
    }
    await savePreview(this, preview_data)
    retrieveBindedElement(this, 'cloned_reload_data_func')()
  }

  notifyValidationError = message => {
    const messageBox = getEmptyMessageBox()

    messageBox.icon = 'warning-icon-color'
    messageBox.message = message

    const continue_btn = createMSBDef(
      translate('continue_browsing'),
      () => {
        retrieveComponent(this, messageBox.id)
      },
      {},
      true
    )
    messageBox.buttons.push(continue_btn)

    const component_definition = {
      specialized: false,
      path: 'message_box',
      data: {
        messageBox,
      },
    }

    injectComponent(this, messageBox.id, component_definition)
  }

  render() {
    const { show_approve_block, show_feedback_block, data_source } = this.state
    const { authentication } = this.props
    const { user } = authentication
    const customer_id = user.ID

    return (
      <>
        <div
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-center justify-content-md-end align-items-center mt-4 pt-2 border-top border-1'
          )}
        >
          <JujoButtonComponent
            bwidth={200}
            bclasses="bg-orange-500 fc-white"
            bicon="ko-icon white-svg"
            blabel={translate('change_request_action')}
            bdata={{}}
            handleClick={this.handleChangeRequestClicked}
          />
          <JujoButtonComponent
            bwidth={200}
            bclasses="bg-green-500 fc-white"
            bicon="ok-icon white-svg"
            blabel={translate('approve')}
            bdata={{}}
            handleClick={this.handleApproveClicked}
          />
        </div>
        {show_approve_block && (
          <div className={classNames('my-4')}>
            <div>{translate('preview_approval_conditions_before')}</div>
            <div className={classNames('py-1 rounded-3 mb-3')}>
              <ECRadioCheckFieldComponent
                field={{
                  name: 'approved_conditions',
                  type: 'checkbox',
                  options: [
                    {
                      key: 'condition_a',
                      label: translate('preview_approval_condition_a'),
                    },
                    {
                      key: 'condition_b',
                      label: translate('preview_approval_condition_b'),
                    },
                    {
                      key: 'condition_c',
                      label: translate('preview_approval_condition_c'),
                    },
                    {
                      key: 'condition_d',
                      label: translate('preview_approval_condition_d'),
                    },
                    {
                      key: 'condition_e',
                      label: translate('preview_approval_condition_e'),
                    },
                  ],
                }}
                dataSource={data_source}
                initialValue=""
                handleValueChanged={this.handleApproveConditionsChanged}
              />
            </div>
            <div>{translate('preview_approval_conditions_after')}</div>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-end'
              )}
            >
              <JujoButtonComponent
                bclasses="bg-green-500 fc-white fs-7"
                bwidth={180}
                blabel={translate('i_approve_the_preview')}
                bdata={{}}
                handleClick={this.handleCompleteApproveRequest}
              />
            </div>
          </div>
        )}
        {show_feedback_block && (
          <div className={classNames('my-4')}>
            <div>
              {translate('preview_change_request_feedback_request_message')}
            </div>
            <div
              className={classNames('p-2 rounded-3 mb-3 shadow-sm bg-gray-100')}
            >
              <ECTextAreaFieldComponent
                field={{ name: 'preview_feedback', richTextMode: true }}
                initialValue=""
                handleValueChanged={this.handlePreviewFeedbackChanged}
              />
              <div className={classNames('mt-2')}>
                <ECDroppableFieldComponent
                  field={{
                    name: 'preview_field',
                    fieldType: 'droppableField',
                    customWidth: 'col-12',
                    acceptedTypes: 'application/pdf, image/png, image/jpeg',
                    maxFileSize: '3MB',
                    relatedEntity: 'order',
                    visibility: 'private',
                    fileOwner: {
                      source: 'static',
                      relatedField: '',
                      defValue: customer_id,
                    },
                  }}
                  handleValueChanged={this.handleFileUploaded}
                />
              </div>
              <div
                className={classNames(
                  'd-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-3 align-items-center'
                )}
              >
                <JujoButtonComponent
                  bclasses="bg-green-500 fc-white"
                  bwidth={180}
                  blabel={translate('i_ask_for_change')}
                  bdata={{}}
                  handleClick={this.handleCompleteChangeRequest}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(CustomerCommandsComponent)
