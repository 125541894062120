/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import {
  formatDate,
  injectComponent,
  mapStateToProps,
  translate,
} from '../../../../../../src/utils'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  goToPayOrder,
  managePreviewMyAccount,
  manageTextsMyAccount,
  payOrderEnabled,
  sendTextsEnabled,
  uploadPaymentProofEnabled,
  viewPreviewEnabled,
} from '../../../actions/orders'
import paymentMethodsMap from '../../../enums/payment_methods_map'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class MyOrdersComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localDataSource: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { }
  }

  componentDidMount = async () => { }

  handleEditClicked = record => {
    const { enterEditMode } = this.props
    enterEditMode(record)
  }

  handleUploadPaymentProofClicked = record => {
    const { ID } = record
    const comp_id = uuid()
    const component_definition = {
      specialized: true,
      path: 'orders_group/upload_payment_proof_component',
      data: {
        sender: this,
        order_id: ID,
      },
    }

    injectComponent(this, comp_id, component_definition)
  }

  handlePayNowClicked = record => {
    const { order_key, ID } = record
    const order_data = {
      order_id: ID,
      order_key,
    }
    goToPayOrder(order_data)
  }

  handleSendTextsClicked = record => {
    manageTextsMyAccount(this, record)
  }

  handleViewPreviewClicked = record => {
    managePreviewMyAccount(this, record)
  }

  renderList = () => {
    const { data } = this.props

    const html = []

    for (let i = 0; i !== data.length; i += 1) {
      const order = data[i]
      const {
        ID,
        order_status,
        order_date,
        payment_method_title,
        payment_proof_uploaded,
        payment_method,
      } = order

      const parsed_status = order_status.replace(new RegExp('-', 'g'), '_')

      const formatted_order_date = formatDate(order_date, true, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })

      const pay_order_enabled = payOrderEnabled(order)
      const upload_payment_proof = uploadPaymentProofEnabled(order)
      const send_texts_enabled = sendTextsEnabled(order)
      const view_preview_enabled = viewPreviewEnabled(order)

      const notification_key = `${parsed_status}_message`
      const notification = translate(notification_key)
      const show_notification = notification !== notification_key

      html.push(
        <div
          key={`order_${ID}`}
          className={classNames('w-300px mx-2 mb-4 p-3 bg-light-gray fs-7')}
        >
          <div className={classNames('mb-2')}>
            <div
              className={classNames(
                'fc-1 fs-6 fw-bold border-bottom pb-2 mb-2'
              )}
            >{`${translate('order_number')}: #${ID}`}</div>
            <div>{`${translate('order_date')}: ${formatted_order_date}`}</div>
            <div className={classNames('fw-bold mb-2')}>{`${translate(
              'order_status'
            )}: ${(
              translate(parsed_status) || parsed_status
            ).toUpperCase()}`}</div>
            <div>{`${translate(
              'payment_method'
            )}: ${payment_method_title}`}</div>
            {payment_method === paymentMethodsMap.bacs && (
              <div>
                <div>{`${translate('payment_proof_uploaded')}: ${translate(
                  payment_proof_uploaded
                )}`}</div>
              </div>
            )}
            {show_notification && (
              <div
                className={classNames('fc-1 fw-bold p-2 my-1 border rounded')}
              >
                {notification}
              </div>
            )}
          </div>
          {pay_order_enabled && (
            <div className={classNames('mt-2 d-flex justify-content-center')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={translate('pay_now')}
                handleClick={() => {
                  this.handlePayNowClicked(order)
                }}
              />
            </div>
          )}
          {upload_payment_proof && (
            <div className={classNames('mt-2 d-flex justify-content-center')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                bheight={payment_proof_uploaded === 'yes' ? '58px' : ''}
                bclasses="px-2"
                blabel={
                  payment_proof_uploaded === 'yes'
                    ? translate('manage_payment_proof')
                    : translate('upload_payment_proof')
                }
                handleClick={() => {
                  this.handleUploadPaymentProofClicked(order)
                }}
              />
            </div>
          )}
          {send_texts_enabled && (
            <div className={classNames('mt-2 d-flex justify-content-center')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={translate('send_texts')}
                handleClick={() => {
                  this.handleSendTextsClicked(order)
                }}
              />
            </div>
          )}
          {view_preview_enabled && (
            <div className={classNames('mt-2 d-flex justify-content-center')}>
              <JujoButtonComponent
                // bstyle={1}
                bclasses="bg-cyan-500 fc-white"
                bwidth="200px"
                blabel={translate('view_preview')}
                handleClick={() => {
                  this.handleViewPreviewClicked(order)
                }}
              />
            </div>
          )}
        </div>
      )
    }

    return html
  }

  render() {
    return (
      <div className={classNames('d-flex flex-wrap')}>{this.renderList()}</div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyOrdersComponent)
