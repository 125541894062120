import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const JujoParsableContentComponent = loadable(() =>
  // eslint-disable-next-line import/no-cycle
  import('./parsable_content')
)

export class JujoPageContentComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { session, environment } = this.props
    const { session_data } = session
    const { jujo_page } = session_data
    const { content } = jujo_page

    const { locale } = environment
    const { defaultLang } = process.env

    const content_lang = content[locale] || content[defaultLang]

    return <JujoParsableContentComponent html={content_lang} />
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoPageContentComponent)
