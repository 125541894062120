import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import countries from '../../../../translations/countries.json'
import { mapStateToProps } from '../../../../utils'

import { GenericField } from './genericField'

export class CountriesField extends GenericField {
  renderField = () => {
    const { fieldValue, data } = this.state
    const { field, environment, dynamicForm } = this.props
    const { name } = field
    const { locale } = environment
    const { type } = dynamicForm

    const html = []
    if (type === requestTypesMap.view) {
      html.push(<div key={uuid()}>{countries[locale][fieldValue]}</div>)
    } else {
      html.push(
        <select
          key={`${name}_countries`}
          name={name}
          defaultValue={fieldValue}
          onChange={e => {
            const { value } = e.target
            this.processOnChange(value)
          }}
        >
          <option value="" disabled>
            --
          </option>

          {data.data &&
            data.data.map(country => {
              const { slug } = country
              return (
                <option key={slug} name={slug} value={slug}>
                  {countries[locale][slug]}
                </option>
              )
            })}
        </select>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(CountriesField)
