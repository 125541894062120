export async function download(sender) {
  const { props } = sender
  const { row, authentication } = props
  const { access_token } = authentication
  const { url } = row

  const file_url = `${url}?access_token=${access_token}`
  if (typeof window !== 'undefined') {
    window.open(file_url, '_blank').focus()
  }
}
