import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class SelectField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { name, disabled, options } = field
    const { translations } = specialization
    const { texts } = translations

    const disabled_field = parseBoolean(disabled, 'false')

    const { locale } = environment
    const { type } = dynamicForm

    const html = []
    if (type === requestTypesMap.view) {
      let renderValue = ''
      for (let i = 0; i !== options.length; i += 1) {
        const opt = options[i]
        if (opt.value === fieldValue) {
          renderValue = texts[locale][opt.label] || opt.label
          break
        }
      }
      html.push(<div key={uuid()}>{renderValue}</div>)
    } else {
      html.push(
        <select
          key={uuid()}
          name={name}
          defaultValue={fieldValue}
          disabled={disabled_field}
          onChange={e => {
            const { value } = e.target
            this.processOnChange(value)
          }}
        >
          <option key={uuid()} value="">
            --
          </option>

          {options.map(opt => (
            <option key={uuid()} value={opt.value}>
              {texts[locale][opt.label] || opt.label}
            </option>
          ))}
        </select>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(SelectField)
