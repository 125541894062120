import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../store/actions'
import { injectComponent, mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class DeleteButton extends React.Component {
  executeAction = async () => {
    const { handleDelete, row } = this.props
    handleDelete(row)
  }

  handleDeleteClicked = () => {
    const { params } = this.props
    const { confirmation } = params

    if (confirmation === undefined) {
      this.executeAction()
    } else {
      const { message } = confirmation

      const comp_id = uuid()
      const component_definition = {
        specialized: false,
        path: 'common/confirmation_box',
        data: {
          message,
          executeAction: this.executeAction,
        },
      }

      injectComponent(this, comp_id, component_definition)
    }
  }

  render() {
    return (
      <div
        className={classNames('delete-icon theme-svg')}
        style={{
          backgroundSize: '20px',
          height: '20px',
          width: '20px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        label="delete"
        role="button"
        tabIndex={0}
        onClick={this.handleDeleteClicked}
        onKeyPress={this.handleDeleteClicked}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(DeleteButton)
