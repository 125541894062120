import * as apiService from '../../../services/apiService'
import computedCommandsMap from '../../../enums/computedCommandsMap'
import apiRequestTypesMap from '../../../enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../services/servicesHelper'
import apiCallMap from '../../../enums/apiCallMap'
import requestTypesMap from '../../../enums/requestTypesMap'
import sourcesMap from '../../../enums/sourcesMap'
import conditionCheckTypesMap from '../../../enums/conditionCheckTypesMap'

export async function verifyConditions(conditions, props) {
  const { authentication, dynamicForm } = props
  const { user } = authentication
  let errors = false

  for (let j = 0; j !== conditions.length; j += 1) {
    const cond = conditions[j]
    const { source, relatedField, checkType, check } = cond

    let relFieldValue = null
    if (source === sourcesMap.dynamicField) {
      relFieldValue = dynamicForm.fields[relatedField]
    } else if (source === sourcesMap.parent) {
      relFieldValue = dynamicForm.parent[relatedField]
    } else if (source === sourcesMap.user) {
      relFieldValue = user[relatedField] || ''
    }

    if (checkType === conditionCheckTypesMap.contains) {
      errors = !check.includes(relFieldValue)
    } else if (checkType === conditionCheckTypesMap.equals_to) {
      if (Array.isArray(check)) {
        let meet = false
        for (let x = 0; x !== check.length; x += 1) {
          const currentCheck = check[x]
          if (currentCheck === relFieldValue) {
            meet = true
            break
          }
        }
        errors = !meet
      } else {
        const relFieldValueString =
          relFieldValue !== null && relFieldValue !== undefined
            ? relFieldValue.toString()
            : ''

        errors = relFieldValueString !== check.toString()
      }
    } else if (checkType === conditionCheckTypesMap.greater_than) {
      if (relFieldValue === undefined) {
        errors = true
      } else {
        errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
      }
    } else if (checkType === conditionCheckTypesMap.not_equals_to) {
      const relFieldValueString = relFieldValue ? relFieldValue.toString() : ''
      errors = relFieldValueString === check.toString()
    }

    if (errors === true) {
      break
    }
  }

  return !errors
}

export function getFieldInitialValue(field, dynamicForm, environment) {
  const { type, source, parent, fields } = dynamicForm
  const { name, defValue, relatedField, repeatingField } = field
  const { path, contextVariables } = environment

  let initialValue = ''

  if (repeatingField) {
    const splittedField = name.split('_')
    const repeater = splittedField[0]
    const index = splittedField[1]
    splittedField.splice(0, 2)
    const fieldName = splittedField.join('_')

    initialValue =
      fields[repeater] && fields[repeater][index]
        ? fields[repeater][index][fieldName]
        : defValue
  } else if (!repeatingField) {
    if (field.source && field.source === sourcesMap.parent) {
      initialValue = parent[relatedField] || defValue
    }

    if (field.source && field.source === sourcesMap.contextVariable) {
      initialValue =
        contextVariables[path] && contextVariables[path][relatedField]
          ? contextVariables[path][relatedField]
          : defValue
    } else if (!field.source) {
      if (type === requestTypesMap.update || type === requestTypesMap.view) {
        initialValue = source[name]
      } else if (defValue !== undefined) {
        initialValue = defValue
      }
    }
  }

  return initialValue
}

export async function getSourceDataFromEndpoint(
  field,
  dynamicForm,
  dynamicFields,
  environment,
  authentication
) {
  const { entity, parent } = dynamicForm
  if (field.apis && field.apis.getData) {
    const { apis } = field
    const { getData } = apis

    const {
      apiCall,
      defaultFilters,
      placeholderMapping,
      relatedEntity,
      requestType,
      postData,
    } = getData

    const requestEntity =
      relatedEntity && relatedEntity !== 'inherit' ? relatedEntity : entity

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      requestEntity,
      requestType,
      environment,
      authentication
    )
    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    requestData.dynamicFields = dynamicFields
    requestData.parentData = {
      entity,
      data: parent,
    }

    const parsedEp = parseEndpoint(requestData)

    let result = false
    if (requestType === apiRequestTypesMap.get) {
      result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)
    } else if (requestType === apiRequestTypesMap.post) {
      const dataToSend = {}
      for (let i = 0; i !== postData.length; i += 1) {
        const dataField = postData[i]

        const { dataSource, dataKey, dataValue } = dataField
        if (dataSource === sourcesMap.dynamicField) {
          dataToSend[dataKey] = dynamicForm.fields[dataValue]
        } else if (dataSource === sourcesMap.static) {
          dataToSend[dataKey] = dataValue
        }
      }

      result = await apiService.httpPost(
        `${process.env.apiUrl}${parsedEp}`,
        dataToSend
      )
    }

    if (result) {
      const { status, data } = result
      if (status === 200) {
        return data
      }

      return []
    }
  }

  return []
}

export async function getComputedFieldValue(field, props) {
  const { dynamicForm } = props

  const { computedSettings } = field
  const { command, rules, fields, operation } = computedSettings

  let fieldValue = ''
  if (command === computedCommandsMap.concatenate) {
    for (let j = 0; j !== fields.length; j += 1) {
      const f = fields[j]
      fieldValue += dynamicForm.fields[f] || ' '
      if (j !== fields.length - 1) {
        fieldValue += ' '
      }
    }
  } else if (command === computedCommandsMap.takeIf) {
    const { check, takeConditions, defValue } = rules

    const fieldToCheck = dynamicForm.fields[check]
    let fieldToTake = ''
    for (let j = 0; j !== takeConditions.length; j += 1) {
      const condition = takeConditions[j]
      const { verify, field: conditionField } = condition

      if (fieldToCheck === verify) {
        fieldToTake = conditionField
        break
      }
    }

    fieldValue = dynamicForm.fields[fieldToTake] || defValue
  } else if (command === computedCommandsMap.setIf) {
    const { setConditions, defValue } = rules
    fieldValue = defValue
    for (let i = 0; i !== setConditions.length; i += 1) {
      const condition = setConditions[i]
      const currConditions = [condition]
      // eslint-disable-next-line no-await-in-loop
      const verified = await verifyConditions(currConditions, props)
      if (verified === true) {
        const { set } = condition
        fieldValue = set
        break
      }
    }
  } else if (command === computedCommandsMap.math) {
    const bracesPattern = /[^{]+(?=\})/g
    const placeholderList = operation.match(bracesPattern)
    let parsedOperation = operation
    for (let i = 0; i !== placeholderList.length; i += 1) {
      const p = placeholderList[i]

      const multivalueP = p.split('|')
      let pValue = 0
      if (multivalueP.length === 1) {
        pValue = dynamicForm.fields[p] || 0
      } else {
        for (let j = 0; j !== multivalueP.length; j += 1) {
          const currentPValue = multivalueP[j]
          const currentValue = dynamicForm.fields[currentPValue]
          if (currentValue > 0) {
            pValue = currentValue
            break
          }
        }
      }

      parsedOperation = parsedOperation.replace(`{${p}}`, pValue)
    }

    // eslint-disable-next-line no-eval
    fieldValue = parseFloat(eval(parsedOperation), 10)
  }

  return fieldValue
}

export async function performFieldDidMount(props) {
  const { field, dynamicForm, environment, authentication } = props
  const { conditions } = field

  const dynamicFields = { ...dynamicForm.source }

  const conditionsMet = conditions
    ? await verifyConditions(conditions, props)
    : true

  const data =
    conditionsMet === true
      ? await getSourceDataFromEndpoint(
          field,
          dynamicForm,
          dynamicFields,
          environment,
          authentication
        )
      : []

  const initialValue = getFieldInitialValue(field, dynamicForm, environment)

  const updatedState = {
    conditionsMet,
    data,
    fieldValue: initialValue,
    initialized: true,
  }

  return updatedState
}

export async function performFieldDidUpdate(prevProps, props, state) {
  const { field, dynamicForm, environment, authentication } = props

  const { conditionsMet, data } = state

  const { fieldsToUpdate } = dynamicForm

  let updatedState = {}

  if (fieldsToUpdate.indexOf(field.name) >= 0) {
    const { conditions, resetOn } = field
    const { conditionalKeyUpdated, resetKeyUpdated, computedKeyUpdated } =
      dynamicForm

    let updatedConditionsMet = conditionsMet
    if (
      conditions &&
      conditionalKeyUpdated !== prevProps.dynamicForm.conditionalKeyUpdated &&
      conditionalKeyUpdated !== false
    ) {
      updatedConditionsMet = await verifyConditions(conditions, props)
    }

    const resetValueChanged = Boolean(
      resetOn &&
        resetKeyUpdated !== prevProps.dynamicForm.resetKeyUpdated &&
        resetOn.indexOf(resetKeyUpdated) >= 0
    )

    if (updatedConditionsMet !== conditionsMet || resetValueChanged === true) {
      const dynamicFields = { ...dynamicForm.fields }
      if (resetKeyUpdated !== false) {
        dynamicFields[resetKeyUpdated] = dynamicForm.fields[resetKeyUpdated]
      }

      const updatedData =
        updatedConditionsMet === true
          ? await getSourceDataFromEndpoint(
              field,
              dynamicForm,
              dynamicFields,
              environment,
              authentication
            )
          : data

      let fieldValue = dynamicForm.fields[field.name]

      if (
        field.source &&
        field.source === sourcesMap.actionData &&
        updatedConditionsMet === true
      ) {
        fieldValue = updatedData
      } else if (resetValueChanged === true) {
        fieldValue = field.defValue
      }

      updatedState = {
        initialized: true,
        fieldValue,
        conditionsMet: updatedConditionsMet,
        data: updatedData,
      }
    }

    if (computedKeyUpdated !== false && field.computedSettings) {
      const fieldValue = await getComputedFieldValue(field, props)
      updatedState = {
        initialized: true,
        fieldValue,
        conditionsMet,
        data,
      }
    }
  }

  return updatedState
}
