import React from 'react'
import { connect } from 'react-redux'
import Parser from 'html-react-parser'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'
import JujoLoading from '../../../../../../src/components/loading'
import ECSelectFieldComponent from '../../../../../../src/components/common/fields/selectField'
import PTQEstimateCustomerSelectorComponent from './_parts/customer_selector'
import PTQEstimateNewCustomerComponent from './_parts/new_customer'

const classNames = require('classnames')

export class PTQEstimateCustomerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      customer_type: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  handleCustomerTypeChanged = async (fieldName, value) => {
    await this.clearFieldValue()
    this.setState({ customer_type: value })
  }

  clearFieldValue = async () => {
    const { field, handleValueChanged } = this.props
    const { name } = field

    handleValueChanged(name, {})
  }

  handleValueChanged = async (fieldName, value) => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }
    cloned_field_value[fieldName] = value

    handleValueChanged(name, cloned_field_value)
  }

  renderSelectCustomerTypeField = () => {
    const field_def = {
      name: 'customer_type',
      fieldType: 'selectField',
      defValue: '',
      options: [
        {
          label: 'new_customer',
          value: 'new_customer',
        },
        {
          label: 'customers_registry',
          value: 'customers_registry',
        },
      ],
    }

    const html = []
    html.push(
      <ECSelectFieldComponent
        key="customer_type_selection"
        field={field_def}
        handleValueChanged={this.handleCustomerTypeChanged}
        initialValue=""
      />
    )
    return html
  }

  render() {
    const { loading, customer_type } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div>
            <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
              {Parser(`* ${translate('customer_type')}`, {})}
            </div>
            <div
              className={classNames(
                'p-1 border border-color-4 rounded bg-white mb-3'
              )}
            >
              {this.renderSelectCustomerTypeField()}
            </div>
            {customer_type === 'customers_registry' && (
              <div>
                <PTQEstimateCustomerSelectorComponent
                  handleValueChanged={this.handleValueChanged}
                />
              </div>
            )}
            {customer_type === 'new_customer' && (
              <div>
                <PTQEstimateNewCustomerComponent
                  handleValueChanged={this.handleValueChanged}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateCustomerComponent)
