/* eslint-disable react/no-array-index-key */
import React from 'react'
import { connect } from 'react-redux'
import apiCallMap from '../../enums/apiCallMap'
import * as apiService from '../../services/apiService'
import apiRequestTypesMap from '../../enums/apiRequestTypesMap'
import sourcesMap from '../../enums/sourcesMap'
import { baseRequestObject, parseEndpoint } from '../../services/servicesHelper'

import * as actionCreators from '../../store/actions'
import * as actFunctions from './functions'
import conditionCheckTypesMap from '../../enums/conditionCheckTypesMap'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class ActionButtonComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      verifiedActions: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    await this.initComponent()
  }

  componentDidUpdate = async prevProps => {
    const { environment } = this.props
    const { path } = environment

    if (prevProps.environment.path !== path) {
      await this.initComponent()
    }
  }

  initComponent = async () => {
    this.setState({ initialized: false })
    const { c_def } = this.props
    const { actions } = c_def

    const verifiedActionList = []
    const dataFilledActionList = []

    await this.recursivelyCheckActionsConditions(
      [...actions],
      verifiedActionList
    )

    await this.recursivelyGetSourceData(
      [...verifiedActionList],
      dataFilledActionList
    )

    this.setState({ verifiedActions: dataFilledActionList, initialized: true })
  }

  recursivelyCheckActionsConditions = async (
    actionList,
    verifiedActionList
  ) => {
    if (actionList.length === 0) return

    const act = actionList.pop()
    const { conditions } = act
    const isActVerified = await this.verifyActConditions(conditions)
    if (isActVerified) {
      verifiedActionList.unshift(act)
    }

    await this.recursivelyCheckActionsConditions(actionList, verifiedActionList)
  }

  verifyActConditions = async conditions => {
    if (!conditions) return true

    const cList = [...conditions]
    const cListResults = []

    await this.recursivelyCheckConditions(cList, cListResults)
    const verified = cListResults.indexOf(false) === -1

    return verified
  }

  recursivelyCheckConditions = async (cList, cListResults) => {
    if (cList.length === 0) return

    const cond = cList.pop()
    const { source, apis, relatedField, checkType, check } = cond

    let verified = true

    if (source === sourcesMap.actionData) {
      const { environment, authentication, dynamicForm } = this.props
      const { getData } = apis
      const { postData } = getData

      const {
        apiCall,
        requestType,
        relatedEntity,
        defaultFilters,
        placeholderMapping,
      } = getData

      const requestData = baseRequestObject(
        apiCallMap[apiCall],
        relatedEntity,
        requestType,
        environment,
        authentication
      )

      requestData.defaultFilters = defaultFilters
      requestData.placeholderMapping = placeholderMapping

      const parsedEp = parseEndpoint(requestData)

      let result = false
      if (requestType === apiRequestTypesMap.post) {
        const dataToSend = {}
        for (let i = 0; i !== postData.length; i += 1) {
          const dataField = postData[i]
          const { dataSource, dataKey, dataValue } = dataField
          if (dataSource === sourcesMap.dynamicField) {
            dataToSend[dataKey] = dynamicForm.fields[dataValue]
          } else if (dataSource === sourcesMap.static) {
            dataToSend[dataKey] = dataValue
          } else if (dataSource === sourcesMap.user) {
            const { user } = authentication
            dataToSend[dataKey] = user[dataValue]
          }
        }

        result = await apiService.httpPost(
          `${process.env.apiUrl}${parsedEp}`,
          dataToSend
        )
      }
      const { status, data } = result
      if (status === 200 || status === 201) {
        const valueToCheck = data[relatedField]
        if (checkType === conditionCheckTypesMap.equals_to) {
          verified = valueToCheck === check
        }
      } else {
        verified = false
      }
    }

    cListResults.push(verified)
    await this.recursivelyCheckConditions(cList, cListResults)
  }

  recursivelyGetSourceData = async (actionList, actionListData) => {
    if (actionList.length === 0) return

    const act = actionList.pop()
    const { apis } = act

    let sourceData = []
    if (apis && apis.getData) {
      const { getData } = apis
      const reqObj = this.composeRequest(getData)
      const { parsedEp } = reqObj

      const response = await apiService.httpGet(
        `${process.env.apiUrl}${parsedEp}`
      )
      if (response) {
        const { status, data } = response
        if (status === 200 || status === 201) {
          sourceData = data.data
        }
      }
    }

    act.sourceData = sourceData
    actionListData.unshift(act)

    await this.recursivelyGetSourceData(actionList, actionListData)
  }

  composeRequest = getData => {
    const { environment, authentication } = this.props
    const { apiCall, defaultFilters, placeholderMapping, relatedEntity } =
      getData

    const requestData = baseRequestObject(
      apiCallMap[apiCall],
      relatedEntity,
      apiRequestTypesMap.get,
      environment,
      authentication
    )
    requestData.defaultFilters = defaultFilters || []
    requestData.placeholderMapping = placeholderMapping || []
    const parsedEp = parseEndpoint(requestData)

    return {
      parsedEp,
      data: [],
    }
  }

  handleActClicked = act => {
    const { func, relatedEntity } = act
    const fn = actFunctions[func]

    const props = { ...this.props }
    props.action = act
    props.entity = relatedEntity

    // eslint-disable-next-line prefer-spread
    fn.apply(null, [props])
  }

  render() {
    const { verifiedActions, initialized } = this.state
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div className={classNames('container row g-3 my-4')}>
        {initialized === false && (
          <div
            className={classNames(
              'd-flex justify-content-center align-items-center'
            )}
            style={{ height: '300px' }}
          >
            {texts[locale].loading}
            <div
              className={classNames('loading-icon theme-svg ms-1')}
              style={{
                backgroundPosition: 'center',
                backgroundSize: '30px 30px',
                backgroundRepeat: 'no-repeat',
                height: '30px',
                width: '30px',
              }}
            />
          </div>
        )}
        {initialized === true && (
          <>
            {verifiedActions.map((act, idx) => {
              const { icon, label } = act

              return (
                <div
                  className={classNames('col-12 col-md-6 col-lg-4')}
                  key={`act_${idx}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleActClicked(act)
                  }}
                  onKeyPress={() => {
                    this.handleActClicked(act)
                  }}
                >
                  <div
                    className={classNames(
                      'd-flex flex-column justify-content-center align-items-center p-2 shadow-sm bg-white'
                    )}
                  >
                    <div
                      className={classNames(
                        'p-3 rounded-percentage-100 border border-color-1 border-2'
                      )}
                    >
                      <div
                        className={classNames('theme-svg', icon)}
                        style={{
                          height: '50px',
                          width: '50px',
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                        }}
                      />
                    </div>
                    <div className={classNames('py-2')}>
                      {texts[locale][label] || label}
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ActionButtonComponent)
