import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { httpPost } from '../../../../../../src/services/apiService'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const PTQAddressListComponent = loadable(() =>
  import('../customer_address_list/_parts/address_list')
)

function CustomerSummary({ customer_id }) {
  const [customer, setCustomer] = useState(null)
  const [address_visible, setAddressVisible] = useState(false)

  const initData = async () => {
    const ep = 'action/post/customer/summary'
    const response = await httpPost(`${process.env.apiUrl}${ep}`, {
      customer_id,
    })
    const { status } = response
    if (status === 200 || status === 201) {
      const { data } = response.data
      setCustomer(data)
    }
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const goToEditCustomer = () => {
    if (typeof window !== 'undefined') {
      const url = `/admin/customers/?hcdm=[{"an":"emode","p":{"ID":${customer_id}}}]`
      window.location.href = url
    }
  }

  const showHideAddresses = async () => {
    setAddressVisible(!address_visible)
  }

  return (
    <>
      {customer === null && <JujoLoading />}
      {customer !== null && (
        <div className={classNames('')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center mb-3 mb-md-1'
            )}
          >
            <div
              className={classNames(
                'd-flex align-items-center me-auto me-md-2'
              )}
            >
              <div
                className={classNames(
                  'edit-icon background-image theme-svg me-2'
                )}
                style={{ width: 30, height: 30 }}
                role="button"
                label="edit_customer"
                tabIndex={0}
                onClick={() => {
                  goToEditCustomer()
                }}
                onKeyPress={() => {
                  goToEditCustomer()
                }}
              />
              <div
                className={classNames(
                  'address-icon background-image theme-svg'
                )}
                style={{ width: 30, height: 30 }}
                role="button"
                label="edit_customer"
                tabIndex={0}
                onClick={() => {
                  showHideAddresses()
                }}
                onKeyPress={() => {
                  showHideAddresses()
                }}
              />
            </div>
            <div
              className={classNames('me-auto d-flex flex-column flex-md-row')}
            >
              <div className={classNames('fw-bold ms-1 me-2')}>
                {`${customer.first_name} ${customer.last_name} (${customer.user_id})`}
              </div>
              <div>
                <a
                  className={classNames('fw-bold fc-1 text-decoration-none')}
                  href={`https://wa.me/${customer.billing_phone.replace(
                    / /g,
                    ''
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {customer.billing_phone}
                </a>
              </div>
              <div className={classNames('mx-1 d-none d-md-flex')}>/</div>
              <div>
                <a
                  className={classNames('fw-bold fc-1 text-decoration-none')}
                  href={`mailto:${customer.billing_email}`}
                >
                  {customer.billing_email}
                </a>
              </div>
            </div>
          </div>
          {address_visible === true && (
            <div className={classNames('bg-orange-300 p-4 my-4 rounded-5')}>
              <PTQAddressListComponent data={[customer]} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CustomerSummary
