import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import {
  cloneObj,
  mapStateToProps,
  envContext,
  translate,
} from '../../../../../../../../src/utils'
import productTypesMap from '../enums/productTypesMap'
import contextTypesMap from '../../../../../../../../src/enums/contextTypesMap'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)
const ECDataListFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/dataListField')
)

const PTQEstimateProductOptionsBlockComponent = loadable(() =>
  import('../options_block')
)
const PTQEstimateProductQtySelectorBlockComponent = loadable(() =>
  import('../qty_selector_block')
)

const PTQEstimateCustomProductBlockComponent = loadable(() =>
  import('../custom_product_block')
)

const classNames = require('classnames')

export class PTQEstimateProductBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      product_type: '',
      selected_product: {},
      custom_product: {},
      quantity: 1,
      product_options: {},
      notes: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { block_value } = this.props
    const {
      product_type,
      selected_product,
      custom_product,
      quantity,
      product_options,
      notes,
    } = block_value

    await this.setState({
      product_type,
      selected_product: selected_product || {},
      custom_product: custom_product || {},
      quantity: quantity || 1,
      product_options: product_options || {},
      notes: notes || '',
      loading: false,
    })
  }

  onBlockChanged = async () => {
    const { block_key, handleBlockChanged } = this.props
    const {
      product_type,
      selected_product,
      custom_product,
      quantity,
      product_options,
      notes,
    } = this.state

    const updated_data = {
      block_key,
      block_value: {
        product_type,
        selected_product,
        custom_product,
        quantity,
        product_options,
        notes,
      },
    }

    await handleBlockChanged(updated_data)
  }

  handleQuantityChanged = async (fieldName, value) => {
    await this.setState({ quantity: value })
    this.onBlockChanged()
  }

  handleNotesChanged = async (fieldName, value) => {
    await this.setState({ notes: value })
    this.onBlockChanged()
  }

  handleOptionChanged = async (fieldName, value) => {
    const { product_options } = this.state

    const updated_options = cloneObj(product_options)
    updated_options[fieldName] = value

    await this.setState({ product_options: updated_options })
    this.onBlockChanged()
  }

  handleSelectedProductChanged = async (fieldName, value) => {
    const { selected_product } = this.state
    const { product_list } = this.props

    let new_selected_product = {}
    for (let i = 0; i !== product_list.length; i += 1) {
      const prod = product_list[i]
      const { ID } = prod
      if (ID === value) {
        new_selected_product = { ...prod }
        break
      }
    }

    if (
      JSON.stringify(new_selected_product) !== JSON.stringify(selected_product)
    ) {
      await this.setState({ selected_product: new_selected_product })
      this.onBlockChanged()
    }
  }

  handleCustomProductChanged = async (fieldName, value) => {
    const new_custom_product = value
    await this.setState({ custom_product: new_custom_product })

    this.onBlockChanged()
  }

  renderSelectedProductQtySelector = () => {
    const { selected_product, quantity } = this.state
    const { ID } = selected_product

    const html = []

    html.push(
      <div key={`product_${ID}_qty`}>
        <PTQEstimateProductQtySelectorBlockComponent
          product_id={ID}
          initialValue={quantity}
          handleQuantityChanged={this.handleQuantityChanged}
        />
      </div>
    )

    return html
  }

  renderSelectedProductDetails = () => {
    const { selected_product } = this.state

    const { ID, product_name } = selected_product
    const html = []

    html.push(
      <div key={`product_${ID}_details`}>
        <div className={classNames('my-2 py-2 fw-bold fc-1 fs-5')}>
          <div>{product_name}</div>
        </div>
      </div>
    )
    return html
  }

  renderSelectedProductOptions = () => {
    const { handleNewOptionsLoaded } = this.props
    const { selected_product, product_options } = this.state

    const { ID } = selected_product
    const html = []

    html.push(
      <div key={`product_${ID}_opts`} className={classNames('mt-2')}>
        <PTQEstimateProductOptionsBlockComponent
          product_id={ID}
          product_options={product_options}
          handleOptionChanged={this.handleOptionChanged}
          handleNewOptionsLoaded={handleNewOptionsLoaded}
        />
      </div>
    )
    return html
  }

  renderExistingProductBlock = () => {
    const { selected_product } = this.state

    const { product_list } = this.props
    const html = []

    const ctx = envContext()
    const options = []
    for (let i = 0; i !== product_list.length; i += 1) {
      const { ID, product_name, composed_name } = product_list[i]
      options.push({
        label: ctx === contextTypesMap.admin ? composed_name : product_name,
        value: ID,
      })
    }

    const productsListField = {
      name: 'product_list_field',
      renderField: 'label',
      valueField: 'value',
      options,
    }

    html.push(
      <div key={`static_key_existing_${selected_product.ID}`}>
        <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
          {`* ${translate('select_product')}`}
        </div>
        <div
          className={classNames(
            'p-1 border border-color-4 rounded bg-white fs-7'
          )}
        >
          <ECDataListFieldComponent
            field={productsListField}
            handleValueChanged={this.handleSelectedProductChanged}
            initialValue={selected_product.ID || 0}
          />
        </div>

        {Object.keys(selected_product).length > 0 && (
          <div>
            <div>{this.renderSelectedProductDetails()}</div>
            <div>{this.renderSelectedProductQtySelector()}</div>
            <div>{this.renderSelectedProductOptions()}</div>
          </div>
        )}
      </div>
    )

    return html
  }

  renderCustomProductBlock = () => {
    const { block_value, user_permissions } = this.props
    const html = []

    html.push(
      <div key="static_key_custom">
        <PTQEstimateCustomProductBlockComponent
          user_permissions={user_permissions}
          handleCustomProductChanged={this.handleCustomProductChanged}
          handleQuantityChanged={this.handleQuantityChanged}
          handleNotesChanged={this.handleNotesChanged}
          initialValue={block_value}
        />
      </div>
    )
    return html
  }

  renderProductBlock = () => {
    const { block_value } = this.props
    const { product_type } = block_value

    if (product_type === productTypesMap.existing) {
      return this.renderExistingProductBlock()
    }
    if (product_type === productTypesMap.custom) {
      return this.renderCustomProductBlock()
    }

    return <></>
  }

  handleDeleteBlock = async () => {
    const { handleBlockRemoved, block_key } = this.props
    await handleBlockRemoved(block_key)
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('bg-light-gray p-4 mb-4 rounded-5')}>
            <div
              className={classNames(
                'background-image close-icon theme-svg ms-auto mb-3'
              )}
              style={{ height: 20, width: 20 }}
              label="delete"
              role="button"
              tabIndex={0}
              onClick={async () => {
                await this.handleDeleteBlock()
              }}
              onKeyPress={async () => {
                await this.handleDeleteBlock()
              }}
            />
            {this.renderProductBlock()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateProductBlockComponent)
