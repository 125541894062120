import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ECNumberFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      changed_value: null,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { changed_value } = this.state
    const { field, initialValue, handleValueChanged } = this.props
    const { name, customClass } = field

    const min = parseFloat(field.min || 0)
    const max = parseFloat(field.max || 10 ** 1000)
    const step = parseFloat(field.step || 1)

    return (
      <input
        className={classNames('', customClass || '')}
        type="number"
        min={min}
        step={step}
        max={max}
        defaultValue={initialValue}
        onChange={e => {
          const { value } = e.target
          this.setState({ changed_value: value })
        }}
        onKeyDown={e => {
          const { value } = e.target
          this.setState({ changed_value: value })
        }}
        onMouseDown={e => {
          const { value } = e.target
          this.setState({ changed_value: value })
        }}
        onBlur={e => {
          let { value } = e.target

          if (changed_value === null) return

          if (value < min) {
            value = min
          }
          if (value > max) {
            value = max
          }

          value = Math.ceil(value / step) * step

          value =
            Math.floor(value) !== value ? parseFloat(value).toFixed(2) : value

          e.target.value = value
          this.setState({ changed_value: null })
          handleValueChanged(name, parseFloat(value))
        }}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECNumberFieldComponent)
