/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import fileTypesMap from '../../../../enums/fileTypesMap'
import fileVisibilityTypesMap from '../../../../enums/fileVisibilityTypesMap'
import requestTypesMap from '../../../../enums/requestTypesMap'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, uploadFileToServer } from '../../../../utils'

import { GenericField } from './genericField'

const classNames = require('classnames')

export class FileField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field, dynamicForm, authentication } = this.props
    const { access_token } = authentication
    const { name, acceptedTypes, visibility } = field
    const { type } = dynamicForm

    const fileUrl =
      visibility === fileVisibilityTypesMap.private
        ? `${fieldValue}?access_token=${access_token}`
        : fieldValue

    const ext = fieldValue ? fieldValue.split('.').pop() : ''
    let typeClass = ''
    switch (ext) {
      case fileTypesMap.pdf:
        typeClass = 'pdf-icon'
        break
      case fileTypesMap.csv:
        typeClass = 'csv-icon'
        break
      case fileTypesMap.txt:
        typeClass = 'txt-icon'
        break
      default:
        typeClass = 'image-icon'
    }

    const html = []
    html.push(
      <div key={uuid()}>
        <div
          className={classNames(
            'd-flex justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames(
              'me-2 theme-svg',
              fieldValue ? typeClass : ''
            )}
            style={{
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
              height: '24px',
              width: '24px',
            }}
          />

          <div className={classNames('d-flex align-items-center')}>
            {type !== requestTypesMap.view && (
              <div
                className={classNames(
                  'theme-svg mx-1',
                  fieldValue ? 'edit-icon' : 'upload-icon'
                )}
                style={{
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  height: '18px',
                  width: '18px',
                }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  const inputFile = document.getElementById(name)
                  inputFile.click()
                }}
                onKeyPress={() => {
                  const inputFile = document.getElementById(name)
                  inputFile.click()
                }}
              />
            )}

            {fieldValue && (
              <>
                {type !== requestTypesMap.view && (
                  <div
                    className={classNames('trash-icon theme-svg mx-1')}
                    style={{
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '18px',
                      width: '18px',
                    }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      this.processOnChange('')
                    }}
                    onKeyPress={() => {
                      this.processOnChange('')
                    }}
                  />
                )}
                <div
                  className={classNames('download-icon-2 theme-svg mx-1')}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '18px',
                    width: '18px',
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open(fileUrl)
                    }
                  }}
                  onKeyPress={() => {
                    if (typeof window !== 'undefined') {
                      window.open(fileUrl)
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <input
          id={name}
          type="file"
          accept={acceptedTypes}
          className={classNames('d-none')}
          onChange={async e => {
            const { files } = e.target

            if (files && files.length > 0) {
              const uploadedFile = await uploadFileToServer(
                field,
                files[0],
                dynamicForm.fields
              )
              const { data, status } = uploadedFile
              if (status === 200) {
                this.setState({ fieldDynamicProps: data })
                this.processOnChange(data.url)
              }
            }
          }}
        />
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(FileField)
