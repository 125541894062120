import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGGuideBtn({ svg_id }) {
  const [svg, setSvg] = useState(null)

  const initData = async () => {
    setTimeout(() => {
      const svg_element = document.getElementById(svg_id)
      setSvg(svg_element)
    }, 1)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const handleGuideClicked = () => {
    const guide_element = svg.querySelector('g[label=guida]')
    if (guide_element) {
      const { style } = guide_element
      const { display } = style

      guide_element.style.display = display === 'none' ? '' : 'none'
    }
  }

  return (
    <>
      {svg === null && <JujoLoading />}
      {svg !== null && (
        <div
          className={classNames('bg-white p-2 mx-1 my-1 my-md-0')}
          role="button"
          tabIndex={0}
          label="zoom"
          onClick={() => {
            handleGuideClicked()
          }}
          onKeyPress={() => {
            handleGuideClicked()
          }}
        >
          <div
            className={classNames('background-image deny-icon')}
            style={{ width: 25, height: 25 }}
          />
        </div>
      )}
    </>
  )
}

export default JujoSVGGuideBtn
