import React from 'react'
import { connect } from 'react-redux'
import conditionCheckTypesMap from '../../../enums/conditionCheckTypesMap'
import sourcesMap from '../../../enums/sourcesMap'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'
import JujoLoading from '../../loading'
import { getGridDefinition } from '../helper/gridFunctions'

import GridActionComponent from './_parts/gridAction'

const classNames = require('classnames')

export class GridActionsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      enabled_actions: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { environment } = this.props
    const { permissions } = environment
    const gridDefinition = getGridDefinition(this.props)
    const { actions } = gridDefinition

    const enabled_actions = []
    for (let i = 0; i !== actions.length; i += 1) {
      const act = actions[i]
      const { conditions, required_permission } = act
      const action_permitted = required_permission
        ? permissions.includes(required_permission)
        : true
      if (action_permitted) {
        if (!conditions) {
          enabled_actions.push(act)
        } else {
          const conditionsMet = this.verifyConditions(conditions)
          if (conditionsMet === true) {
            enabled_actions.push(act)
          }
        }
      }
    }

    this.setState({ initialized: true, enabled_actions })
  }

  componentDidUpdate = async () => {}

  verifyConditions = conditions => {
    const { authentication } = this.props
    const { user } = authentication
    let errors = false
    for (let j = 0; j !== conditions.length; j += 1) {
      const cond = conditions[j]
      const { source, relatedField, checkType, check } = cond

      let relFieldValue = null
      if (source === sourcesMap.user) {
        relFieldValue = user[relatedField] || ''
      }

      if (checkType === conditionCheckTypesMap.contains) {
        errors = !check.includes(relFieldValue)
      } else if (checkType === conditionCheckTypesMap.equals_to) {
        if (Array.isArray(check)) {
          let meet = false
          for (let x = 0; x !== check.length; x += 1) {
            const currentCheck = check[x]
            if (currentCheck === relFieldValue) {
              meet = true
              break
            }
          }
          errors = !meet
        } else {
          const relFieldValueString =
            relFieldValue !== null && relFieldValue !== undefined
              ? relFieldValue.toString()
              : ''

          errors = relFieldValueString !== check.toString()
        }
      } else if (checkType === conditionCheckTypesMap.greater_than) {
        if (relFieldValue === undefined) {
          errors = true
        } else {
          errors = parseInt(relFieldValue, 10) <= parseInt(check, 10)
        }
      } else if (checkType === conditionCheckTypesMap.not_equals_to) {
        const relFieldValueString = relFieldValue
          ? relFieldValue.toString()
          : ''
        errors = relFieldValueString === check.toString()
      }

      if (errors === true) {
        break
      }
    }

    return !errors
  }

  render() {
    const { initialized, enabled_actions } = this.state
    const { entity, parentData, editableComponentProps, gridComponent } =
      this.props

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && enabled_actions.length > 0 && (
          <div
            className={classNames(
              'd-flex flex-column flex-md-row flex-wrap align-items-center justify-content-center justify-content-md-start py-2 border-top'
            )}
          >
            {enabled_actions.map(action => {
              const { actionName } = action
              return (
                <GridActionComponent
                  key={actionName}
                  gridComponent={gridComponent}
                  action={action}
                  entity={entity}
                  parentData={parentData}
                  editableComponentProps={editableComponentProps}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GridActionsComponent)
