import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'

const PageComponent = loadable(() => import('../_parts/page'))

const classNames = require('classnames')

export class BlankPageLayout extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderBody = () => {
    const html = []
    html.push(
      <div key="page_body" className={classNames('d-flex p-0 m-0')}>
        <PageComponent />
      </div>
    )
    return html
  }

  render() {
    return this.renderBody()
  }
}

export default connect(mapStateToProps, actionCreators)(BlankPageLayout)
