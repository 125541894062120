import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

export class ComponentField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const html = []
    html.push(<div key="component_field">to be implemented</div>)
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(ComponentField)
