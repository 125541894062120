import React from 'react'
import classNames from 'classnames'

function JujoSvgMobileCenterBtn({ resetSvgPosition }) {
  return (
    <div className={classNames('position-absolute top-50 end-0')}>
      <div
        className={classNames('d-flex p-1 bg-1 shadow-sm rounded-3')}
        role="button"
        tabIndex={0}
        onClick={() => {
          resetSvgPosition()
        }}
        onKeyPress={() => {
          resetSvgPosition()
        }}
      >
        <div
          className={classNames('background-image white-svg center-icon')}
          style={{ width: 30, height: 30 }}
        />
      </div>
    </div>
  )
}

export default JujoSvgMobileCenterBtn
