import React from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'
import { getImposeModelData } from '../../../actions/modeling/impose'

const ECDataListFieldComponent = loadable(() =>
  import('../../../../../../src/components/common/fields/dataListField')
)
const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class ImposeModelEditorComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      impose_model_id: 0,
      impose_model_data: {},
      svg_size: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data } = this.props
    const { impose_model, svg_size } = data

    const impose_model_id = impose_model || 0

    const impose_model_data =
      impose_model_id > 0
        ? await getImposeModelData(this, impose_model_id)
        : null

    this.setState({
      impose_model_id,
      impose_model_data,
      svg_size,
      loading: false,
    })
  }

  handleValueChanged = async (_field_name, value) => {
    const { data } = this.props

    const impose_model_data = value
      ? await getImposeModelData(this, value)
      : value

    this.setState({
      impose_model_id: value,
      impose_model_data,
    })

    const { updateParsedData } = data
    updateParsedData('impose_model', value)
  }

  renderImposeModelSelector = () => {
    const { impose_model_id } = this.state

    const datalista_field_def = {
      name: 'impose_model',
      fieldType: 'datalistField',
      renderField: 'name',
      valueField: 'id',
      defValue: 0,
      apis: {
        getData: {
          apiCall: 'getAllKeyValue',
          requestType: 'get',
          relatedEntity: 'impose_model',
          defaultFilters: [
            {
              fEntity: [],
              fSource: 'environment',
              fValue: 'locale={locale}',
              fDefValue: 'it',
            },
          ],
        },
      },
    }

    const html = []
    html.push(
      <div key="impose_model_editor">
        <div className={classNames('border rounded-4 py-1 px-2')}>
          <ECDataListFieldComponent
            field={datalista_field_def}
            handleValueChanged={this.handleValueChanged}
            initialValue={impose_model_id}
          />
        </div>
      </div>
    )
    return html
  }

  renderDetails = () => {
    const { impose_model_data, svg_size } = this.state
    const html = []

    if (!impose_model_data) return html
    const { mm } = svg_size
    const { width, height } = mm

    const { page_width, page_height, edge_distance, product_margin } =
      impose_model_data

    html.push(
      <div
        key="svg_size_info"
        className={classNames(
          'container row text-center mx-auto my-2 rounded-4 bg-gray-100 fs-7'
        )}
      >
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('svg_width')}
          </div>
          <div>{`${width} mm`}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('svg_height')}
          </div>
          <div>{`${height} mm`}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('page_width')}
          </div>
          <div>{`${page_width} mm`}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('page_height')}
          </div>
          <div>{`${page_height} mm`}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('edge_distance')}
          </div>
          <div>{`${edge_distance} mm`}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('product_margin')}
          </div>
          <div>{`${product_margin} mm`}</div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div>
            <div>{this.renderImposeModelSelector()}</div>
            <div>{this.renderDetails()}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ImposeModelEditorComponent)
