import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

function JujoSVGNumberSelector({ handleChange, defaultValue }) {
  const inputRef = useRef(null)

  useEffect(() => {}, [])

  const handleMinusClicked = () => {
    const { current } = inputRef
    const { value } = current
    const new_value = parseInt(value, 10) - 1
    current.value = new_value
    handleChange(new_value)
  }
  const handlePlusClicked = () => {
    const { current } = inputRef
    const { value } = current
    const new_value = parseInt(value, 10) + 1
    current.value = new_value
    handleChange(new_value)
  }

  return (
    <div className={classNames('d-flex')}>
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center border w-36px h-36px'
        )}
        role="button"
        tabIndex={0}
        onClick={e => handleMinusClicked(e)}
        onKeyPress={e => handleMinusClicked(e)}
      >
        <div
          className={classNames('background-image minus-icon')}
          style={{ width: 20, height: 20 }}
        />
      </div>
      <div className={classNames('bg-white p-1 mx-1 border h-36px w-70px')}>
        <input
          ref={inputRef}
          className={classNames('no-arrow text-center')}
          type="number"
          onChange={e => handleChange(e.target.value)}
          defaultValue={defaultValue}
        />
      </div>
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center border w-36px h-36px'
        )}
        role="button"
        tabIndex={0}
        onClick={e => handlePlusClicked(e)}
        onKeyPress={e => handlePlusClicked(e)}
      >
        <div
          className={classNames('background-image plus-icon')}
          style={{ width: 20, height: 20 }}
        />
      </div>
    </div>
  )
}

export default JujoSVGNumberSelector
