import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import uuid from 'react-uuid'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'

import { doCalculations } from '../../../actions/estimates'
import productTypesMap from './_parts/enums/productTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)
const PTQEstimateProductBlockComponent = loadable(() =>
  import('./_parts/product_block')
)
const PTQEstimateTicketComponent = loadable(() => import('./_parts/ticket'))
const PTQEstimateShippingBlockComponent = loadable(() =>
  import('./_parts/shipping_block')
)

const classNames = require('classnames')

export class PTQEstimateDataComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      product_list: [],
      shipping_methods: [],
      fields_options_prices: {},
      user_permissions: {
        can_modify_price: false,
        can_create_product: false,
      },
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const product_list = await this.getProductList()
    const shipping_methods = await this.getShippingMethods()
    const user_permissions = this.verifyUserPermissions()
    this.setState({
      loading: false,
      product_list,
      shipping_methods,
      user_permissions,
    })
  }

  verifyUserPermissions = () => {
    const { authentication } = this.props
    const { user } = authentication
    const { permissions_id } = user
    const is_admin = permissions_id === 1 || permissions_id === 2

    const user_permissions = {
      can_modify_price: is_admin,
      can_create_product: is_admin,
      can_assing_text_form: is_admin,
    }

    return user_permissions
  }

  getShippingMethods = async () => {
    const reqObj = this.composeShippingMethodsRequest()
    const { parsedEp, data } = reqObj

    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, data)

    let shipping_methods = []
    if (response.status === 200 || response.status === 201) {
      shipping_methods = response.data.data || []
    }
    return shipping_methods
  }

  composeShippingMethodsRequest = () => {
    const { environment, authentication } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'shipping_method',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-list',
      pDefValue: 'get-list',
    })

    const parsedEp = parseEndpoint(requestData)
    const data = {}

    return {
      parsedEp,
      data,
    }
  }

  getProductList = async () => {
    const reqObj = this.composeProductListRequest()
    const { parsedEp, data } = reqObj

    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, data)

    let product_list = []
    if (response.status === 200 || response.status === 201) {
      product_list = response.data.data || []
    }
    return product_list
  }

  composeProductListRequest = () => {
    const { environment, authentication } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'product',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-products-list',
      pDefValue: 'get-products-list',
    })

    const parsedEp = parseEndpoint(requestData)
    const data = {}

    return {
      parsedEp,
      data,
    }
  }

  executeCalculations = field => {
    const { fields_options_prices } = this.state

    const calculations = doCalculations(field, fields_options_prices)
    return calculations
  }

  addProductInstance = async product_type => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    if (!('products' in cloned_field_value)) {
      cloned_field_value.products = {}
    }

    const { products } = cloned_field_value

    const new_block_key = uuid()
    products[new_block_key] = {
      product_type,
    }

    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleAddProductClicked = async () => {
    await this.addProductInstance(productTypesMap.existing)
  }

  handleCreateProductClicked = async () => {
    await this.addProductInstance(productTypesMap.custom)
  }

  handleResetPrice = async key => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    if (!('customized_price_list' in cloned_field_value)) {
      cloned_field_value.customized_price_list = {}
    }

    const { customized_price_list } = cloned_field_value
    delete customized_price_list[key]

    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleCustomPriceChanged = async record => {
    const { key } = record

    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    if (!('customized_price_list' in cloned_field_value)) {
      cloned_field_value.customized_price_list = {}
    }

    const { customized_price_list } = cloned_field_value
    customized_price_list[key] = record

    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleShippingChanged = async data => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    cloned_field_value.shipping = data
    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleCouponChanged = async data => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    if (!('coupon_data' in cloned_field_value)) {
      cloned_field_value.coupon_data = {}
    }

    cloned_field_value.coupon_data = data
    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleBlockChanged = async data => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    const { products } = cloned_field_value

    const { block_key, block_value } = data

    products[block_key] = block_value
    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleBlockRemoved = async key => {
    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }

    const { products } = cloned_field_value
    delete products[key]
    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)

    await handleValueChanged(name, cloned_field_value)
  }

  handleNewOptionsLoaded = async list => {
    const { fields_options_prices } = this.state
    const cloned_options_prices = { ...fields_options_prices }

    for (let i = 0; i !== list.length; i += 1) {
      const field = list[i]

      const { field_definition } = field
      const { title, name, options } = field_definition

      for (let j = 0; j !== options.length; j += 1) {
        const opt = options[j]
        opt.title = title
      }

      cloned_options_prices[name] = options
    }

    await this.setState({ fields_options_prices: cloned_options_prices })

    const { dataSource, field, handleValueChanged } = this.props
    const { name } = field

    const cloned_field_value = { ...dataSource[name] }
    cloned_field_value.calculations =
      this.executeCalculations(cloned_field_value)
    await handleValueChanged(name, cloned_field_value)
  }

  renderBlocks = () => {
    const { product_list, user_permissions } = this.state
    const { dataSource, field } = this.props
    const { name } = field

    const html = []

    const field_value = dataSource[name]
    if (!field_value || !field_value.products) return html

    const { products } = field_value
    const keys = Object.keys(products)

    for (let i = 0; i !== keys.length; i += 1) {
      const block_key = keys[i]
      const block_value = field_value.products[block_key]

      html.push(
        <div key={`product_block_${block_key}`}>
          <PTQEstimateProductBlockComponent
            block_key={block_key}
            block_value={block_value}
            product_list={product_list}
            user_permissions={user_permissions}
            handleBlockChanged={this.handleBlockChanged}
            handleBlockRemoved={this.handleBlockRemoved}
            handleNewOptionsLoaded={this.handleNewOptionsLoaded}
          />
        </div>
      )
    }

    return html
  }

  renderButtons = () => {
    const { user_permissions } = this.state
    const { can_create_product } = user_permissions

    const html = []
    html.push(
      <div
        key="data_component_buttons"
        className={classNames(
          'd-flex flex-column justify-content-center align-items-center mb-3'
        )}
      >
        <div className={classNames('mb-2')}>
          <JujoButtonComponent
            bstyle={2}
            bwidth={300}
            blabel={translate('add_product')}
            handleClick={this.handleAddProductClicked}
          />
        </div>
        {can_create_product && (
          <div>
            <JujoButtonComponent
              bstyle={2}
              bwidth={300}
              blabel={translate('create_custom_product')}
              handleClick={this.handleCreateProductClicked}
            />
          </div>
        )}
      </div>
    )
    return html
  }

  render() {
    const { loading, shipping_methods, user_permissions } = this.state
    const { dataSource, field } = this.props

    const { name } = field
    const { calculations, shipping, coupon_data } = dataSource[name] || {}

    const { customer_id, user_email } = dataSource

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>
            <PTQEstimateShippingBlockComponent
              initial_value={shipping}
              shipping_methods={shipping_methods}
              handleShippingChanged={this.handleShippingChanged}
            />
            <div className={classNames('row')}>
              <div className={classNames('col-12 col-md-7')}>
                {this.renderBlocks()}
                {this.renderButtons()}
              </div>
              <div className={classNames('col-12 col-md-5')}>
                <PTQEstimateTicketComponent
                  user_permissions={user_permissions}
                  calculations={calculations}
                  coupon_data={coupon_data}
                  customer_data={{
                    customer_id: customer_id || 0,
                    user_email: user_email || '',
                  }}
                  handleCouponChanged={this.handleCouponChanged}
                  handleCustomPriceChanged={this.handleCustomPriceChanged}
                  handleResetPrice={this.handleResetPrice}
                />
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateDataComponent)
