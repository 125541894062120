/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import loadable from '@loadable/component'

import classNames from 'classnames'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

import { getCurrentPageDefinition } from './helper/pageFunctions'

const InfoBoxComponent = loadable(() => import('../info_box'))
const JujoInjectableComponent = loadable(() => import('../jujo_injectable'))

export class PageLayoutComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { initialized } = this.state
    const { environment, infoBox } = this.props
    const { environment: nextEnvironment, infoBox: nextInfoBox } = nextProps

    if (initialized !== nextState.initialized) {
      return true
    }
    if (environment.path !== nextEnvironment.path) {
      return true
    }

    if (infoBox && nextInfoBox && infoBox.visible !== nextInfoBox.visible) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ initialized: true })
  }

  componentDidUpdate = () => {}

  renderPageLayout = () => {
    const pageDef = getCurrentPageDefinition(this.props)
    const { layout, specialized } = pageDef

    let DynamicPageLayout = null

    if (!specialized) {
      DynamicPageLayout = require(`./layouts/${layout}/index.js`).default
    } else {
      DynamicPageLayout =
        require(`../../../jujo_specializations/src/${process.env.client}/layouts/${layout}/index.js`).default
    }

    const html = []
    html.push(
      <div key={pageDef.layout} className={classNames(pageDef.layout)}>
        <DynamicPageLayout pageDef={pageDef} />
      </div>
    )
    return html
  }

  render() {
    const { initialized } = this.state
    const { infoBox } = this.props
    return (
      <>
        {initialized && (
          <div
            style={{
              width: '100vw',
              overflowX: 'clip',
            }}
          >
            {this.renderPageLayout()}
            <JujoInjectableComponent />
            {infoBox && infoBox.visible && <InfoBoxComponent />}
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PageLayoutComponent)
