import loadable from '@loadable/component'
import React from 'react'
import ReactDOM from 'react-dom'

import uuid from 'react-uuid'
import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../src/services/apiService'
import { lsGet } from '../../../../../src/services/localStorage'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import {
  defaultManageResponse,
  injectComponent,
  retrieveBindedElement,
  retrieveComponent,
} from '../../../../../src/utils'
import orderStatusMap from '../../enums/order_status_map'
import paymentMethodsMap from '../../enums/payment_methods_map'

export async function getSelectedIdList(sender) {
  const { props } = sender

  const environment = lsGet('environment')

  const { gridComponent, authentication } = props

  const { state } = gridComponent
  const { gridDataObj, selectedRows } = state
  const { request_ep } = gridDataObj

  let request_id_list = selectedRows.idList

  if (request_id_list.length === 0) {
    const grid_filter_url = request_ep.split('?')[1]

    /* Removing the page and per_page params from the url. */
    // eslint-disable-next-line no-undef
    const grid_filter_params = new URLSearchParams(grid_filter_url)
    grid_filter_params.delete('page')
    grid_filter_params.delete('per_page')
    const parsed_grid_params = grid_filter_params.toString()

    /* Creating a request object that will be used to make the api call. */
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-filtered-ID-list',
      pDefValue: 'get-filtered-ID-list',
    })

    /* Parsing the endpoint and injecting the grid params into the endpoint. */
    const parsedEp = parseEndpoint(requestData)
    const injected_ep = `${parsedEp.split('?')[0]}?${parsed_grid_params}`

    const response = await httpPost(
      `${process.env.apiUrl}${injected_ep}`,
      {},
      true
    )
    const { status, data } = response
    if (status === 200 || status === 201) {
      request_id_list = data.id_list
    }
  }

  return request_id_list
}

export async function totalTurnover(sender) {
  const { props } = sender
  const { environment, authentication, gridComponent } = props

  const { state } = gridComponent
  const { gridDataObj } = state
  const { request_ep } = gridDataObj

  const grid_filter_url = request_ep.split('?')[1]

  const grid_filter_params = new URLSearchParams(grid_filter_url)
  grid_filter_params.delete('page')
  grid_filter_params.delete('per_page')
  const parsed_grid_params = grid_filter_params.toString()

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'order',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'get-filtered-order-total-turnover',
    pDefValue: 'get-filtered-order-total-turnover',
  })

  const parsedEp = parseEndpoint(requestData)
  const injected_ep = `${parsedEp.split('?')[0]}?${parsed_grid_params}`

  const response = await httpPost(
    `${process.env.apiUrl}${injected_ep}`,
    {},
    true
  )

  const { status } = response
  if (status === 200) {
    const TotalTurnover = loadable(() =>
      import('../../components/orders_group/total_turnover')
    )

    const c_id = 'total_turnover_container'
    const dynamicComponent = React.createElement(TotalTurnover, {
      key: Math.random(),
      container_id: c_id,
      total: parseFloat(response.data.data).toFixed(2),
    })
    const container = document.createElement('div')
    container.id = c_id
    document.body.appendChild(container)
    ReactDOM.render(dynamicComponent, container)
  }
}

export async function exportPdf(sender) {
  const { props } = sender

  const { authentication } = props
  const { access_token } = authentication

  const request_id_list = await getSelectedIdList(sender)
  const request_path = `${process.env.apiServerUrl}pdf/0/order-tickets/?tk=${access_token}&id_list=${request_id_list}`
  if (typeof window !== 'undefined') {
    window.open(request_path, '_self')
  }
}

export async function archive(sender) {
  const { props } = sender
  const { environment, authentication, gridComponent } = props
  const { state } = gridComponent
  const { selectedRows } = state
  const { idList } = selectedRows

  /* Creating a request object that will be used to make the api call. */
  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'order',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'archive',
    pDefValue: 'archive',
  })

  const parsedEp = parseEndpoint(requestData)

  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    {
      id_list: idList,
    },
    true
  )

  const customizations = {}
  defaultManageResponse(sender, customizations, response)
}

export function payOrderEnabled(order) {
  const { order_status, payment_completed, payment_method } = order
  if (order_status === orderStatusMap.wc_cancelled) return false
  if (payment_method === paymentMethodsMap.bacs) return false

  const pay_order_enabled = payment_completed === 'no'
  return pay_order_enabled
}

export function goToPayOrder(data) {
  const { order_id, order_key } = data
  const payment_link = `https://www.popthequestion.it/checkout/order-pay/${order_id}/?pay_for_order=true&key=${order_key}`

  if (typeof window !== 'undefined') {
    window.open(payment_link, '_blank').focus()
  }
}

export function uploadPaymentProofEnabled(order) {
  const { order_status, payment_method } = order
  if (order_status === orderStatusMap.wc_cancelled) return false

  const upload_payment_proof = payment_method === paymentMethodsMap.bacs

  return upload_payment_proof
}

export function sendTextsEnabled(order) {
  const { order_status } = order
  return order_status === orderStatusMap.wc_inattesaditesti
}

export function viewPreviewEnabled(order) {
  return order.has_preview === 'yes'
  // const { order_status } = order
  // return order_status === orderStatusMap.wc_attesapprovazione
}

export function showPaymentProof(sender) {
  const { props } = sender
  const { row, authentication } = props
  const { access_token } = authentication
  const { order_payment_proof } = row

  const file_url = `${order_payment_proof}?access_token=${access_token}`

  if (typeof window !== 'undefined') {
    window.open(file_url, '_blank').focus()
  }
}

export function orderDetails(sender) {
  const { props } = sender
  const { row } = props
  const { order_id } = row

  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'orders_group/details',
    data: {
      sender,
      order_id,
    },
  }

  injectComponent(sender, comp_id, component_definition)
}

export function changeStatus(sender) {
  const { props } = sender
  const { row } = props
  const { order_id } = row

  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'orders_group/change_status_component',
    data: {
      sender,
      order_id,
    },
  }

  injectComponent(sender, comp_id, component_definition)
}

export function showEditTextsPanel(sender, order_id) {
  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'orders_group/edit_texts_component',
    data: {
      sender,
      order_id,
    },
  }

  injectComponent(sender, comp_id, component_definition)
}

export function manageTextsAdmin(sender) {
  const { props } = sender
  const { row } = props
  const { order_id } = row

  showEditTextsPanel(sender, order_id)
}

export function manageTextsMyAccount(sender, record) {
  const { ID } = record
  showEditTextsPanel(sender, ID)
}

export async function sendTexts(sender) {
  const { state, props } = sender
  const { fields, localDataSource } = state
  const { c_def, environment, authentication } = props
  const { entity } = c_def
  const { data } = entity
  const { order_id, parent_component_id } = data

  /* Creating a request object that will be used to make the api call. */
  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'order',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'save-texts',
    pDefValue: 'save-texts',
  })

  const parsedEp = parseEndpoint(requestData)

  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    { order_id, fields, values: localDataSource },
    true
  )

  const customizations = {
    on_success_callback: params => {
      const { msbox_instance_id } = params
      retrieveComponent(sender, msbox_instance_id)
      retrieveComponent(sender, parent_component_id)
    },
  }
  defaultManageResponse(sender, customizations, response)
}

export function showPreviewPanel(data) {
  const { sender } = data
  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'orders_group/preview_component',
    data,
  }

  injectComponent(sender, comp_id, component_definition)
}

export function managePreviewAdmin(sender) {
  const { props } = sender
  const { row } = props
  const { order_id, customer_id } = row

  const data = {
    sender,
    order_id,
    customer_id,
  }

  showPreviewPanel(data)
}

export function managePreviewMyAccount(sender, record) {
  const { props } = sender
  const { authentication } = props
  const { user } = authentication
  const { ID } = user

  const data = {
    sender,
    order_id: record.ID,
    customer_id: ID,
  }

  showPreviewPanel(data)
}

export async function savePreview(sender, preview_data) {
  const { props } = sender

  const { data, environment, authentication } = props
  const { comp_id } = data

  /* Creating a request object that will be used to make the api call. */
  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'order',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'save-preview-data',
    pDefValue: 'save-preview-data',
  })

  const parsedEp = parseEndpoint(requestData)

  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    preview_data,
    true
  )

  const customizations = {
    on_success_callback: params => {
      const { msbox_instance_id } = params
      retrieveComponent(sender, msbox_instance_id)
      retrieveComponent(sender, comp_id)
    },
  }
  defaultManageResponse(sender, customizations, response)
}

export async function handleSendPreview(sender) {
  const { state, props } = sender
  const { attachments, text, status } = state
  const { data } = props

  const { order_id, message_list } = data

  const current_message = {
    attachments,
    text,
    status,
    feedback: '',
    datetime: Date.now(),
  }
  message_list.push(current_message)

  const preview_data = {
    order_id,
    messages: message_list,
    status: orderStatusMap.wc_attesapprovazione,
  }

  await savePreview(sender, preview_data)

  const clonedLoadGrid = retrieveBindedElement(sender, 'cloned_load_grid_func')

  if (clonedLoadGrid) {
    await clonedLoadGrid()
  }
}

export function overridedGetColumnBgColor(sender, idx) {
  let bg_color = idx % 2 !== 0 ? 'bg-light' : 'bg-white'


  if (typeof window !== 'undefined') {
    const { pathname } = window.location
    if (pathname === '/admin/orders/all/') {
      const { props } = sender
      const { row } = props
      const { order_type } = row

      if (order_type === 'sample') {
        bg_color = 'bg-blue'
      } else if (order_type === 'boutique') {
        bg_color = 'bg-amber'
      }
    }
  }
  return bg_color
}
