import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  bindElementToComponent,
  cloneObj,
  defaultManageResponse,
  mapStateToProps,
  retrieveBindedElement,
  retrieveComponent,
  translate,
} from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import JujoLoading from '../../../../../../src/components/loading'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import { httpPost } from '../../../../../../src/services/apiService'
import ECSelectFieldComponent from '../../../../../../src/components/common/fields/selectField'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'

const classNames = require('classnames')

export class ChangeStatusComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  performActionOnWillUnmount = async () => {}

  performActionOnDidMount = async () => {
    const { component_data } = this.state
    const { data } = this.props
    const { order_id } = data

    const cloned_data = cloneObj(component_data)
    cloned_data.loading = true
    await this.updateData(cloned_data)

    const { environment, authentication } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-order-statuses',
      pDefValue: 'get-order-statuses',
    })

    const parsedEp = parseEndpoint(requestData)
    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
      order_id,
    })

    const { status } = response

    let flow_status = ''
    let paid_status = 'no'
    let flow_status_list = []
    if (status === 200 || status === 201) {
      const order_data = response.data.data
      flow_status = order_data.flow_status
      flow_status_list = order_data.flow_status_list
      paid_status = order_data.paid_status
    }

    cloned_data.loading = false

    cloned_data.flow_status = flow_status
    cloned_data.new_flow_status = flow_status
    cloned_data.flow_status_list = flow_status_list

    cloned_data.paid_status = paid_status
    cloned_data.new_paid_status = paid_status

    cloned_data.flow_field_def = this.createFieldDefinition(flow_status_list)
    cloned_data.paid_field_def = this.createFieldDefinition([
      { label: 'yes', value: 'yes' },
      { label: 'no', value: 'no' },
    ])

    bindElementToComponent(
      this,
      'cloned_load_grid_func',
      this.props.data.sender.props.gridComponent.loadGrid
    )

    await this.updateData(cloned_data)
  }

  handleFlowStatusChanged = async (_name, value) => {
    const { component_data } = this.state

    const cloned_data = cloneObj(component_data)
    cloned_data.new_flow_status = value
    await this.updateData(cloned_data)
  }

  handlePaidStatusChanged = async (_name, value) => {
    const { component_data } = this.state

    const cloned_data = cloneObj(component_data)
    cloned_data.new_paid_status = value
    await this.updateData(cloned_data)
  }

  handleOnSuccessCallback = async params => {
    const { msbox_instance_id } = params

    retrieveComponent(this, msbox_instance_id)

    retrieveBindedElement(this, 'cloned_load_grid_func')()

    this.handleClose()
  }

  handleSaveOrderStatusesClicked = async () => {
    const { data, environment, authentication } = this.props

    const { component_data } = this.state

    const { new_flow_status, new_paid_status } = component_data
    const { order_id } = data

    /* Creating a request object that will be used to make the api call. */
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'update-status',
      pDefValue: 'update-status',
    })

    const parsedEp = parseEndpoint(requestData)

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { order_id, new_flow_status, new_paid_status },
      true
    )

    const customizations = {
      on_success_callback: this.handleOnSuccessCallback,
    }
    defaultManageResponse(this, customizations, response)
  }

  createFieldDefinition = flow_status_list => {
    const options = []
    for (let i = 0; i !== flow_status_list.length; i += 1) {
      const status = flow_status_list[i]
      const { label, value } = status
      options.push({ label, value })
    }
    const flow_field_def = {
      name: 'status_field',
      options,
    }
    return flow_field_def
  }

  renderBody = () => {
    this.fieldRef = React.createRef()

    const { component_data } = this.state
    const { data } = this.props

    const { order_id } = data

    const {
      loading,
      flow_status,
      paid_status,
      flow_field_def,
      paid_field_def,
    } = component_data

    const html = []

    html.push(
      <div key="order_details_component">
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>
            <div className={classNames('mb-3')}>
              {`${translate('changing_status_order_number')} ${order_id}`}
            </div>
            <div className={classNames('row')}>
              <div className={classNames('col-12 col-md-6')}>
                <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
                  {translate('flow_status')}
                </div>
                <div
                  className={classNames(
                    'p-1 border border-color-4 rounded bg-white mb-3'
                  )}
                >
                  <ECSelectFieldComponent
                    field={flow_field_def}
                    handleValueChanged={this.handleFlowStatusChanged}
                    initialValue={flow_status}
                  />
                </div>
              </div>
              <div className={classNames('col-12 col-md-6')}>
                <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
                  {translate('payment_status')}
                </div>
                <div
                  className={classNames(
                    'p-1 border border-color-4 rounded bg-white mb-3'
                  )}
                >
                  <ECSelectFieldComponent
                    field={paid_field_def}
                    handleValueChanged={this.handlePaidStatusChanged}
                    initialValue={paid_status}
                  />
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'd-flex justify-content-center justify-content-md-end align-items-center'
              )}
            >
              <JujoButtonComponent
                bstyle={3}
                blabel={translate('save')}
                bdata={{}}
                handleClick={this.handleSaveOrderStatusesClicked}
              />
            </div>
          </div>
        )}
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(ChangeStatusComponent)
