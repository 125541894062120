import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../src/services/apiService'
import { lsGet } from '../../../../../src/services/localStorage'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import {
  defaultManageResponse,
  retrieveComponent,
  slugifyString,
  translate,
} from '../../../../../src/utils'
import productTypesMap from '../../components/estimates_group/data_component/_parts/enums/productTypesMap'
import { goToPayOrder } from '../orders'

/**
 * It takes a type and returns a jujo_field_type
 * @param type - The type of field you want to create.
 * @returns the jujo_field_type.
 */
export function getJujoFieldTypeByType(type) {
  let jujo_field_type = ''
  if (type === 'radio' || type === 'checkbox') {
    jujo_field_type = 'radioButtonCheckBoxField'
  } else if (type === 'select') {
    jujo_field_type = 'selectField'
  }
  return jujo_field_type
}

/**
 * It takes a data object and returns a string that is used as the label for the option
 * @param data - The data object of the option
 * @returns A string of HTML
 */
export function generateOptionLabel(data) {
  const { type, use_images, image, title, price } = data
  const formatted_price = `(${price} €)`
  const render_price = price > 0

  let label = ''

  if (type === 'radio' || type === 'checkbox') {
    if (use_images) {
      const image_element =
        image !== ''
          ? `<div class='background-image' style='background-image:url(${image}); width:120px; height:120px;' ></div>`
          : `<div class='bshadow-c1 d-flex justify-content-center align-items-center' style='width:120px; height:120px;'>No Image</div>`
      label = `
      <div class="d-flex flex-column align-items-center">
        ${image_element}
        <div class="mt-2 text-center">${title}</div>
        <div class="fs-7">${formatted_price}</div>
      </div>`
    } else {
      label = `<div><span class="">${title}</span>${render_price ? ` - <span class="fs-7">${formatted_price}</span>` : ''
        }</div>`
    }
  } else if (type === 'select') {
    label = title + (render_price ? ` - ${formatted_price}` : '')
  }
  return label
}

/**
 * It takes a parent key and a value, and returns a new key that is a combination of the parent key and
 * the slugified value
 * @param parent_key - The key of the parent object.
 * @param value - The value of the field you want to slugify.
 * @returns A function that takes two arguments, parent_key and value, and returns a string.
 */
export function composeKey(parent_key, value) {
  const key = `${parent_key}_${slugifyString(value)}`
  return key
}

/**
 * It takes a field definition object and returns a Jujo field object
 * @param fdef - the field definition object
 * @returns A function that takes in a field definition and returns a field object.
 */
export function parseToJujoField(fdef) {
  const options = []

  const direction = fdef.use_images ? 'horizontal' : 'vertical'

  const field_name = `${fdef.product_id}_options_${fdef.uid}`
  for (let i = 0; i !== fdef.title_list.length; i += 1) {
    const enabled = JSON.parse(fdef.enabled_list[i] || '0')
    if (!enabled) {
      continue
    }

    const price =
      fdef.price_list[i] !== ''
        ? parseFloat(fdef.price_list[i]).toFixed(2)
        : parseFloat('0.00').toFixed(2)

    const set_to_fee =
      fdef.set_to_fee.length > 0 ? JSON.parse(fdef.set_to_fee[i] || '0') : 0

    const label_data = {
      type: fdef.type,
      use_images: fdef.use_images,
      image: fdef.images_list[i],
      title: fdef.title_list[i],
      price,
    }

    const label = generateOptionLabel(label_data)
    const value = fdef.values_list[i]
    const key = composeKey(field_name, value)

    options.push({
      key,
      label,
      value,
      price,
      global_set_to_fee: fdef.global_set_to_fee,
      set_to_fee,
    })
  }

  const field_definition = {
    name: field_name,
    type: fdef.type,
    direction,
    title: fdef.header_title || '',
    hidden_input: !!fdef.use_images,
    uid: fdef.uid,
    options,
  }

  const field = {
    field_type: getJujoFieldTypeByType(fdef.type),
    field_definition,
  }

  return field
}

/**
 * It takes a list of field definitions and returns a list of fields
 * @param f_defs - the object that contains all the field definitions
 * @returns An object with the following properties:
 * product_id,
 * type,
 * header_title,
 * uniqid_list,
 * use_image_list,
 * global_set_to_fee,
 * options_set_to_fee,
 * options_enabled,
 * options_title,
 * options_value,
 * options_price,
 * options_image
 */
export function generateFieldsFromDefinitionList(f_defs) {
  const fields_list = []

  for (let i = 0; i !== f_defs.uniqid_list.length; i += 1) {
    const f_definition = {
      product_id: f_defs.product_id,
      type: f_defs.type,
      uid: f_defs.uniqid_list[i],
      header_title: f_defs.header_title[i],
      global_set_to_fee: f_defs.global_set_to_fee
        ? JSON.parse(f_defs.global_set_to_fee[i] || '0')
        : 0,
      set_to_fee: f_defs.options_set_to_fee ? f_defs.options_set_to_fee[i] : [],
      enabled_list: f_defs.options_enabled ? f_defs.options_enabled[i] : true,
      title_list: f_defs.options_title[i],
      values_list: f_defs.options_value[i],
      price_list: f_defs.options_price[i],
      images_list: f_defs.options_image[i],
      use_images:
        (f_defs.use_image_list && f_defs.use_image_list[i] === 'images') ||
        (f_defs.replacement_mode && f_defs.replacement_mode[i] === 'image'),
    }

    const field = parseToJujoField(f_definition)
    fields_list.push(field)
  }
  return fields_list
}

/**
 * It takes the builder's state and returns a list of fields type definitions
 * @param tmfbuilder - the builder object
 * @param f_type_conversion - This is an object that contains the following properties:
 * @returns An object with the following properties:
 * product_id,
 * type,
 * header_title,
 * uniqid_list,
 * use_image_list,
 * global_set_to_fee,
 * options_set_to_fee,
 * options_enabled,
 * options_title,
 * options_value,
 * options_price,
 * options_image,
 */
export function getFieldsTypeDefinitionsList(tmfbuilder, f_type_conversion) {
  const { wp_obj, html_type } = f_type_conversion

  const uniqid_list = tmfbuilder[`${wp_obj}_uniqid`]
  if (!uniqid_list || uniqid_list.length === 0) return false

  const header_title = tmfbuilder[`${wp_obj}_header_title`]

  /**
   * @deprecated
   */
  // use_images has been changed to replacement_mode in new plugin version
  // however use_images management must also be retained to ensure backward
  // compatibility with definitions created before the Extra Product Options
  // plugin was updated
  const use_image_list = tmfbuilder[`${wp_obj}_use_images`]

  const replacement_mode = tmfbuilder[`${wp_obj}_replacement_mode`]
  const global_set_to_fee = tmfbuilder[`${wp_obj}_fee`]
  const options_set_to_fee = tmfbuilder[`multiple_${wp_obj}_options_fee`]
  const options_enabled = tmfbuilder[`multiple_${wp_obj}_options_enabled`]
  const options_title = tmfbuilder[`multiple_${wp_obj}_options_title`]
  const options_value = tmfbuilder[`multiple_${wp_obj}_options_value`]
  const options_price = tmfbuilder[`multiple_${wp_obj}_options_price`]
  const options_image = tmfbuilder[`multiple_${wp_obj}_options_image`]

  const { product_id } = tmfbuilder

  return {
    product_id,
    type: html_type,
    header_title,
    uniqid_list,
    use_image_list,
    replacement_mode,
    global_set_to_fee,
    options_set_to_fee,
    options_enabled,
    options_title,
    options_value,
    options_price,
    options_image,
  }
}

/**
 * It takes the tmfbuilder object and returns a list of fields
 * @param tmfbuilder - the object that contains the form builder data
 * @returns An array of objects.
 */
export function getParsedFields(tmfbuilder) {
  const fields_type_conversion_map = [
    { wp_obj: 'selectbox', html_type: 'select' },
    { wp_obj: 'checkboxes', html_type: 'checkbox' },
    { wp_obj: 'radiobuttons', html_type: 'radio' },
  ]

  const fields_list = []

  for (let i = 0; i !== fields_type_conversion_map.length; i += 1) {
    const f_type_conversion = fields_type_conversion_map[i]
    const f_defs = getFieldsTypeDefinitionsList(tmfbuilder, f_type_conversion)
    if (f_defs) {
      const f_list = generateFieldsFromDefinitionList(f_defs)
      fields_list.push(...f_list)
    }
  }

  return fields_list
}

/**
 * It iterates through the options lists and returns a list of fields
 * @param sender - the component that is calling the function
 * @returns An array of objects.
 */
export function iterateOptionsLists(sender) {
  const { state, props } = sender
  const { product_id } = props

  const state_options_groups = [
    'prod_options',
    'global_options',
    'category_options',
  ]

  const grouped_fields_list = []
  const used_templates = []
  for (let i = 0; i !== state_options_groups.length; i += 1) {
    const group = state[state_options_groups[i]]

    for (let j = 0; j !== group.length; j += 1) {
      const list = group[j]
      const { tmfbuilder } = list


      /* The above code is checking if the `tmfbuilder` object exists and if it has an array property called
      `template_templateids`. If it does, it loops through each `templateId` in the array and checks if it
      is already included in the `used_templates` array. If it is not already included, it adds it to the
      `used_templates` array. */
      if (tmfbuilder && Array.isArray(tmfbuilder.template_templateids)) {
        for (const templateId of tmfbuilder.template_templateids) {
          if (!used_templates.includes(templateId)) {
            used_templates.push(templateId);
          }
        }
      }


      // inject the product id
      tmfbuilder.product_id = product_id

      const fields_list = getParsedFields(tmfbuilder)
      grouped_fields_list.push(...fields_list)
    }
  }


  /** TEMPLATE INTEGRATION */
  const filtered_templates = state.template_list.filter((template) =>
    used_templates.includes(String(template.template_id))
  );

  for (let i = 0; i < filtered_templates.length; i++) {
    const { tmfbuilder } = filtered_templates[i].definition;

    // inject the product id
    tmfbuilder.product_id = product_id
    // parse template 
    setUniqIdToTemplateField(tmfbuilder)

    const fields_list = getParsedFields(tmfbuilder)
    grouped_fields_list.push(...fields_list)
  }


  return grouped_fields_list
}



/**
 * The function sets a unique ID to a template field based on certain conditions.
 * @param obj - The `obj` parameter is an object that contains properties and values. The function
 * `setUniqIdToTemplateField` takes this object as an argument and modifies it by adding new properties
 * to it.
 */
function setUniqIdToTemplateField(obj) {
  const keysToCheck = ['selectbox_enabled', 'checkboxes_enabled', 'radiobuttons_enabled'];

  keysToCheck.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      const arr = obj[key];
      if (Array.isArray(arr) && arr.length > 0 && arr[0] === '1') {
        const newKey = key.replace('_enabled', '_uniqid');
        obj[newKey] = obj.sections_uniqid;
      }
    }
  });
}


/**
 * It takes a list of fields and returns a list of fields with duplicates removed
 * @param list - The list of fields to filter.
 * @returns A function that takes a list as an argument and returns a filtered list.
 */
export function removeDuplicateFields(list) {
  const filtered = []
  const fields_already_entered = []

  for (let i = 0; i !== list.length; i += 1) {
    const field = list[i]
    const { field_definition } = field
    const { name } = field_definition

    if (fields_already_entered.indexOf(name) < 0) {
      filtered.push(field)
      fields_already_entered.push(name)
    }
  }

  return filtered
}

/**
 * It iterates through all the options lists, and returns a list of all the fields that are not
 * duplicated
 * @param sender - The sender of the message.
 * @returns An array of objects.
 */
export function getExtraProductOptionFields(sender) {
  const fields_list = iterateOptionsLists(sender)
  const filtered_list = removeDuplicateFields(fields_list)

  return filtered_list
}

/**
 * It takes a list of options and prices, a list of customized prices, an option key, a search field, a
 * search value, and a quantity, and returns an object with the option key, value, unit price, price,
 * and calculated option price
 * @param options_prices - The options_prices object from the product.
 * @param customized_price_list - This is the list of customized prices for the options.
 * @param option_key - the key of the option you want to extract the price from
 * @param search_field - The field to search for the value in the options_prices object.
 * @param search_value - The value of the option you want to search for.
 * @param quantity - the quantity of the product
 */
export function extractPrice(
  options_prices,
  customized_price_list,
  option_key,
  search_field,
  search_value,
  quantity
) {
  const opt_def_list = options_prices[option_key] || []
  const filtered_opt = opt_def_list.filter(
    o => o[search_field] === search_value
  )
  const selected_opt = filtered_opt[0] || {}

  if (Object.keys(selected_opt).length > 0) {
    const { title, key, value, price, global_set_to_fee, set_to_fee } =
      selected_opt
    const set_to_fee_enabled = global_set_to_fee || set_to_fee

    const calculated_opt_price = parseFloat(
      set_to_fee_enabled ? price : price * quantity
    ).toFixed(2)

    let opt_price = 0
    let customized_price_type = ''
    let customized_price_value = ''
    if (customized_price_list && key in customized_price_list) {
      const customied_price_record = customized_price_list[key]

      const { type, value: custom_value } = customied_price_record
      customized_price_type = type
      customized_price_value = custom_value
      if (type === 'percentage') {
        const percentage_value = (
          (calculated_opt_price / 100) *
          custom_value
        ).toFixed(2)
        opt_price = (calculated_opt_price - percentage_value).toFixed(2)
      } else {
        opt_price = custom_value.toFixed(2)
      }
    } else {
      opt_price = calculated_opt_price
    }

    return {
      key,
      value,
      title,
      unit_price: price,
      price: opt_price,
      customized_price_type,
      customized_price_value,
      calculated_opt_price,
    }
  }
  return false
}

/**
 * It takes a product object, an options_prices object, and a customized_price_list object, and returns
 * an array of price objects
 * @param prod - the product object
 * @param options_prices - the options_prices object from the product
 * @param customized_price_list - This is the price list that is customized for the user.
 * @returns An array of objects.
 */
export function doOptionsCalculation(
  prod,
  options_prices,
  customized_price_list
) {
  const { quantity, product_options } = prod
  const options_keys = Object.keys(product_options)

  const options_price_list = []
  for (let j = 0; j !== options_keys.length; j += 1) {
    const o_key = options_keys[j]
    const opt_value = product_options[o_key]

    if (opt_value !== null && typeof opt_value === 'object') {
      const record_keys = Object.keys(opt_value)
      for (let k = 0; k !== record_keys.length; k += 1) {
        const r_key = record_keys[k]
        const selected = opt_value[r_key]
        if (selected) {
          const price_obj = extractPrice(
            options_prices,
            customized_price_list,
            o_key,
            'key',
            r_key,
            quantity
          )
          if (price_obj) {
            options_price_list.push(price_obj)
          }
        }
      }
    } else {
      const price_obj = extractPrice(
        options_prices,
        customized_price_list,
        o_key,
        'value',
        opt_value,
        quantity
      )
      if (price_obj) {
        options_price_list.push(price_obj)
      }
    }
  }

  return options_price_list
}

/**
 * If the product type is custom, return the custom product data, otherwise return the selected product
 * data.
 * @param prod - The product object
 * @returns The product data for the product type.
 */
export function getProductData(prod) {
  const { product_type, custom_product, selected_product } = prod

  let product_data = {}
  if (product_type === productTypesMap.custom) {
    product_data = custom_product
  } else if (product_type === productTypesMap.existing) {
    product_data = selected_product
  }

  return product_data
}

/**
 * It takes a price and a quantity and returns the calculated price of the instances
 * @param price - The price of the instance
 * @param quantity - The number of instances you want to create.
 * @returns the calculated_instaces_price.
 */
export function getCalculatedInstancesPrice(price, quantity) {
  const calculated_instaces_price = (
    parseFloat(price) * parseInt(quantity, 10)
  ).toFixed(2)

  return calculated_instaces_price
}

/**
 * It filters out products that not have a custom price
 * @param list - the list of products
 * @returns A list of products that not have a customized price.
 */
export function filterProductsWithoutCustomPrice(list) {
  const filtered_list = []
  for (let i = 0; i !== list.length; i += 1) {
    const prod = list[i]
    const { product_customized_price } = prod

    if (product_customized_price === false) {
      filtered_list.push(prod)
    }
  }

  filtered_list
    .sort((a, b) => a.not_customized_amount - b.not_customized_amount)
    .reverse()

  return filtered_list
}

/**
 * "If the limit is 0, return the list. If the limit is greater than or equal to the list's length,
 * return the list. Otherwise, return a list of the first limit items from the list, prioritizing
 * non-discounted items."
 *
 * The function is a bit long, but it's not too complicated. It's just a bunch of if statements and for
 * loops
 * @param list - an array of objects, each object has a property called on_sale which is either 0 or 1
 * @param limit - The number of items to limit the list to.
 * @returns A list of products that are not on sale, and if the list is not long enough, a list of
 * products that are on sale.
 */
export function limitUsageToXItems(list, limit) {
  if (limit === 0) return list

  if (limit >= list.length) return list

  const limited_list = []

  const non_discounted_products = list.filter(x => x.on_sale === 0)

  for (let i = 0; i !== non_discounted_products.length; i += 1) {
    const prod = non_discounted_products[i]
    limited_list.push(prod)
    if (i === limit - 1) break
  }

  if (limited_list.length < limit) {
    const discounted_products = list.filter(x => x.on_sale === 1)
    const missing = limit - limited_list.length

    for (let i = 0; i !== discounted_products.length; i += 1) {
      const prod = discounted_products[i]
      limited_list.push(prod)
      if (i === missing - 1) break
    }
  }

  return limited_list
}

/**
 * It applies a discount to a list of products, and returns the total discountable amount and the total
 * discount value
 * @param list - the list of products in the cart
 * @param coupon - the coupon object
 * @returns An object with two properties: discountable_amount and discount_value.
 */
export function applyDiscount(list, coupon) {
  const { limit_usage_to_x_items, coupon_amount, discount_type } = coupon
  const cancel_offer_on_product = []

  /* Checking if the discount type is fixed_cart. If it is, it returns the discountable amount and the
  discount value. */
  if (discount_type === 'fixed_cart') {
    return {
      discountable_amount: 0.0,
      discount_value: coupon_amount.toFixed(2),
      cancel_offer_on_product,
    }
  }

  /* Filtering out all the products that do not have a custom price. */
  const filtered_list = filterProductsWithoutCustomPrice(list)
  /* Limiting the usage of the filtered list to the number of items specified in the
  limit_usage_to_x_items variable. */
  const limited_list = limitUsageToXItems(filtered_list, limit_usage_to_x_items)

  let total_discountable_amount = 0.0
  let total_discount_value = 0.0

  /* Calculating the total discountable amount and total discount value. */
  for (let i = 0; i !== limited_list.length; i += 1) {
    const prod = limited_list[i]
    const {
      key,
      not_customized_amount,
      regular_not_customized_amount,
      on_sale,
      total_product_price_with_options,
      total_regular_price_with_options,
    } = prod

    const price_to_discount =
      on_sale === 1 ? regular_not_customized_amount : not_customized_amount

    let discounted_price = 0.0
    let discount_value = 0.0

    discount_value =
      (parseFloat(price_to_discount) / 100) * parseFloat(coupon_amount)

    discounted_price = parseFloat(price_to_discount) - discount_value

    const better_than_offer_price = discounted_price <= not_customized_amount

    /* Checking if the price is better than the offer price. If it is, it adds the price to discount and
    the discount value to the total discount value. */
    if (better_than_offer_price) {
      total_discountable_amount =
        parseFloat(total_discountable_amount) + parseFloat(price_to_discount)
      total_discount_value =
        parseFloat(total_discount_value) + parseFloat(discount_value)

      cancel_offer_on_product.push({
        key,
        offer_total: total_product_price_with_options,
        regular_total: total_regular_price_with_options,
      })
    }
  }

  return {
    discountable_amount: total_discountable_amount.toFixed(2),
    discount_value: total_discount_value.toFixed(2),
    cancel_offer_on_product,
  }
}

/**
 * It calculates the total price of the cart, the subtotal, the discount amount, the discountable
 * amount, the shipping price and the list of products with their prices
 * @param field - the field object
 * @param options_prices - an object containing the prices of the options.
 * @returns An object with the following properties:
 */
export function doCalculations(field, options_prices) {
  const { products, shipping, customized_price_list, coupon_data } = field
  const prod_keys = products ? Object.keys(products) : []

  let subtotal = 0
  const list = []
  for (let i = 0; i !== prod_keys.length; i += 1) {
    const p_key = prod_keys[i]
    const prod = products[p_key]

    const { quantity } = prod

    const product_data = getProductData(prod)

    if (!product_data || Object.keys(product_data).length === 0) continue
    const { product_name, price, regular_price, on_sale } = product_data

    const parsed_regular_price = regular_price || price

    const calculated_instaces_price = getCalculatedInstancesPrice(
      price,
      quantity
    )

    const regular_instances_price = getCalculatedInstancesPrice(
      parsed_regular_price,
      quantity
    )

    let product_instances_price = 0
    let customized_price_type = ''
    let customized_price_value = ''
    if (customized_price_list && p_key in customized_price_list) {
      const customied_price_record = customized_price_list[p_key]
      const { type, value } = customied_price_record

      customized_price_type = type
      customized_price_value = value

      if (type === 'percentage') {
        const percentage_value = (
          (calculated_instaces_price / 100) *
          value
        ).toFixed(2)
        product_instances_price = (
          calculated_instaces_price - percentage_value
        ).toFixed(2)
      } else {
        product_instances_price = value.toFixed(2)
      }
    } else {
      product_instances_price = calculated_instaces_price
    }

    const product_customized_price =
      product_instances_price !== calculated_instaces_price

    subtotal = (
      parseFloat(subtotal) + parseFloat(product_instances_price)
    ).toFixed(2)

    const options = doOptionsCalculation(
      prod,
      options_prices,
      customized_price_list
    )

    let total_product_price_with_options = product_instances_price
    let total_regular_price_with_options = regular_instances_price
    let total_customized_options_price = 0
    for (let j = 0; j !== options.length; j += 1) {
      const curr_opt = options[j]

      if (curr_opt.calculated_opt_price !== curr_opt.price) {
        total_customized_options_price = (
          parseFloat(total_customized_options_price) +
          parseFloat(curr_opt.price)
        ).toFixed(2)
      }

      total_product_price_with_options = (
        parseFloat(total_product_price_with_options) +
        parseFloat(curr_opt.price)
      ).toFixed(2)

      total_regular_price_with_options = (
        parseFloat(total_regular_price_with_options) +
        parseFloat(curr_opt.price)
      ).toFixed(2)

      subtotal = (parseFloat(subtotal) + parseFloat(curr_opt.price)).toFixed(2)
    }

    const not_customized_amount = (
      product_customized_price
        ? total_product_price_with_options -
        product_instances_price -
        total_customized_options_price
        : total_product_price_with_options - total_customized_options_price
    ).toFixed(2)

    const regular_not_customized_amount = (
      product_customized_price
        ? total_regular_price_with_options -
        product_instances_price -
        total_customized_options_price
        : total_regular_price_with_options - total_customized_options_price
    ).toFixed(2)

    const prod_obj = {
      key: p_key,
      product_name,
      quantity,
      price,
      regular_price: parsed_regular_price,
      on_sale: on_sale || 0,
      product_instances_price,
      calculated_instaces_price,
      regular_instances_price,
      product_customized_price,
      customized_price_type,
      customized_price_value,
      total_product_price_with_options,
      total_regular_price_with_options,
      total_customized_options_price,
      not_customized_amount,
      regular_not_customized_amount,
      options,
    }

    list.push(prod_obj)
  }

  let total_price = subtotal
  let cumulative_discount_amount = parseFloat(0.0).toFixed(2)
  let discountable_amount = 0
  const coupon_discount_list = {}

  // apply discount to subtotal
  if (coupon_data) {
    const coupon_code_list = Object.keys(coupon_data)

    for (let i = 0; i !== coupon_code_list.length; i += 1) {
      const current_coupon = coupon_code_list[i]
      const current_coupon_data = coupon_data[current_coupon]

      // calculate discountable amount
      const discount = applyDiscount(list, current_coupon_data)

      const { cancel_offer_on_product } = discount
      for (let j = 0; j !== cancel_offer_on_product.length; j += 1) {
        const offer_to_remove = cancel_offer_on_product[j]
        const { offer_total, regular_total } = offer_to_remove
        subtotal = (
          parseFloat(subtotal) -
          parseFloat(offer_total) +
          parseFloat(regular_total)
        ).toFixed(2)
      }

      const current_code_discount_value = parseFloat(
        discount.discount_value
      ).toFixed(2)

      coupon_discount_list[current_coupon] = current_code_discount_value

      if (discount.discountable_amount > 0) {
        discountable_amount = discount.discountable_amount
      }

      cumulative_discount_amount = (
        parseFloat(cumulative_discount_amount) +
        parseFloat(current_code_discount_value)
      ).toFixed(2)
    }

    total_price = (
      parseFloat(subtotal) - parseFloat(cumulative_discount_amount)
    ).toFixed(2)
  }

  // add shipping price
  const shipping_price = {}
  if (shipping && Object.keys(shipping).length > 0) {
    const { method } = shipping
    const { settings } = method
    const { title, cost } = settings
    shipping_price.title = title
    shipping_price.cost = parseFloat(cost || 0).toFixed(2)

    total_price = (parseFloat(total_price) + parseFloat(cost || 0)).toFixed(2)
  }

  const calculations = {
    total_price,
    discount_amount: cumulative_discount_amount,
    discountable_amount,
    subtotal,
    shipping_price,
    list,
    coupon_discount_list,
  }
  return calculations
}

/**
 * It takes the data from the estimate and sends it to the server to be processed as an order
 * @param sender - the component that is calling the function
 */
export async function toOrder(sender) {
  const { props, state } = sender
  const { localDataSource } = state || {}
  const { row, authentication } = props

  const environment = lsGet('environment')

  const data = localDataSource || row

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'estimate',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'to-order',
    pDefValue: 'to-order',
  })

  const parsedEp = parseEndpoint(requestData)

  const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, data)

  const customizations = {
    success_message: translate('estimate_processed_successfully'),
    on_success_callback:
      environment.path === '/my-account/estimates/'
        ? params => {
          const { msbox_instance_id } = params
          const { closeMessageAndExitEdit } = params.sender
          const { reloadData } = params.sender.props

          goToPayOrder(params.data)

          if (closeMessageAndExitEdit) {
            closeMessageAndExitEdit(params)
          } else {
            reloadData()
            retrieveComponent(sender, msbox_instance_id)
          }
        }
        : null,
  }
  defaultManageResponse(sender, customizations, response)
}

// export function listSelectedOptions(product_options, options_prices) {
//   const selected_options_list = {}
//   const options_keys = Object.keys(product_options)

//   if (Object.keys(options_prices).length === 0) return selected_options_list

//   for (let j = 0; j !== options_keys.length; j += 1) {
//     const o_key = options_keys[j]

//     const opt_value = product_options[o_key]
//     if (typeof opt_value === 'object') {
//       const record_keys = Object.keys(opt_value)
//       for (let k = 0; k !== record_keys.length; k += 1) {
//         const r_key = record_keys[k]
//         const selected = opt_value[r_key]

//         if (selected) {
//           selected_options_list[r_key] = {
//             option_key: o_key,
//           }
//         }
//       }
//     } else if (opt_value !== '') {
//       const generated_key = composeKey(o_key, opt_value)
//       selected_options_list[generated_key] = {
//         option_key: o_key,
//       }
//     }
//   }

//   const option_prop_keys = Object.keys(selected_options_list)
//   for (let i = 0; i !== option_prop_keys.length; i += 1) {
//     const key = option_prop_keys[i]
//     const prop_obj = selected_options_list[key]

//     const { option_key } = prop_obj

//     const opt = options_prices[option_key].filter(x => x.key === key)[0]
//     const { price, set_to_fee } = opt

//     prop_obj.price = price
//     prop_obj.set_to_fee = set_to_fee
//   }

//   return selected_options_list
// }
