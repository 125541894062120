import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { getSelectedSvgRows } from '../../../../../utils/svg/interactive_text'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))
const JujoSVGNumberSelector = loadable(() => import('../number_selector'))

function JujoSVGLineHeightManagerComponent({ element_key, curr_nidx }) {
  const [text_element, setTextElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const t_element = g_element.querySelector('text')
    setTextElement(t_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  const handleLineHeightChanged = l_height => {
    const selected_rows = getSelectedSvgRows(element_key)
    for (let i = 0; i !== selected_rows.length; i += 1) {
      const node = selected_rows[i]
      node.setAttribute('dy', l_height)
    }
    updatedRectBorder(text_element)
  }

  const getDefaultValue = () => {
    const selected_node =
      text_element.querySelectorAll('tspan>tspan')[curr_nidx]

    if (selected_node) {
      const { parentNode } = selected_node

      const dy = parentNode.getAttribute('dy')
      return dy
    }
    return 0
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && (
        <div className={classNames('')}>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('line_height')}</div>
          </div>
          <div key={curr_nidx}>
            <JujoSVGNumberSelector
              handleChange={handleLineHeightChanged}
              defaultValue={getDefaultValue()}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGLineHeightManagerComponent
