import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Parser from 'html-react-parser'
import loadable from '@loadable/component'

import { useSelector, useDispatch } from 'react-redux'
import { mqMobileOrTablet } from '../../../../../src/utils/mediaquery'
import { cloneObj, translate } from '../../../../../src/utils'
import { resetSession } from '../../../../../src/services/sessionHelper'
import { updateSession } from '../../../../../src/store/actions/session'
import { resetAuthentication } from '../../../../../src/store/actions/authentication'

const PtqSvgCustomEditorComponent = loadable(() =>
  import('../modeling_group/custom_svg_template_editor')
)

function JujoTestEnvironment() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(mqMobileOrTablet())
  const [svgMobileEditorVisible, setSvgMobileEditorVisible] = useState(false)
  const [testingFeature, setTestingFeature] = useState(false)
  const session = useSelector(state => state.session)
  const dispatch = useDispatch()

  const handleResize = () => {
    setIsMobileOrTablet(mqMobileOrTablet())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const processLogout = async () => {
    const session_copy = cloneObj(session)
    const { session_id } = session_copy

    const session_data = await resetSession(session_id)
    session_copy.session_data = session_data

    await dispatch(updateSession(session_copy))
    await dispatch(resetAuthentication())
  }

  const handleTestMobileSvgEditor = () => {
    setTestingFeature(true)
    setSvgMobileEditorVisible(true)
  }

  const exitTestingMode = () => {
    setTestingFeature(false)
    setSvgMobileEditorVisible(false)
  }

  return (
    <div className={classNames('p-2')}>
      {!testingFeature && (
        <>
          <div
            className={classNames(
              'ffamily-secondary mb-2 pb-2 text-center fc-1 border-bottom'
            )}
          >
            {Parser(translate('test_env_incipit'), {})}
          </div>
          {isMobileOrTablet && (
            <>
              <div
                className={classNames(
                  'bg-orange-500 fc-white rounded-2 text-center mb-2 p-1'
                )}
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleTestMobileSvgEditor()
                }}
                onKeyPress={() => {
                  handleTestMobileSvgEditor()
                }}
              >
                {translate('test_mobile_svg_editor')}
              </div>
            </>
          )}
          <div
            className={classNames(
              'bg-1 fc-white text-center ffamily-secondary d-flex justify-content-center align-items-center p-1 rounded-2'
            )}
            role="button"
            tabIndex={0}
            onClick={() => {
              processLogout({ props: { session } })
            }}
            onKeyPress={() => {
              processLogout({ props: { session } })
            }}
          >
            <div className={classNames('me-2')}>{translate('exit')}</div>
            <div
              className={classNames('background-image logout-icon-2 white-svg')}
              style={{ width: 20, height: 20 }}
            />
          </div>
        </>
      )}
      {testingFeature && (
        <div
          className={classNames(
            'bg-red-500 fc-white text-center ffamily-secondary d-flex justify-content-center align-items-center p-1 rounded-2 mb-2'
          )}
          role="button"
          tabIndex={0}
          onClick={() => {
            exitTestingMode()
          }}
          onKeyPress={() => {
            exitTestingMode()
          }}
        >
          <div
            className={classNames(
              'background-image arrow-back-long-icon white-svg'
            )}
            style={{ width: 15, height: 15 }}
          />
          <div className={classNames('ms-2')}>{translate('quit_test')}</div>
        </div>
      )}
      {svgMobileEditorVisible && (
        <PtqSvgCustomEditorComponent transitionaData={{ template_id: 119 }} />
      )}
    </div>
  )
}

export default JujoTestEnvironment
