function setCookie(name, value, days = 30) {
    const expires = new Date();
    expires.setDate(expires.getDate() + days);
    const options = {
        expires: expires.toUTCString(),
        path: '/',
        secure: true
    };
    document.cookie = name + "=" + encodeURIComponent(value) + ";" + Object.entries(options).map(([key, value]) => key + "=" + value).join(";");
}

function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return decodeURIComponent(cookie.substring(name.length + 1));
        }
    }
    return null;
}

function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

function updateCookie(name, value, days = 30) {
    const existingValue = getCookie(name);

    const isValueEmpty = value === null || value === "";
    const isExistingValueEmpty = existingValue === null || existingValue === "";

    if (isValueEmpty && isExistingValueEmpty) {
        return;
    }

    if (value === existingValue) {
        return;
    }

    if (isValueEmpty) {
        deleteCookie(name);
        return;
    }

    setCookie(name, value, days);
}



export { setCookie, updateCookie, getCookie, deleteCookie };
