/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  formatDate,
  defaultHandleGenericBtnClick,
  mapStateToProps,
  translate,
} from '../../../../../../src/utils'
import estimateStatusMap from '../../../enums/estimate_status_map'

const classNames = require('classnames')

export class MyEstimatesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localDataSource: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  extractProductName = product => {
    const { product_type, custom_product, selected_product } = product
    const product_definition =
      product_type === 'custom' ? custom_product : selected_product

    const product_name = product_definition
      ? product_definition.product_name
      : ''
    return product_name
  }

  renderEstimateProductsList = products => {
    const html = []
    const prod_keys = Object.keys(products)
    for (let i = 0; i !== prod_keys.length; i += 1) {
      const key = prod_keys[i]
      const prod = products[key]
      const name = this.extractProductName(prod)
      html.push(<li key={key}>{name}</li>)
    }
    return html
  }

  handleEditClicked = record => {
    const { enterEditMode } = this.props
    enterEditMode(record)
  }

  handleConvertToOrderClicked = async record => {
    // eslint-disable-next-line react/no-unused-state
    await this.setState({ localDataSource: record })
    const custom_action_btn_definition = {
      actionPath: 'actions/estimates',
      actionName: 'toOrder',
      confirmation: {
        message: 'convert_to_order_confirmation_message',
      },
    }
    defaultHandleGenericBtnClick(custom_action_btn_definition, this)
  }

  handleGoToMyOrders = () => {
    if (typeof window !== 'undefined') {
      window.location = '/my-account/orders/'
    }
  }

  renderList = () => {
    const { data } = this.props

    const html = []

    for (let i = 0; i !== data.length; i += 1) {
      const estimate = data[i]

      if (Object.keys(estimate.data).length === 0) continue

      const { id, created_at, updated_at, total_price, status, order_id } =
        estimate
      const { shipping, products } = estimate.data
      const { method } = shipping
      const { settings } = method
      const { title } = settings

      const formatted_created_at = formatDate(created_at, true, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
      const formatted_updated_at = formatDate(updated_at, false)

      const edit_enabled = status === estimateStatusMap.not_converted_yet
      const convert_enabled = status === estimateStatusMap.not_converted_yet
      const show_order_id = status === estimateStatusMap.converted_to_order

      html.push(
        <div
          key={`estimate_${id}`}
          className={classNames('w-250px mx-2 mb-4 p-3 bg-light-gray fs-7')}
        >
          <div className={classNames('fst-uppercase ffamily-secondary fw-700')}>
            {`${translate('estimate_of')}: ${formatted_created_at}`}
          </div>
          <div className={classNames('fs-8')}>
            {`${translate('last_modification')}: ${formatted_updated_at}`}
          </div>
          <div className={classNames('my-3')}>
            <ul>{this.renderEstimateProductsList(products)}</ul>
          </div>
          <div>{`${translate('shipping')}: ${title}`}</div>
          <div className={classNames('fw-700 fs-6 mb-2')}>
            {`${translate('total')} € ${total_price}`}
          </div>
          {edit_enabled && (
            <div className={classNames('mt-2')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={'Apri preventivo'}
                handleClick={() => {
                  this.handleEditClicked(estimate)
                }}
              />
            </div>
          )}
          {convert_enabled && (
            <div className={classNames('mt-2')}>
              <JujoButtonComponent
                bstyle={3}
                bwidth="200px"
                blabel={translate('convert_to_order')}
                handleClick={() => {
                  this.handleConvertToOrderClicked(estimate)
                }}
              />
            </div>
          )}
          {show_order_id && (
            <>
              <div className={classNames('fw-700 my-2')}>
                <div>{translate('converted_to_order')}</div>
                <div className={classNames('fst-uppercase ffamily-secondary')}>
                  <span className={classNames('me-2')}>{`${translate(
                    'order_number'
                  )}: `}</span>
                  <span>{`#${order_id}`}</span>
                </div>
              </div>
              <div className={classNames('mt-2')}>
                <JujoButtonComponent
                  bstyle={3}
                  bwidth="200px"
                  blabel={translate('go_to_my_orders')}
                  handleClick={() => {
                    this.handleGoToMyOrders(estimate)
                  }}
                />
              </div>
            </>
          )}
        </div>
      )
    }

    return html
  }

  render() {
    return (
      <div className={classNames('d-flex flex-wrap')}>{this.renderList()}</div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(MyEstimatesComponent)
