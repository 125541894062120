import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'
import { JujoJsonEditor } from './_parts/json_editor'

export class ECJsonFieldComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    // https://github.com/josdejong/svelte-jsoneditor
    this.setState = () => {}
  }

  componentDidMount = async () => {}

  handleJsonChanged = updatedContent => {
    const { field, handleValueChanged } = this.props
    const { name } = field

    const { json } = updatedContent

    handleValueChanged(name, json)
  }

  render() {
    const { initialValue } = this.props

    return (
      <JujoJsonEditor
        content={{ text: undefined, json: initialValue }}
        onChange={this.handleJsonChanged}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ECJsonFieldComponent)
