import React, { useState } from 'react'
import classNames from 'classnames'
import { translate } from '../../../../utils'

function JujoSVGTemplateName({ onChange }) {
  const [title, setTitle] = useState('')

  const handleTitleChanged = e => {
    const { value } = e.target
    setTitle(value)
    onChange(value)
  }

  return (
    <div className={classNames('mb-3')}>
      <div className={classNames('ffamily-secondary mb-2 fs-5')}>
        <div>{translate('template_name')}</div>
      </div>
      <input
        className={classNames('bg-white p-2')}
        type="text"
        onChange={e => handleTitleChanged(e)}
        defaultValue={title}
      />
    </div>
  )
}

export default JujoSVGTemplateName
