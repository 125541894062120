import lsGridPropsMap from '../../../enums/lsGridPropsMap'
import { cloneObj } from '../../../utils'
import { getGridDefinition } from './gridFunctions'
import { getStoredGridProps } from './lsInteractionFunctions'

export async function clearFilters(props) {
  const { entity, updateGridIntoStorageAndRedux } = props
  const gridDefinition = getGridDefinition(props)
  const { defaults } = gridDefinition

  const parameters = []
  const defaultKeys = Object.keys(defaults)
  for (let i = 0; i !== defaultKeys.length; i += 1) {
    const key = defaultKeys[i]
    parameters.push({ rowId: null, key, value: defaults[key] })
  }

  await updateGridIntoStorageAndRedux(entity, parameters)
  return true
}

export async function sortByColumn(name, props) {
  const { entity, updateGridIntoStorageAndRedux } = props

  const gridProps = getStoredGridProps(props)
  const sortList = gridProps.sort.split(',')

  if (sortList.includes(name)) {
    const sortKeyIdx = sortList.indexOf(name)
    const newValue = `-${name}`
    sortList.splice(sortKeyIdx, 1, newValue)
  } else if (sortList.includes(`-${name}`)) {
    const sortKeyIdx = sortList.indexOf(`-${name}`)
    sortList.splice(sortKeyIdx, 1)
  } else {
    sortList.push(name)
  }

  let sortValue = sortList.join()
  if (sortValue.indexOf(',') === 0) {
    sortValue = sortValue.substring(1)
  }

  const sortProps = [
    {
      rowId: null,
      key: lsGridPropsMap.sort,
      value: sortValue,
    },
  ]
  await updateGridIntoStorageAndRedux(entity, sortProps)
  return true
}

export async function setPage(page, props) {
  const { entity, updateGridIntoStorageAndRedux } = props

  const pageProps = [
    {
      rowId: null,
      key: lsGridPropsMap.page,
      value: page,
    },
  ]
  await updateGridIntoStorageAndRedux(entity, pageProps)
  return true
}

export async function setPerPage(perPage, props) {
  const { entity, updateGridIntoStorageAndRedux } = props

  const perPageProps = [
    {
      rowId: null,
      key: lsGridPropsMap.perPage,
      value: perPage,
    },
    {
      rowId: null,
      key: lsGridPropsMap.page,
      value: 1,
    },
  ]
  await updateGridIntoStorageAndRedux(entity, perPageProps)
  return true
}

export async function setAdvancedSearchFilter(filter, props) {
  const { entity, updateGridIntoStorageAndRedux } = props

  const gridProps = getStoredGridProps(props)
  const clonedGridProps = cloneObj(gridProps)
  const { advancedSearchList } = clonedGridProps

  const { key, field, value, operator } = filter

  const isNulledValue = value === null || value === ''

  if (isNulledValue) {
    if (advancedSearchList[key]) {
      delete advancedSearchList[key]
    }
  } else if (!isNulledValue) {
    if (!advancedSearchList[key]) {
      advancedSearchList[key] = {}
    }

    advancedSearchList[key].value = value
    advancedSearchList[key].queryValue = `${field}[${operator}]=${value}`
  }

  const advancedSearchKeys = Object.keys(advancedSearchList)
  const advancedSearchQuery = []
  for (let i = 0; i !== advancedSearchKeys.length; i += 1) {
    const fKey = advancedSearchKeys[i]
    const fVal = advancedSearchList[fKey].queryValue
    advancedSearchQuery.push(fVal)
  }

  const advancedSearch = advancedSearchQuery.join('&')

  const advancedSearchProps = [
    {
      rowId: null,
      key: lsGridPropsMap.advancedSearchList,
      value: advancedSearchList,
    },
    {
      rowId: null,
      key: lsGridPropsMap.advancedSearch,
      value: advancedSearch,
    },
  ]
  await updateGridIntoStorageAndRedux(entity, advancedSearchProps)
}
