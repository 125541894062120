export function lsGet(name) {
  let json = false
  if (typeof window !== 'undefined') {
    json = window.localStorage.getItem(
      `${process.env.client}_${window.location.host.replace(':', '_')}_${name}`
    )
    json = json !== '' ? JSON.parse(json) : false
  }

  return json
}

export function lsSet(name, obj) {
  if (typeof window !== 'undefined') {
    return window.localStorage.setItem(
      `${process.env.client}_${window.location.host.replace(':', '_')}_${name}`,
      JSON.stringify(obj)
    )
  }

  return ''
}

export function lsEmpty() {
  if (typeof window !== 'undefined') {
    window.localStorage.clear()
  }
}
