import React, { useState } from 'react'
import classNames from 'classnames'
import { translate } from '../../../../../utils'

function JujoSVGMobileImageActionsBar() {
  const [show_notification, setShowNotification] = useState(true)

  return (
    <>
      <div
        id="m_main_actions_bar"
        className={classNames(
          'position-absolute start-0 bottom-0 p-1 w-100 bg-1 d-flex'
        )}
      >
        qui implementiamo le actions per image{' '}
      </div>
    </>
  )
}

export default JujoSVGMobileImageActionsBar
