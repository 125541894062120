import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import {
  cloneObj,
  defaultHandleGenericBtnClick,
  mapStateToProps,
} from '../../utils'

import JujoLoading from '../loading'
import JujoButtonComponent from '../jujo_button'

const classNames = require('classnames')

export class JujoFormComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      dataSource: {},
    }
  }

  componentDidMount = () => {
    const updatedDataSource = this.fillDataSourceWithDefaultValuesIfEmpty()
    this.setState({ initialized: true, dataSource: updatedDataSource })
  }

  fillDataSourceWithDefaultValuesIfEmpty = () => {
    const { dataSource } = this.state
    const { data } = this.props
    const { fields } = data

    const updatedData = cloneObj(dataSource)
    for (let i = 0; i !== fields.length; i += 1) {
      const field = fields[i]
      const { name, defValue } = field
      const current_val = updatedData[name]
      if (current_val === null || current_val === undefined) {
        updatedData[name] = defValue
      }
    }

    return updatedData
  }

  emptyForm = async () => {
    await this.setState({ initialized: false })
    this.setState({ initialized: true, dataSource: {} })
  }

  handleValueChanged = (key, value) => {
    const { dataSource } = this.state
    const updatedData = cloneObj(dataSource)
    updatedData[key] = value
    this.setState({ dataSource: updatedData })
  }

  renderFields = () => {
    const { dataSource } = this.state
    const { specialization, environment, data } = this.props
    const { fields } = data

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    for (let i = 0; i !== fields.length; i += 1) {
      const field = fields[i]

      const {
        name,
        alias,
        defValue,
        required,
        fieldType,
        hidden,
        hiddenTitle,
        noBorder,
        customWidth,
        before,
        after,
        customizations,
      } = field

      const DynamicField = require(`../common/fields/${fieldType}`).default

      const { titleStyleOverride } = customizations || {}

      html.push(
        <div
          key={name}
          name={name}
          className={classNames(
            'my-3',
            customWidth || 'col-md-6',
            hidden ? 'd-none' : ''
          )}
        >
          {!hiddenTitle && (
            <div
              className={classNames(
                titleStyleOverride || 'ffamily-secondary fs-9 fc-gray'
              )}
            >
              {texts[locale][alias] || texts[locale][name] || name}
              {required && JSON.parse(required) === true && <span>*</span>}
            </div>
          )}

          {before && (
            <div className={classNames('fs-8 text-start')}>
              {texts[locale][before] || before}
            </div>
          )}
          <div
            className={classNames(
              'p-1',
              noBorder ? '' : 'border border-color-4 rounded bg-white'
            )}
          >
            <DynamicField
              field={field}
              dataSource={dataSource}
              initialValue={dataSource[name] || defValue || ''}
              handleValueChanged={this.handleValueChanged}
            />
          </div>
          {after && (
            <div className={classNames('fs-8 text-start')}>
              {texts[locale][after] || after}
            </div>
          )}
        </div>
      )
    }

    return html
  }

  renderButtons = () => {
    const { specialization, environment, data } = this.props
    const { buttons } = data

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []
    if (buttons.length > 0) {
      html.push(
        <div
          key="generic_form_btns"
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-center align-items-center my-4'
          )}
        >
          {buttons.map(btn => {
            const { blabel, bwidth, bstyle } = btn

            return (
              <JujoButtonComponent
                key={blabel}
                bstyle={bstyle}
                bwidth={bwidth}
                blabel={texts[locale][blabel] || blabel}
                handleClick={async () => {
                  await defaultHandleGenericBtnClick(btn, this)
                }}
              />
            )
          })}
        </div>
      )
    }
    return html
  }

  render() {
    const { initialized } = this.state

    return (
      <>
        {!initialized && <JujoLoading />}
        {initialized && (
          <>
            <div className={classNames('row')}>{this.renderFields()}</div>
            <div>{this.renderButtons()}</div>
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoFormComponent)
