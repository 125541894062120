import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'

const classNames = require('classnames')

export class PTQThemeFooter extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    return (
      <div
        className={classNames('bg-1 g-column-1-1')}
        style={{ width: '100%' }}
      >
        <div className={classNames('container my-4')}>
          <div
            className={classNames(
              'd-flex flex-column flex-md-row justify-content-between align-items-center'
            )}
          >
            <div
              className={classNames('logo-white')}
              label="logo"
              style={{
                height: '40px',
                width: '180px',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />
          </div>
          <div
            className={classNames(
              'fc-white fs-8 mt-4 text-center text-md-start'
            )}
          >
            <div>{process.env.companyName} </div>
            <div>{`${translate('vat_fc')} ${process.env.vatNumber}`}</div>
            <div>
              {`${translate('registered_office')}: ${
                process.env.registeredOffice
              }`}
            </div>
            <div>{translate('privacy_policy')}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PTQThemeFooter)
