import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class NumberField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, dynamicForm } = this.props
    const {
      name,
      disabled,
      min,
      max,
      step,
      placeholder,
      prefix,
      autocomplete,
    } = field
    const { type } = dynamicForm

    const disabled_field = parseBoolean(disabled, 'false')

    const html = []
    if (type === requestTypesMap.view) {
      html.push(<div key={uuid()}>{fieldValue}</div>)
    } else {
      html.push(
        <div key={uuid()} className={classNames('d-flex')}>
          {prefix && (
            <div className={classNames('bg-1 fc-white px-2 mx-1')}>
              {prefix}
            </div>
          )}
          <input
            type="number"
            min={min}
            max={max}
            step={step || 1}
            name={name}
            defaultValue={fieldValue}
            placeholder={placeholder}
            disabled={disabled_field}
            autoComplete={autocomplete || 'on'}
            onPaste={e => {
              if (field.paste_disabled === true) {
                e.preventDefault()
              }
              return false
            }}
            onChange={e => {
              const { value } = e.target
              this.processOnChange(value)
            }}
            onBlur={e => {
              const { value } = e.target
              this.processOnBlur(value)
            }}
          />
        </div>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(NumberField)
