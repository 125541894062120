import React from 'react'
import { Provider } from 'react-redux'

import MainComponent from '../pages'
import createLightStore from '../store/createLightStore'
import createStore from '../store/createStore'

const store =
  process.env.reduxVersion === 'light' ? createLightStore() : createStore()

// eslint-disable-next-line react/display-name,react/prop-types
export default () => (
  <Provider store={store}>
    <MainComponent store={store} />
  </Provider>
)
