/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'
import JujoLoading from '../../../../../../../../src/components/loading'
import apiCallMap from '../../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../../src/services/servicesHelper'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../../src/utils'
import { getExtraProductOptionFields } from '../../../../../actions/estimates'

const classNames = require('classnames')

export class PTQEstimateProductOptionsBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      prod_options: [],
      global_options: [],
      category_options: [],
      fields_list: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { handleNewOptionsLoaded } = this.props
    const reqObj = this.composeRequest()
    const { parsedEp, data } = reqObj

    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, data)

    if (response.status === 200 || response.status === 201) {
      const product_data = response.data.data || {}
      const { opt_list, global_opt_list, category_opt_list, template_list } = product_data

      await this.setState({
        prod_options: opt_list || [],
        global_options: global_opt_list || [],
        category_options: category_opt_list || [],
        template_list: template_list || []
      })

      const fields_list = getExtraProductOptionFields(this)

      await handleNewOptionsLoaded(fields_list)

      this.setState({
        fields_list,
        loading: false,
      })
    }
  }

  composeRequest = () => {
    const { environment, authentication, product_id } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'product',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-options',
      pDefValue: 'get-options',
    })

    const parsedEp = parseEndpoint(requestData)
    const data = { product_id }

    return {
      parsedEp,
      data,
    }
  }

  handleValueChanged = async (fieldName, value) => {
    const { handleOptionChanged } = this.props
    handleOptionChanged(fieldName, value)
  }

  renderProductOptions = () => {
    const { product_options } = this.props
    const { fields_list } = this.state
    const html = []

    for (let i = 0; i !== fields_list.length; i += 1) {
      const field = fields_list[i]
      const { field_type, field_definition } = field
      const { name, title, uid } = field_definition
      const DynamicFieldComponent =
        require(`../../../../../../../../src/components/common/fields/${field_type}`).default

      html.push(
        <div key={uid} className={classNames('mb-4')}>
          {title !== '' && (
            <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
              {title}
            </div>
          )}
          <div
            className={classNames(
              'fs-7',
              field_type === 'selectField' &&
                'bg-white p-1 border-color-4 rounded border'
            )}
          >
            <DynamicFieldComponent
              dataSource={product_options}
              field={field_definition}
              handleValueChanged={this.handleValueChanged}
              initialValue={product_options[name]}
            />
          </div>
        </div>
      )
    }
    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderProductOptions()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateProductOptionsBlockComponent)
