import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import JoditEditor from 'jodit-react'
import * as actionCreators from '../../../../store/actions'

import { GenericField } from './genericField'
import requestTypesMap from '../../../../enums/requestTypesMap'
import { mapStateToProps } from '../../../../utils'

const classNames = require('classnames')

export class MultilingualField extends GenericField {
  processOnChange = (key, value) => {
    const { fieldValue } = this.state
    // const { value } = e.target

    const duplicatedFieldValue = { ...fieldValue }
    duplicatedFieldValue[key] = value

    this.setState({ fieldValue: duplicatedFieldValue })
  }

  processOnBlur = (key, value) => {
    const { fieldValue } = this.state
    // const { value } = e.target

    const duplicatedFieldValue = { ...fieldValue }
    duplicatedFieldValue[key] = value

    this.saveOnRedux(duplicatedFieldValue)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { name, direction, richTextMode } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const keys = process.env.languages.split(',')

    const html = []
    html.push(
      <div
        key={field.name}
        className={classNames(
          'd-flex flex-column',
          direction === 'vertical' ? '' : 'flex-md-row'
        )}
      >
        {keys.map(key => (
          <div key={uuid()} className={classNames('flex-fill px-1')}>
            <div className={classNames('ffamily-secondary')}>
              {texts[locale][key] || key}
            </div>
            {richTextMode && (
              <JoditEditor
                key={`richtext_${name}_${key}`}
                value={fieldValue && fieldValue[key] ? fieldValue[key] : ''}
                config={{
                  enter: 'div',
                  useSplitMode: true,
                  readonly: type === requestTypesMap.view, // all options from https://xdsoft.net/jodit/doc/
                }}
                tabIndex={0}
                onBlur={content => {
                  this.processOnBlur(content)
                }}
                onChange={value => {
                  this.processOnChange(key, value)
                }}
              />
            )}
            {!richTextMode && (
              <textarea
                disabled={type === requestTypesMap.view}
                key={`textarea_${name}_${key}`}
                className={classNames(
                  'p-2 rounded-4',
                  type === requestTypesMap.view ? 'border' : 'shadow-sm'
                )}
                style={{ background: 'transparent', opacity: '1' }}
                name={name}
                rows={3}
                defaultValue={
                  fieldValue && fieldValue[key] ? fieldValue[key] : ''
                }
                onChange={e => {
                  this.processOnChange(key, e.target.value)
                }}
                onBlur={e => {
                  this.processOnBlur(key, e.target.value)
                }}
              />
            )}
          </div>
        ))}
      </div>
    )

    return html
  }
}

export default connect(mapStateToProps, actionCreators)(MultilingualField)
