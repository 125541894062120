import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))
const JujoSVGNumberSelector = loadable(() => import('../number_selector'))

function ImageRotation({ element_key }) {
  const [image_element, setImageElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const img_el = g_element.getElementsByTagName('image')[0]

    setImageElement(img_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const getAngle = () => {
    const transform = image_element.transform.baseVal

    // Get the matrix for the first transform in the list
    const { matrix } = transform[0]

    // Calculate the rotation angle from the matrix
    const angle = parseInt((Math.atan2(matrix.b, matrix.a) * 180) / Math.PI, 10)
    return angle
  }

  const handleRotationChanged = angle => {
    const current_angle = getAngle()
    const rotation = angle - current_angle

    const transform = image_element.transform.baseVal

    // Get the current position of the image
    const x = image_element.x.baseVal.value
    const y = image_element.y.baseVal.value

    // Get the width and height of the image
    const width = image_element.width.baseVal.value
    const height = image_element.height.baseVal.value

    // Calculate the center position of the image
    const centerX = x + width / 2
    const centerY = y + height / 2

    // Translate the image to the center of the SVG canvas
    const translate1 = transform.createSVGTransformFromMatrix(
      transform[0].matrix.translate(centerX, centerY)
    )
    transform[0] = translate1

    // Rotate the image by n degrees
    const rotate = transform.createSVGTransformFromMatrix(
      transform[0].matrix.rotate(rotation)
    )
    transform[0] = rotate

    // Translate the image back to its original position
    const translate2 = transform.createSVGTransformFromMatrix(
      transform[0].matrix.translate(-centerX, -centerY)
    )
    transform[0] = translate2

    updatedRectBorder(image_element)
  }

  return (
    <>
      {image_element === null && <JujoLoading />}
      {image_element !== null && (
        <div className={classNames('d-flex flex-column flex-md-row mb-md-2')}>
          <div className={classNames('me-md-3 mb-2 mb-md-0')}>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('rotate_image')}</div>
            </div>
            <JujoSVGNumberSelector
              handleChange={handleRotationChanged}
              defaultValue={getAngle()}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ImageRotation
