const fileTypesMap = {
  image: 'image',
  pdf: 'pdf',
  txt: 'txt',
  unset: 'unset',
  xml: 'xml',
  csv: 'csv',
}

export default fileTypesMap
