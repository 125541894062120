import React from 'react'
import loadable from '@loadable/component'
import Parser from 'html-react-parser'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class OrdersCounterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  renderCounter = () => {
    const { data } = this.props
    const { total } = data
    const html = []

    const text_to_render = translate('orders_counter_label')
    const parsed_text = text_to_render.replaceAll('[n]', total)
    const html_parsed_text = Parser(parsed_text, {})

    html.push(
      <div key="orders_counter_container">
        <div className={classNames('')}>{html_parsed_text}</div>
      </div>
    )
    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderCounter()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(OrdersCounterComponent)
