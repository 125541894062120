/* eslint-disable react/no-unused-state */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import apiCallMap from '../../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../../src/enums/apiRequestTypesMap'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../../src/services/servicesHelper'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../../../src/utils'
import { httpPost } from '../../../../../../../../src/services/apiService'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)
const ECNumberFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/numberField')
)

const classNames = require('classnames')

export class PTQEstimateProductQtySelectorBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      field_value: 1,
      rules: {},
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initialValue, handleQuantityChanged } = this.props

    const reqObj = this.composeRequest()
    const { parsedEp, data } = reqObj

    const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, data)

    if (response.status === 200 || response.status === 201) {
      const qty_rules_data = response.data.data || {}
      const min = qty_rules_data.min || 1
      const field_value = parseFloat(initialValue < min ? min : initialValue)

      await this.setState({
        loading: false,
        field_value,
        rules: qty_rules_data,
      })

      await handleQuantityChanged('static_unused_placeholder', field_value)
    }
  }

  composeRequest = () => {
    const { environment, authentication, product_id } = this.props
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'product',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'get-qty-rules',
      pDefValue: 'get-qty-rules',
    })

    const parsedEp = parseEndpoint(requestData)
    const data = { product_id }

    return {
      parsedEp,
      data,
    }
  }

  renderQuantitySelectorField = () => {
    const { field_value, rules } = this.state
    const { product_id, handleQuantityChanged } = this.props

    const { min, max, step } = rules

    const field_def = {
      name: 'product_qty',
      fieldType: 'numberField',
      defValue: min,
      min,
      max,
      step,
    }

    const html = []

    html.push(
      <div key={`product_${product_id}_qty`}>
        <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
          {`* ${translate('quantity')}`}
        </div>
        <div
          className={classNames(
            'p-1 border border-color-4 rounded bg-white fs-7'
          )}
        >
          <ECNumberFieldComponent
            field={field_def}
            initialValue={field_value}
            handleValueChanged={handleQuantityChanged}
          />
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('my-4')}>
            {this.renderQuantitySelectorField()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateProductQtySelectorBlockComponent)
