import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import JujoLoading from '../../../../../../../src/components/loading'
import JujoEditableComponent from '../../../../../../../src/components/editable_component'

const classNames = require('classnames')

export class PTQEstimateNewCustomerComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    const { handleValueChanged } = this.props

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>
            <JujoEditableComponent
              c_def={{
                comp: 'editable_component',
                entity: {
                  source: 'static',
                  defValue: 'customer',
                },
                renderer: {},
                hooks: { handleValueChanged },
              }}
            />
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateNewCustomerComponent)
