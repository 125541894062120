import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class EmailField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { name, disabled, translateRenderField } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const { type } = dynamicForm

    const disabled_field = parseBoolean(disabled, 'false')

    const html = []
    if (type === requestTypesMap.view) {
      html.push(
        <div key={uuid()}>
          {translateRenderField ? texts[locale][fieldValue] : fieldValue}
        </div>
      )
    } else {
      html.push(
        <input
          key={uuid()}
          type="email"
          name={name}
          defaultValue={
            translateRenderField ? texts[locale][fieldValue] : fieldValue
          }
          disabled={disabled_field}
          onPaste={e => {
            if (field.paste_disabled === true) {
              e.preventDefault()
            }
            return false
          }}
          onChange={e => {
            const { value } = e.target
            this.processOnChange(value)
          }}
          onBlur={e => {
            const { value } = e.target
            this.processOnBlur(value)
          }}
        />
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(EmailField)
