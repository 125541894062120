import React from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../src/utils'

const PTQAddressListComponent = loadable(() => import('./_parts/address_list'))

const classNames = require('classnames')

export class PTQCustomerAddressListView extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { data } = this.props

    return (
      <div className={classNames('')}>
        <PTQAddressListComponent data={data} />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQCustomerAddressListView)
