import classNames from 'classnames'
import Parser from 'html-react-parser'
import React from 'react'
import { translate } from '../../../../../../src/utils'

const TotalTurnover = ({ container_id, total }) => {
  const killContainer = () => {
    document.getElementById(container_id).remove()
  }
  return (
    <div
      className={classNames(
        'position-fixed w-100 h-100 top-0 start-0 bg-black-opacity-5'
      )}
      // style={{ background: '#0000005e' }}
    >
      <div
        className={classNames(
          'position-absolute top-50 start-50 translate-middle bg-white p-4 rounded-4 shadow'
        )}
      >
        <div
          className={classNames(
            'd-flex align-items-center justify-content-end fs-7 fc-1 mb-2'
          )}
          role="button"
          tabIndex={0}
          onClick={() => killContainer()}
          onKeyPress={() => killContainer()}
        >
          <div
            className={classNames('background-image close-icon theme-svg')}
            style={{ width: 10, height: 10 }}
          />
          <div className={classNames('ms-1 fst-uppercase')}>
            {translate('close')}
          </div>
        </div>
        {Parser(
          translate('total_turnover_message').replace('{total}', total),
          {}
        )}
      </div>
    </div>
  )
}
export default TotalTurnover
