import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  bindElementToComponent,
  cloneObj,
  defaultManageResponse,
  mapStateToProps,
  retrieveBindedElement,
  retrieveComponent,
  translate,
} from '../../../../../../src/utils'
import { FillableComponent } from '../../../../../../src/components/fillable_component'
import ECDroppableFieldComponent from '../../../../../../src/components/common/fields/droppableField'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import { JujoButtonComponent } from '../../../../../../src/components/jujo_button'
import { reloadSession } from '../../../../../../src/services/sessionHelper'

const classNames = require('classnames')

export class UploadPaymentProofComponent extends FillableComponent {
  windowStyle = () => ({
    width: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'slide-left position-absolute top-0 end-0 bg-light px-3 py-5 p-md-5 h-100'

  performActionOnDidMount = async () => {
    const { component_data } = this.state
    const { data, session } = this.props

    const { sender } = data
    const { reloadData } = sender.props
    bindElementToComponent(this, 'cloned_reload_data_function', reloadData)

    const cloned_data = cloneObj(component_data)
    cloned_data.loading = true
    await this.updateData(cloned_data)

    const { session_data } = session
    const { my_orders } = session_data

    const { order_id } = data
    let current_order = {}

    for (let i = 0; i !== my_orders.length; i += 1) {
      const order = my_orders[i]
      const { ID } = order
      if (ID === order_id) {
        current_order = order
        break
      }
    }

    const { payment_proof } = current_order
    if (payment_proof !== '') {
      cloned_data.uploaded_file = [
        { url: payment_proof, name: translate('payment_proof') },
      ]
    }

    cloned_data.loading = false
    await this.updateData(cloned_data)
  }

  handleFileUploaded = async (_name, value) => {
    const { component_data } = this.state
    const cloned_data = cloneObj(component_data)
    cloned_data.uploaded_file = value
    await this.updateData(cloned_data)
  }

  handleOnSuccessCallback = async params => {
    const { msbox_instance_id } = params

    retrieveComponent(this, msbox_instance_id)
    this.handleClose()
    await reloadSession(this)
    retrieveBindedElement(this, 'cloned_reload_data_function')()
  }

  handleSaveClicked = async () => {
    const { component_data } = this.state
    const { data } = this.props
    const { sender, order_id } = data

    const { uploaded_file } = component_data
    const { url } = uploaded_file[0]

    const { props } = sender

    const { environment, authentication } = props

    /* Creating a request object that will be used to make the api call. */
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'save-proof-of-payment',
      pDefValue: 'save-proof-of-payment',
    })

    const parsedEp = parseEndpoint(requestData)

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { order_id, file_url: url },
      true
    )

    const customizations = {
      on_success_callback: this.handleOnSuccessCallback,
    }

    defaultManageResponse(this, customizations, response)
  }

  renderBody = () => {
    this.fieldRef = React.createRef()

    const { authentication, data } = this.props
    const { order_id } = data

    const { user } = authentication

    const { component_data } = this.state
    const { uploaded_file } = component_data

    const save_enabled = !!(
      'uploaded_file' in component_data &&
      component_data.uploaded_file.length > 0
    )

    const html = []

    html.push(
      <div key="upload_payment_proof_component" className={classNames('')}>
        <div className={classNames('mb-2')}>
          <div>
            <span className={classNames('me-1')}>
              {translate('upload_payment_proof_instruction_line_1')}
            </span>
            <span className={classNames('fw-bold')}>{order_id}</span>
          </div>
          <div className={classNames('fst-italic')}>
            {translate('upload_payment_proof_instruction_line_2')}
          </div>
        </div>
        <ECDroppableFieldComponent
          field={{
            name: 'payment_proof_field',
            fieldType: 'droppableField',
            customWidth: 'col-12',
            custom_drag_drop_instruction: 'drop_the_file_here',
            acceptedTypes: 'image/png, image/jpeg, application/pdf',
            maxFileSize: '3MB',
            maxFileCount: 1,
            relatedEntity: 'order',
            visibility: 'private',
            fileOwner: {
              source: 'static',
              relatedField: '',
              defValue: user.ID,
            },
          }}
          handleValueChanged={this.handleFileUploaded}
          initialValue={uploaded_file}
        />
        <div
          className={classNames(
            'd-flex justify-content-center align-items-center w-100 mt-2'
          )}
        >
          <JujoButtonComponent
            bstyle={3}
            bwidth="200px"
            bDisabled={!save_enabled}
            blabel={translate('save')}
            handleClick={() => {
              this.handleSaveClicked()
            }}
          />
        </div>
      </div>
    )

    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(UploadPaymentProofComponent)
