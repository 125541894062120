import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import * as actionCreators from '../../../../../src/store/actions'

import { JujoLeraLayout } from '../../../../../src/components/page_layout/layouts/lera'
import { mapStateToProps } from '../../../../../src/utils'
import PTQThemeSidebar from './sidebar'
import PTQThemeFooter from './footer'
import AccessRegisterComponent from '../../../../../src/components/page_layout/layouts/_parts/access_register'

export class PTQAdminLayout extends JujoLeraLayout {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  goToHomePage = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/'
    }
  }

  renderHead = () => {
    const html = []

    html.push(
      <div
        key="header"
        className={classNames(
          'shadow zindex-2 g-column-1-1 position-fixed bg-1'
        )}
        style={{ width: '100%' }}
      >
        <div
          className={classNames(
            'container d-flex flex-row p-2 justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames('logo-white d-flex')}
            label="logo"
            style={{
              height: '40px',
              width: '180px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.goToHomePage()
            }}
            onKeyPress={() => {
              this.goToHomePage()
            }}
          />
          <AccessRegisterComponent />
        </div>
      </div>
    )

    return html
  }

  renderSideBar = () => <PTQThemeSidebar />

  renderFooter = () => <PTQThemeFooter />

  gridTemplate = () => 'g-template-col-md-250px-auto'
}

export default connect(mapStateToProps, actionCreators)(PTQAdminLayout)
