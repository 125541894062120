/* eslint-disable react/no-unused-state */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)

const PTQEstimateCouponComponent = loadable(() => import('../coupon'))
const PTQEstimateTicketCustomPriceComponent = loadable(() =>
  import('../custom_price')
)

const classNames = require('classnames')

export class PTQEstimateTicketComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({
      loading: false,
    })
  }

  handleClearCustomPrice = async key => {
    const { handleResetPrice } = this.props
    await handleResetPrice(key)
  }

  renderCustomPriceWithTrash = (key, price, can_modify_price) => {
    const html = []

    html.push(
      <div
        key={`delete_${key}`}
        className={classNames('d-flex flex-row align-items-center mt-1')}
      >
        <div
          className={classNames('fw-bold text-center fc-orange-500')}
        >{`${price} €`}</div>
        {can_modify_price && (
          <div className={classNames('ms-1')}>
            <div
              className={classNames('background-image theme-svg reset-icon')}
              style={{ width: 20, height: 20 }}
              role="button"
              tabIndex={0}
              label="delete"
              onClick={() => {
                this.handleClearCustomPrice(key)
              }}
              onKeyPress={() => {
                this.handleClearCustomPrice(key)
              }}
            />
          </div>
        )}
      </div>
    )

    return html
  }

  drawTicket = () => {
    const {
      calculations,
      coupon_data,
      customer_data,
      handleCouponChanged,
      handleCustomPriceChanged,
      user_permissions,
    } = this.props

    const { can_modify_price } = user_permissions

    const subtotal = calculations ? calculations.subtotal : 0
    const total_price = calculations ? calculations.total_price : 0
    const discountable_amount = calculations
      ? calculations.discountable_amount
      : 0
    const discount_amount = calculations ? calculations.discount_amount : 0
    const shipping_price = calculations ? calculations.shipping_price || {} : {}
    const list = calculations ? calculations.list : []

    const html = []

    for (let i = 0; i !== list.length; i += 1) {
      const prod = list[i]
      const {
        key,
        product_name,
        quantity,
        calculated_instaces_price,
        product_instances_price,
        options,
        price,
        regular_price,
        on_sale,
      } = prod

      const opt_html = []
      for (let j = 0; j !== options.length; j += 1) {
        const opt = options[j]
        const { set_to_fee_enabled } = opt

        opt_html.push(
          <div key={`${opt.key}_instance_${j}`} className={classNames('')}>
            <div
              className={classNames(
                'd-flex flex-column flex-md-row justify-content-center justify-content-md-between text-center text-md-start border-bottom py-2 align-items-center'
              )}
            >
              <div
                className={classNames('fs-8')}
                style={{ width: 'min(100%, 240px)' }}
              >
                <div className={classNames('')}>{opt.value}</div>
                <div>
                  <span>{` €${opt.unit_price}`}</span>
                  {!set_to_fee_enabled && <span>{` x ${quantity}`}</span>}
                </div>
              </div>
              <div
                className={classNames(
                  'd-flex align-items-center flex-column w-md-100px justify-content-center justify-content-md-end pt-2 pt-md-0'
                )}
              >
                <div
                  className={classNames(
                    '',
                    opt.calculated_opt_price !== opt.price
                      ? 'text-decoration-line-through'
                      : ''
                  )}
                >{`${opt.calculated_opt_price} €`}</div>
                {can_modify_price && (
                  <PTQEstimateTicketCustomPriceComponent
                    element={opt}
                    handleCustomPriceChanged={handleCustomPriceChanged}
                  />
                )}
                {opt.calculated_opt_price !== opt.price &&
                  this.renderCustomPriceWithTrash(
                    opt.key,
                    opt.price,
                    can_modify_price
                  )}
              </div>
            </div>
          </div>
        )
      }

      html.push(
        <div
          key={key}
          className={classNames('pb-2 my-md-2 border-unset border-md-bottom')}
        >
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between text-center text-md-start border-bottom py-2'
            )}
          >
            <div
              className={classNames('fs-8')}
              style={{ width: 'min(100%, 240px)' }}
            >
              <div className={classNames('fw-bold')}>{product_name}</div>
              <div>
                {on_sale === 1 && (
                  <span
                    className={classNames('text-decoration-line-through')}
                  >{` €${regular_price}`}</span>
                )}
                <span>{` €${price}`}</span>
                <span>{` x ${quantity}`}</span>
                {on_sale === 1 && (
                  <span className={classNames('fc-1 fw-bold ms-1')}>
                    {translate('on_sale')}
                  </span>
                )}
              </div>
            </div>
            <div
              className={classNames(
                'd-flex align-items-center flex-column w-md-100px mt-2 mt-md-0'
              )}
            >
              <div
                className={classNames(
                  '',
                  product_instances_price !== calculated_instaces_price
                    ? 'text-decoration-line-through'
                    : ''
                )}
              >{`${calculated_instaces_price} €`}</div>
              {can_modify_price && (
                <PTQEstimateTicketCustomPriceComponent
                  element={prod}
                  handleCustomPriceChanged={handleCustomPriceChanged}
                />
              )}
              {product_instances_price !== calculated_instaces_price &&
                this.renderCustomPriceWithTrash(
                  key,
                  product_instances_price,
                  can_modify_price
                )}
            </div>
          </div>
          <div className={classNames('ps-2')}>{opt_html}</div>
        </div>
      )
    }

    html.push(
      <div key="coupon_row" className={classNames('my-3')}>
        <PTQEstimateCouponComponent
          coupon_data={coupon_data}
          customer_data={customer_data}
          handleCouponChanged={handleCouponChanged}
        />
      </div>
    )

    html.push(
      <div key="data_footer">
        <div
          key="subtotal_row"
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-center mt-2 fw-bold'
          )}
        >
          <div>{translate('subtotal')}</div>
          <div>{`${subtotal} €`}</div>
        </div>
        {discount_amount > 0 && (
          <>
            <div
              key="discountable_amount_row"
              className={classNames(
                'd-flex flex-column flex-md-row justify-content-between align-items-center mt-2'
              )}
            >
              <div>{translate('discountable_amount')}</div>
              <div className={classNames('')}>{`${discountable_amount} €`}</div>
            </div>
            <div
              key="discount_row"
              className={classNames(
                'd-flex flex-column flex-md-row justify-content-between align-items-center mt-2'
              )}
            >
              <div>{translate('discount')}</div>
              <div
                className={classNames('fc-1')}
              >{`- ${discount_amount} €`}</div>
            </div>
          </>
        )}

        <div key="shipping_row">
          <div
            className={classNames(
              'd-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between mt-2 border-bottom border-md-unset'
            )}
          >
            <div>
              <span>{translate('shipping')}</span>
              {shipping_price.title && (
                <span>{` (${shipping_price.title})`}</span>
              )}
            </div>
            <div>{`${shipping_price.cost || (0).toFixed(2)} €`}</div>
          </div>
        </div>

        <div
          key="total_row"
          className={classNames(
            'd-flex flex-column flex-md-row justify-content-between align-items-center mt-2 fs-5 fw-bold'
          )}
        >
          <div>{translate('total')}</div>
          <div>{`${total_price} €`}</div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div
            className={classNames(
              'bg-light-gray p-3 fs-7 rounded-5 sticky-md-top-65px zindex-1'
            )}
          >
            {this.drawTicket()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateTicketComponent)
