import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps } from '../../../../../../../src/utils'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../../src/services/apiService'

const JujoLoading = loadable(() =>
  import('../../../../../../../src/components/loading')
)
const ECTextAreaFieldComponent = loadable(() =>
  import('../../../../../../../src/components/common/fields/textAreaField')
)

const classNames = require('classnames')

export class OrderNotesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      saving: false,
      stored_value: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { order_data } = this.props
    const { order_notes } = order_data

    this.setState({
      stored_value: order_notes,
      loading: false,
    })
  }

  handleNotesChanged = async (_fieldName, value) => {
    const { stored_value } = this.state
    if (value !== stored_value) {
      this.setState({ stored_value: value })
    }
  }

  handleSaveNotesClicked = async () => {
    this.setState({ saving: true })
    const { stored_value } = this.state
    const { environment, authentication, order_data } = this.props
    const { order_id } = order_data

    /* Creating a request object that will be used to make the api call. */
    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'order',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'update-notes',
      pDefValue: 'update-notes',
    })

    const parsedEp = parseEndpoint(requestData)

    await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { order_id, notes: stored_value },
      true
    )

    this.setState({ saving: false })
  }

  renderNotes = () => {
    const { saving } = this.state
    const { order_data } = this.props
    const { order_notes } = order_data
    const html = []

    html.push(
      <div key="notes_field_container">
        <div className={classNames('bg-white p-2 rounded-3')}>
          <ECTextAreaFieldComponent
            field={{ name: 'order_notes' }}
            initialValue={order_notes}
            handleValueChanged={this.handleNotesChanged}
          />
        </div>
        {!saving && (
          <div className={classNames('position-relative')}>
            <div
              className={classNames(
                'position-absolute d-flex justify-content-center align-items-center border border-color-1 rounded-5 w-30px h-30px bg-1'
              )}
              style={{ bottom: 10, right: 20 }}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleSaveNotesClicked()
              }}
              onKeyPress={() => {
                this.handleSaveNotesClicked()
              }}
            >
              <div
                className={classNames('background-image check-icon white-svg')}
                style={{ width: 20, height: 20 }}
              />
            </div>
          </div>
        )}
        {saving && (
          <div className={classNames('position-relative')}>
            <div
              className={classNames(
                'position-absolute w-30px h-30px background-image loading-icon theme-svg'
              )}
              style={{ bottom: 10, right: 10 }}
            />
          </div>
        )}
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && <div className={classNames('')}>{this.renderNotes()}</div>}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(OrderNotesComponent)
