import React from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'
import JujoSVGParser from '../../../../../../src/components/svg_editor/parser'
import { cloneObj, translate } from '../../../../../../src/utils'
import ParserProvider from '../../../../../../src/components/svg_editor/parser/parser_provider'
import {
  createTemplate,
  injectDefaultFont,
  remapFontFamilies,
} from '../../../actions/modeling/templates'

const SvgImposeModel = loadable(() =>
  import('../custom_svg_template_editor/_parts/impose_model')
)

const SvgPrintingRegistration = loadable(() =>
  import('../custom_svg_template_editor/_parts/printing_registration')
)

function PtqSvgCustomParserComponent(props) {
  const onComplete = definition => {
    remapFontFamilies(definition)
    injectDefaultFont(definition)
    createTemplate(props, definition)
  }

  const specializedFields = (definition, setDefinition) => (
    <div className={classNames('d-flex flex-column flex-md-row row')}>
      <div className={classNames('col-12 col-md-6')}>
        <div className={classNames('ffamily-secondary mb-2 fs-5')}>
          <div>{translate('impose_model')}</div>
        </div>
        <SvgImposeModel
          impose_model_id={0}
          onChange={(key, value) => {
            const updated_def = cloneObj(definition)
            updated_def[key] = value
            setDefinition(updated_def)
          }}
        />
      </div>
      <div className={classNames('col-12 col-md-6')}>
        <div className={classNames('ffamily-secondary mb-2 fs-5')}>
          <div>{translate('printing_registration')}</div>
        </div>
        <SvgPrintingRegistration
          printing_registration_id={0}
          onChange={(key, value) => {
            const updated_def = cloneObj(definition)
            updated_def[key] = value
            setDefinition(updated_def)
          }}
        />
      </div>
    </div>
  )

  return (
    <ParserProvider>
      <JujoSVGParser
        specializedFields={specializedFields}
        onComplete={onComplete}
      />
    </ParserProvider>
  )
}

export default PtqSvgCustomParserComponent
