import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import PanZ from '@thesoulfresh/pan-z'

const JujoSvgMobileMainActionsBar = loadable(() =>
  import('./m_main_actions_bar')
)

const JujoSVGMobileTextActionsBar = loadable(() =>
  import('./m_text_actions_bar')
)
const JujoSVGMobileImageActionsBar = loadable(() =>
  import('./m_img_actions_bar')
)

const JujoSvgMobileCenterBtn = loadable(() => import('./m_center_btn'))

function JujoSvgMobileEditor({
  svg_id,
  element_key,
  default_font,
  setFocusedElement,
}) {
  class CustomPanZ extends PanZ {
    // eslint-disable-next-line class-methods-use-this, no-unused-vars
    onDoubleClick({ _px0, _py0 }) {}
  }
  const [selected_element_type, setSelectedElementType] = useState(null)
  const [pz] = useState(
    () =>
      new CustomPanZ({
        bounds: 1,
      })
  )

  let originalViewportContent = null
  useEffect(() => {
    let viewportMeta = document.querySelector('meta[name=viewport]')
    if (viewportMeta) {
      originalViewportContent = viewportMeta.content
    } else {
      viewportMeta = document.createElement('meta')
      viewportMeta.name = 'viewport'
      document.head.appendChild(viewportMeta)
    }
    viewportMeta.content =
      'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no'
    return () => {
      if (originalViewportContent !== null) {
        viewportMeta.content = originalViewportContent
      } else {
        document.head.removeChild(viewportMeta)
      }
    }
  }, [])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const preview_container = document.getElementById('preview')
    const svg = document.getElementById(svg_id)

    if (svg) {
      pz.init(preview_container, {
        boundingElement: svg,
      })
      pz.disablePan()
      pz.disableZoom()
      return () => {
        pz.destroy()
      }
    }
  }, [svg_id])

  const enableZoom = () => {
    const handleZoom = e => {
      window.d3.select(`#${svg_id} g`).attr('transform', e.transform)
    }
    const zoom = window.d3.zoom().on('zoom', handleZoom)
    window.d3.select(`#${svg_id}`).call(zoom)
  }
  const disableZoom = () => {
    window.d3.select(`#${svg_id}`).on('.zoom', null)
  }

  const disableDrag = () => {
    window.d3.select(`#${element_key}`).on('.drag', null)
  }

  const enableTextDrag = () => {
    const handleDrag = d => {
      disableZoom()
      const rect = window.d3.select(`#${element_key}`).select('#rect_border')
      const line = window.d3
        .select(`#${element_key}`)
        .select('#center_reference_line')

      const text = window.d3.select(`#${element_key}`).select('text')
      const tspan = window.d3
        .select(`#${element_key}`)
        .selectAll('text > tspan')

      const textBBox = text.node().getBBox()

      const textWidth = textBBox.width
      const textHeight = textBBox.height

      const rY = +rect.attr('y')
      const tY = +text.attr('y')
      const yDiff = tY - rY

      const rectX = d.x - textWidth / 2
      const rectWidth = +rect.attr('width')
      const rectCenterX = rectX + rectWidth / 2

      let textX = d.x - textWidth / 2
      if (text.attr('text-anchor')) {
        if (text.attr('text-anchor') === 'middle') {
          textX = d.x
        } else if (text.attr('text-anchor') === 'end') {
          textX = d.x + textWidth / 2
        }
      }

      const y = d.y - textHeight / 2
      const rectY = y - yDiff

      rect.attr('x', rectX).attr('y', rectY)
      line
        .attr('x1', rectCenterX)
        .attr('x2', rectCenterX)
        .attr('y2', rectY)
        .attr('y1', rectY - 5)
      text.attr('x', textX).attr('y', y)
      tspan.attr('x', textX)
    }

    const handleDragEnd = () => {
      disableDrag()
      enableZoom()
    }

    const drag = window.d3
      .drag()
      .on('drag', handleDrag)
      .on('end', handleDragEnd)

    window.d3.select(`#${element_key}`).call(drag)
  }
  const enableImageDrag = () => {
    const handleDrag = d => {
      disableZoom()
      const rect = window.d3.select(`#${element_key}`).select('#rect_border')
      const rectNode = rect.node()
      const rectMatrix = rectNode.transform.baseVal[0].matrix

      const line = window.d3
        .select(`#${element_key}`)
        .select('#center_reference_line')

      const img = window.d3.select(`#${element_key}`).select('image')
      const imgNode = img.node()
      const imgBBox = imgNode.getBBox()
      const imgMatrix = imgNode.transform.baseVal[0].matrix

      const imgWidth = imgBBox.width
      const imgHeight = imgBBox.height

      const rY = +rectMatrix.f
      const iY = +imgMatrix.f
      const yDiff = iY - rY

      const rectX = d.x - imgWidth / 2
      const rectWidth = +rect.attr('width')
      const rectCenterX = rectX + rectWidth / 2

      const imgX = d.x - imgWidth / 2

      const y = d.y - imgHeight / 2
      const rectY = y - yDiff

      rectMatrix.e = rectX
      rectMatrix.f = rectY
      rectNode.transform.baseVal[0].setMatrix(rectMatrix)

      line
        .attr('x1', rectCenterX)
        .attr('x2', rectCenterX)
        .attr('y2', rectY)
        .attr('y1', rectY - 5)

      imgMatrix.e = imgX
      imgMatrix.f = y
      imgNode.transform.baseVal[0].setMatrix(imgMatrix)
    }

    const handleDragEnd = () => {
      disableDrag()
      enableZoom()
    }

    const drag = window.d3
      .drag()
      .on('drag', handleDrag)
      .on('end', handleDragEnd)

    window.d3.select(`#${element_key}`).call(drag)
  }

  useEffect(() => {
    function loadScript(url) {
      if (!document.querySelector(`script[src="${url}"]`)) {
        return new Promise((resolve, reject) => {
          const script = document.createElement('script')
          script.src = url
          script.onload = resolve
          script.onerror = reject
          document.head.appendChild(script)
        })
      }
      return Promise.resolve()
    }

    loadScript('https://d3js.org/d3.v6.min.js')
      .then(() => {
        enableZoom()
      })
      .catch(error => {
        console.error('Error loading d3js script:', error)
      })
  }, [])

  const resetSvgPosition = () => {
    if (window.d3) {
      window.d3.select(`#${svg_id} g`).attr('transform', null)
    }
  }

  useEffect(() => {
    if (window.d3) {
      if (element_key) {
        const element = document.getElementById(element_key)
        const textNode = element.querySelector('text')
        const imageNode = element.querySelector('image')

        if (textNode) {
          enableTextDrag()
          setSelectedElementType('text')
        } else if (imageNode) {
          enableImageDrag()
          setSelectedElementType('image')
        }
      } else {
        setSelectedElementType(null)
      }
    }
  }, [element_key])

  return (
    <div className={classNames('')}>
      <JujoSvgMobileCenterBtn resetSvgPosition={resetSvgPosition} />
      {!element_key && (
        <JujoSvgMobileMainActionsBar
          svg_id={svg_id}
          default_font={default_font}
          setFocusedElement={setFocusedElement}
        />
      )}
      {element_key && selected_element_type === 'text' && (
        <JujoSVGMobileTextActionsBar
          element_key={element_key}
          setFocusedElement={setFocusedElement}
        />
      )}
      {element_key && selected_element_type === 'image' && (
        <JujoSVGMobileImageActionsBar />
      )}
    </div>
  )
}

export default JujoSvgMobileEditor
