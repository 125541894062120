import apiCallMap from '../enums/apiCallMap'
// eslint-disable-next-line import/no-cycle
import { cloneObj } from '../utils'
import { httpGet, httpPost, httpUpdate } from './apiService'

export async function getSessionFromServer(session_id) {
  let session_data = {}
  const endPoint = apiCallMap.publicSession.replace('{id}', session_id)

  const result = await httpGet(`${process.env.apiUrl}${endPoint}`)

  if (result) {
    const { status } = result
    if (status === 200) {
      session_data = result.data.data
    }
  }

  return session_data
}

export async function reloadSession(sender) {
  const { props } = sender
  const { session, updateSession } = props
  const updated_session = cloneObj(session)
  const { session_id } = updated_session
  const session_data = await getSessionFromServer(session_id)
  updated_session.session_data = session_data
  await updateSession(updated_session)
}

export async function saveSessionToServer(session_id, session_obj) {
  let session_data = {}
  const endPoint = apiCallMap.publicSession.replace('{id}', session_id)

  const result = await httpUpdate(
    `${process.env.apiUrl}${endPoint}`,
    session_obj
  )

  if (result) {
    const { status } = result
    if (status === 200) {
      session_data = result.data.data
    }
  }
  return session_data
}

export async function resetSession(session_id) {
  let session_data = {}

  const endPoint = apiCallMap.publicSessionAction.replace('{id}', session_id)
  const result = await httpPost(`${process.env.apiUrl}${endPoint}`, {
    action: 'reset',
  })

  if (result) {
    const { status } = result
    if (status === 200) {
      session_data = result.data.data
    }
  }
  return session_data
}
