import React from 'react'
import { connect } from 'react-redux'
import loadable from '@loadable/component'

import * as actionCreators from '../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../src/utils'
import { getPrintingRegistrationData } from '../../../actions/modeling/printing_registrations'

const ECDataListFieldComponent = loadable(() =>
  import('../../../../../../src/components/common/fields/dataListField')
)
const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class PrintingRegistrationEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      printing_registration_id: 0,
      printing_registration_data: null,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { data } = this.props
    const { printing_registration } = data

    const printing_registration_id = printing_registration || 0

    const printing_registration_data =
      printing_registration_id > 0
        ? await getPrintingRegistrationData(this, printing_registration_id)
        : null

    this.setState({
      printing_registration_id,
      printing_registration_data,
      loading: false,
    })
  }

  handleValueChanged = async (_field_name, value) => {
    const { data } = this.props

    const printing_registration_data = value
      ? await getPrintingRegistrationData(this, value)
      : value

    this.setState({
      printing_registration_id: value,
      printing_registration_data,
    })

    const { updateParsedData } = data
    updateParsedData('printing_registration', value)
  }

  renderPrintingRegistrationSelector = () => {
    const { printing_registration_id } = this.state

    const datalista_field_def = {
      name: 'printing_registration',
      fieldType: 'datalistField',
      renderField: 'name',
      valueField: 'id',
      defValue: 0,
      apis: {
        getData: {
          apiCall: 'getAllKeyValue',
          requestType: 'get',
          relatedEntity: 'printing_registration',
          defaultFilters: [
            {
              fEntity: [],
              fSource: 'environment',
              fValue: 'locale={locale}',
              fDefValue: 'it',
            },
          ],
        },
      },
    }

    const html = []
    html.push(
      <div key="impose_model_editor">
        <div className={classNames('border rounded-4 py-1 px-2')}>
          <ECDataListFieldComponent
            field={datalista_field_def}
            handleValueChanged={this.handleValueChanged}
            initialValue={printing_registration_id}
          />
        </div>
      </div>
    )
    return html
  }

  renderDetails = () => {
    const { printing_registration_data } = this.state
    const html = []
    if (!printing_registration_data) return html

    const { data } = printing_registration_data
    const { svg_size } = data
    const { mm } = svg_size
    const { width, height } = mm

    html.push(
      <div
        key="printing_registration_data_details"
        className={classNames(
          'container row text-center mx-auto my-2 rounded-4 bg-gray-100 fs-7'
        )}
      >
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('width_mm')}
          </div>
          <div>{width}</div>
        </div>
        <div className={classNames('col-12 col-md-6 my-1')}>
          <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
            {translate('height_mm')}
          </div>
          <div>{height}</div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div>
            <div>{this.renderPrintingRegistrationSelector()}</div>
            <div>{this.renderDetails()}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PrintingRegistrationEditor)
