import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import requestTypesMap from '../../../../enums/requestTypesMap'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, parseBoolean } from '../../../../utils'
import { GenericField } from './genericField'

export class PickerField extends GenericField {
  renderField = () => {
    const { fieldValue, data } = this.state
    const { field, environment, specialization, dynamicForm } = this.props
    const { name, renderField, valueField, translateRenderField, disabled } =
      field

    const disabled_field = parseBoolean(disabled, 'false')

    const { translations } = specialization
    const { texts } = translations

    const { locale } = environment
    const { type } = dynamicForm

    const html = []
    if (type === requestTypesMap.view) {
      let renderValue = ''
      for (let i = 0; i !== data.data.length; i += 1) {
        const element = data.data[i]
        if (element[valueField] === fieldValue) {
          renderValue = element[renderField]
          break
        }
      }
      html.push(<div key={uuid()}>{renderValue}</div>)
    } else {
      html.push(
        <select
          key={uuid()}
          name={name}
          defaultValue={fieldValue}
          disabled={disabled_field}
          onChange={e => {
            const { value } = e.target
            this.processOnChange(value)
          }}
        >
          <option key={uuid()} value="0">
            --
          </option>

          {data.data &&
            data.data.map(element => {
              const renderValue =
                translateRenderField && translateRenderField === true
                  ? texts[locale][element[renderField]]
                  : element[renderField]
              return (
                <option key={uuid()} value={element[valueField]}>
                  {renderValue}
                </option>
              )
            })}
        </select>
      )
    }
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(PickerField)
