import React from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'
import * as apiService from '../../../services/apiService'
import ErrorsComponent from '../error_component'
import { mapStateToProps } from '../../../utils'
import JujoButtonComponent from '../../jujo_button'

const classNames = require('classnames')

export class ResetPasswordComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      token: '',
      processing_request: false,
      error: {},
      response_message: '',
    }

    this.passwordFieldRef = React.createRef()
  }

  componentDidMount = () => {
    const tokenKey = 'token'
    const match = RegExp(`[?&]${tokenKey}=([^&]*)`).exec(window.location.search)
    const token = match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    this.setState({ token })
  }

  renderErrorsMessage = result => {
    this.setState({ error: result, processing_request: false })
  }

  handleShowPassFieldValue = fieldReferenceObj => {
    const { current } = fieldReferenceObj
    const { type } = current
    current.type = type === 'text' ? 'password' : 'text'
  }

  handleSaveNewPassword = async () => {
    const { email, password, token } = this.state
    this.setState({ error: {}, processing_request: true })

    const result = await apiService.httpPost(
      `${process.env.apiUrl}reset-password`,
      { email, password, token },
      false
    )
    if (result) {
      const { status, data } = result
      if (status === 200) {
        const { message } = data
        this.setState({ processing_request: false, response_message: message })
      } else {
        this.renderErrorsMessage(result)
      }
    }
  }

  handleGoBack = () => {
    if (typeof window !== 'undefined') {
      window.location.href = '/my-account/'
    }
  }

  renderLoading = () => {
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    const html = []

    html.push(
      <div
        key="loading"
        className={classNames(
          'd-flex justify-content-center align-items-center p-2 shadow-sm'
        )}
      >
        {texts[locale].loading}
        <div
          className={classNames('loading-icon theme-svg ms-1')}
          style={{
            backgroundPosition: 'center',
            backgroundSize: '30px 30px',
            backgroundRepeat: 'no-repeat',
            height: '30px',
            width: '30px',
          }}
        />
      </div>
    )

    return html
  }

  render() {
    const { processing_request, error, response_message } = this.state
    const { environment, specialization } = this.props
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div
        className={classNames(
          'bg-gradient-auth d-flex justify-content-center align-items-center'
        )}
        style={{
          height: '100vh',
          backgroundImage: `url(/${process.env.client}/authbg.webp)`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className={classNames('d-flex flex-column')}>
          <div
            className={classNames('container-fluid rounded shadow p-4 p-md-5')}
            style={{ maxWidth: '400px', background: '#ffffffeb' }}
          >
            <div
              className={classNames('logo mb-3')}
              style={{
                height: '80px',
                width: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            />
            <div
              className={classNames(
                'fs-2 fw-bold mb-4 text-center ffamily-secondary'
              )}
            >
              {texts[locale].new_password}
            </div>
            <div className={classNames('f-flex flex-column px-2 mb-5')}>
              <input
                className={classNames(
                  'form-control rounded-0 border-top-0 border-start-0 border-end-0 mb-3'
                )}
                type="email"
                placeholder={texts[locale].login_email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <div className={classNames('position-relative')}>
                <input
                  ref={this.passwordFieldRef}
                  className={classNames(
                    'form-control rounded-0 border-top-0 border-start-0 border-end-0 mb-3'
                  )}
                  type="password"
                  placeholder={texts[locale].new_password}
                  onChange={e => {
                    this.setState({ password: e.target.value })
                  }}
                />
                <div
                  className={classNames(
                    'eye-icon theme-svg position-absolute top-50 end-0 translate-middle-y'
                  )}
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    height: '20px',
                    width: '20px',
                  }}
                  label="show/hide"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    this.handleShowPassFieldValue(this.passwordFieldRef)
                  }}
                  onKeyPress={() => {
                    this.handleShowPassFieldValue(this.passwordFieldRef)
                  }}
                />
              </div>
            </div>
            {Object.keys(error).length > 0 && (
              <div
                className={classNames('text-break mb-3 fc-red border p-2 fs-8')}
                style={{
                  maxHeight: '100px',
                  overflowY: 'auto',
                }}
                key={uuid()}
              >
                <ErrorsComponent response={error} />
              </div>
            )}
            {response_message !== '' && (
              <>
                <div
                  key="resp_message"
                  className={classNames(
                    'mb-2 container p-2 border text-center'
                  )}
                >
                  {response_message}
                </div>

                <JujoButtonComponent
                  bstyle={7}
                  bwidth={236}
                  blabel={texts[locale].go_back || 'go_back'}
                  handleClick={this.handleGoBack}
                />
              </>
            )}
            {processing_request && this.renderLoading()}
            {!processing_request && response_message === '' && (
              <JujoButtonComponent
                bstyle={7}
                bwidth={236}
                blabel={texts[locale].save || 'save'}
                handleClick={this.handleSaveNewPassword}
              />
            )}
          </div>
          <div
            className={classNames(
              'tracking-in-expand d-flex justify-content-center align-items-center text-center fs-8 mt-2 fst-italic'
            )}
          >
            <div className={classNames('me-1')}>Powered by</div>
            <div
              className={classNames('jujo-icon')}
              style={{
                width: '10px',
                height: '10px',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                opacity: '0.7',
              }}
            />
            <div className={classNames('ms-1')}>Jujo Framework</div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ResetPasswordComponent)
