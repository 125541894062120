import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)

const ECSelectFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/selectField')
)

const classNames = require('classnames')

export class PTQEstimateShippingBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selected_zone: '',
      selected_method: '',
      allowed_methods: [],
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initial_value } = this.props
    if (initial_value && Object.keys(initial_value).length > 0) {
      const { zone, method } = initial_value
      const { slug } = method

      await this.refreshAllowedMethodsList(zone)

      await this.setState({
        selected_zone: zone,
        selected_method: slug,
        loading: false,
      })
    } else {
      this.setState({ loading: false })
    }
  }

  handleShippingMethodChanged = async (fieldName, value) => {
    const { handleShippingChanged } = this.props
    const { selected_zone, allowed_methods } = this.state

    if (value.length === 0) {
      this.resetShippingMethodSelection()
      return
    }

    let selected_method = {}
    for (let i = 0; i !== allowed_methods.length; i += 1) {
      const curr_method = allowed_methods[i]
      const { slug } = curr_method
      if (slug === value) {
        selected_method = curr_method
        break
      }
    }

    const shipping_data = {
      zone: selected_zone,
      method: selected_method,
    }
    handleShippingChanged(shipping_data)
  }

  renderShippingMethodsSelection = () => {
    const { allowed_methods, selected_method } = this.state

    const options = []
    for (let i = 0; i !== allowed_methods.length; i += 1) {
      const method = allowed_methods[i]
      const { slug, settings, conditional_selection_enabled } = method
      const { title, cost } = settings

      let can_be_pushed = true
      if (conditional_selection_enabled === false && slug !== selected_method) {
        can_be_pushed = false
      }

      if (can_be_pushed) {
        options.push({
          label: `${title} (${cost || 0} €)`,
          value: slug,
        })
      }
    }
    const field_def = {
      name: 'shipping_method',
      fieldType: 'selectField',
      defValue: '',
      options,
    }

    const html = []
    html.push(
      <div key="shipping_method_selector">
        <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
          {translate('shipping_method')}
        </div>
        <div
          className={classNames(
            'p-1 border border-color-4 rounded bg-white fs-7'
          )}
        >
          <ECSelectFieldComponent
            field={field_def}
            handleValueChanged={this.handleShippingMethodChanged}
            initialValue={selected_method}
          />
        </div>
      </div>
    )
    return html
  }

  refreshAllowedMethodsList = async selected_zone => {
    const { shipping_methods } = this.props
    const { methods } = shipping_methods

    const allowed_methods = methods.filter(
      x => x.zones.indexOf(selected_zone) >= 0
    )
    allowed_methods.sort((a, b) =>
      parseInt(a.position, 10) < parseInt(b.position, 10) ? -1 : 1
    )

    this.setState({ selected_zone, selected_method: '', allowed_methods })
  }

  handleZoneSelectionChanged = async (fieldName, value) => {
    if (value.length === 0) {
      this.resetShippingMethodSelection()
      await this.setState({ selected_zone: value, selected_method: '' })
      return
    }
    this.refreshAllowedMethodsList(value)
  }

  resetShippingMethodSelection = async () => {
    const { handleShippingChanged } = this.props
    handleShippingChanged({})
  }

  renderZonesSelection = () => {
    const { selected_zone } = this.state
    const { shipping_methods } = this.props
    const { zones } = shipping_methods

    const options = []
    for (let i = 0; i !== zones.length; i += 1) {
      const opt = zones[i]
      options.push({
        label: opt,
        value: opt,
      })
    }
    const field_def = {
      name: 'shipping_zone',
      fieldType: 'selectField',
      defValue: '',
      options,
    }

    const html = []
    html.push(
      <div key="shipping_zone_selector" className={classNames('mb-2')}>
        <div className={classNames('ffamily-secondary fs-9 fc-gray')}>
          {translate('shipping_zone')}
        </div>
        <div
          className={classNames(
            'p-1 border border-color-4 rounded bg-white fs-7'
          )}
        >
          <ECSelectFieldComponent
            field={field_def}
            handleValueChanged={this.handleZoneSelectionChanged}
            initialValue={selected_zone}
          />
        </div>
      </div>
    )
    return html
  }

  render() {
    const { loading, selected_zone } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('mb-3')}>
            {this.renderZonesSelection()}
            {selected_zone !== '' && this.renderShippingMethodsSelection()}
          </div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateShippingBlockComponent)
