import React from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'

const JujoSVGZoomBtn = loadable(() => import('./zoom_btn'))
const JujoSVGUnzoomBtn = loadable(() => import('./unzoom_btn'))
const JujoSVGGrid = loadable(() => import('./grid'))
const JujoSVGGuideBtn = loadable(() => import('./guide'))

function JujoSVGGlobalActions({ svg_id }) {
  return (
    <div className={classNames('d-flex flex-wrap justify-content-center')}>
      <JujoSVGZoomBtn svg_id={svg_id} />
      <JujoSVGUnzoomBtn svg_id={svg_id} />
      <JujoSVGGrid svg_id={svg_id} />
      <JujoSVGGuideBtn svg_id={svg_id} />
    </div>
  )
}

export default JujoSVGGlobalActions
