import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { getSelectedSvgNodes } from '../../../../../utils/svg/interactive_text'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGTextColorManager({ element_key, curr_nidx }) {
  const [text_element, setTextElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const t_element = g_element.querySelector('text')
    setTextElement(t_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  const handleColorChanged = e => {
    const { value: color } = e.target

    const selected_nodes = getSelectedSvgNodes(element_key)
    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.setAttribute('fill', color)
    }
  }

  const getDefaultValue = () => {
    const selected_node =
      text_element.querySelectorAll('tspan>tspan')[curr_nidx]

    if (selected_node) {
      const color = selected_node.getAttribute('fill')
      return color
    }
    return '#000'
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && (
        <div className={classNames('')}>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('text_color')}</div>
          </div>
          <input
            key={curr_nidx}
            type="color"
            onChange={e => handleColorChanged(e)}
            style={{ width: 25, height: 25 }}
            defaultValue={getDefaultValue()}
          />
        </div>
      )}
    </>
  )
}

export default JujoSVGTextColorManager
