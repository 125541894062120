/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { translate } from '../../../../utils'

function JujoDropSvg({ onChange }) {
  const dropRef = useRef()
  const [previewUrl, setPreviewUrl] = useState(null)
  const [error, setError] = useState(null)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/svg+xml',
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length === 0) {
        setError('No files were dropped')
        onChange(null)
        return
      }
      const file = acceptedFiles.slice(0, 1)[0]
      if (file.type !== 'image/svg+xml') {
        setError('The dropped file is not an SVG file')
        onChange(null)
        return
      }
      if (onChange) {
        onChange(file)
      }
      setError(null)
      setPreviewUrl(URL.createObjectURL(file))
    },
  })

  return (
    <div
      {...getRootProps()}
      ref={dropRef}
      className={classNames(
        'd-flex justify-content-center align-items-center p-5 bg-white mb-2'
      )}
      style={{ minHeight: 150 }}
    >
      <input {...getInputProps()} accept="image/svg+xml" />
      {error ? (
        <div>{error}</div>
      ) : isDragActive ? (
        <div>{translate('drop_file_here')}</div>
      ) : previewUrl ? (
        <img className={classNames('w-300px')} src={previewUrl} alt="Preview" />
      ) : (
        <div>{translate('drag_svg_instructions')}</div>
      )}
    </div>
  )
}

export default JujoDropSvg
