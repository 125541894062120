import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  injectComponent,
  mapStateToProps,
  retrieveComponent,
} from '../../../../../../src/utils'
import { JujoSVGEditorComponent } from '../../../../../../src/components/svg_editor/editor'
import { getEditableSvgCode } from '../../../actions/modeling/templates'
import { httpPost } from '../../../../../../src/services/apiService'

export class PtqInstanceEditorComponent extends JujoSVGEditorComponent {
  getSvgData = async () => {}

  renderTitle = () => {
    const { template_name } = this.state
    const html = []
    html.push(
      <div className={classNames('fw-bold')} key="template_title">
        {template_name}
      </div>
    )
    return html
  }

  doInitialDataParsing = async () => {
    const { svg_data } = this.props
    const { front: f_def, back: b_def, name } = svg_data

    const front = {
      svg_name: name,
      svg_id: f_def.svg_id,
      svg_code: new TextDecoder('utf-8').decode(
        Buffer.from(f_def.svg, 'base64')
      ),
      svg_size: f_def.svg_size,
    }

    const back =
      b_def === null
        ? null
        : {
            svg_name: name,
            svg_id: b_def.svg_id,
            svg_code: new TextDecoder('utf-8').decode(
              Buffer.from(b_def.svg, 'base64')
            ),
            svg_size: b_def.svg_size,
          }

    return {
      front,
      back,
    }
  }

  handleSave = async () => {
    const { session_id, product_id, variant_code, svg_data, origin_url } =
      this.props

    const saving_message_comp_id = uuid()
    const saving_message_comp_def = {
      specialized: false,
      path: 'svg_editor/editor/_parts/saving_message',
      data: {
        sender: this,
      },
    }

    injectComponent(this, saving_message_comp_id, saving_message_comp_def)

    await this.updateCurrentSideParsedData()

    const editable_code = await getEditableSvgCode(this)
    const updated_svg_data = cloneObj(svg_data)

    updated_svg_data.front.svg = editable_code.front_code
    if (updated_svg_data.back !== null) {
      updated_svg_data.back.svg = editable_code.back_code
    }

    const ep = 'public/action/post/svg-instance/save'
    await httpPost(
      `${process.env.apiUrl}${ep}`,
      {
        session_id,
        product_id,
        variant_code,
        svg_data: updated_svg_data,
      },
      false
    )

    retrieveComponent(this, saving_message_comp_id)

    if (typeof window !== 'undefined') {
      window.opener.postMessage('save_completed', origin_url)
    }

    // const customizations = {
    //   on_success_callback: this.handleOnSuccessCallback,
    //   success_message: translate('svg_template_successfully_updated_message'),
    // }

    // defaultManageResponse(this, customizations, response)
  }

  renderLogo = () => {
    const html = []

    html.push(
      <div
        key="logo"
        className={classNames('background-image logo')}
        style={{ width: 180, height: 40 }}
      />
    )

    return html
  }

  rightPanel = () => {
    const html = []
    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PtqInstanceEditorComponent)
