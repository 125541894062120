const initialState = {
  entity: '',
  type: '',
  fields: {},
  updateFieldsDefs: {},
  fieldsToUpdate: [],
  conditionalKeyUpdated: false,
  resetKeyUpdated: false,
  computedKeyUpdated: false,
  conditionalKeys: [],
  resetKeys: [],
  computedKeys: [],
  errors: {},
  source: {},
  parent: {},
  callback: {
    onSave: {
      action: null,
      params: {},
    },
    onClose: {
      action: null,
      params: {},
    },
  },
  visible: false,
}
export default initialState
