import lsEntryMap from '../../../enums/lsEntryMap'
import { lsGet, lsSet } from '../../../services/localStorage'
import { deleteCookie, updateCookie } from '../../../utils/cookies'
import initialState from '../../reducers/authentication/initialState'

export function reloadOrInitAuthenticationIntoRedux() {
  return async dispatch => {
    const authentication = lsGet(lsEntryMap.authentication) || initialState

    authentication.force_login = false
    const { access_token } = authentication

    if (typeof access_token === 'string' && access_token.length > 0) {
      updateCookie('jujo_token', authentication.access_token)
    }

    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}

export function resetAuthentication() {
  return async dispatch => {
    const authentication = initialState
    lsSet(lsEntryMap.authentication, authentication)
    deleteCookie('jujo_token')
    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}

export function updateAuthentication(authentication) {
  return async dispatch => {
    lsSet(lsEntryMap.authentication, authentication)
    updateCookie('jujo_token', authentication.access_token)
    dispatch({
      type: 'UPDATE_AUTHENTICATION',
      authentication,
    })
  }
}
