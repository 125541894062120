import gridEditorTypesMap from '../../../../../enums/gridEditorTypesMap'
import requestTypesMap from '../../../../../enums/requestTypesMap'
import { cloneObj } from '../../../../../utils'
import { getGridEditor } from '../../../helper/gridFunctions'

export async function createNewEntity(sender) {
  const { props } = sender
  const {
    entity,
    dynamicForm,
    parentData,
    updateDynamicFormIntoRedux,
    editableComponentProps,
  } = props

  const editor = getGridEditor(props)

  if (editor === gridEditorTypesMap.form) {
    const dForm = cloneObj(dynamicForm)
    dForm.entity = entity
    dForm.type = requestTypesMap.add
    dForm.visible = true
    dForm.parent = parentData ? parentData.data : {}
    await updateDynamicFormIntoRedux({ ...dForm })
  }
  if (editor === gridEditorTypesMap.editable_component) {
    const { enterAddMode } = editableComponentProps
    enterAddMode()
  }
}
