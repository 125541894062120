import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

import './styles.scss'
import { mapStateToProps } from '../../../../utils'

const PageComponent = loadable(() => import('../_parts/page'))
const JujoLeraSidebar = loadable(() => import('./_parts/sidebar'))
const JujoLeraFooter = loadable(() => import('./_parts/footer'))
const AccessRegisterComponent = loadable(() =>
  import('../_parts/access_register')
)

const classNames = require('classnames')

export class JujoLeraLayout extends React.Component {
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderHead = () => {
    const html = []

    html.push(
      <div
        key="header"
        className={classNames(
          'shadow zindex-2 g-column-1-1 position-fixed bg-white'
        )}
        style={{ width: '100%' }}
      >
        <div
          className={classNames(
            'container d-flex flex-row p-2 justify-content-between align-items-center'
          )}
        >
          <div
            className={classNames('logo d-flex')}
            label="logo"
            style={{
              height: '40px',
              width: '110px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          />
          <AccessRegisterComponent />
        </div>
      </div>
    )

    return html
  }

  renderSideBar = () => <JujoLeraSidebar />

  renderPageBody = () => {
    const { environment } = this.props
    const { path } = environment
    const page_key = `body_${path.replace(/\//g, '_').replace(/-/g, '_')}`
    const html = []
    html.push(
      <div
        key={page_key}
        className={classNames('p-3 p-md-5')}
        style={{ marginTop: '56px' }}
      >
        <PageComponent />
      </div>
    )
    return html
  }

  renderFooter = () => <JujoLeraFooter />

  gridTemplate = () => 'g-template-col-md-30-70'

  render() {
    return (
      <div
        className={classNames(
          `d-grid g-templata-row-min-auto-min ffamily-primary ${this.gridTemplate()}`
        )}
        style={{ minHeight: '100vh' }}
      >
        {this.renderHead()}
        {this.renderSideBar()}
        {this.renderPageBody()}
        {this.renderFooter()}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoLeraLayout)
