import React, { useState, useEffect } from 'react'
import loadable from '@loadable/component'
import classNames from 'classnames'
import { getQsValue } from '../../../../../../src/utils'
import { httpPost } from '../../../../../../src/services/apiService'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const PtqInstanceEditorComponent = loadable(() =>
  import('../custom_svg_instance_editor')
)

function SvgInstanceEditor() {
  const [origin_url, setOriginUrl] = useState(null)

  const [session_id, setSessionId] = useState(null)
  const [product_id, setProductId] = useState(null)
  const [variant_code, setVariantCode] = useState(null)
  const [svg_data, setSvgData] = useState(null)

  const initData = async () => {
    const origin = getQsValue('origin')
    const sid = getQsValue('session_id')
    const pid = getQsValue('product_id')
    const vcode = getQsValue('variant_code')
    const cid = getQsValue('customer_id')

    setOriginUrl(origin)
    setSessionId(sid)
    setProductId(pid)
    setVariantCode(vcode)

    const ep = 'public/action/post/svg-instance/get-instance'
    const response = await httpPost(
      `${process.env.apiUrl}${ep}`,
      {
        session_id: sid,
        product_id: pid,
        variant_code: vcode,
        customer_id: cid,
      },
      false
    )

    const { status, data } = response
    if (status === 200 || status === 202) {
      const { data: svg_resp_data } = data
      setSvgData(svg_resp_data)
    }
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  return (
    <>
      {svg_data !== null && (
        <div className={classNames('')}>
          <PtqInstanceEditorComponent
            svg_data={svg_data}
            origin_url={origin_url}
            session_id={session_id}
            product_id={product_id}
            variant_code={variant_code}
          />
        </div>
      )}
      {svg_data === null && <JujoLoading />}
    </>
  )
}

export default SvgInstanceEditor
