import React from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const JujoSVGNewTextBtn = loadable(() => import('../quick_actions/new_text'))
const JujoSVGNewImageBtn = loadable(() => import('../quick_actions/new_image'))

function JujoSvgMobileMainActionsBar({
  svg_id,
  default_font,
  setFocusedElement,
}) {
  return (
    <>
      <div
        id="m_main_actions_bar"
        className={classNames(
          'position-absolute start-0 bottom-0 p-1 w-100 bg-1 d-flex'
        )}
      >
        <div
          className={classNames(
            'd-flex align-items-center col-3 justify-content-center fs-8 fw-bold border-end bg-white'
          )}
        >
          <JujoSVGNewTextBtn
            svg_id={svg_id}
            default_font={default_font}
            setFocusedElement={setFocusedElement}
          />
        </div>
        <div
          className={classNames(
            'd-flex align-items-center col-3 justify-content-center fs-8 fw-bold bg-white'
          )}
        >
          <JujoSVGNewImageBtn
            svg_id={svg_id}
            setFocusedElement={setFocusedElement}
          />
        </div>
      </div>
    </>
  )
}

export default JujoSvgMobileMainActionsBar
