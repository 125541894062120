import apiCallMap from '../../../../../enums/apiCallMap'
import apiRequestTypesMap from '../../../../../enums/apiRequestTypesMap'
import * as apiService from '../../../../../services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../services/servicesHelper'
import { injectComponent, retrieveComponent } from '../../../../../utils'
import {
  createMSBDef,
  getEmptyMessageBox,
} from '../../../../message_box/helper'
import { getStoredGridProps } from '../../../helper/lsInteractionFunctions'

function closeMessageBox(params) {
  const { sender, msbox_instance_id } = params
  retrieveComponent(sender, msbox_instance_id)
}

export async function exportAsExcel(sender) {
  const { props } = sender
  const { entity, authentication, environment } = props
  const gridProps = getStoredGridProps(props)

  const requestData = baseRequestObject(
    apiCallMap.export,
    entity,
    apiRequestTypesMap.get,
    environment,
    authentication
  )
  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{fileType}',
    pValue: 'excel',
    pDefValue: 'excel',
  })
  requestData.advancedSearch = gridProps.advancedSearch || ''
  if (requestData && requestData.qsDefaultProps) {
    requestData.qsDefaultProps.searchValue = gridProps.searchValue || ''
  }
  const parsedEp = parseEndpoint(requestData)

  const response = await apiService.httpGet(
    `${process.env.apiUrl}${parsedEp}`,
    true
  )
  const { status } = response
  if (status === 200) {
    this.manageResponse(sender, response)
  }
}

export function manageResponse(sender, response) {
  const { props } = sender
  const { specialization, environment } = props

  const { translations } = specialization
  const { texts } = translations
  const { locale } = environment

  const messageBox = getEmptyMessageBox()

  if (response) {
    const { status, data } = response
    if (status === 200 || status === 201) {
      const { message } = data
      messageBox.icon = 'confirm-icon-color'
      messageBox.message = message

      const continue_btn = createMSBDef(
        texts[locale].continue_browsing,
        closeMessageBox,
        {
          sender,
          msbox_instance_id: messageBox.id,
        },
        true
      )
      messageBox.buttons.push(continue_btn)
    }
  }

  const component_definition = {
    specialized: false,
    path: 'message_box',
    data: {
      messageBox,
    },
  }

  injectComponent(sender, messageBox.id, component_definition)
}
