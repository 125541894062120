import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import { mapStateToProps } from '../../utils'

const classNames = require('classnames')

export class JujoInjectableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  renderComponents = () => {
    const { injectedComponents } = this.props
    const { list } = injectedComponents

    const keys = Object.keys(list)
    const html = []

    for (let i = 0; i !== keys.length; i += 1) {
      const k = keys[i]
      const comp = list[k]

      const { specialized, path, data } = comp

      let DynamicField = <></>

      if (specialized) {
        DynamicField =
          require(`../../../jujo_specializations/src/${process.env.client}/components/${path}/index`).default
      } else {
        DynamicField = require(`../${path}`).default
      }

      html.push(<DynamicField key={k} comp_id={k} data={data} />)
    }

    return html
  }

  render() {
    return <div className={classNames('')}>{this.renderComponents()}</div>
  }
}

export default connect(mapStateToProps, actionCreators)(JujoInjectableComponent)
