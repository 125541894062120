import React, { useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'

const JujoSVGDeleteBtn = loadable(() => import('../quick_actions/delete'))

const JujoSVGInteractiveText = loadable(() =>
  import('../text/interactive_text')
)
const JujoSVGFontFamilyManager = loadable(() =>
  import('../text/ffamily_manager')
)
const JujoSVGFontSizeManagerComponent = loadable(() =>
  import('../text/font_size_manager')
)
const JujoSVGTextAlignmentManager = loadable(() =>
  import('../text/text_alignment_manager')
)
const JujoSVGLetterSpacingManagerComponent = loadable(() =>
  import('../text/letter_spacing_manager')
)
const JujoSVGTextColorManager = loadable(() =>
  import('../text/text_color_manager')
)

function JujoSVGMobileTextActionsBar({ element_key, setFocusedElement }) {
  const actionContainer = useRef(null)

  const { jujo_fonts: fonts } = useSelector(state => state.session.session_data)

  const [btns] = useState({
    edit_text: {
      icon: 'edit-text-icon',
    },
    font: {
      icon: 'edit-font-icon',
    },
    text_options: {
      icon: 'text-options-icon',
    },
  })

  const [active_btn, setActiveBtn] = useState(null)

  return (
    <>
      {active_btn === null && (
        <div
          id="m_text_actions_bar"
          className={classNames(
            'position-absolute start-0 bottom-0 p-1 w-100 bg-1 d-flex'
          )}
        >
          {Object.keys(btns).map(key => (
            <div
              key={key}
              className={classNames(
                'd-flex align-items-center col-3 justify-content-center fs-8 fw-bold border-end bg-white'
              )}
              style={{ height: 70 }}
              role="button"
              tabIndex={0}
              onClick={() => {
                setActiveBtn(key)
              }}
              onKeyPress={() => {
                setActiveBtn(key)
              }}
            >
              <div
                className={classNames(
                  'text-center d-flex flex-column align-items-center justify-content-center'
                )}
              >
                <div>{translate(key)}</div>
                {btns[key].icon && (
                  <div
                    className={classNames('background-image', btns[key].icon)}
                    style={{ width: 25, height: 25 }}
                  />
                )}
              </div>
            </div>
          ))}
          <div
            className={classNames(
              'd-flex align-items-center col-3 justify-content-center fs-8 fw-bold bg-white'
            )}
          >
            <JujoSVGDeleteBtn
              element_key={element_key}
              setFocusedElement={setFocusedElement}
            />
          </div>
        </div>
      )}
      {active_btn !== null && (
        <div
          ref={actionContainer}
          className={classNames(
            'position-absolute start-0 bottom-0 w-100 bg-white container row mx-auto'
          )}
        >
          {active_btn === 'edit_text' && (
            <JujoSVGInteractiveText element_key={element_key} />
          )}
          {active_btn === 'font' && (
            <JujoSVGFontFamilyManager
              element_key={element_key}
              curr_nidx={0}
              fonts={fonts}
            />
          )}
          {active_btn === 'text_options' && (
            <div className={classNames('container row py-2 fs-7')}>
              <div className={classNames('col-6')}>
                <JujoSVGFontSizeManagerComponent
                  element_key={element_key}
                  curr_nidx={0}
                />
              </div>
              <div className={classNames('col-6')}>
                <JujoSVGTextAlignmentManager element_key={element_key} />
              </div>
              <div className={classNames('col-6')}>
                <JujoSVGLetterSpacingManagerComponent
                  element_key={element_key}
                  curr_nidx={0}
                />
              </div>
              <div className={classNames('col-6')}>
                <JujoSVGTextColorManager
                  element_key={element_key}
                  curr_nidx={0}
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              'bg-1 fst-uppercase fc-white text-center py-2 mb-1'
            )}
            style={{ height: 45 }}
            role="button"
            tabIndex={0}
            onClick={() => {
              setActiveBtn(null)
            }}
            onKeyPress={() => {
              setActiveBtn(null)
            }}
          >
            <div
              className={classNames(
                'd-flex align-items-center justify-content-center ffamily-secondary fs-5'
              )}
            >
              <div
                className={classNames(
                  'background-image close-icon white-svg me-2'
                )}
                style={{ width: 16, height: 16 }}
              />
              <div style={{ letterSpacing: 4 }}>{translate('close')}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default connect()(JujoSVGMobileTextActionsBar)
