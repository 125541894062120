/* eslint-disable react/no-unused-state */
/* eslint-disable no-debugger */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps, translate } from '../../../../utils'
import { getStoredGridProps } from '../../helper/lsInteractionFunctions'
import { setAdvancedSearchFilter } from '../../helper/sortFilterFunctions'

const classNames = require('classnames')

export class GenericFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      filterValue: null,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { filter } = this.props
    const { label } = filter

    const gridProps = getStoredGridProps(this.props)
    const { advancedSearchList } = gridProps

    const filterValue = advancedSearchList[label]
      ? advancedSearchList[label].value
      : null

    this.setState({ initialized: true, filterValue })
  }

  componentDidUpdate = async () => {}

  updateFilter = async value => {
    // const { filterValue } = this.state
    const { filter, loadGrid } = this.props
    const { field, label, operator } = filter

    const updatedFilter = {
      key: label,
      field,
      value,
      operator,
    }
    await setAdvancedSearchFilter(updatedFilter, this.props)
    loadGrid()
  }

  clearFilter = async () => {
    const { filter, loadGrid } = this.props
    const { label } = filter

    const updatedFilter = {
      key: label,
      value: null,
      operator: null,
    }
    await setAdvancedSearchFilter(updatedFilter, this.props)
    loadGrid()
  }

  renderFilter = () => {
    const html = []
    html.push(<div>Be creative! :)</div>)
    return html
  }

  filterTitleEnabled = () => true

  render() {
    const { filter } = this.props
    const { initialized } = this.state

    const { label } = filter
    return (
      <>
        {initialized && (
          <div className={classNames('pb-2 mb-2')}>
            {this.filterTitleEnabled() && (
              <div className={classNames('fs-7 text-secondary ps-2')}>
                {translate(label)}
              </div>
            )}
            <div>{this.renderFilter()}</div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(GenericFilter)
