import React, { Component } from 'react'
import { connect } from 'react-redux'

import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'

import boxIconMap from '../../../enums/boxIconsMap'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

class Box extends Component {
  handleClick = button => {
    const { onClick, item } = this.props
    onClick(item, button)
  }

  render() {
    const { content, buttons } = this.props
    return (
      <div
        className={classNames(
          'shadow p-2 m-2 rounded-4 bg-white fs-7 position-relative'
        )}
        style={{ width: '250px' }}
        key={uuid()}
      >
        <div className={classNames('flex-column p-2 shadow-sm')}>
          {content &&
            content.length > 0 &&
            content.map(row => <div key={uuid()}>{row}</div>)}
        </div>
        <div className={classNames('d-flex justify-content-end')}>
          {buttons &&
            buttons.length > 0 &&
            buttons.map(button => (
              <div
                key={uuid()}
                label="btn"
                className={classNames(
                  button.icon === boxIconMap.edit_icon ? 'edit-icon' : '',
                  'bg-1 rounded-3 p-2 m-2'
                )}
                style={{
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  width: '25px',
                  height: '25px',
                }}
                role="button"
                tabIndex={0}
                onClick={() => {
                  this.handleClick(button)
                }}
                onKeyPress={() => {
                  this.handleClick(button)
                }}
              />
            ))}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(Box)
