import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGTextAlignmentManager({ element_key }) {
  const [text_element, setTextElement] = useState(null)
  const [anchor, setAnchor] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const e_element = g_element.querySelector('text')

    setTextElement(e_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  useEffect(() => {
    if (text_element) {
      const t_anchor = text_element.getAttribute('text-anchor') || 'start'
      setAnchor(t_anchor)
    }
  }, [text_element])

  const alignText = position => {
    const before_bbox = text_element.getBBox()

    text_element.setAttribute('text-anchor', position)

    const after_bbox = text_element.getBBox()
    const shift = before_bbox.x - after_bbox.x

    const x = text_element.getAttribute('x')
    const new_x = parseFloat(x) + parseFloat(shift)
    text_element.setAttribute('x', new_x)

    const { childNodes } = text_element
    for (let i = 0; i !== childNodes.length; i += 1) {
      const child = childNodes[i]
      child.setAttribute('x', new_x)
    }

    setAnchor(position)
    updatedRectBorder(text_element)
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && (
        <div className={classNames('')}>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('text_alignment')}</div>
          </div>
          <div className={classNames('d-flex')}>
            <div
              className={classNames(
                'background-image text-left-icon',
                anchor === 'start' ? 'theme-svg' : ''
              )}
              style={{ width: 40, height: 40 }}
              role="button"
              label="text-left"
              tabIndex={0}
              onClick={() => alignText('start')}
              onKeyPress={() => alignText('start')}
            />
            <div
              className={classNames(
                'background-image text-center-icon mx-2',
                anchor === 'middle' ? 'theme-svg' : ''
              )}
              style={{ width: 40, height: 40 }}
              role="button"
              label="text-center"
              tabIndex={0}
              onClick={() => alignText('middle')}
              onKeyPress={() => alignText('middle')}
            />
            <div
              className={classNames(
                'background-image text-right-icon',
                anchor === 'end' ? 'theme-svg' : ''
              )}
              style={{ width: 40, height: 40 }}
              role="button"
              label="text-right"
              tabIndex={0}
              onClick={() => alignText('end')}
              onKeyPress={() => alignText('end')}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGTextAlignmentManager
