/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../store/actions'
import {
  lockBodyScroll,
  mapStateToProps,
  retrieveComponent,
  unlockBodyScroll,
} from '../../utils'
import JujoLoading from '../loading'

const classNames = require('classnames')

export class FillableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fullscreen: false,
      component_data: {},
    }
    this.fieldRef = React.createRef()
  }

  componentWillUnmount = async () => {
    unlockBodyScroll()

    await this.performActionOnWillUnmount()

    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    lockBodyScroll()
    this.setState({ fullscreen: this.isFullscreenOnInit() })
    await this.performActionOnDidMount()
    this.setState({ loading: false })
  }

  isFullscreenOnInit = () => false

  handleClose = async () => {
    const { comp_id } = this.props
    retrieveComponent(this, comp_id)
  }

  handleChangeScreenSize = () => {
    const { fullscreen } = this.state
    this.setState({ fullscreen: !fullscreen })
  }

  updateData = component_data => {
    this.setState({ component_data })
  }

  performActionOnDidMount = async () => {}

  performActionOnWillUnmount = async () => {}

  renderCloseButton = () => {
    const html = []

    html.push(
      <div
        key="close_btn"
        className={classNames('close-icon position-absolute zindex-2')}
        style={{
          width: '18px',
          height: '18px',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          right: '20px',
          top: '20px',
        }}
        role="button"
        tabIndex={0}
        label="close"
        onClick={() => {
          this.handleClose()
        }}
        onKeyPress={() => {
          this.handleClose()
        }}
      />
    )

    return html
  }

  renderFullScreenButton = () => {
    const { fullscreen } = this.state

    const html = []

    html.push(
      <div
        key="screen_size_btn"
        className={classNames(
          'position-absolute d-none d-md-block',
          fullscreen ? 'shrink-icon' : 'widen-icon'
        )}
        style={{
          width: '18px',
          height: '18px',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          right: '50px',
          top: '20px',
        }}
        role="button"
        tabIndex={0}
        label="close"
        onClick={() => {
          this.handleChangeScreenSize()
        }}
        onKeyPress={() => {
          this.handleChangeScreenSize()
        }}
      />
    )

    return html
  }

  renderBody = () => {
    const html = []
    html.push(
      <div key="placeholder">To use this component you need to extend it!!</div>
    )
    return html
  }

  windowStyle = () => ({
    width: 'min(100%, 750px)',
    height: 'min(100%, 850px)',
    overflowY: 'scroll',
  })

  fullScreenStyle = () => ({
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  })

  windowClassNames = () =>
    'position-absolute top-50 start-50 bg-light translate-middle rounded-5 p-5'

  render() {
    const { loading, fullscreen } = this.state
    return (
      <>
        <div
          className={classNames(
            'position-fixed zindex-2 bg-black-opacity-2 w-100 h-100 top-0 start-0'
          )}
        >
          <div
            className={classNames(this.windowClassNames())}
            style={fullscreen ? this.fullScreenStyle() : this.windowStyle()}
          >
            {this.renderFullScreenButton()}
            {this.renderCloseButton()}
            {loading === true && <JujoLoading />}
            {loading === false && this.renderBody()}
          </div>
        </div>
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(FillableComponent)
