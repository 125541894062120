import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

export class TimeField extends GenericField {
  processOnChange = value => {
    this.setState({ fieldValue: value })
  }

  processOnBlur = value => {
    this.saveOnRedux(value)
  }

  renderField = () => {
    const { fieldValue } = this.state
    const { field } = this.props
    const { name } = field

    const html = []
    html.push(
      <input
        key={uuid()}
        type="time"
        name={name}
        defaultValue={fieldValue}
        onChange={e => {
          const { value } = e.target
          this.processOnChange(value)
        }}
        onBlur={e => {
          const { value } = e.target
          this.processOnBlur(value)
        }}
      />
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(TimeField)
