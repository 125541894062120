import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../store/actions'
import { mapStateToProps } from '../../../../../../utils'

import JujoLeraMenu from '../menu'

const classNames = require('classnames')

export class JujoLeraSidebar extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  render() {
    const { specialization, environment } = this.props

    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment

    return (
      <div
        className={classNames('bg-light-gray d-flex flex-column p-3 p-md-5')}
        style={{ marginTop: '56px' }}
      >
        <div className={classNames('ffamily-secondary fc-1 fs-4 text-center')}>
          {texts[locale].personal_area}
        </div>
        <JujoLeraMenu />
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoLeraSidebar)
