/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'

import uuid from 'react-uuid'
import * as actionCreators from '../../../../store/actions'

import componentTypesMap from '../../../../enums/componentTypesMap'

import { getCurrentPageDefinition } from '../../helper/pageFunctions'
import { mapStateToProps } from '../../../../utils'

const JujoGridComponent = loadable(() => import('../../../jujo_grid'))
const TabsComponent = loadable(() => import('../../../tabs'))
const ContextVariableListComponent = loadable(() =>
  import('../../../context_variable_list')
)
const UserPanelComponent = loadable(() => import('../../../user_panel'))
const PopupComponent = loadable(() => import('../../../popup'))

const classNames = require('classnames')

export class PageComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { initialized } = this.state
    const { environment, dynamicForm } = this.props
    const { environment: nextEnvironment, dynamicForm: nextDynamicForm } =
      nextProps

    if (initialized !== nextState.initialized) {
      return true
    }

    if (environment.path !== nextEnvironment.path) {
      return true
    }

    if (
      JSON.stringify(environment.tabs) !== JSON.stringify(nextEnvironment.tabs)
    ) {
      return true
    }

    if (
      dynamicForm &&
      nextDynamicForm &&
      dynamicForm.visible !== nextDynamicForm.visible
    ) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ initialized: true })
  }

  componentDidUpdate = () => {}

  getTabsComponent = (compKey, compDef) => {
    const { tabList } = compDef

    return <TabsComponent key={uuid()} label={compKey} tabList={tabList} />
  }

  initContextVariables = (compKey, compDef) => {
    const { varList } = compDef
    return <ContextVariableListComponent key={compKey} varList={varList} />
  }

  initUserPanelComponent = compKey => <UserPanelComponent key={compKey} />

  initJujoGridComponent = (compKey, compDef) => {
    const { environment } = this.props
    const { tabs } = environment

    const { gridEntity } = compDef
    const { defValue, staticGrid, relatedTabList } = gridEntity

    let gridEntityValue = defValue

    if (staticGrid === false) {
      const relatedTabSelectedIndex = tabs[relatedTabList] || -1

      if (relatedTabSelectedIndex >= 0) {
        const components = this.getPageComponents()

        const relatedTabComponent = components[relatedTabList]
        const { tabList } = relatedTabComponent
        const tabDef = tabList[relatedTabSelectedIndex]
        const { tabEntity } = tabDef

        gridEntityValue = tabEntity
      }
    }
    return (
      <div key={compKey} className={classNames('')}>
        <JujoGridComponent entity={gridEntityValue} />
      </div>
    )
  }

  getPageComponents = () => {
    const pageDef = getCurrentPageDefinition(this.props)
    return pageDef.components || {}
  }

  render() {
    const { dynamicForm } = this.props
    const { initialized } = this.state
    const components = initialized === true ? this.getPageComponents() : {}
    const renderList = []
    const compKeys = Object.keys(components)
    for (let i = 0; i !== compKeys.length; i += 1) {
      const key = compKeys[i]
      const compDefinition = components[key]
      const { specialized, cType, comp } = compDefinition
      if (!specialized) {
        if (cType === componentTypesMap.context_variables) {
          const jujoContextVariablesComp = this.initContextVariables(
            key,
            compDefinition
          )
          renderList.push(jujoContextVariablesComp)
        }
        if (cType === componentTypesMap.jujo_grid) {
          const jujoGridComp = this.initJujoGridComponent(key, compDefinition)
          renderList.push(jujoGridComp)
        }
        if (cType === componentTypesMap.tabs) {
          const tabsComp = this.getTabsComponent(key, compDefinition)
          renderList.push(tabsComp)
        }
        if (cType === componentTypesMap.user_panel) {
          const userPanelComp = this.initUserPanelComponent(key)
          renderList.push(userPanelComp)
        }

        if (!cType && comp) {
          const DynamicComponent = require(`../../../${comp}`).default
          renderList.push(<DynamicComponent key={key} c_def={compDefinition} />)
        }
      } else {
        const DynamicComponent =
          require(`../../../../../jujo_specializations/src/${process.env.client}/components/${comp}`).default
        renderList.push(<DynamicComponent key={key} c_def={compDefinition} />)
      }
    }

    return (
      <div className={classNames('w-100')}>
        <div>{renderList}</div>
        {dynamicForm && dynamicForm.visible && <PopupComponent />}
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PageComponent)
