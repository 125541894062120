import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'

import loadable from '@loadable/component'
import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { JujoSVGGenericSingleElementComponent } from './generic_single_element'

const ImageEditorQuickActions = loadable(() =>
  import('./image/image_editor_quick_actions')
)
// const ImageRotationManager = loadable(() => import('./image_rotation_manager'))
const ImageSizeManager = loadable(() => import('./image/image_size_manager'))

export class JujoSVGSingleImageEditorComponent extends JujoSVGGenericSingleElementComponent {
  retrieveGenericElement = () => {
    const { g_element } = this.state
    const generic_element = g_element.getElementsByTagName('image')[0]
    return generic_element
  }

  redraw = () => {
    this.drawEditElement()
  }

  drawEditElement = () => {
    const { element_key } = this.props

    const { generic_element } = this.state
    const value = generic_element.getAttribute('href')

    const html = []
    html.push(
      <div
        key={`${element_key}_not_expandable_part`}
        className={classNames(
          'd-flex flex-column flex-md-row align-items-center'
        )}
      >
        <div>
          <img
            id={`${element_key}_editor_preview_img`}
            className={classNames('ofit-contain w-120px h-120px')}
            src={value}
            alt={element_key}
          />
        </div>
        <div
          className={classNames('d-flex flex-column mx-auto p-3 bg-gray-100')}
        >
          <div className={classNames('mb-3')}>
            <ImageSizeManager element_key={element_key} />
          </div>
          {/* <div className={classNames('mb-3')}>
            <ImageRotationManager element_key={element_key} />
          </div> */}
          <div>
            <ImageEditorQuickActions element_key={element_key} />
          </div>
        </div>
      </div>
    )
    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGSingleImageEditorComponent)
