import React from 'react'
import classNames from 'classnames'
import { randomString, translate } from '../../../../../utils'
import { bindSvgDragActionToTextElement } from '../../../../../utils/svg'
import { bindDoubleClickToElement } from '../../../../../utils/svg/double_click'

function JujoSVGNewTextBtn({ svg_id, default_font, setFocusedElement }) {
  const handleAddNewTextClicked = () => {
    const svg_element = document.getElementById(svg_id)
    const new_text_value = 'this is a new text'
    const default_font_name = default_font ? `font_${default_font.id}` : ''

    const bbox = svg_element.getBBox()
    const { height } = bbox

    const g_element = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'g'
    )

    const gid = randomString(16)
    g_element.setAttribute('id', gid)

    const text_element = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    )

    text_element.setAttribute('x', 50)
    text_element.setAttribute('y', height / 2)
    text_element.setAttribute('parsed', true)

    g_element.appendChild(text_element)

    const row_element = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    )
    row_element.setAttribute('x', 50)
    row_element.setAttribute('dy', 0)

    text_element.appendChild(row_element)

    for (let i = 0; i !== new_text_value.length; i += 1) {
      const char = new_text_value[i]

      const char_element = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'tspan'
      )
      char_element.textContent = char
      char_element.style.fontSize = '20px'
      char_element.style.fontFamily = default_font_name
      row_element.appendChild(char_element)
    }

    svg_element.appendChild(g_element)

    setTimeout(() => {
      bindSvgDragActionToTextElement(svg_id, text_element)
      bindDoubleClickToElement(svg_id, text_element, setFocusedElement)

      const doubleClickEvent = new Event('dblclick', {})
      text_element.dispatchEvent(doubleClickEvent)
    }, '1')
  }

  return (
    <div>
      <div
        className={classNames(
          'd-flex flex-column align-items-center w-80px w-md-70px p-1 p-md-0 m-1 m-md-0'
        )}
        role="button"
        tabIndex={0}
        onClick={() => handleAddNewTextClicked()}
        onKeyPress={() => handleAddNewTextClicked()}
      >
        <div className={classNames('fs-8')}>{translate('add_text')}</div>
        <div
          className={classNames('background-image add-text-icon')}
          style={{ width: 25, height: 25 }}
        />
      </div>
    </div>
  )
}

export default JujoSVGNewTextBtn
