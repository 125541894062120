import React, { useState, useEffect } from 'react'
import loadable from '@loadable/component'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

const CustomerSummary = loadable(() =>
  import('../../customers_group/customer_summary')
)

function CustomerSummaryField(params) {
  const [customer_id, setCustomerId] = useState(0)

  const initData = async () => {
    const { dataSource } = params
    const { customer_id: cid } = dataSource

    setCustomerId(cid)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
    return () => {
      setCustomerId(0)
    }
  }, [])

  return (
    <>
      {customer_id === 0 && <JujoLoading />}
      {customer_id !== 0 && <CustomerSummary customer_id={customer_id} />}
    </>
  )
}

export default CustomerSummaryField
