import initialState from './initialState'

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DYNAMIC_FORM':
      return action.dynamicform
    case 'RESET_DYNAMIC_FORM':
      return initialState
    default:
      return state
  }
}

export default reducer
