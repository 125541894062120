import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { translate } from '../../../../../utils'
import { getSelectedSvgNodes } from '../../../../../utils/svg/interactive_text'
import { updatedRectBorder } from '../../../../../utils/svg/rect_border'

const JujoLoading = loadable(() => import('../../../../loading'))
const JujoSVGNumberSelector = loadable(() => import('../number_selector'))

function JujoSVGFontSizeManagerComponent({ element_key, curr_nidx }) {
  const [text_element, setTextElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    const t_element = g_element.querySelector('text')
    setTextElement(t_element)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  const handleSizeChanged = new_size => {
    const selected_nodes = getSelectedSvgNodes(element_key)

    for (let i = 0; i !== selected_nodes.length; i += 1) {
      const node = selected_nodes[i]
      node.style['font-size'] = `${new_size}px`
    }
    updatedRectBorder(text_element)
  }

  const getDefaultValue = () => {
    const selected_node =
      text_element.querySelectorAll('tspan>tspan')[curr_nidx]

    if (selected_node) {
      const fsize = selected_node.style['font-size']
      if (fsize) {
        const parsed_fsize = fsize.replace('px', '')
        return parsed_fsize
      }
    }
    return 0
  }

  return (
    <>
      {text_element === null && <JujoLoading />}
      {text_element !== null && (
        <div className={classNames('')}>
          <div
            className={classNames('fw-bold ffamily-secondary fst-uppercase')}
          >
            <div>{translate('font_size')}</div>
          </div>
          <div key={curr_nidx}>
            <JujoSVGNumberSelector
              handleChange={handleSizeChanged}
              defaultValue={getDefaultValue()}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGFontSizeManagerComponent
