const orderStatusMap = {
  wc_cancelled: 'wc-cancelled',
  wc_inattesaditesti: 'wc-inattesaditesti',
  wc_inattesaanteprima: 'wc-inattesaanteprima',
  wc_attesapprovazione: 'wc-attesapprovazione',
  wc_on_hold: 'wc-on-hold',
  wc_pending: 'wc-pending',
  wc_vss: 'wc-vss',
}
export default orderStatusMap
