import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { httpGet } from '../../../../../services/apiService'
import { translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

function JujoSVGPaperSelector({ svg_id }) {
  const [svg, setSvg] = useState(null)
  const [papers, setPapers] = useState([])
  const [selected_paper, setSelectedPaper] = useState(0)

  const initData = async () => {
    const svg_element = document.getElementById(svg_id)
    if (svg_element) {
      const ep = 'key-value-list/paper-library'
      const response = await httpGet(`${process.env.apiUrl}${ep}`)
      const { status } = response
      if (status === 200 || status === 201) {
        const { data } = response.data

        const { background } = svg_element.style
        if (background !== '') {
          const url = background.match(/\((.*?)\)/)[1].replace(/('|")/g, '')

          const sel_paper = data.filter(x => x.url === url)[0].id
          setSelectedPaper(sel_paper)
        }

        setPapers(data)
        setSvg(svg_element)
      }
    } else {
      setTimeout(initData, 100)
    }
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [])

  const handlePaperChanged = e => {
    const { value } = e.target
    const parsed_id = parseInt(value, 10)
    const p = papers.filter(x => x.id === parsed_id)[0]

    if (p !== undefined) {
      svg.style.background = `url(${p.url}) center center / cover`
    } else {
      svg.style.background = null
    }
  }

  return (
    <div className={classNames('')}>
      <div className={classNames('fw-bold ffamily-secondary fst-uppercase')}>
        <div>{translate('paper')}</div>
      </div>
      {papers.length === 0 && <JujoLoading />}
      {papers.length > 0 && (
        <div className={classNames('bg-white p-2 mb-2 border')}>
          <select
            onChange={e => handlePaperChanged(e)}
            defaultValue={selected_paper}
          >
            <option value={0}>{translate('select')}</option>
            {papers.map(opt => {
              const { id, name } = opt
              return (
                <option key={`opt_${id}`} value={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
      )}
    </div>
  )
}

export default JujoSVGPaperSelector
