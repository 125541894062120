import uuid from 'react-uuid'
import { injectComponent } from '../../../../../src/utils'

export async function editProduct(sender) {
  const { props } = sender
  const { row } = props
  const { ID } = row

  console.log(ID)
  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'products_group',
    data: {
      sender,
      ID,
    },
  }

  injectComponent(sender, comp_id, component_definition)
}
