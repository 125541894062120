import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'

export async function parseFile(sender, _field, file) {
  const { props } = sender
  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'printing_registration',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'parse',
    pDefValue: 'parse',
  })

  const parsedEp = parseEndpoint(requestData)

  const formData = new FormData()
  formData.append('file', file)

  const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, formData)

  return response
}

export async function shapeServerResponse(sender, response) {
  const { onDropCompleted } = sender
  const { data, status } = response[0]

  onDropCompleted([{ ...data, status }])
}

export async function getPrintingRegistrationData(
  sender,
  printing_registration_id
) {
  const { props } = sender
  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'printing_registration',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'get-data',
    pDefValue: 'get-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(`${process.env.apiUrl}${parsedEp}`, {
    printing_registration_id,
  })

  const { status, data } = response

  if (status === 200 || status === 201) {
    return data.data
  }
  return {}
}
