import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'

const ImageSizeManager = loadable(() => import('./image_size_manager'))
const ImageMultiplyManager = loadable(() => import('./image_multiply_manager'))
const JujoSVGImageSelector = loadable(() => import('./image_selector'))
const ImageRotation = loadable(() => import('./image_rotation'))

function JujoSVGImagePanel({ element_key }) {
  const [image_element, setImageElement] = useState(null)

  const initData = async () => {
    const g_element = document.getElementById(element_key)
    if (g_element === null) return

    const image_el = g_element.querySelector('image')
    if (image_el === null) return

    setImageElement(image_el)
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [element_key])

  return (
    <>
      {image_element === null && <></>}
      {image_element !== null && (
        <div key={element_key} className={classNames('d-flex flex-column')}>
          <div className={classNames('mb-2')}>
            <JujoSVGImageSelector element_key={element_key} />
          </div>
          <div className={classNames('mb-2')}>
            <ImageSizeManager element_key={element_key} />
          </div>
          <div className={classNames('d-flex flex-column flex-md-row')}>
            <div className={classNames('mb-2 col-12 col-md-6')}>
              <ImageRotation element_key={element_key} />
            </div>
            <div className={classNames('mb-2 col-12 col-md-6')}>
              <ImageMultiplyManager element_key={element_key} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default JujoSVGImagePanel
