import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import {
  cloneObj,
  mapStateToProps,
  translate,
} from '../../../../../../../../src/utils'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../../../src/services/apiService'
import { JujoButtonComponent } from '../../../../../../../../src/components/jujo_button'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)

const classNames = require('classnames')

export class PTQEstimateCouponComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }

    this.couponInputRef = React.createRef()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({
      loading: false,
      notification_message: '',
    })
  }

  handleDeleteCouponClicked = async code => {
    const { coupon_data, handleCouponChanged } = this.props

    const cloned_coupon_data = cloneObj(coupon_data)
    delete cloned_coupon_data[code]

    this.setState({ loading: true })
    await handleCouponChanged(cloned_coupon_data)
    this.setState({ loading: false })
  }

  handleApplyCouponClicked = async () => {
    const { coupon_data, handleCouponChanged } = this.props
    this.setState({ loading: true, notification_message: '' })

    const reqObj = this.composeRequest()
    const { parsedEp, data } = reqObj

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      data,
      true
    )

    const { status } = response
    if (status === 200 || status === 201) {
      const server_coupon_data = response.data.data
      const { code } = server_coupon_data

      const cloned_coupon_data = cloneObj(coupon_data)
      cloned_coupon_data[code] = server_coupon_data
      await handleCouponChanged(cloned_coupon_data)
    } else {
      const { message } = response.data
      await this.setState({ notification_message: message })
    }

    this.setState({ loading: false })
  }

  composeRequest = () => {
    const { environment, authentication, customer_data, coupon_data } =
      this.props

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'coupon',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'validate-coupon',
      pDefValue: 'validate-coupon',
    })

    const parsedEp = parseEndpoint(requestData)

    const { current } = this.couponInputRef
    const { value } = current

    return {
      parsedEp,
      data: {
        code: value,
        customer_data,
        coupon_data,
      },
    }
  }

  renderCouponLines = () => {
    const { coupon_data } = this.props
    const coupon_code_list = Object.keys(coupon_data || {})

    const html = []

    for (let i = 0; i !== coupon_code_list.length; i += 1) {
      const code = coupon_code_list[i]

      html.push(
        <div
          key={`${code}_row`}
          className={classNames(
            'd-flex justify-content-md-end justify-content-center align-items-center mt-2'
          )}
        >
          <div className={classNames('fc-1 fw-bold me-unset me-1 fs-6')}>
            {code}
          </div>
          <div
            className={classNames('background-image close-icon theme-svg')}
            style={{ width: 14, height: 14 }}
            role="button"
            tabIndex={0}
            label="delete_coupon"
            onClick={() => {
              this.handleDeleteCouponClicked(code)
            }}
            onKeyPress={() => {
              this.handleDeleteCouponClicked(code)
            }}
          />
        </div>
      )
    }

    return html
  }

  drawDynamicPart = () => {
    const html = []
    html.push(
      <div key="coupon_input_part">
        <div
          className={classNames(
            'd-flex flex-column flex-md-row align-items-center justify-content-between'
          )}
        >
          <div
            className={classNames(
              'p-1 border border-color-4 rounded bg-white fs-7 me-unset me-md-3 d-flex align-items-center'
            )}
          >
            <input ref={this.couponInputRef} type="text" />
          </div>
          <JujoButtonComponent
            bstyle={5}
            bheight="30px"
            bwidth="100px"
            bclasses="bg-1 border-color-1"
            blabel={translate('apply')}
            handleClick={this.handleApplyCouponClicked}
          />
        </div>

        {this.renderCouponLines()}
      </div>
    )

    return html
  }

  drawCoupon = () => {
    const { notification_message } = this.state
    const html = []

    html.push(
      <div
        key="coupon_row"
        className={classNames('border-bottom border-md-unset py-2 py-md-0')}
      >
        <div
          className={classNames('d-flex flex-column justify-content-between')}
        >
          <div className={classNames('fw-bold mb-1 text-center text-md-start')}>
            {translate('discount_code')}
          </div>
          {notification_message !== '' && (
            <div
              className={classNames(
                'd-flex fc-red-500 fs-7 justify-content-center justify-content-md-start'
              )}
            >
              {notification_message}
            </div>
          )}
          {this.drawDynamicPart()}
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && <div className={classNames('')}>{this.drawCoupon()}</div>}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateCouponComponent)
