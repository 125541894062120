const initialState = {
  locale: process.env.locale,
  path: '/',
  screenSize: 'lg',
  permissions: [],
  sideControllerExpanded: true,
  contextVariables: {},
  grids: {},
  tabs: {},
}
export default initialState
