import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import loadable from '@loadable/component'
import { httpGet } from '../../../../../../../src/services/apiService'
import { translate } from '../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../src/components/loading')
)

function SvgPrintingRegistration({ printing_registration_id, onChange }) {
  const [options, setOptions] = useState([])

  const initData = async () => {
    const ep = 'key-value-list/printing-registration'
    const response = await httpGet(`${process.env.apiUrl}${ep}`)

    const { status, data } = response
    if (status === 200 || status === 202) {
      const { data: opts } = data
      setOptions(opts)
    }
  }

  useEffect(() => {
    const execInitData = async () => {
      await initData()
    }

    execInitData()
  }, [printing_registration_id])

  const handlePrintingRegistrationChanged = e => {
    const { value: selected_printing_registration_id } = e.target
    onChange('printing_registration', selected_printing_registration_id)
  }

  return (
    <div className={classNames('')}>
      {options.length === 0 && <JujoLoading />}
      {options.length > 0 && (
        <div className={classNames('bg-white p-2 mb-2 border')}>
          <select
            onChange={e => handlePrintingRegistrationChanged(e)}
            defaultValue={printing_registration_id}
          >
            <option value={0}>{translate('select')}</option>
            {options.map(opt => {
              const { id, name } = opt
              return (
                <option key={`opt_${id}`} value={id}>
                  {name}
                </option>
              )
            })}
          </select>
        </div>
      )}
    </div>
  )
}

export default SvgPrintingRegistration
