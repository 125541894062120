import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../../src/utils'
import JujoLoading from '../../../../../../../src/components/loading'

const classNames = require('classnames')

export class PTQAddressListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  renderRowData = (key, body) => {
    const html = []
    html.push(
      <div key={key}>
        <span className={classNames('fw-bold')}>{`${translate(key)}: `}</span>
        <span>{body}</span>
      </div>
    )
    return html
  }

  renderAddressList = () => {
    const { data } = this.props

    const html = []

    if (data.length > 0) {
      const {
        billing_address_1,
        billing_address_2,
        billing_city,
        billing_company,
        billing_country,
        billing_email,
        billing_first_name,
        billing_last_name,
        billing_phone,
        billing_postcode,
        billing_state,
        shipping_address_1,
        shipping_address_2,
        shipping_city,
        shipping_company,
        shipping_country,
        shipping_first_name,
        shipping_last_name,
        shipping_phone,
        shipping_postcode,
        shipping_state,
        user_id,
      } = data[0]

      html.push(
        <div className={classNames('d-flex flex-column')} key={user_id}>
          <div className={classNames('p-3 shadow-sm rounded-5 bg-3')}>
            <div className={classNames('fc-1 fs-5 fw-bold')}>
              {translate('billing_address')}
            </div>
            <div>
              {this.renderRowData(
                'invoice_holder',
                `${billing_first_name} ${billing_last_name}`
              )}
              {this.renderRowData('company_name', billing_company)}
              {this.renderRowData('phone', billing_phone)}
              {this.renderRowData('email', billing_email)}
              {this.renderRowData(
                'address',
                `${billing_address_1} ${billing_address_2}, ${billing_city} (${billing_postcode}) ${billing_state} - ${billing_country}`
              )}
            </div>
          </div>
          <div className={classNames('p-3 shadow-sm mt-3 rounded-5 bg-3')}>
            <div className={classNames('fc-1 fs-5 fw-bold')}>
              {translate('shipping_address')}
            </div>
            <div>
              {this.renderRowData(
                'name',
                `${shipping_first_name} ${shipping_last_name}`
              )}
              {this.renderRowData('company_name', shipping_company)}
              {this.renderRowData('phone', shipping_phone)}
              {this.renderRowData(
                'address',
                `${shipping_address_1} ${shipping_address_2}, ${shipping_city} (${shipping_postcode}) ${shipping_state} - ${shipping_country}`
              )}
            </div>
          </div>
        </div>
      )
    }

    return html
  }

  render() {
    const { loading } = this.state

    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderAddressList()}</div>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(PTQAddressListComponent)
