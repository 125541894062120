import apiCallMap from '../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../src/services/apiService'

import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../src/services/servicesHelper'
import { cloneObj } from '../../../../../src/utils'

/**
 * It takes a sender object and an ID, and returns a customer's data
 * @param sender - The component that is calling the function.
 * @param ID - The ID of the customer you want to get the data for.
 * @returns The customer data
 */
export async function getComposedCustomerData(sender, ID) {
  const { props } = sender
  const { environment, authentication } = props

  const requestData = baseRequestObject(
    apiCallMap.serverAction,
    'customer',
    apiRequestTypesMap.post,
    environment,
    authentication
  )

  requestData.placeholderMapping.push({
    pSource: 'static',
    pKey: '{action}',
    pValue: 'get-composed-data',
    pDefValue: 'get-composed-data',
  })

  const parsedEp = parseEndpoint(requestData)
  const response = await httpPost(
    `${process.env.apiUrl}${parsedEp}`,
    {
      ID,
    },
    true
  )

  const { data } = response
  return data.data
}

/**
 * It returns the data that is needed to populate the form in edit mode
 * @param sender - The component that is calling the function.
 * @returns The composed customer data for the customer with the ID of the
 * editModeTransitionalDataSource.
 */
export async function getComposedCustomerDataForEdit(sender) {
  const { state } = sender
  const { editModeTransitionalDataSource } = state
  const { ID } = editModeTransitionalDataSource

  return getComposedCustomerData(sender, ID)
}

/**
 * It returns a promise that resolves to an object containing the data needed to render the edit
 * profile page.
 * @param sender - The component that is calling the function.
 * @returns An object with the following properties:
 *   - customer
 *   - customerAddresses
 *   - customerPaymentMethods
 *   - customerShippingMethods
 *   - customerOrders
 *   - customerOrderItems
 *   - customerOrderShippingMethods
 *   - customerOrderPaymentMethods
 *   - customerOrderShippingAddresses
 *   - customerOrderBillingAddresses
 *   - customerOrderShipping
 */
export async function getComposedMyProfileDataForEdit(sender) {
  const { props } = sender
  const { authentication } = props
  const { user } = authentication

  const { ID } = user

  return getComposedCustomerData(sender, ID)
}

export async function copyFromBillingAddress(sender) {
  const { state } = sender
  const { localDataSource } = state

  const updatedData = cloneObj(localDataSource)

  const {
    billing_address_1,
    billing_address_2,
    billing_city,
    billing_company,
    billing_country,
    billing_first_name,
    billing_last_name,
    billing_phone,
    billing_postcode,
    billing_state,
  } = localDataSource
  updatedData.shipping_address_1 = billing_address_1
  updatedData.shipping_address_2 = billing_address_2
  updatedData.shipping_city = billing_city
  updatedData.shipping_company = billing_company
  updatedData.shipping_country = billing_country
  updatedData.shipping_first_name = billing_first_name
  updatedData.shipping_last_name = billing_last_name
  updatedData.shipping_phone = billing_phone
  updatedData.shipping_postcode = billing_postcode
  updatedData.shipping_state = billing_state

  sender.setState({ localDataSource: updatedData })
}

export async function editCustomer(sender, params) {
  const { enterEditMode } = sender
  await sender.setState({ editModeTransitionalDataSource: params })
  const data = await getComposedCustomerDataForEdit(sender)
  enterEditMode(data, false)
}
