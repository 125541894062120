import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'
import * as actionCreators from '../../../store/actions'
import * as apiService from '../../../services/apiService'
import apiCallMap from '../../../enums/apiCallMap'
import customActionTypesMap from '../../../enums/customActionTypesMap'
import apiRequestTypesMap from '../../../enums/apiRequestTypesMap'
import { injectComponent, mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class CustomButton extends React.Component {
  executeAction = async () => {
    const { row, params, gridComponent, authentication, specialization } =
      this.props
    const { action, reloadOnComplete, handleResponse } = params
    const { loadGrid } = gridComponent
    const {
      type,
      specialized,
      actionPath,
      actionName,
      name,
      entity,
      requestType,
      data,
      url,
    } = action
    const { access_token } = authentication

    if (type === customActionTypesMap.interactive_action) {
      const { actions } = specialization
      actions[name](this)
    } else if (type === customActionTypesMap.injectable_action) {
      let injected_actions
      if (specialized) {
        injected_actions =
          await require(`../../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
      } else {
        console.log(
          'this is not working yet as jujo src contains jujo_scripts/prerender.js that require fs and doesnt work'
        )
        // injected_actions = await require(`../../../${actionPath}`)
      }

      injected_actions[actionName](this)
    } else if (type === customActionTypesMap.open_window_action) {
      let parsedUrl = `${url}?`
      for (let i = 0; i < data.length; i += 1) {
        const singleData = data[i]
        const { key, field, defaultField } = singleData || {}
        const parsedField = !field ? defaultField : row[field]
        parsedUrl += `&${key}=${parsedField}`
      }
      parsedUrl += `&tk=${access_token}`
      if (typeof window !== 'undefined') {
        window.open(`${process.env.apiServerUrl}${parsedUrl}`, '_blank')
      }
    } else if (type === customActionTypesMap.open_external_url_action) {
      const parsedUrl = row[url]
      if (typeof window !== 'undefined') {
        window.open(`${parsedUrl}`, '_blank')
      }
    } else {
      const parsedEp = apiCallMap.serverAction
        .replace('{reqType}', requestType)
        .replace('{entity}', entity)
        .replace('{action}', name)

      const parsedData = {}
      for (let i = 0; i !== data.length; i += 1) {
        const dataDefinition = data[i]
        const { key, value } = dataDefinition
        parsedData[key] = row[value]
      }
      let result = {}
      if (requestType === apiRequestTypesMap.get) {
        result = await apiService.httpGet(`${process.env.apiUrl}${parsedEp}`)
      } else if (requestType === apiRequestTypesMap.post) {
        result = await apiService.httpPost(
          `${process.env.apiUrl}${parsedEp}`,
          parsedData
        )
      } else if (requestType === apiRequestTypesMap.put) {
        result = await apiService.httpUpdate(
          `${process.env.apiUrl}${parsedEp}`,
          parsedData
        )
      }

      if (handleResponse) {
        await this.execHandleResponse(result)
      } else {
        const { status } = result
        if (status === 200 || status === 201) {
          if (reloadOnComplete) {
            loadGrid()
          }
        }
      }
    }
  }

  execHandleResponse = async response => {
    const { params } = this.props
    const { handleResponse } = params

    const { specialized, actionPath, actionName } = handleResponse

    if (specialized !== undefined && specialized === false) {
      const core_actions = await require(`../${actionPath}`)
      core_actions[actionName](this, response)
    } else {
      const specialized_actions =
        await require(`../../../../jujo_specializations/src/${process.env.client}/${actionPath}`)
      specialized_actions[actionName](this, response)
    }
  }

  handleClick = async () => {
    const { params, gridComponent } = this.props
    const { reloadOnClick, confirmation } = params
    const { loadGrid } = gridComponent
    if (confirmation === undefined) {
      this.executeAction()
    } else {
      const { message } = confirmation

      const comp_id = uuid()
      const component_definition = {
        specialized: false,
        path: 'common/confirmation_box',
        data: {
          message,
          executeAction: this.executeAction,
        },
      }
      injectComponent(this, comp_id, component_definition)
    }

    if (reloadOnClick) {
      loadGrid()
    }
  }

  render() {
    const { params, row } = this.props
    const { icon } = params

    let btnIcon = ''
    if (typeof icon === 'string' || icon instanceof String) {
      btnIcon = icon
    } else {
      const { fieldBasedCondition } = icon
      const { key, values } = fieldBasedCondition
      btnIcon = values[row[key]]
    }

    const { action } = params || {}
    const { status_conditions } = action || false
    let condition_checked = false
    if (status_conditions) {
      for (let i = 0; i < status_conditions.length; i += 1) {
        const condition = status_conditions[i]
        const relateFieldValue = row[condition.relatedField]
        let checked = condition.check.includes(relateFieldValue)
        checked = condition.checkType === 'in' ? checked : !checked
        if (checked === true) {
          condition_checked = condition
        }
      }
    }

    let { action_class } = condition_checked || 'theme-svg'
    action_class = action_class === undefined ? 'theme-svg' : action_class
    let { action_enabled } = condition_checked || true
    action_enabled = action_enabled === undefined ? true : action_enabled
    return (
      <div
        className={classNames('d-flex ', btnIcon, action_class)}
        style={{
          cursor: action_enabled ? 'pointer' : 'default',
          width: '20px',
          height: '20px',
          backgroundSize: '20px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        role="button"
        tabIndex={0}
        onClick={action_enabled ? this.handleClick : null}
        onKeyPress={action_enabled ? this.handleClick : null}
      >
        &nbsp;
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(CustomButton)
