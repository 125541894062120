/* eslint-disable react/no-unused-state */
import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../store/actions'
import { cloneObj, mapStateToProps, translate } from '../../../utils'
import { mqDesktop, mqMobileOrTablet } from '../../../utils/mediaquery'

const JujoLoading = loadable(() => import('../../loading'))
const SVGSwitcher = loadable(() => import('./_parts/switcher/switcher'))
const JujoSVGEditableName = loadable(() => import('./_parts/editable_name'))
const JujoSvgMobileNotification = loadable(() =>
  import('./_parts/mobile_editor/m_notification')
)

const JujoSVGQuickActions = loadable(() =>
  import('./_parts/quick_actions/quick_actions')
)

const JujoSVGGlobalActions = loadable(() =>
  import('./_parts/global_actions/global_actions')
)

const JujoSVGLeftPanel = loadable(() => import('./_parts/left_panel'))

const JujoSVGPreviewComponent = loadable(() => import('./_parts/svg_preview'))

const JujoButtonComponent = loadable(() => import('../../jujo_button'))

const JujoSvgMobileEditor = loadable(() =>
  import('./_parts/mobile_editor/m_editor')
)

export class JujoSVGEditorComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      parsed_data: {},
      focused_key: null,
      curr_nidx: 0,
      curr_side: 'front',
      template_name: '',
      isMobileOrTablet: mqMobileOrTablet(),
      isDesktop: mqDesktop(),
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount = async () => {
    const { curr_side, isMobileOrTablet } = this.state
    const parsed_data = await this.doInitialDataParsing()
    const { svg_name } = parsed_data[curr_side]

    window.addEventListener('resize', this.handleResize)

    setTimeout(() => {
      if (isMobileOrTablet) {
        this.enterFullscreen()
      }
    }, 1)

    this.setState({
      template_name: svg_name,
      parsed_data,
      loading: false,
    })
  }

  enterFullscreen = () => {
    const element = document.documentElement
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }

  handleResize = () => {
    const isMobileOrTablet = mqMobileOrTablet()
    const isDesktop = mqDesktop()

    this.setState({ isMobileOrTablet, isDesktop })
  }

  doInitialDataParsing = async () => {
    const template_data = await this.getSvgData()

    const default_data = this.extractDefaultData(template_data)
    const specialized_data = this.extractSpecializedData(template_data)

    const parsed_data = { ...default_data, ...specialized_data }

    return parsed_data
  }

  // eslint-disable-next-line no-unused-vars
  extractSpecializedData = _data => ({})

  extractDefaultData = data => {
    if (Object.keys(data).length > 0) {
      const { name, definition } = data

      const { front: f_def, back: b_def, default_font } = definition

      const front = {
        svg_name: name,
        svg_id: f_def.svg_id,
        svg_code: new TextDecoder('utf-8').decode(
          Buffer.from(f_def.svg, 'base64')
        ),
        svg_size: f_def.svg_size,
      }

      const back =
        b_def === null
          ? null
          : {
              svg_name: name,
              svg_id: b_def.svg_id,
              svg_code: new TextDecoder('utf-8').decode(
                Buffer.from(b_def.svg, 'base64')
              ),
              svg_size: b_def.svg_size,
            }

      return {
        front,
        back,
        default_font,
      }
    }
    return {}
  }

  getSvgData = async () => {
    console.log(
      'This is a core function. You should never use this function directly. Create your specialized class and extend JujoSVGEditorComponent, then use this function in your specialization. Thank you!'
    )
  }

  handleSave = async () => {
    console.log(
      'This functionality has not been implemented directly in the core. It is usually specialized into client code. However, if desired, you can define a default functionality here!'
    )
  }

  updateName = name => {
    this.setState({ template_name: name })
  }

  setFocusedElement = key => {
    this.setState({ focused_key: key })
  }

  handleSelectedNodeChange = idx => {
    this.setState({ curr_nidx: idx })
  }

  rightPanel = () => []

  renderLogo = () => []

  updateCurrentSideParsedData = async () => {
    const { curr_side, parsed_data } = this.state

    const cloned_parsed_data = cloneObj(parsed_data)

    const { svg_id } = cloned_parsed_data[curr_side]
    const { outerHTML } = document.getElementById(svg_id)
    cloned_parsed_data[curr_side].svg_code = outerHTML

    this.setState({ parsed_data: cloned_parsed_data })
  }

  changeSide = side => {
    this.updateCurrentSideParsedData()
    this.setFocusedElement(null)
    this.setState({ curr_side: side })
  }

  renderTitle = () => {
    const { template_name } = this.state
    const html = []
    html.push(
      <JujoSVGEditableName
        key="editable_title"
        name={template_name}
        updateName={this.updateName}
      />
    )
    return html
  }

  render() {
    const { session } = this.props
    const { session_data } = session
    const { jujo_fonts } = session_data

    const {
      loading,
      parsed_data,
      focused_key,
      curr_nidx,
      curr_side,
      isDesktop,
      isMobileOrTablet,
    } = this.state

    const svg_code = parsed_data[curr_side]
      ? parsed_data[curr_side].svg_code
      : ''
    const svg_id = parsed_data[curr_side] ? parsed_data[curr_side].svg_id : ''

    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <>
            {isDesktop && (
              <>
                <div
                  className={classNames(
                    'd-flex flex-column flex-md-row align-items-center bg-white mb-2 mb-md-4 py-2 px-2 px-md-4 shadow-sm'
                  )}
                >
                  <div
                    className={classNames(
                      'col-12 col-md-3 d-flex justify-content-center justify-content-md-start'
                    )}
                  >
                    {this.renderLogo()}
                  </div>
                  <div
                    className={classNames('col-12 col-md-6 fs-5 text-center')}
                  >
                    <div>{`${translate('customizing_template')}:`}</div>
                    {this.renderTitle()}
                  </div>
                  <div
                    className={classNames(
                      'col-12 col-md-3 d-flex justify-content-center justify-content-md-end mt-2 mt-md-0'
                    )}
                  >
                    <JujoButtonComponent
                      bstyle={0}
                      bclasses="bg-white border fs-7 d-flex align-items-center justify-content-center"
                      bicon="save-icon"
                      bheight="45px"
                      bwidth="100px"
                      biconHeight="18px"
                      biconWidth="18px"
                      blabel={translate('save')}
                      bdata={{ svg_id }}
                      handleClick={this.handleSave}
                    />
                  </div>
                </div>

                <div
                  className={classNames('d-flex flex-column flex-md-row')}
                  key={`editor_${svg_id}`}
                >
                  <div className={classNames('')}>
                    <div
                      className={classNames(
                        'sticky-md-top w-280px w-md-450px bg-white shadow-sm p-2 mb-3'
                      )}
                    >
                      <JujoSVGQuickActions
                        svg_id={svg_id}
                        element_key={focused_key}
                        default_font={parsed_data.default_font}
                        setFocusedElement={this.setFocusedElement}
                      />
                    </div>
                    {focused_key && (
                      <div
                        className={classNames(
                          'sticky-md-top w-280px w-md-450px bg-white shadow-sm p-4 mb-3'
                        )}
                      >
                        <JujoSVGLeftPanel
                          element_key={focused_key}
                          curr_nidx={curr_nidx}
                          fonts={jujo_fonts}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classNames('w-100 mx-md-2')}>
                    {parsed_data.back && (
                      <SVGSwitcher
                        changeSide={this.changeSide}
                        curr_side={curr_side}
                      />
                    )}
                    <div id="preview">
                      <JujoSVGPreviewComponent
                        svg_id={svg_id}
                        svg_code={svg_code}
                        setFocusedElement={this.setFocusedElement}
                        handleSelectedNodeChange={this.handleSelectedNodeChange}
                      />
                    </div>
                    <div id="svg_parsing_container" />
                  </div>
                  <div className={classNames('')}>
                    <div
                      className={classNames(
                        'sticky-md-top w-280px w-md-320px bg-iceblue-300 shadow-sm p-2 mb-3'
                      )}
                    >
                      <JujoSVGGlobalActions svg_id={svg_id} />
                    </div>
                    <div
                      className={classNames('w-280px w-md-320px sticky-md-top')}
                    >
                      {this.rightPanel()}
                    </div>
                  </div>
                </div>
              </>
            )}
            {isMobileOrTablet && (
              <>
                <JujoSvgMobileNotification />

                <div key={`mobile_editor_${svg_id}`}>
                  {parsed_data.back && (
                    <SVGSwitcher
                      changeSide={this.changeSide}
                      curr_side={curr_side}
                    />
                  )}
                  <div id="preview">
                    <JujoSVGPreviewComponent
                      svg_id={svg_id}
                      svg_code={svg_code}
                      setFocusedElement={this.setFocusedElement}
                      handleSelectedNodeChange={this.handleSelectedNodeChange}
                    />
                  </div>
                  <div id="svg_parsing_container" />
                  <JujoSvgMobileEditor
                    svg_id={svg_id}
                    element_key={focused_key}
                    default_font={parsed_data.default_font}
                    setFocusedElement={this.setFocusedElement}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(JujoSVGEditorComponent)
