/* eslint-disable react/no-unused-state */
import React from 'react'
import loadable from '@loadable/component'

import { connect } from 'react-redux'
import * as actionCreators from '../../../../../../../../src/store/actions'
import { mapStateToProps, translate } from '../../../../../../../../src/utils'

const JujoLoading = loadable(() =>
  import('../../../../../../../../src/components/loading')
)
const ECNumberFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/numberField')
)
const ECTextFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/textField')
)
const ECTextAreaFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/textAreaField')
)
const ECDroppableFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/droppableField')
)
const ECDataListFieldComponent = loadable(() =>
  import('../../../../../../../../src/components/common/fields/dataListField')
)

const classNames = require('classnames')

export class PTQEstimateCustomProductBlockComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      product_name: '',
      text_form_id: 0,
      price: 0,
      qty: 1,
      attachments: [],
      notes: '',
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { initialValue } = this.props
    const { custom_product, quantity, notes } = initialValue
    const { product_name, text_form_id, price, attachments } =
      custom_product || {}

    await this.setState({
      product_name: product_name || '',
      price: price || 0,
      qty: quantity || 1,
      text_form_id: text_form_id || 0,
      notes: notes || '',
      attachments: attachments || [],
      loading: false,
    })
  }

  updateCustomProductData = async () => {
    const { handleCustomProductChanged } = this.props
    const { product_name, price, attachments, text_form_id } = this.state
    const product_data = {
      product_name,
      price,
      attachments,
      text_form_id,
    }

    await handleCustomProductChanged('custom_product', product_data)
  }

  handleValueChanged = async (fieldName, value) => {
    await this.setState({ [fieldName]: value })
    this.updateCustomProductData()
  }

  renderProductDetails = () => {
    const {
      handleQuantityChanged,
      handleNotesChanged,
      user_permissions,
      authentication,
    } = this.props

    const { user } = authentication

    const { product_name, price, attachments, text_form_id, qty, notes } =
      this.state

    const { can_modify_price, can_assing_text_form } = user_permissions

    const html = []

    html.push(
      <div key="custom_product_detaild" className={classNames('row g-3')}>
        <div className={classNames('col-12')}>
          <div className="ffamily-secondary fs-9 fc-gray">
            {`* ${translate('name')}`}
          </div>
          <div
            className={classNames(
              'p-1 border border-color-4 rounded bg-white fs-7'
            )}
          >
            <ECTextFieldComponent
              field={{ name: 'product_name' }}
              initialValue={product_name}
              handleValueChanged={this.handleValueChanged}
            />
          </div>
        </div>
        <div className={classNames('col-12 col-md-6')}>
          <div className="ffamily-secondary fs-9 fc-gray">
            {`* ${translate('unit_price')}`}
          </div>
          <div
            className={classNames(
              'p-1 border border-color-4 rounded fs-7',
              can_modify_price ? 'bg-white' : 'bg-light-gray'
            )}
          >
            {can_modify_price && (
              <ECNumberFieldComponent
                field={{ name: 'price', min: 0, step: 0.01 }}
                initialValue={price}
                handleValueChanged={this.handleValueChanged}
              />
            )}
            {!can_modify_price && <div>{price}</div>}
          </div>
        </div>
        <div className={classNames('col-12 col-md-6')}>
          <div className="ffamily-secondary fs-9 fc-gray">
            {`* ${translate('quantity')}`}
          </div>
          <div
            className={classNames(
              'p-1 border border-color-4 rounded bg-white fs-7'
            )}
          >
            <ECNumberFieldComponent
              field={{
                name: 'product_qty',
                min: 1,
                step: 1,
              }}
              initialValue={qty}
              handleValueChanged={handleQuantityChanged}
            />
          </div>
        </div>
        {can_assing_text_form && (
          <div className={classNames('col-12')}>
            <div className="ffamily-secondary fs-9 fc-gray">
              {translate('text_form')}
            </div>
            <div
              className={classNames(
                'p-1 border border-color-4 rounded bg-white fs-7'
              )}
            >
              <ECDataListFieldComponent
                field={{
                  name: 'text_form_id',
                  alias: 'text_form',
                  fieldType: 'datalistField',
                  renderField: 'title',
                  valueField: 'id',
                  defValue: 0,
                  apis: {
                    getData: {
                      apiCall: 'getAllKeyValue',
                      requestType: 'get',
                      relatedEntity: 'textForm',
                      defaultFilters: [
                        {
                          fEntity: [],
                          fSource: 'environment',
                          fValue: 'locale={locale}',
                          fDefValue: 'it',
                        },
                      ],
                    },
                  },
                }}
                handleValueChanged={this.handleValueChanged}
                initialValue={text_form_id}
              />
            </div>
          </div>
        )}
        <div className={classNames('col-12')}>
          <div className="ffamily-secondary fs-9 fc-gray">
            {translate('attachments')}
          </div>
          <ECDroppableFieldComponent
            field={{
              name: 'attachments',
              fieldType: 'droppableField',
              customWidth: 'col-12',
              custom_drag_drop_instruction: 'drop_the_file_here',
              acceptedTypes: 'image/png, image/jpeg',
              maxFileSize: '3MB',
              maxFileCount: 0,
              relatedEntity: 'estimate',
              visibility: 'private',
              fileOwner: {
                source: 'static',
                relatedField: '',
                defValue: user.ID,
              },
            }}
            initialValue={attachments}
            handleValueChanged={this.handleValueChanged}
          />
        </div>
        <div className={classNames('col-12')}>
          <div className="ffamily-secondary fs-9 fc-gray">
            {translate('notes')}
          </div>
          <div
            className={classNames(
              'p-1 border border-color-4 rounded bg-white fs-7'
            )}
          >
            <ECTextAreaFieldComponent
              field={{ name: 'product_notes' }}
              initialValue={notes}
              handleValueChanged={handleNotesChanged}
            />
          </div>
        </div>
      </div>
    )
    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading && <JujoLoading />}
        {!loading && (
          <div className={classNames('')}>{this.renderProductDetails()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PTQEstimateCustomProductBlockComponent)
