import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

const classNames = require('classnames')

export class ViewButton extends React.Component {
  handleViewClicked = () => {
    const { handleView, row } = this.props
    handleView(row)
  }

  render() {
    return (
      <div
        className={classNames('eye-icon theme-svg')}
        style={{
          backgroundSize: '20px',
          height: '20px',
          width: '20px',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
        label="edit"
        role="button"
        tabIndex={0}
        onClick={this.handleViewClicked}
        onKeyPress={this.handleViewClicked}
      />
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ViewButton)
