import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import { mapStateToProps } from '../../../utils'

export class ECAcceptanceFieldComponent extends React.Component {
  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  handleClicked = () => {
    const { field, handleValueChanged, dataSource } = this.props
    const { name } = field

    const current_value = dataSource[name] || false
    handleValueChanged(name, !current_value)
  }

  render() {
    const { field, dataSource } = this.props
    const { name, compPath } = field

    const RenderComponent =
      require(`../../../../jujo_specializations/src/${process.env.client}/${compPath}`).default

    const selected = dataSource[name] || false
    return (
      <div
        key={name}
        className={classNames('d-flex align-items-top my-2 fs-9 fst-italic')}
        role="button"
        tabIndex={0}
        onClick={() => {
          this.handleClicked()
        }}
        onKeyPress={() => {
          this.handleClicked()
        }}
      >
        <div
          className={classNames('bg-white border rounded-percentage-100 me-2')}
          style={{ width: '14px', height: '14px', marginTop: '2px' }}
        >
          <div
            className={classNames(
              'rounded-percentage-100',
              selected ? 'bg-1' : 'bg-white'
            )}
            style={{ width: '12px', height: '12px' }}
          />
        </div>
        <div>
          <RenderComponent />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(ECAcceptanceFieldComponent)
