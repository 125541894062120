import React from 'react'
import { connect } from 'react-redux'

import loadable from '@loadable/component'
import classNames from 'classnames'

import * as actionCreators from '../../../../../store/actions'
import { mapStateToProps, translate } from '../../../../../utils'

const JujoLoading = loadable(() => import('../../../../loading'))

export class JujoSVGTextAreaManagerComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      rect_obj: null,
    }
  }

  componentWillUnmount() {
    // Fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {}
  }

  componentDidMount = async () => {
    const { element_key } = this.props

    let rect_obj = null
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      rect_obj = document
        .getElementById(element_key)
        .getElementsByTagName('rect')[0]
    }
    this.setState({ rect_obj, loading: false })
  }

  updateRectProp = async (prop, value) => {
    const { rect_obj } = this.state
    rect_obj.setAttribute(prop, value)
  }

  drawElement = () => {
    const { rect_obj } = this.state
    const { element_key } = this.props

    const html = []

    html.push(
      <div key={`${element_key}_text_area_manager`}>
        <div>{translate('area_management')}</div>
        <div
          onFocus={() => {
            rect_obj.setAttribute('fill', '#d7d6d6')
          }}
          onBlur={() => {
            rect_obj.setAttribute('fill', 'none')
          }}
        >
          <div className={classNames('row g-2 g-md-3')}>
            <div className={classNames('col-12 col-md-6')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('x')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={rect_obj.getAttribute('x')}
                  onChange={e => {
                    this.updateRectProp('x', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={classNames('col-12 col-md-6')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('y')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={rect_obj.getAttribute('y')}
                  onChange={e => {
                    this.updateRectProp('y', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={classNames('col-12 col-md-6')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('width')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={rect_obj.getAttribute('width')}
                  onChange={e => {
                    this.updateRectProp('width', e.target.value)
                  }}
                />
              </div>
            </div>
            <div className={classNames('col-12 col-md-6')}>
              <div className={classNames('ffamily-secondary ps-2 fc-gray-500')}>
                {translate('height')}
              </div>
              <div className={classNames('bg-white px-2 py-1 rounded-5')}>
                <input
                  type="number"
                  defaultValue={rect_obj.getAttribute('height')}
                  onChange={e => {
                    this.updateRectProp('height', e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )

    return html
  }

  render() {
    const { loading } = this.state
    return (
      <>
        {loading === true && <JujoLoading />}
        {loading === false && (
          <div className={classNames('')}>{this.drawElement()}</div>
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(JujoSVGTextAreaManagerComponent)
