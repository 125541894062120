import React from 'react'
import { connect } from 'react-redux'

import classNames from 'classnames'
import loadable from '@loadable/component'

import uuid from 'react-uuid'
import * as actionCreators from '../../../../../../src/store/actions'
import {
  cloneObj,
  defaultManageResponse,
  injectComponent,
  mapStateToProps,
  retrieveComponent,
  translate,
} from '../../../../../../src/utils'
import { JujoSVGEditorComponent } from '../../../../../../src/components/svg_editor/editor'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import { httpPost } from '../../../../../../src/services/apiService'
import {
  generateCutSvgCode,
  generateRasterSvgCode,
  getEditableSvgCode,
} from '../../../actions/modeling/templates'

const SvgImposeModel = loadable(() => import('./_parts/impose_model'))
const SvgPrintingRegistration = loadable(() =>
  import('./_parts/printing_registration')
)
const JujoSVGPaperSelector = loadable(() =>
  import(
    '../../../../../../src/components/svg_editor/editor/_parts/paper/paper_selector'
  )
)
const JujoSVGDefaultFontManager = loadable(() =>
  import(
    '../../../../../../src/components/svg_editor/editor/_parts/text/default_font_manager'
  )
)

export class PtqSvgCustomEditorComponent extends JujoSVGEditorComponent {
  getSvgData = async () => {
    const { authentication, environment, transitionaData } = this.props
    const { template_id } = transitionaData

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'svg-template',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'data',
      pDefValue: 'data',
    })

    const parsedEp = parseEndpoint(requestData)

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      { template_id },
      true
    )

    const { status, data } = response
    let template_data = {}
    if (status === 200) {
      template_data = data.data
    }

    return template_data
  }

  extractSpecializedData = _data => {
    if (Object.keys(_data).length > 0) {
      const { definition } = _data

      const { impose_model, printing_registration } = definition

      return {
        impose_model,
        printing_registration,
      }
    }
    return {}
  }

  handleOnSuccessCallback = async params => {
    const { injections } = this.props
    const { closeEditPanel } = injections

    const { msbox_instance_id } = params
    retrieveComponent(this, msbox_instance_id)
    closeEditPanel()
  }

  handleSave = async () => {
    const saving_message_comp_id = uuid()
    const saving_message_comp_def = {
      specialized: false,
      path: 'svg_editor/editor/_parts/saving_message',
      data: {
        sender: this,
      },
    }

    injectComponent(this, saving_message_comp_id, saving_message_comp_def)

    await this.updateCurrentSideParsedData()

    const { environment, authentication, transitionaData } = this.props
    const { template_name, parsed_data } = this.state

    const { template_id } = transitionaData
    const { impose_model, printing_registration, default_font } = parsed_data

    const editable_code = await getEditableSvgCode(this)
    const cut_code = await generateCutSvgCode(this)
    const raster_code = await generateRasterSvgCode(this)

    const requestData = baseRequestObject(
      apiCallMap.serverAction,
      'svg-template',
      apiRequestTypesMap.post,
      environment,
      authentication
    )

    requestData.placeholderMapping.push({
      pSource: 'static',
      pKey: '{action}',
      pValue: 'update',
      pDefValue: 'update',
    })

    const parsedEp = parseEndpoint(requestData)

    const response = await httpPost(
      `${process.env.apiUrl}${parsedEp}`,
      {
        template_name,
        template_id,
        impose_model,
        printing_registration,
        default_font,
        editable_code,
        cut_code,
        raster_code,
      },
      true
    )

    retrieveComponent(this, saving_message_comp_id)

    const customizations = {
      on_success_callback: this.handleOnSuccessCallback,
      success_message: translate('svg_template_successfully_updated_message'),
    }

    defaultManageResponse(this, customizations, response)
  }

  updateParsedData = (key, value) => {
    const { parsed_data } = this.state
    const updated_parsed_data = cloneObj(parsed_data)
    updated_parsed_data[key] = value
    this.setState({ parsed_data: updated_parsed_data })
  }

  renderLogo = () => {
    const html = []

    html.push(
      <div
        key="logo"
        className={classNames('background-image logo')}
        style={{ width: 180, height: 40 }}
      />
    )

    return html
  }

  rightPanel = () => {
    const { parsed_data, curr_side } = this.state
    const { impose_model, printing_registration, default_font } = parsed_data
    const { svg_id } = parsed_data[curr_side]

    const html = []

    html.push(
      <div key="ptq_right_panel">
        <div className={classNames('bg-white shadow-sm p-4 mb-3')}>
          <div className={classNames('mb-3')}>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('impose_model')}</div>
            </div>
            <SvgImposeModel
              impose_model_id={impose_model}
              onChange={this.updateParsedData}
            />
          </div>
          <div>
            <div
              className={classNames('fw-bold ffamily-secondary fst-uppercase')}
            >
              <div>{translate('printing_registration')}</div>
            </div>
            <SvgPrintingRegistration
              printing_registration_id={printing_registration}
              onChange={this.updateParsedData}
            />
          </div>
          <div>
            <JujoSVGPaperSelector svg_id={svg_id} />
          </div>
          <div>
            <JujoSVGDefaultFontManager
              svg_id={svg_id}
              default_font={default_font}
              onChange={this.updateParsedData}
            />
          </div>
        </div>
      </div>
    )

    return html
  }
}

export default connect(
  mapStateToProps,
  actionCreators
)(PtqSvgCustomEditorComponent)
