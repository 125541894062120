import React, { useRef, useState, useEffect } from 'react'

import classNames from 'classnames'
import DropdownTreeSelect from 'react-tree-selector'
import loadable from '@loadable/component'
import { translate } from '../../../../../../src/utils'
import { httpGet, httpPost } from '../../../../../../src/services/apiService'
import '../../../../../../src/assets/css/jujo/dropdown_tree_select.scss'

const JujoLoading = loadable(() =>
  import('../../../../../../src/components/loading')
)

function SampleConfiguration() {
  const dropdownRef = useRef()
  const [notification, setNotification] = useState('')
  const [selected_ids_loaded, setSelectedIDsLoaded] = useState(false)
  const [selected_ids, setSelectedIDs] = useState([])
  const [data, setData] = useState([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const ep = 'action/post/shop-configuration/get-sample-products-list'
      const response = await httpPost(`${process.env.apiUrl}${ep}`, {}, true)

      const { status } = response
      if (status === 200) {
        const parsed_response = response.data.data.map(id_string =>
          parseInt(id_string, 10)
        )

        setSelectedIDs(parsed_response)
        setSelectedIDsLoaded(true)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      const ep = 'key-value-list/product'
      const response = await httpGet(`${process.env.apiUrl}${ep}`)

      const { status } = response
      if (status === 200) {
        const parsed_data = response.data.data.map(
          ({ ID: value, post_title: label, ...rest }) => {
            const checked = selected_ids.includes(value)
            return {
              value,
              label,
              checked,
              ...rest,
            }
          }
        )

        setData(parsed_data)
      }
    }
    fetchData()
  }, [selected_ids_loaded])

  async function saveChanges() {
    setSaving(true)
    const selected = dropdownRef.current.state.tags
    const ids = selected.map(({ value }) => value)

    const ep = 'action/post/shop-configuration/set-sample-products-list'
    const response = await httpPost(
      `${process.env.apiUrl}${ep}`,
      {
        id_list: ids,
      },
      true
    )

    setNotification(response.data.message)
    setSaving(false)
  }

  function handleSelectionChanged(currentNode, selectedNodes) {
    const updated_data = data.map(obj => ({
      ...obj,
      checked: selectedNodes.some(node => node.value === obj.value),
    }))
    setData(updated_data)
  }

  return (
    <div className={classNames('p-2')}>
      {data.length === 0 && <JujoLoading />}
      {data.length > 0 && (
        <div>
          <div>{translate('sample_config_instructions')}</div>
          <DropdownTreeSelect
            ref={dropdownRef}
            data={data}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleSelectionChanged}
            texts={{ placeholder: translate('search') }}
            className="jujo-dropdown-tree-select p-4 my-3 rounded-2 shadow-sm"
          />

          <div className={classNames('ms-auto')} style={{ width: 200 }}>
            {!saving && (
              <div
                className={classNames(
                  'bg-1 fc-white p-1 rounded-2 text-center'
                )}
                role="button"
                tabIndex={0}
                onClick={() => {
                  saveChanges()
                }}
                onKeyPress={() => {
                  saveChanges()
                }}
              >
                {translate('save')}
              </div>
            )}
            {saving && <JujoLoading />}
          </div>

          {notification.length > 0 && (
            <div
              className={classNames(
                'd-flex mt-2 p-2 rounded-2 align-items-center justify-content-between bg-gray-100 shadow'
              )}
              role="button"
              tabIndex={0}
              onClick={() => {
                setNotification('')
              }}
              onKeyPress={() => {
                setNotification('')
              }}
            >
              <div>{notification}</div>
              <div
                className={classNames('background-image theme-svg close-icon')}
                style={{ width: 16, height: 16 }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SampleConfiguration
