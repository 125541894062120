import fileTypesMap from '../../../../enums/fileTypesMap'

export function getFileTypeClass(ext) {
  let typeClass = ''
  switch (ext) {
    case fileTypesMap.pdf:
      typeClass = 'pdf-icon'
      break

    case fileTypesMap.txt:
      typeClass = 'txt-icon'
      break

    case fileTypesMap.csv:
      typeClass = 'csv-icon'
      break

    case fileTypesMap.xml:
      typeClass = 'xml-icon'
      break

    default:
      typeClass = 'image-icon'
  }
  return typeClass
}

export function setDefaultIfInitialValueIsEmpty(sender) {
  const { initialValue, field, handleValueChanged } = sender.props
  const { name, defValue } = field

  if (initialValue === undefined && defValue !== undefined) {
    // I use the setTimeout to create the thread and allow the processor
    // to manage parallel access concurrency on the data managed
    // by handleValueChanged function. Without this method, all fields would
    // try to update the centralized data at the same time creating conflicts
    setTimeout(() => {
      handleValueChanged(name, defValue)
    })
  }
}
