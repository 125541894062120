import uuid from 'react-uuid'
import apiCallMap from '../../../../../../src/enums/apiCallMap'
import apiRequestTypesMap from '../../../../../../src/enums/apiRequestTypesMap'
import svgParsingStepsMap from '../../../../../../src/enums/svgParsingStepsMap'
import { httpPost } from '../../../../../../src/services/apiService'
import {
  baseRequestObject,
  parseEndpoint,
} from '../../../../../../src/services/servicesHelper'
import {
  defaultManageResponse,
  injectComponent,
  retrieveComponent,
  translate,
} from '../../../../../../src/utils'
import { rasterize } from '../../../../../../src/utils/svg'

export async function createNew(sender) {
  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'modeling_group/template_manager',
    data: {
      sender,
    },
  }

  injectComponent(sender, comp_id, component_definition)
}

/**
 * It takes a font family name and a source URL, and returns a string of HTML that will load the font
 * @param newFamilyName - The new name of the font family.
 * @param src - The url of the font file.
 * @returns A string of HTML that will create a new font-family.
 */
export function createStyle(newFamilyName, src) {
  return `<style id="${newFamilyName}">
  @font-face {
    font-family:'${newFamilyName}';
    src: url('${src}');
  }</style>`
}

/**
 * It injects a default font into the SVG of a card definition
 * @param definition - The definition object that is passed to the template.
 * @returns A function that takes in a definition and returns a new definition with the default font
 * injected into the SVG.
 */
export function injectDefaultFont(definition) {
  if (definition.default_font === null || definition.default_font === undefined)
    return
  const { default_font: font } = definition
  // eslint-disable-next-line no-restricted-syntax
  for (const side of ['front', 'back']) {
    if (definition[side] !== null) {
      const { svg } = definition[side]
      const newFamilyName = `font_${font.id}`
      if (svg.indexOf(`id="${newFamilyName}"`) === -1) {
        const style = createStyle(newFamilyName, font.src)

        // eslint-disable-next-line no-param-reassign
        definition[side].svg = svg.replace('</svg>', `${style}</svg>`)
      }
    }
  }
}

/**
 * It takes a definition object and if it has a families_mapping property, it will loop through each
 * key in the families_mapping object and replace the font family name with a new font family name
 * @param definition - The definition object that contains the SVG data.
 */
export function remapFontFamilies(definition) {
  const { families_mapping } = definition
  if (families_mapping) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in families_mapping) {
      const mappedIds = []

      if (families_mapping[key].length > 0) {
        if (definition[key] && definition[key] != null) {
          let { svg } = definition[key]
          if (svg) {
            for (let i = 0; i < families_mapping[key].length; i += 1) {
              const { family, mapped_id } = families_mapping[key][i]
              const newFamilyName = `font_${mapped_id}`

              if (mappedIds.indexOf(mapped_id) === -1) {
                const { src } = families_mapping[key][i]
                const style = createStyle(newFamilyName, src)
                svg = svg.replace('</svg>', `${style}</svg>`)
                mappedIds.push(mapped_id)
              }
              svg = svg.replace(new RegExp(family, 'g'), newFamilyName)
            }
            // eslint-disable-next-line no-param-reassign
            definition[key].svg = svg
          }
        }
      }
    }
  }
}

export async function createTemplate(props, definition) {
  const ep = 'action/post/svg-template/save'
  const response = await httpPost(
    `${process.env.apiUrl}${ep}`,
    { definition },
    true
  )

  const { status, data } = response
  if (status === 200 || status === 202) {
    const { template_id } = data.data
    const { updateFlowData, updateFlowState } = props
    await updateFlowData({ template_id })
    updateFlowState(svgParsingStepsMap.editing)
  }
}

export async function edit(sender) {
  const { props } = sender
  const { row } = props
  const { id } = row

  const comp_id = uuid()
  const component_definition = {
    specialized: true,
    path: 'modeling_group/template_manager',
    data: {
      sender,
      initial_state: svgParsingStepsMap.editing,
      initial_data: { template_id: id },
    },
  }

  injectComponent(sender, comp_id, component_definition)
}

export async function cleanUpCode(data) {
  if (data === null) return null
  const { svg_code } = data

  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(svg_code, 'image/svg+xml')
  const svg = svgDoc.querySelector('svg')

  const rect = svg.querySelector('#rect_border')
  if (rect) {
    rect.parentNode.removeChild(rect)
  }

  const { outerHTML } = svg
  return outerHTML
}

export async function getEditableSvgCode(sender) {
  if (typeof window !== 'undefined') {
    const { state } = sender
    const { parsed_data } = state

    const { front, back } = parsed_data
    return {
      front_code: await cleanUpCode(front),
      back_code: await cleanUpCode(back),
    }
  }
  return ''
}

export async function getCutCode(data) {
  if (data === null) return ''
  const { svg_code, svg_size } = data

  const { mm } = svg_size
  const { width, height } = mm

  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(svg_code, 'image/svg+xml')
  const svg = svgDoc.querySelector('svg')

  svg.setAttribute('width', `${width}mm`)
  svg.setAttribute('height', `${height}mm`)

  const group_list = svg.querySelector('g').getElementsByTagName('g')
  for (let i = group_list.length; i >= 0; i -= 1) {
    const group = group_list[i]
    if (group) {
      const label = group.getAttribute('label')
      if (label !== 'fustella') {
        group.remove()
      }
    }
  }
  const { outerHTML } = svg
  return outerHTML
}

export async function generateCutSvgCode(sender) {
  let front_code = ''
  let back_code = ''
  if (typeof window !== 'undefined') {
    const { state } = sender
    const { parsed_data } = state
    const { front, back } = parsed_data

    front_code = await getCutCode(front)
    back_code = await getCutCode(back)
  }
  return {
    front_code,
    back_code,
  }
}

export async function getRasterCode(data) {
  if (data === null) return ''
  const { svg_code, svg_size } = data
  const { mm } = svg_size
  const { width, height } = mm

  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(svg_code, 'image/svg+xml')
  const svg = svgDoc.querySelector('svg')

  document.getElementById('svg_parsing_container').appendChild(svg)

  svg.setAttribute('width', `${width}mm`)
  svg.setAttribute('height', `${height}mm`)

  const group_list = svg.querySelector('g').getElementsByTagName('g')
  for (let i = group_list.length; i >= 0; i -= 1) {
    const group = group_list[i]
    if (group) {
      const label = group.getAttribute('label')
      if (label === 'fustella') {
        group.remove()
      }
      if (label === 'guida') {
        group.remove()
      }
    }
  }

  const images = svg.querySelectorAll('image')
  for (let i = 0; i !== images.length; i += 1) {
    const img = images[i]
    const href = img.getAttribute('href')
    img.removeAttribute('href')
    img.setAttribute('xlink:href', href)
  }

  await rasterize(svg)

  svg.querySelectorAll('style').forEach(s_obj => {
    s_obj.remove()
  })

  const { outerHTML } = svg

  document.getElementById('svg_parsing_container').removeChild(svg)

  return outerHTML
}

export async function generateRasterSvgCode(sender) {
  let front_code = ''
  let back_code = ''
  if (typeof window !== 'undefined') {
    const { state } = sender
    const { parsed_data } = state
    const { front, back } = parsed_data

    front_code = await getRasterCode(front)
    back_code = await getRasterCode(back)
  }
  return {
    front_code,
    back_code,
  }
}
