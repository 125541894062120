import React from 'react'
import { connect } from 'react-redux'

import Parser from 'html-react-parser'
import * as actionCreators from '../../../store/actions'
import { mapStateToProps, retrieveComponent, translate } from '../../../utils'
import JujoButtonComponent from '../../jujo_button'

const classNames = require('classnames')

export class ConfirmationBox extends React.Component {
  handleClose = () => {
    const { comp_id } = this.props
    retrieveComponent(this, comp_id)
  }

  handleConfirm = () => {
    const { data, comp_id } = this.props
    const { executeAction } = data

    retrieveComponent(this, comp_id)
    executeAction()
  }

  render() {
    const { data } = this.props

    const { message } = data

    return (
      <div
        className={classNames(
          'position-fixed zindex-2 bg-black-opacity-2 w-100 h-100 top-0 start-0'
        )}
      >
        <div
          className={classNames(
            'position-absolute top-50 start-50 bg-light translate-middle rounded-5 p-5'
          )}
          style={{ width: 'min(95%, 450px)' }}
        >
          <div
            className={classNames('close-icon position-absolute')}
            style={{
              width: '18px',
              height: '18px',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              right: '20px',
              top: '20px',
            }}
            role="button"
            tabIndex={0}
            label="close"
            onClick={() => {
              this.handleClose()
            }}
            onKeyPress={() => {
              this.handleClose()
            }}
          />
          <div
            className={classNames(
              'd-flex flex-column jusity-content-center align-items-center'
            )}
          >
            <div
              className={classNames('question-icon-color')}
              style={{
                height: '70px',
                width: '80px',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
            <div className={classNames('my-4 text-center')}>
              {Parser(translate(message), {})}
            </div>
            <div
              className={classNames(
                'd-flex flex-column flex-md-row justify-content-center align-items-center'
              )}
            >
              <JujoButtonComponent
                bstyle={2}
                blabel={translate('no')}
                handleClick={this.handleClose}
              />
              <JujoButtonComponent
                bstyle={1}
                blabel={translate('yes')}
                handleClick={this.handleConfirm}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, actionCreators)(ConfirmationBox)
