import React from 'react'
import { connect } from 'react-redux'
import uuid from 'react-uuid'

import * as actionCreators from '../../../../store/actions'
import { mapStateToProps } from '../../../../utils'
import { GenericField } from './genericField'

const classNames = require('classnames')

export class StaticField extends GenericField {
  renderField = () => {
    const { fieldValue } = this.state
    const { field, environment, specialization } = this.props
    const { translateRenderField } = field
    const { translations } = specialization
    const { texts } = translations
    const { locale } = environment
    const html = []
    html.push(
      <div
        key={uuid()}
        className={classNames('')}
        style={{ opacity: '0.5', minHeight: '24px' }}
      >
        {translateRenderField ? texts[locale][fieldValue] : fieldValue}
      </div>
    )
    return html
  }
}

export default connect(mapStateToProps, actionCreators)(StaticField)
