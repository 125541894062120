/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/html-has-lang */
import React from 'react'
import PropTypes from 'prop-types'

export default function HTML(props) {
  const {
    headComponents,
    htmlAttributes,
    bodyAttributes,
    preBodyComponents,
    body,
    postBodyComponents,
  } = props

  const d = new Date()
  const time = d.getTime()
  const version = `?ver=${time}`

  let global_style_path = ''
  for (let i = 0; i !== headComponents.length; i += 1) {
    const comp = headComponents[i]
    const { key } = comp

    if (comp.type && comp.type === 'meta') {
      if (key.startsWith('generator')) {
        comp.props.content = 'Jujo Framework 1.0'
      }
    }
    if (comp.type && comp.type === 'style') {
      const { id } = comp.props
      if (id === 'gatsby-global-css') {
        global_style_path = `${process.env.site_url.replace(/\/$/, '')}${
          comp.props['data-href']
        }`
        headComponents.splice(i, 1)
        i -= 1
      }
    } else if (key.endsWith('.js') || key.endsWith('.json')) {
      const { href } = comp.props
      comp.props.href = `${href}${version}`
    }
  }

  for (let i = 0; i !== postBodyComponents.length; i += 1) {
    const comp = postBodyComponents[i]
    const { key } = comp

    if (key.endsWith('.js') || key.endsWith('.json')) {
      const { src } = comp.props
      comp.props.src = `${src}${version}`
    }
  }

  return (
    <html {...htmlAttributes}>
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          id="jujo_framework_style"
          type="text/css"
          rel="stylesheet"
          href={`${global_style_path}${version}`}
        />
        {headComponents}
      </head>
      <body {...bodyAttributes}>
        {preBodyComponents}
        <div
          key="body"
          id="___gatsby"
          dangerouslySetInnerHTML={{ __html: body }}
        />
        {postBodyComponents}
      </body>
    </html>
  )
}

HTML.propTypes = {
  htmlAttributes: PropTypes.object,
  headComponents: PropTypes.array,
  bodyAttributes: PropTypes.object,
  preBodyComponents: PropTypes.array,
  body: PropTypes.string,
  postBodyComponents: PropTypes.array,
}
